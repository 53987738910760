import React, { Fragment, useState } from 'react'
import { Card, Divider, Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { DrawerLine } from '../../helpers/svgConst';
import PrimaryButton from '../Login/PrimaryButton'
import USER_HELPER from '../../helpers/apis/userHelper';
import * as Web3 from "web3";
import { useSelector } from 'react-redux';
import { ADMIN_ETH_ADDRESS, CONTRACT_ADDRESS, DepositABI } from "../../constants/commonFunc"

const web3 = new Web3(Web3.givenProvider);

const DepositBlockchain = ({ cardInfo, onOpen, open, setOpen, toggleDrawer }) => {



    const metaMaskAddress = useSelector((state) => state?.user?.metaMaskAddress)
    const assetId = cardInfo?.assetId
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [loading, setLoading] = useState(false)


    const handleDepositCard = async () => {
        // checking erc721 support or not

        const controllerContract = new web3.eth.Contract(DepositABI, CONTRACT_ADDRESS);

        if (!!metaMaskAddress) {
            setLoading(true)
            controllerContract.methods
                .safeTransferFrom(metaMaskAddress, ADMIN_ETH_ADDRESS, assetId)
                .send({
                    from: metaMaskAddress,
                })
                .on("transactionHash", (hash) => {
                    console.log("transactionHash", hash);

                })
                .on("receipt", (receipt) => {
                    console.log("receipt sendToken", receipt);

                })
                .on("confirmation", (confirmationNumber, receipt) => {
                    console.log("confirmationNumber sendToken", confirmationNumber);
                    console.log("confirmation receipt", receipt);
                    setSuccessMessage("successfully deposited, but it will take some time to reflect. Please check after sometime.")
                    setLoading(false)
                    setTimeout(() => {
                        setOpen({ ['bottom']: false });
                    }, 15000)
                })
                .on("error", (error) => {
                    console.log("error sendToken", error);
                    setErrorMessage(error?.message)
                    setLoading(false)
                });
        }

    }




    const DepositBlockchainTray = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel mb-25"
            >

                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>
                <div className='p-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Deposit Blockchain</h1>
                    <Divider />

                    <p className="font14px ml-2 mt-15 mb-5">
                        OOPS!! It looks like you haven't deposited your cards yet . Please deposit your card first to accept the offers
                    </p>

                    {errorMessage &&
                        <p className='alert alert-danger font14px'>
                            {errorMessage}
                        </p>
                    }

                    {successMessage &&
                        <p className='alert alert-success font14px'>
                            {successMessage}
                        </p>
                    }

                    <PrimaryButton
                        isLoading={loading}
                        heading="Deposit"
                        primaryClassName="mt-15"
                        onClick={handleDepositCard}
                    />

                </div>
            </Box>
        )
    }




    return (
        <Fragment>
            <Drawer
                anchor={"bottom"}
                open={open["bottom"]}
                onClose={toggleDrawer("bottom", false)}
                onOpen={onOpen}
                className="swipableDrawerBlock"
            >
                {DepositBlockchainTray("bottom")}
            </Drawer>
        </Fragment>
    )
}

export default DepositBlockchain
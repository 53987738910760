// for all pages all the apis paths are defined here

import { API_BASE_URL, OPERATOR_ID } from "../constants/envConstants";

// Login Apis
export const VERIFY_OTP_API = API_BASE_URL + "user/verify-otp/" + OPERATOR_ID

//User APIs
export const GET_USER_DETAILS_API = API_BASE_URL + "user/details";
export const GET_MY_VAULT_CARDS_API = API_BASE_URL + "card/my-vault";
export const GET_MY_LISTED_CARDS_API = API_BASE_URL + "marketplace/my-listed-cards/1";
export const GET_MY_WISHLISTED_API = API_BASE_URL + "card/my-selected-cards/1/:selectionFilter";
export const GET_MY_BIDS_API = API_BASE_URL + "marketplace/my-offers";
export const WALLET_HISTORY_API = API_BASE_URL + "user/wallet-activity"
export const GET_USER_ONCHAIN_CARDS_API = API_BASE_URL + "card/my-on-chain-vault"


// Collection APIs
export const GET_COLLECTION_LIST_API = API_BASE_URL + "card/collections/" + OPERATOR_ID;
export const GET_COLLECTION_ACTIVITY_API = API_BASE_URL + "card/collection-activity/:collectionId";
export const LIKE_DISLIKE_COLLECTION_API = API_BASE_URL + "card/toggle-favorite/:collectionId/1/:status";
export const GET_ALL_FAVOURITE_COLLECTION_API = API_BASE_URL + "card/my-selected-cards/1/1";
export const GET_COLLECTION_FULL_INFO_API = API_BASE_URL + "card/collection-details/:collectionId";
export const GET_BUY_PACK_LIST_API = API_BASE_URL + "primary-sale/active";

// Primary Sale APIs
export const HANDLE_BUY_PRIMARY_FLOW = API_BASE_URL + "primary-sale/buy"

// Detail Page APIs
export const GET_CARD_FULL_INFO_API = API_BASE_URL + "card/details/:cardId";
export const LIST_YOUR_NFT_API = API_BASE_URL + "marketplace/list-card";
export const GET_NFT_LISTING_FEES_API = API_BASE_URL + "marketplace/fee/:cardId";
export const DIRECT_BUY_NFT_API = API_BASE_URL + "marketplace/direct-buy-card/:marketplaceId";
export const ALL_OFFERS_API = API_BASE_URL + "marketplace/offers/:marketplaceId";
export const HANDLE_ACCEPT_OFFER_API = API_BASE_URL + "marketplace/accept-offer/:offerId";
export const HANDLE_ACCEPT_SPECIAL_OFFER_API = API_BASE_URL + "card/accept-special-offer/:specialOfferId"
export const MAKE_AN_OFFER_API = API_BASE_URL + "marketplace/offer"
export const MAKE_OFFER_UNLISTED_CARDS = API_BASE_URL + "card/special-offer"
export const GET_UNLISTED_OFFER_LISTING_API = API_BASE_URL + "card/special-offers/:cardId"
export const CANCEL_OFFER_API = API_BASE_URL + "marketplace/cancel-offer/:marketplaceId"
export const CANCEL_OFFER_FOR_UNLISTED_NFT_API = API_BASE_URL + "card/cancel-special-offer/:specialOfferId"
export const GET_SPECIAL_OFFER_BY_USER_FOR_CARD_API = API_BASE_URL + "card/my-special-offers"

//CARD APIs
export const GET_ALL_FAVOURITE_CARDS_API = API_BASE_URL + "card/my-selected-cards";
export const SET_LIKE_DISLIKE_CARD_API = API_BASE_URL + "card/update-selection"
export const CARD_UTILITY_DETAILS_API = API_BASE_URL + "card/utilities/" + OPERATOR_ID;
export const CANCEL_NFT_LISTING = API_BASE_URL + "marketplace/cancel-list/:marketplaceId"
export const WITHDRAW_CARD_API = API_BASE_URL + "card/withdraw"

//Login Flow API
export const UPDATE_PROFILE_API = API_BASE_URL + "user/profile";
export const UPLOAD_PROFILE_IMAGE_API = API_BASE_URL + "common/getIPFS";

// Payment APIs
export const CREATE_ADD_TOKEN_ORDER_API = API_BASE_URL + "wallet/initiate-token-buy";
export const INITATE_PAYMENT_API = API_BASE_URL + "pay/initiate-payment/";
export const HANDLE_CAPTURE_PAYMENT_API = API_BASE_URL + "pay/complete-payment/";

//Marketplace APIs
export const MARKETPLACE_CARDS_API = API_BASE_URL + "marketplace/listed-cards/" + OPERATOR_ID + "/1";
export const MARKETPLACE_COLLECTION_TRANSACTIONS_API = API_BASE_URL + "marketplace/sold-cards";
export const MARKETPLACE_NOT_FOR_SALE_API = API_BASE_URL + "marketplace/unlisted-cards/" + OPERATOR_ID
export const MARKETPLACE_ONCHAIN_CARDS = API_BASE_URL + "card/on-chain-cards/" + OPERATOR_ID

//KYC Verify
export const GET_KYC_INFO_API = API_BASE_URL + "third-party/glance/user-kyc-details"
export const UPLOAD_AADHAR_CARD_API = API_BASE_URL + "third-party/glance/aadhaar-verify-document"
export const UPLOAD_PAN_CARD_API = API_BASE_URL + "third-party/glance/pan-verify-document"
export const UPLOAD_VERIFY_FACE_API = API_BASE_URL + "third-party/glance/verify-face"

// Header Apis
export const HEADER_SEARCH_CARD_API = API_BASE_URL + "card/search/" + OPERATOR_ID;

// Withdraw Flow 
export const WITHDRAW_BANK_LIST_API = API_BASE_URL + "user/banks"
export const WITHDRAW_USER_BANK_DETAILS_API = API_BASE_URL + "user/bank-details"
export const WITHDRAW_MONEY_API = API_BASE_URL + "wallet/payout/RAZ"
import React, { Fragment, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import http from "../../helpers/Interceptors"
import { API_BASE_URL, OPERATOR_ID } from "../../constants/envConstants";
import { useDispatch } from "react-redux";
import { InitiateLogin } from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { GET_ERROR_TOAST_MSG, validateEmail } from "../../constants/commonFunc";

const InputMobileNumber = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [termsCheck, setTermsCheck] = useState(true)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ status: false, message: "" })
    const [mobileNumber, setMobileNumber] = useState("")

    const handleChangeTerms = () => {
        setTermsCheck(prevState => !prevState)
    }

    const isValidEmail = validateEmail(mobileNumber)
    const loginType = isValidEmail ? 1 : 4

    const handleMobileNumberSubmit = () => {

        setErrorMessage({ status: false, message: "" })
        if (!mobileNumber) {
            setErrorMessage({ status: true, message: "Please input email first" })
            return
        }
        if (!termsCheck) {
            setErrorMessage({ status: true, message: "Please accept terms and conditions" })
            return
        }

        setLoading(true)
        const formData = {
            "loginType": loginType,
            "data": mobileNumber
        }

        http.post(API_BASE_URL + "user/onboard/" + OPERATOR_ID, formData)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(InitiateLogin({ phone: mobileNumber }))
                    navigate("/login/otp", { replace: true })
                }
            })
            .catch(function (error) {
                const errorMsg = GET_ERROR_TOAST_MSG(error)
                setErrorMessage({ status: true, message: "Failed to send OTP" + errorMsg })
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const handleMobileNumberChange = (e) => {

        setMobileNumber(e.target.value)


    }


    return (
        <Fragment>
            <div className="pi-5 mt-30 pos-rel">
                {/* <span className="appendText">+91</span> */}
                <input type="email" className="enterNumber px-4" placeholder="Enter your email" id="mobileNumb" value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            {errorMessage?.status &&
                <p className="form-error">
                    {errorMessage.message}
                </p>
            }
            <div className={`primaryBtn-block mt-10`}>
                <PrimaryButton heading="Continue"
                    isLoading={loading}
                    onClick={handleMobileNumberSubmit} />
            </div>
            <div className="mt-30 checkbox-box">
                <input
                    checked={termsCheck}
                    onChange={handleChangeTerms}
                    className="styled-checkbox"
                    id="styled-checkbox-1"
                    type="checkbox"
                    value="value1"
                />
                <label htmlFor="styled-checkbox-1" />
                <p className="font12px lightmode-text-gray w-80">
                    By clicking continue you agree to our{" "}
                    <span className="font12pxMedium lightmode-text-primary">
                        Terms of Use
                    </span>{" "}
                    and acknowledge that you have read our{" "}
                    <span className="font12pxMedium lightmode-text-primary">
                        Privacy Policy
                    </span>
                </p>
            </div>
        </Fragment>
    );
};

export default InputMobileNumber;

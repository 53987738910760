import React, { Fragment } from 'react'
import { TimerIcon, VerifiedIcon } from "../../helpers/svgConst"
import imageBG from "../../images/CollectionImage/background.png"
import "../Listing/ListingStyles.css"
import { CheckWAXImage, convertToSlug, PRICE_FORMAT, TIME_RENDERER } from "../../constants/commonFunc";
import { useNavigate } from 'react-router-dom';
import GlanceImage from "./GlanceImage";
import Countdown from "react-countdown";
import PrimaryButton from '../Login/PrimaryButton';

const UserNFTTemplate = ({ shopData, type }) => {

    const navigate = useNavigate()

    const handleGoToDetailNFT = (key) => {
        if (type === "PACKS") {
            navigate(`/unpack/${convertToSlug(key?.name)}/${key?.collectionId}/${key?.cardId}`)
        } else {
            navigate("/card/detail/" + key?.cardId)
        }

    }


    return (
        <Fragment>
            {shopData && shopData.length > 0 && shopData.map((key) => {
                const marketInfo = key?.marketplaceDetails
                return (
                    <Fragment key={key?.cardId}>
                        <div className={`main-white-card p-0 mt-10 mb-10 br-10`}
                            onClick={() => handleGoToDetailNFT(key)}>
                            <div className='inside-white-card p-1 white-list-block'>
                                <div className='listingImageBlock'>
                                    <GlanceImage src={key?.image ? CheckWAXImage(key?.image) : imageBG} alt="thumb" />
                                </div>

                                <div className='d-flex flex-column mt-1'>
                                    <p className='font12pxMedium lightmode-text-midnight-blue overflowText max-w-160'>{key?.name}</p>
                                    <div className='d-flex align-items-center mt-1'>
                                        <VerifiedIcon width="12" height="12" />
                                        <span className='font10px ml-1 lightmode-text-light-gray'>
                                            #{key?.mintNumber} | {key?.collectionDisplayName}
                                        </span>
                                    </div>
                                    {!!key?.listedPrice &&
                                        <p className='font12px lightmode-text-marigold mt-2'>
                                            <span className='font12pxMedium'>$ </span>
                                            {PRICE_FORMAT(key?.listedPrice)}
                                        </p>
                                    }
                                </div>
                                <div className='d-flex flex-column'>

                                    {/* <GoldTierIcon className="ml-auto"/> */}

                                    {key?.listingType === 6 &&
                                        <p className='font8px mt-auto ml-auto lightmode-text-blue d-flex gap-10 nowrap'>
                                            Rental price:<span
                                                className='font10px'>$ {PRICE_FORMAT(key?.listedPrice)}</span>
                                        </p>
                                    }

                                    {key?.listingType !== 6 && !key?.marketplaceDetails?.expiryTime && !key?.offeredPrice && !!key?.listedPrice &&
                                        <p className='font8px mt-auto ml-auto lightmode-text-blue d-flex gap-10 nowrap'>
                                            Fixed price:<span
                                                className='font10px'>$ {PRICE_FORMAT(key?.listedPrice)}</span>
                                        </p>
                                    }

                                    {!!key?.marketplaceDetails?.expiryTime &&
                                        <p className='font8px mt-auto ml-auto lightmode-text-blue d-flex gap-10 nowrap'>
                                            Auction starts at:<span
                                                className='font10px'>$ {PRICE_FORMAT(key?.listedPrice)}</span>
                                        </p>
                                    }
                                    {type === "PACKS" &&
                                        <PrimaryButton heading="Unpack" primaryClassName='font10px py-2 px-3 mt-auto ml-auto mr-2 mb-2 d-flex gap-10 nowrap w-fit' />


                                    }


                                    {/*{key?.offeredPrice &&
                                    <p className='font8px mt-auto ml-auto lightmode-text-blue d-flex gap-10 nowrap'>
                                        offered Price at:<span className='font10px'>$ {PRICE_FORMAT(key?.offeredPrice)}</span>
                                    </p>
                                    }*/}
                                </div>

                            </div>

                            {!!marketInfo && !!key?.marketplaceDetails?.expiryTime &&
                                <div className='bottom-card-bar'>


                                    <div className='d-flex align-items-center'>
                                        {!!key?.marketplaceDetails?.expiryTime &&
                                            <Fragment>
                                                <TimerIcon className="mr-1" />
                                                <p className='font12pxSemiBold lightmode-text-blue'>
                                                    <Countdown
                                                        date={key?.marketplaceDetails?.expiryTime}
                                                        zeroPadDays={1}
                                                        renderer={TIME_RENDERER}
                                                    />
                                                </p>
                                            </Fragment>
                                        }
                                    </div>


                                    {!!marketInfo?.offers && Array.isArray(marketInfo?.offers) && marketInfo?.offers.length > 0 &&
                                        <div className='d-flex align-items-center gap-10'>
                                            <p className='font8px'>Highest Offer:</p>
                                            <p className='font14pxSemiBold hotpink-clr'>
                                                $ {marketInfo?.offers[0]?.price ? PRICE_FORMAT(marketInfo?.offers[0]?.price) : ""}
                                            </p>
                                            {marketInfo?.offers.length > 1 &&
                                                <p className='font8pxSemiBold lightmode-text-blue'>+ {marketInfo.offers.length - 1} more</p>
                                            }
                                        </div>
                                    }

                                    {!!marketInfo?.offers && Array.isArray(marketInfo?.offers) && marketInfo?.offers.length === 0 &&
                                        <div className='d-flex align-items-center gap-10'>
                                            <p className='font8pxSemiBold lightmode-text-blue'>
                                                No offers yet!
                                            </p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </Fragment>
                )
            })}
        </Fragment >
    )
}

export default UserNFTTemplate

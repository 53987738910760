import React, { Fragment } from 'react'
import { VerifiedIcon } from "../../helpers/svgConst"
import imageBG from "../../images/CollectionImage/background.png"
import "./ListingStyles.css"
import { useNavigate } from "react-router-dom";
import { CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import GlanceImage from '../Common/GlanceImage';

const NFTListViewDesign = ({ shopData }) => {

    const navigate = useNavigate()

    const handleGoToDetailNFT = (cardId) => {
        navigate("/card/detail/" + cardId + "?type=list")
    }

    return (
        shopData && shopData.length > 0 && shopData.map((key) => {
            return (
                <Fragment key={key?.id}>
                    <div className={` mb-10`}
                        onClick={() => handleGoToDetailNFT(key?.id)}>
                        <div className='inside-white-card white-list-block'>
                            <div className='listingImageBlock'>
                                <GlanceImage src={key?.image ? CheckWAXImage(key?.image) : imageBG} alt="thumb" />
                            </div>
                            <div className='d-flex flex-column'>
                                <p className='font12px lightmode-text-midnight-blue'>{key?.name}</p>
                                <div className='d-flex align-items-center mt-1'>
                                    <VerifiedIcon width="12" height="12" />
                                    <span className='font10px ml-1 lightmode-text-light-gray checkoutNFTNameOverflow'>
                                        #{key?.mintNumber}/ {key?.totalMint} | {key?.schemeName}
                                    </span>
                                </div>
                                {/* <p className='font8px mt-auto lightmode-text-light-gray'>Purchase date: 12 May, 2022</p> */}
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='goldExpBlock d-flex'>
                                    {/*<p className='font8pxSemiBold mr-1 lightmode-text-secondary'> +590</p>*/}
                                    {/*<GoldExpCoinIcon />*/}
                                </div>
                                {key.listedPrice &&
                                    <p className='font14pxSemiBold mt-auto ml-auto lightmode-text-blue'>
                                        <span>$ </span>
                                        {PRICE_FORMAT(key?.listedPrice)}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        })
    )
}

export default NFTListViewDesign

import React, { Fragment, useEffect, useState } from 'react'
import "./WalletStyles.css"
import http from "../../helpers/Interceptors";
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    CheckWAXImage,
    DATE_FORMAT,
    PRICE_FORMAT
} from "../../constants/commonFunc";
import { WALLET_HISTORY_API } from "../../helpers/ApisPath";
import NFTSkeleton from "../Common/Skeleton/NFTSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from '../Common/NoDataFound';
import { DrawerLine, VerifiedIcon } from "../../helpers/svgConst";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import GlanceImage from '../Common/GlanceImage';

const PaymentHistoryDetail = () => {

    const [loading, setLoading] = useState("")
    const [data, setData] = useState([])
    const [totalProduct, setTotalProduct] = useState(4)
    const PAGE_LIMIT = 20
    const [groupedData, setGroupedData] = useState([])
    const [drawerData, setDrawerData] = useState({})

    const [openState, setOpenState] = useState({
        bottom: false,
    });

    const getHistoryType = (historyType) => {
        let typeAdd = ""
        let typeText = ""
        if (!historyType) return
        let type = parseInt(historyType)
        if (type === 1) {
            typeAdd = "+"
            typeText = "Incoming Transfer"
        }
        if (type === 2) {
            typeAdd = "-"
            typeText = "Outgoing Transfer"
        }
        if (type === 3) {
            typeAdd = ""
            typeText = "List Card"
        }
        if (type === 4) {
            typeAdd = ""
            typeText = "Cancel list"
        }
        if (type === 5) {
            typeAdd = "-"
            typeText = "Added Offer"
        }
        if (type === 6) {
            typeAdd = "+"
            typeText = "Offer Cancel"
        }
        if (type === 7) {
            typeAdd = "-"
            typeText = "Direct Buy"
        }
        if (type === 8) {
            typeAdd = "+"
            typeText = "Offer Accept"
        }
        if (type === 9) {
            typeAdd = ""
            typeText = "Card Deposit"
        }
        if (type === 10) {
            typeAdd = ""
            typeText = "Card Withdraw"
        }
        if (type === 11) {
            typeAdd = ""
            typeText = "Gift Send"
        }
        if (type === 12) {
            typeAdd = ""
            typeText = "Gift Received"
        }
        if (type === 13) {
            typeAdd = "+"
            typeText = "Direct Sold"
        }
        if (type === 14) {
            typeAdd = ""
            typeText = "Burn"
        }
        if (type === 15) {
            typeAdd = ""
            typeText = "Mint"
        }
        if (type === 16) {
            typeAdd = ""
            typeText = "Unpacked Card"
        }
        if (type === 17) {
            typeAdd = ""
            typeText = "Unpacked Received Card"
        }
        if (type === 18) {
            typeAdd = ""
            typeText = "Offer Expired"
        }
        if (type === 19) {
            typeAdd = ""
            typeText = "Add Special Offer"
        }
        if (type === 20) {
            typeAdd = ""
            typeText = "Lent"
        }
        if (type === 21) {
            typeAdd = ""
            typeText = "Borrowed"
        }
        if (type === 22) {
            typeAdd = ""
            typeText = "Returned"
        }
        if (type === 23) {
            typeAdd = ""
            typeText = "Settled"
        }
        if (type === 24) {
            typeAdd = ""
            typeText = "Accept Counter Offer"
        }
        return { typeAdd, typeText }
    }


    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (data.length === 0) return 1
        return data
            ? Math.ceil(data.length / PAGE_LIMIT) + 1
            : 1;
    }

    const getWalletHistoryData = (reloadSearch = true) => {
        setLoading(API_FETCH_START)
        const pageNo = getPageNo(reloadSearch)
        const queryParam = `?page=${pageNo}&limit=${PAGE_LIMIT}`
        http.get(WALLET_HISTORY_API + queryParam)
            .then((res) => {
                let mergedFinalProducts = []
                const apiData = res?.data?.data;
                const apiProductList = apiData?.walletActivity || [];
                const apiProductCount = apiData.count || 10;
                setTotalProduct(apiProductCount)
                mergedFinalProducts = [...data, ...apiProductList];
                const formattedData = groupByDate(mergedFinalProducts)
                setData(mergedFinalProducts);
                setGroupedData(formattedData)

            }).catch((err) => console.error("wallet history integrated", err))
            .finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })

    }

    const fetchMoreItems = () => {
        if (data.length < totalProduct) {
            getWalletHistoryData(false);
        }
    }

    useEffect(() => {
        getWalletHistoryData()
    }, [])

    const groupByDate = (arr) => {
        if (arr.length === 0) return
        const groups = arr.reduce((groups, game) => {
            const date = game.created.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(game);
            return groups;
        }, {});
        return Object.keys(groups).map((date) => {
            return {
                date,
                history: groups[date]
            };
        });
    }


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenState({ ...openState, [anchor]: open });
    };

    const PaymentHistoryData = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                className="pos-rel"
            >
                <List>
                    <div className='text-center'>
                        <DrawerLine className="drawerLine" />
                    </div>
                    <ListItem disablePadding>

                        <ListItemText>
                            <div className='pi-15 soraFont'>
                                <div className='mt-10 d-flex justify-content-between mb-10'>
                                    <p className='font16pxBold lightmode-text-blue'>
                                        {drawerData?.cardName}
                                    </p>
                                    <p className='font10px lightmode-text-light-blue'>
                                        <VerifiedIcon width="10" height="10" className="mr-2" />
                                        {drawerData?.collectionDisplayName}
                                    </p>
                                </div>
                                <Divider />
                                <div className='d-flex gap-10 w-100 mt-25'>
                                    <div className='transactionDrawerImgBlock'>
                                        <GlanceImage src={CheckWAXImage(drawerData?.cardImage)} alt="thumb" />
                                    </div>
                                    <div className='detailblock w-100'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <p className='font14pxSemiBold lightmode-text-blue'>#{drawerData?.mintNumber}
                                                {/*<span className='font10px'>/9999</span>*/}
                                            </p>
                                            {/*<p className='font10px lightmode-text-light-gray'>2 weeks ago</p>*/}
                                        </div>

                                        <p className='font10px lightmode-text-light-gray mt-15'>Sold for:</p>
                                        <p className='font14pxSemiBold lightmode-text-blue'>$ {PRICE_FORMAT(drawerData?.amount)}</p>
                                    </div>
                                </div>
                                {drawerData?.buyer || drawerData?.seller &&
                                    <div className='buyerSellerBottomBlock mt-15'>
                                        <div className='buyer'>
                                            <p className='font10px lightmode-text-light-gray'>Buyer</p>
                                            <p className='font12px nftNameOverflow max-w-120'>{drawerData?.buyer}</p>
                                        </div>
                                        <div className='seller'>
                                            <p className='font10px lightmode-text-light-gray'>Seller</p>
                                            <p className='font12px nftNameOverflow max-w-120'>{drawerData?.seller}</p>
                                        </div>
                                    </div>
                                }
                                <div className='buyerSellerBottomBlock mt-15'>
                                    <p className="font12px lightmode-text-blue">
                                        Wallet balance after this transaction
                                    </p>
                                    <p className="font14pxSemiBold lightmode-text-green text-center">$ {PRICE_FORMAT(drawerData?.balance)}</p>
                                </div>
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Box>
        )
    }

    return (
        <Fragment>

            <div className='paymentHistoryDetailBlock '>
                <InfiniteScroll
                    next={fetchMoreItems}
                    hasMore={data.length < totalProduct}
                    dataLength={data.length}
                    loader={(loading !== API_FETCH_COMPLETE) &&
                        <NFTSkeleton count={10} imageHeight={40} className={"mt-3 mb-4"} />
                    }>

                    {groupedData && groupedData.length > 0 && groupedData.map((group, index) => {
                        const dateFormatted = DATE_FORMAT(group?.date)
                        return (
                            <Fragment key={index}>
                                <p className='font10pxBold lightmode-text-blue text-center pt-15 mb-10 bdr-btm mt-3'>

                                    {dateFormatted?.day + " " + dateFormatted?.monthShort + " " + dateFormatted?.year + " "}
                                </p>
                                {group?.history && group?.history.length > 0 && group.history.map((key, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className='historyDetailGrid'
                                                onClick={() => setDrawerData(key)}
                                                onClickCapture={toggleDrawer("bottom", true)}>
                                                <div>
                                                    {/*<p className='font10px lightmode-text-midnight-gray'>*/}
                                                    {/*    {getHistoryType(key?.type).typeText}*/}
                                                    {/*</p>*/}
                                                    <p className='font12px lightmode-text-midnight-blue bdr-btm'>
                                                        {getHistoryType(key?.type).typeText}
                                                    </p>
                                                </div>
                                                <p className={`font12px lightmode-text-midnight-blue text-right pr-15 bdr-btm ${getHistoryType(key?.type).typeAdd === "+" ? "lightmode-text-green" : ""}`}>{getHistoryType(key?.type).typeAdd}  $
                                                    {PRICE_FORMAT(key?.amount)}
                                                </p>
                                            </div>
                                        </Fragment>
                                    )
                                })}

                            </Fragment>
                        )
                    })}
                </InfiniteScroll>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={openState["bottom"]}
                    onClose={toggleDrawer("bottom", false)}
                    onOpen={toggleDrawer("bottom", true)}
                    className="swipableDrawerBlock"
                >
                    {PaymentHistoryData("bottom")}
                </SwipeableDrawer>

                {(loading === API_FETCH_COMPLETE) && data?.length === 0 &&
                    <NoDataFound text="No transactions made so far" />
                }

            </div>

        </Fragment>
    )
}

export default PaymentHistoryDetail

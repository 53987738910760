import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { API_BASE_URL } from '../../../../constants/envConstants';

import TableDataGrid from '../DataGrid/DataGrid';

const WalletDetails = () => {


    const [loading, setLoading] = useState(false)
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [count, setCount] = useState(0)

    const tableColumns = [

        {
            field: 'balance',
            headerName: 'Balance',
            minWidth: 100,
            flex: 1,
        },

        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 100,
            flex: 1,
            hide: true,
        },
        {
            field: 'lockedBalance',
            headerName: 'LockedBalance',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'vaccount',
            headerName: 'Vaccount',
            minWidth: 100,
            flex: 1,
        }


    ]

    const maxPage = Math.ceil(maxResult / pageLimit)

    const getWalletDetails = (limit = 10) => {
        setLoading(true)
        axios
            .get(API_BASE_URL + "reports/wallet-details" + "?page=" + activePage + "&limit=" + limit)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.walletReportData


                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                balance: !!key?.balance ? parseFloat(key?.balance) : 0,
                                lockedBalance: !!key?.lockedBalance ? parseFloat(key?.lockedBalance) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    console.log('finaldata', finalData)
                    setCount(res.data.data.count)
                }
            })
            .catch((err) => {
                setCount(0);
                setTableData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePageChange = (e) => {
        setActivePage(e + 1)
    }

    useEffect(() => {
        getWalletDetails()
    }, [])
    useEffect(() => {
        getWalletDetails()
    }, [activePage])


    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">Wallet Details</h2>
                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" handlePageChange={handlePageChange} activePage={activePage} count={count} />
                </div>
                {/* <div className="d-flex justify-content-center mt-5 mb-3">
                    <PaginationComponent handleChange={e => getWalletDetails(e)} maxPage={maxPage} />
                </div> */}
            </div>
        </Fragment >
    )
}

export default WalletDetails

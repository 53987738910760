import React, { Fragment, useState } from 'react'
import PrimaryButton from '../Login/PrimaryButton'
import "./CommonStyles.css"
import { API_FETCH_COMPLETE, API_FETCH_START } from "../../constants/commonFunc";
import http from "../../helpers/Interceptors";
import { UPDATE_PROFILE_API } from "../../helpers/ApisPath";
import USER_HELPER from "../../helpers/apis/userHelper";

const UpdatesNotification = () => {

    const { loginUserInfo } = USER_HELPER()
    const [loading, setLoading] = useState(API_FETCH_COMPLETE)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [email, setEmail] = useState("")

    const handleSubmit = () => {
        setLoading(API_FETCH_START)
        setSuccessMessage("")
        setErrorMessage("")
        const formData = {
            ...(!loginUserInfo?.email ? { "email": email } : {})
        }
        http.post(UPDATE_PROFILE_API, formData)
            .then((res) => {
                if (res.status === 200) {
                    setSuccessMessage("E-mail successfully updated")
                }
            }).catch((err) => {
                // const errMsg = GET_ERROR_TOAST_MSG(err)
                setErrorMessage({ status: true, message: "Failed to update profile info" })
            }).finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })
    }

    return (
        <Fragment>
            {!loginUserInfo?.email &&
                <section className='updatesNotifyBlock mt-50'>
                    <div className='main-white-card'>
                        <p className='font12px'>Enter your e-mail address, so that we can notify you once your listing
                            sells!</p>
                        <label htmlFor='email' className='font10px mt-15'>Email address</label>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            className='listInput font16px w-100' id="email" type="email" placeholder='abcd@example.com' />
                        <div className="mt-25 checkbox-box">
                            <input
                                className="styled-checkbox"
                                id="styled-checkbox-1"
                                type="checkbox"

                            />
                            <label htmlFor="styled-checkbox-1" className='font10px'>
                            </label>
                            <p className="font10px w-80">
                                Send me occassional updates about new drops on Owens
                            </p>
                        </div>
                        {successMessage &&
                            <p className="form-success mt-4">
                                {successMessage}
                            </p>
                        }
                        {errorMessage?.state &&
                            <p className="form-error mt-4">
                                {errorMessage?.message}
                            </p>
                        }
                        <PrimaryButton heading="Submit" primaryClassName="mt-25 p-15" onClick={handleSubmit}
                            isLoading={loading !== API_FETCH_COMPLETE} />
                    </div>
                </section>
            }
        </Fragment>
    )
}

export default UpdatesNotification

import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "../../components/MarketPlace/MarketplaceStyles.css";
import "../../components/Collection/CollectionStyles.css";
import OwensHeader from "../../components/Common/OwensHeader";
import { FilterIcon, NewSortIcon, VerifiedIcon } from "../../helpers/svgConst";
import CollectionNFTCard from "../../components/Common/CollectionNFTCard";
import PrimaryButton from "../../components/Login/PrimaryButton";
import GlanceLoader from "../../helpers/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import http from "../../helpers/Interceptors";
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    changeURLLastStringMarketplace,
    GET_ALL_QUERY_PARAMS,
    SEND_PRICE_FORMAT,
} from "../../constants/commonFunc";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../helpers/Hooks/useOnClickOutside";
import { MARKETPLACE } from "../../constants/envConstants";
import AllTierMenu from "../../components/Common/AllTierMenu";
import NoDataFound from "../../components/Common/NoDataFound";
// import GlanceImage from '../Common/GlanceImage';
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import MarketplaceFilter from "../../components/MarketPlace/MarketplaceFilter";
import {
    MarketplaceAPICallStart,
    SetMarketplaceSchemaListAction,
    ShowHideMarketplaceFiltersListAction,
    ShowHidePointsFilter
} from "../../redux/actions/marketplaceActions";
import SortMenu from "../../components/Common/SortMenu";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import { MARKETPLACE_CARDS_API, MARKETPLACE_NOT_FOR_SALE_API } from "../../helpers/ApisPath";
import { handleChangeTierSchemaValueAction } from "../../redux/actions/commonAction";
import { MARKETPLACE_PAGE_TIER_SCHEMA } from "../../redux/action-types/common.type";
import ActiveFilters from "../../components/MarketPlace/ActiveFilters";

const Marketplace = () => {

    const dispatch = useDispatch()

    const ref = useRef(null);
    const PRICE_FILTER = useSelector((state) => state?.marketplace?.price)
    const RECALL_API = useSelector((state) => state?.marketplace?.apiCallStart)
    const API_FULL_PATH = useSelector((state) => state?.marketplace?.apiFullPath)
    const SCHEMA_LIST = useSelector((state) => state?.marketplace?.schemaList)
    const pointsRange = useSelector((state) => state?.marketplace?.pointsRange)
    const location = useLocation();
    const queryParams = GET_ALL_QUERY_PARAMS(location?.search);
    const COLLECTION_NAME = queryParams?.collection
        ? queryParams?.collection
        : "";

    const activeSchema = useSelector((state) => state?.common?.marketPlacePageTierSchema)
    const activeSortValue = useSelector((state) => state?.marketplace?.sortValue);
    const activeSortCustomText = useSelector((state) => state?.marketplace?.customSortText)

    const PAGE_LIMIT = 10;
    const [loading, setLoading] = useState("");
    const [productList, setProductList] = useState([]);
    const [totalProduct, setTotalProduct] = useState(0);
    const [openSortMenu, setOpenSortMenu] = useState(false);
    const isVisibleFilter = useSelector((state) => state?.marketplace?.isVisibleFilters)
    const [collectionInfo, setCollectionInfo] = useState({})
    const [customSortList, setCustomSortList] = useState([])

    const { getCollectionFullInfoById } = COLLECTION_HELPER()


    const handleSortMenuClickOutside = useCallback(() => {
        if (openSortMenu) {
            setOpenSortMenu(false);
        }
    }, [openSortMenu]);

    const handleToggleSort = () => {
        setOpenSortMenu(!openSortMenu);
    };

    const [filterState, setFilterState] = useState({
        right: isVisibleFilter,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setFilterState({ ...filterState, [anchor]: open });
    };
    const getSortByParams = () => {

        const customSortText = activeSortCustomText ? "&customSortByType=" + activeSortCustomText : ""
        return activeSortValue && activeSortValue.toString().length >= 2
            ? "&sortByType=" + activeSortValue[0] + "&sortBy=" + activeSortValue[1] + customSortText
            : "";
    };

    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1;
        if (productList.length === 0) return 1;
        return productList ? Math.ceil(productList.length / PAGE_LIMIT) + 1 : 1;
    };


    const getMarketPlaceCards = (reloadSearch = false) => {
        setLoading(API_FETCH_START);

        const priceMax = !!PRICE_FILTER?.max ? `&price=${SEND_PRICE_FORMAT(PRICE_FILTER?.max).toString()}` : "&price=1320943204983243943280293"
        const priceParam = `&price=${SEND_PRICE_FORMAT(PRICE_FILTER?.min).toString()}${priceMax}`
        const sortParam = getSortByParams();
        const apiPage = getPageNo(reloadSearch);
        const schemaParam = !!activeSchema ? "&schemaId=" + activeSchema : ""
        const collectionParam = "&collectionId=" + COLLECTION_NAME
        const pointsRangeParam = (!!pointsRange?.max) ? `&filterData=points:${pointsRange?.min}|${pointsRange?.max}` : ""




        let apiPath = ""

        if (API_FULL_PATH !== MARKETPLACE_NOT_FOR_SALE_API) {
            apiPath = API_FULL_PATH +
                "?page=" +
                apiPage +
                "&limit=" +
                PAGE_LIMIT +
                collectionParam +
                schemaParam +
                sortParam +
                priceParam +
                pointsRangeParam
        }

        if (API_FULL_PATH === MARKETPLACE_NOT_FOR_SALE_API) {
            apiPath = API_FULL_PATH +
                "?page=" +
                apiPage +
                "&limit=" +
                PAGE_LIMIT +
                schemaParam +
                sortParam
        }


        http
            .get(apiPath)
            .then((res) => {
                let mergedFinalProducts = [];
                const apiData = res?.data?.data
                const apiProductList = apiData?.lists;
                const apiProductCount = apiData?.count;
                const apiSchemaList = apiData?.schemaList
                const apiFilterList = apiData?.filterList

                //checking points range filter option available or not 
                const pointsRangeShow = apiFilterList.some((key) => {
                    return key.data.includes("points")
                })


                setTotalProduct(apiProductCount);
                //when data is selected with different filter
                if (reloadSearch) {
                    dispatch(SetMarketplaceSchemaListAction(apiSchemaList))
                    setProductList(apiProductList);
                    //custom sorting option list
                    setCustomSortList(apiFilterList)
                    dispatch(ShowHidePointsFilter(pointsRangeShow))
                } else {
                    // when data is selected with same filter but page is change
                    // only params are same
                    mergedFinalProducts = [...productList, ...apiProductList];
                    setProductList(mergedFinalProducts);
                }
            })
            .catch((err) => console.error("failed to fetch marketplace data", err))
            .finally(() => {
                setLoading(API_FETCH_COMPLETE);
                dispatch(MarketplaceAPICallStart(false))
            });
    };

    const fetchMoreItems = () => {
        if (productList.length < totalProduct) {
            getMarketPlaceCards(false);
        }
    };

    const getFilterCountNumber = () => {
        let count = 0;
        console.log("price filter",)
        if (API_FULL_PATH === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 1)) {
            count += 2;
        } else {
            count += 1;
        }

        if (!!activeSchema) {
            count += 1
        }
        if (PRICE_FILTER?.min > 0 || !!PRICE_FILTER.max) {
            count += 1
        }

        return count
    }

    const customSortOptions = useCallback(() => {
        const filteredData = customSortList.find((key) => {
            return key?.name === "sort"
        })
        if (!!filteredData) {
            return filteredData?.data
        }

    }, [customSortList])

    useEffect(() => {
        getMarketPlaceCards(true);
    }, []);


    useEffect(() => {
        // when filter will change then this function will be executed
        if (RECALL_API) {
            setProductList([])
            setTotalProduct(0)
            getMarketPlaceCards(true);
        }
    }, [RECALL_API])

    const handleSchemaClear = useCallback(() => {
        dispatch(handleChangeTierSchemaValueAction(MARKETPLACE_PAGE_TIER_SCHEMA, ""))
        dispatch(MarketplaceAPICallStart(true))
    }, [])


    useOnClickOutside(ref, handleSortMenuClickOutside);


    const FilerList = (anchor) => {
        return (
            <Box
                role="presentation"
                className="pos-rel"
            >
                <div className="lightMode">
                    <MarketplaceFilter />
                </div>
            </Box>
        )
    }

    useEffect(() => {
        //fetching collection info
        if (!COLLECTION_NAME) return
        getCollectionFullInfoById(COLLECTION_NAME)
            .then((res) => {
                console.log("Res", res)
                setCollectionInfo(res?.data?.data?.collectionData)
            }).catch((err) => {
                console.error("failed to fetch info", err)
            })
    }, [COLLECTION_NAME])


    return (
        <Fragment>
            <OwensHeader />
            <section className="lightMode">
                <div className="p-15">
                    <h1 className="font16pxSemiBold lightmode-text-blue pt-15">
                        {collectionInfo?.collectionDisplayName}
                    </h1>
                    {collectionInfo?.author &&
                        <>
                            <h5 className="font10px lightmode-text-light-gray d-inline mr-2">
                                {collectionInfo?.author}
                            </h5>
                            <VerifiedIcon width="10" height="10" />
                        </>
                    }
                </div>
                <div className="marketFilterScrollBlock mt-2 pl-15">
                    <ActiveFilters type="page" />
                </div>
                <div className="p-15 pb-100">
                    <div className="main-white-card px-2">
                        <div className="d-flex justify-content-between align-items-center pos-rel">
                            <AllTierMenu
                                schemaValue={activeSchema}
                                schemaList={SCHEMA_LIST}
                                page={MARKETPLACE} />


                            <div ref={ref}>
                                <button className="btn" onClick={handleToggleSort}>
                                    <NewSortIcon />
                                </button>
                                <SortMenu
                                    customSortOptions={customSortOptions()}
                                    activeSortValue={activeSortValue}
                                    className={`${openSortMenu ? "openSortBlock right-0" : "closeSortBlock"}`}
                                    handleSortMenuClickOutside={handleSortMenuClickOutside}
                                />
                            </div>
                        </div>

                        <div className="mt-30">
                            <InfiniteScroll
                                next={fetchMoreItems}
                                hasMore={productList.length < totalProduct}
                                dataLength={productList.length}
                                loader={
                                    ((productList.length < totalProduct) && (loading !== API_FETCH_COMPLETE)) && (
                                        <div className="col-md-12 text-center mt-4 mb-4">
                                            <GlanceLoader
                                                text={`Loading next ${PAGE_LIMIT}  Items from this collection`}
                                            />
                                        </div>
                                    )
                                }
                            >
                                <div className="NFT-card-sec">
                                    <CollectionNFTCard
                                        data={productList}
                                        loading={loading}
                                    />
                                </div>
                            </InfiniteScroll>

                            {(loading === API_FETCH_COMPLETE) && productList.length === 0 &&
                                <NoDataFound image={true} text="We don't have any NFTs." />
                            }

                        </div>
                    </div>
                </div>
                <div className="bottomBtnBlock fixedButton left-0 right-0">
                    <PrimaryButton
                        onClick={() => dispatch(ShowHideMarketplaceFiltersListAction(true))}
                        heading={`Filters (${getFilterCountNumber()})`}
                        primaryClassName="w-fit px-5 py-2 mx-auto font12px"
                        icon={<FilterIcon color="white" className="mr-2" />}
                    />
                </div>
                <Drawer
                    anchor={"right"}
                    open={isVisibleFilter}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                    className="swipableDrawerBlock"
                >
                    {FilerList("right")}
                </Drawer>
            </section>
        </Fragment>
    );
};

export default Marketplace;

import { useEffect, useState } from "react"
import http from "../../helpers/Interceptors"
import { WITHDRAW_BANK_LIST_API } from "../ApisPath"
const WITHDRAW_HELPER = () => {


    const getUserBanks = () => {
        return http.get(WITHDRAW_BANK_LIST_API)
            .then((res) => {
                return res
            }).catch((err) => {
                console.error("failed to fetch bank info", err)
                return err
            })
    }


    return { getUserBanks }
}

export default WITHDRAW_HELPER
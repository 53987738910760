import React, { Fragment, useEffect } from 'react'
import { EmptyWallet, GoBackIcon } from '../../helpers/svgConst'
import USER_HELPER from "../../helpers/apis/userHelper";
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import { PRICE_FORMAT } from "../../constants/commonFunc";


const SubHeader = () => {

    const { loginUserInfo, handleGoBack, getUserDetails, isUserLogin } = USER_HELPER()
    const navigate = useNavigate()
    const handleGoToWalletPage = () => {
        navigate(Paths.WalletPage)
    }

    useEffect(() => {
        getUserDetails()
    }, [isUserLogin])

    return (
        <Fragment>
            <section className='d-flex justify-content-between align-items-center pt-30'>
                <button className='btn p-1' onClick={handleGoBack}>
                    <GoBackIcon />
                </button>
                <div className='d-flex ml-auto'>
                    <button className='btn emptyWalletBlock' onClick={handleGoToWalletPage}>
                        <div className='d-flex align-items-center'>
                            <EmptyWallet />
                            <p className='ml-10 font12px hotpink-clr'>$ {loginUserInfo?.balance ? PRICE_FORMAT(loginUserInfo?.balance) : 0}</p>
                        </div>
                    </button>
                </div>

                {/* <button className='btn lightmode-text-secondary font12px'>
                    <CopyToClipboardIcon className="mr-10" />
                    {loginUserInfo?.name ? loginUserInfo?.name : loginUserInfo?.vaccount}
                </button> */}
            </section>
        </Fragment>
    )
}

export default SubHeader

import React, { Fragment } from 'react'
import GlanceImage from '../Common/GlanceImage';
import { DrawerLine, VerifiedIcon } from "../../helpers/svgConst";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import NFTSkeleton from '../Common/Skeleton/NFTSkeleton';
import NoDataFound from '../Common/NoDataFound';
import { API_FETCH_COMPLETE, CheckWAXImage, PRICE_FORMAT } from '../../constants/commonFunc';
import { useDispatch, useSelector } from "react-redux";
import { ShowTransactionCardFullInfoAction } from "../../redux/actions/collectionAction";


const TransactionCardDetail = ({ data, loading }) => {

    const dispatch = useDispatch()
    const transactionFullInfo = useSelector((state) => state?.collectionDetail?.transactionCardFullInfo)

    const [state, setState] = React.useState({
        bottom: false,
    });

    const handleClickTransaction = (info) => {
        dispatch(ShowTransactionCardFullInfoAction(info))

    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const ListingFeeData = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                className="pos-rel"
            >
                <List>
                    <div className='text-center'>
                        <DrawerLine className="drawerLine" />
                    </div>
                    <ListItem disablePadding>

                        <ListItemText>
                            <div className='pi-15 soraFont'>
                                <div className='mt-10 d-flex justify-content-between mb-10'>
                                    <p className='font16pxBold lightmode-text-blue'>
                                        {transactionFullInfo?.name}
                                    </p>
                                    <p className='font10px lightmode-text-light-blue'>
                                        <VerifiedIcon width="10" height="10" className="mr-2" />
                                        {transactionFullInfo?.collectionDisplayName}
                                    </p>
                                </div>
                                <Divider />
                                <div className='d-flex gap-10 w-100 mt-25'>
                                    <div className='transactionDrawerImgBlock'>
                                        <GlanceImage src={CheckWAXImage(transactionFullInfo?.image)} alt="thumb" />
                                    </div>
                                    <div className='detailblock  w-100'>
                                        {/* <div className='d-flex justify-content-between align-items-center'>
                                            <p className='font14pxSemiBold lightmode-text-blue'>#134<span
                                                className='font10px'>/9999</span></p>
                                            <p className='font10px lightmode-text-light-gray'>2 weeks ago</p>
                                        </div> */}
                                        {/* <p className='font10px lightmode-text-marigold mt-10'>Gold Tier + 200 points
                                            added</p> */}
                                        <p className='font10px lightmode-text-light-gray mt-15'>Sold for:</p>
                                        <p className='font14pxSemiBold lightmode-text-blue'>$ {PRICE_FORMAT(transactionFullInfo.amount)}</p>
                                    </div>
                                </div>
                                <div className='buyerSellerBottomBlock mt-15'>
                                    <div className='buyer'>
                                        <p className='font10px lightmode-text-light-gray'>Buyer</p>
                                        <p className='font12px nftNameOverflow max-w-120'>{transactionFullInfo.buyer}</p>
                                    </div>
                                    <div className='seller'>
                                        <p className='font10px lightmode-text-light-gray'>Seller</p>
                                        <p className='font12px nftNameOverflow max-w-120'>{transactionFullInfo.seller}</p>
                                    </div>
                                </div>
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Box>
        )
    }


    return (
        <Fragment>

            {!!data && data.length > 0 && data.map((transaction, index) => (
                <Fragment key={index}>
                    <div className="transaction--grid my-3"
                        onClick={() => handleClickTransaction(transaction)}
                        onClickCapture={toggleDrawer("bottom", true)}>
                        <div className="buyerSection d-flex gap-10 align-items-center">
                            <div className="buyerThumb">
                                <GlanceImage src={CheckWAXImage(transaction?.image)} alt="thumb" />
                            </div>
                            <div>
                                <p className="font10px lightmode-text-midnight-gray">
                                    Buyer
                                </p>
                                <p className="font12px lightmode-text-midnight-blue nftNameOverflow ">
                                    {transaction.buyer}
                                </p>
                            </div>
                        </div>
                        <div className="sellerSection">
                            <p className="font10px lightmode-text-midnight-gray">
                                Seller (Sec)
                            </p>
                            <p className="font12px lightmode-text-midnight-blue nftNameOverflow ">
                                {transaction.seller}
                            </p>
                        </div>
                        <div className="soldSection">
                            <p className="font10px lightmode-text-midnight-gray">
                                Sold for
                            </p>
                            <p className="font12px lightmode-text-midnight-blue nftNameOverflow">
                                $ {PRICE_FORMAT(transaction.amount)}
                            </p>
                        </div>
                    </div>
                </Fragment>
            ))}

            <SwipeableDrawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer("bottom", false)}
                onOpen={toggleDrawer("bottom", true)}
                className="swipableDrawerBlock"
            >
                {ListingFeeData("bottom")}
            </SwipeableDrawer>
            {(loading !== API_FETCH_COMPLETE) &&
                <NFTSkeleton imageHeight={0} className={"mb-4"} />
            }
            {(loading === API_FETCH_COMPLETE) && data?.length === 0 &&
                <NoDataFound text="No transactions made so far" />
            }

        </Fragment>
    )
}

export default TransactionCardDetail

import React, { Fragment, useEffect, useState } from "react";
import "../../components/Withdraw/WithdrawStyles.css"
import USER_HELPER from "../../helpers/apis/userHelper";
import { AmountIcon, ArrowRightIcon, BankIcon, GoBackIcon } from "../../helpers/svgConst";
import WalletAccordian from "../../components/Common/WalletAccordian";
import PrimaryButton from "../../components/Login/PrimaryButton";
import { Field, Form, Formik, ErrorMessage } from "formik";
import http from "../../helpers/Interceptors";
import { API_BASE_URL } from "../../constants/envConstants";
import * as Yup from "yup";
import { GET_ERROR_TOAST_MSG, HANDLE_ONLY_NUMBER_INPUT, PRICE_FORMAT } from "../../constants/commonFunc";
import WITHDRAW_HELPER from "../../helpers/apis/WithdrawHelper";
import { useDispatch } from "react-redux";
import { SetActiveBankAccountAction } from "../../redux/actions/withdrawActions";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { WITHDRAW_USER_BANK_DETAILS_API } from "../../helpers/ApisPath";

const WithdrawMoney = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [inputAmount, setInputAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  const { loginUserInfo, handleGoBack } = USER_HELPER();
  const [bankList, setBankList] = useState([])
  const [closeAccordian, setCloseAccordian] = useState(false)

  const { getUserBanks } = WITHDRAW_HELPER()

  const initialSchema = {
    bank: "",
    ifsc: "",
    accountNumber: "",
    recheckAccountNumber: "",
  };

  const formValidationSchema = Yup.object({
    bank: Yup.string().required("Bank is required"),
    ifsc: Yup.string().required("IFSC is required"),
    accountNumber: Yup.string().required("Account Number is required"),
    recheckAccountNumber: Yup.string()
      .required("Re-check Account Number is required")
      .oneOf([Yup.ref("accountNumber"), null], "Account Numbers don't match"),
  });



  const handleSubmit = (value, { resetForm }) => {
    setLoading(true);
    setErrorMessage({ status: false, message: "" })
    const formData = {
      accountNumber: value.accountNumber.toString(),
      name: loginUserInfo?.name,
      ifsc: value.ifsc,
      bankName: value?.bank
    };

    http
      .post(WITHDRAW_USER_BANK_DETAILS_API, formData)
      .then(function (response) {
        if (response.status === 200) {
          setSuccessMessage("Bank Account successfully added");
          userBankList()
          resetForm({ value: "" })
          setCloseAccordian(true)
        }
      })
      .catch(function (error) {
        const errMsg = GET_ERROR_TOAST_MSG(error)
        setErrorMessage({
          status: true,
          message: "Failed to add bank account " + errMsg,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const userBankList = () => {
    getUserBanks().then((res) => {
      if (res?.status === 200) {
        setBankList(res?.data?.data)
      }
    })
  }

  const handleRedirectWithdrawForm = (bankInfo) => {
    dispatch(SetActiveBankAccountAction(bankInfo))
    navigate(Paths.WithdrawForm)

  }


  useEffect(() => {
    userBankList()
  }, [])

  return (
    <Fragment>
      <section className="lightMode">
        <div className="pi-15 pt-30">
          <button className="btn px-0" onClick={handleGoBack}>
            <GoBackIcon />
          </button>

          <h1 className="font14px mt-35">
            Withdraw money to your bank account.
          </h1>
          <p className="font10px lightmode-text-midnight-gray mt-25">
            Current Balance
          </p>
          <p className="font24px lightmode-text-blue">
            {loginUserInfo?.balance ? PRICE_FORMAT(loginUserInfo?.balance) : 0}
          </p>


          <p className="mt-30 font14px lightmode-text-blue">
            Select preferred method
          </p>

          {bankList && bankList.length > 0 && bankList.map((bank, index) => {
            return (
              <Fragment key={index}>
                <div className="main-white-card mt-10 d-flex gap-10 align-items-center" onClick={() => handleRedirectWithdrawForm(bank)}>
                  <BankIcon className="mr-2" />
                  <div>
                    <p className="bankAccoubt font16px lightmode-text-blue ">
                      {bank?.accountNumber}
                    </p>
                    <p className="font10px lightmode-text-gray mt-1">{bank?.bankName}</p>
                  </div>
                  <ArrowRightIcon className="ml-auto mr-10" />
                </div>
              </Fragment>
            )
          })}


          <WalletAccordian
            primaryIcon={<BankIcon className="mr-2" />}
            heading="Add a new account"
            className={`${closeAccordian ? "" : "walletAccordian Mui-expanded"}`}
            secondaryClassName={`${closeAccordian ? "" : " Mui-expanded"}`}
          >
            <Formik
              initialValues={initialSchema}
              onSubmit={handleSubmit}
              validationSchema={formValidationSchema}
            >
              <Form>
                <label className="font10px mb-2">Select your bank</label>
                <Field
                  as="select"
                  name="bank"
                  id="bank"
                  className="bankDropdown listInput w-100"
                  placeholder="Ex: State Bank of India"
                >
                  <option value="">Select your Bank</option>
                  <option value="sbi">State Bank of India</option>
                  <option value="bob">Bank of Baroda</option>
                  <option value="hdfc">HDFC Bank</option>
                  <option value="axis">Axis Bank</option>
                  <option value="yes">Yes Bank</option>
                </Field>
                <p className="form-error">
                  <ErrorMessage name="bank" />
                </p>
                <label className="font10px mb-2 mt-25">IFSC code</label>
                <Field
                  name="ifsc"
                  type="text"
                  placeholder="IFSC Code"
                  className="listInput w-100"
                />
                <p className="form-error">
                  <ErrorMessage name="ifsc" />
                </p>
                <label className="font10px mb-2 mt-25">Accout number</label>
                <Field
                  name="accountNumber"
                  type="password"
                  pattern="\d*"
                  onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                  placeholder="Your bank account number"
                  className="listInput w-100"
                  onWheel={(e) => e.target.blur()}
                />
                <p className="form-error">
                  <ErrorMessage name="accountNumber" />
                </p>
                <label className="font10px mb-2 mt-25">
                  Verify account number
                </label>
                <Field
                  name="recheckAccountNumber"
                  type="number"
                  pattern="\d*"
                  onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                  placeholder="Rewrite bank account number"
                  className="listInput w-100"
                  onWheel={(e) => e.target.blur()}
                />
                <p className="form-error">
                  <ErrorMessage name="recheckAccountNumber" />
                </p>
                {errorMessage?.status &&
                  <p className="form-error mt-4">
                    {errorMessage?.message}
                  </p>
                }
                {successMessage &&
                  <p className="form-success mt-4">
                    {successMessage}
                  </p>
                }
                <PrimaryButton
                  isLoading={loading}
                  primaryClassName="text-center mt-30"
                  heading="Submit"
                  type="submit"
                />
              </Form>
            </Formik>


          </WalletAccordian>
        </div>
      </section>
    </Fragment>
  );
};

export default WithdrawMoney;

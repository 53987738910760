import React, { Fragment } from 'react'
import { ArrowRightIcon } from '../../helpers/svgConst'

const SecondaryButton = ({ className, heading, btnClassName, onClick, arrow = true }) => {
    return (
        <Fragment>
            <div className={`text-center d-flex align-items-center justify-content-center ${className}`}>
                <button className={`btn font12px  lightmode-text-marigold ${btnClassName}`} onClick={onClick}>
                    {heading}
                    {arrow &&
                        <ArrowRightIcon stroke="#AD3430" className="ml-2" />}
                </button>
            </div>
        </Fragment>
    )
}

export default SecondaryButton

import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import TableDataGrid from '../DataGrid/DataGrid';
import { API_BASE_URL } from '../../../../constants/envConstants';

const CollectionDetails = () => {


    const [loading, setLoading] = useState(false)
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])

    const tableColumns = [


        {
            field: 'collectionDisplayName',
            headerName: 'Collection Display Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'totalcards',
            headerName: 'Total cards',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'totalschemas',
            headerName: 'Total schemas',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'totaltemplates',
            headerName: 'Total templates',
            minWidth: 200,
            flex: 1,

        },


    ]

    const maxPage = Math.ceil(maxResult / pageLimit)

    const getCollectionDetails = (pageNo = 1, limit = 10) => {
        setLoading(true)
        axios

            .get(API_BASE_URL + "reports/collection-details" + "?page=" + pageNo + "&limit=" + limit)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.collectionDetails

                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                totalcards: !!key?.totalcards ? parseFloat(key?.totalcards) : 0,
                                totalschemas: !!key?.totalschemas ? parseFloat(key?.totalschemas) : 0,
                                totaltemplates: !!key?.totaltemplates ? parseFloat(key?.totaltemplates) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    console.log('finaldata', finalData)
                    setMaxResult(res.data.data.schemaCount)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setTableData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getCollectionDetails()
    }, [])


    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">Collection Details</h2>
                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" />
                </div>
                <div className="d-flex justify-content-center mt-5 mb-3">
                </div>
            </div>
        </Fragment >
    )
}

export default CollectionDetails

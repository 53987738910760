import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Web3 from "web3";
import http from "../../helpers/Interceptors"
import { setMetaMaskAddress } from "../../redux/actions/userActions";
import '../../components/OnchainComponents/onchainwallet.css'
import OwensHeader from '../../components/Common/OwensHeader'
import { MetamaskIcon } from '../../helpers/svgConst'
import metaImage from '../../images/WalletImage/MetaMask_Fox.svg.png'
import scatterImage from '../../images/WalletImage/scatter.jpg'
import PrimaryButton from "../../components/Login/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import USER_HELPER from "../../helpers/apis/userHelper";
import { GET_USER_DETAILS_API } from "../../helpers/ApisPath";

const web3 = new Web3(Web3.givenProvider);

const ConnectWallet = (props) => {

    const dispatch = useDispatch()
    const metaMaskAddress = useSelector((state) => state?.user?.metaMaskAddress)
    const navigate = useNavigate()


    const getUserDetails = () => {
        return http.get(GET_USER_DETAILS_API)
            .then((res) => {
                return res
            }).catch((err) => {
                return err
            })
    }


    const handleConnectWaxWallet = () => {
        props?.showModal()
    }

    const handleLogoutMetaMask = () => {
        localStorage.removeItem("metaMask")
        dispatch(setMetaMaskAddress(""))
    }

    const connectToMetaMask = async () => {
        try {
            const { ethereum } = window;
            const { chainId } = ethereum;

            let metaMaskAddress = "0x1";

            if (chainId === metaMaskAddress) { // rinkeyby or mainnet
                if (!!ethereum) {
                    const accounts = await ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    console.log("account metamask", accounts[0])
                    dispatch(setMetaMaskAddress(accounts[0]));
                    localStorage.setItem('metaMask', accounts[0])
                    const userInfo = await getUserDetails()
                    const onChainAddress = userInfo?.data?.data?.onChainAddress
                    // redirecting to link your onchain wallet page when user wallet is not linked 
                    if (!onChainAddress || onChainAddress.length === 0) {
                        navigate(Paths.LinkOnChainWallet)
                    }
                }
            } else {
                alert(`Please select Rinkeyby  then connect`);
            }
        } catch (e) {
            console.log("something went wrong ", e);
        }
    };


    return (
        <Fragment>
            <OwensHeader />
            <div className="lightMode pi-15 LightGrayBG">
                <h4 className="font20pxBold  lightmode-text-blue pt-4 mb-2">Welcome</h4>
                <h4 className="font16px mb-5">Connect your on-chain wallet</h4>

                <button className="btn metamaskBG connect-wallet-btn m-0 w-100" onClick={connectToMetaMask}>
                    <MetamaskIcon />
                    {!!metaMaskAddress ? "Connected" : "Connect with MetaMask"}
                </button>

                {/* <button className="btn scatterBG connect-wallet-btn m-0 w-100 mt-3" onClick={handleConnectWaxWallet}>
                    <div className="walletIconBlock">
                        <img src={scatterImage} className="logometa" alt="wax" />
                    </div>
                    Connect with Wax Wallet
                </button> */}

                {metaMaskAddress &&
                    <PrimaryButton heading="Logout MetaMask" primaryClassName=" mt-3" onClick={handleLogoutMetaMask} />
                }
            </div>
        </Fragment>
    )
}

export default ConnectWallet
import React, { Fragment } from 'react'
import { CheckWAXImage, PRICE_FORMAT } from '../../constants/commonFunc'
import USER_HELPER from '../../helpers/apis/userHelper'
import { DiscordIcon, EmptyWallet, InstagramIcon, TwitterIcon, VerifiedIcon } from '../../helpers/svgConst'
import userThumb from "../../images/LoginImage/AsianMan.png"
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import GlanceImage from '../Common/GlanceImage'


const UserProfileDetails = () => {

    const { loginUserInfo } = USER_HELPER()
    const navigate = useNavigate()
    const handleGoToWalletPage = () => {
        navigate(Paths.WalletPage)
    }

    return (
        <Fragment>
            <div className='profile-hero-block'>
                <div className='pos-rel'>
                    <div className="userThumbBlock">
                        <GlanceImage src={loginUserInfo?.profilePic ? CheckWAXImage(loginUserInfo?.profilePic) : userThumb}
                            alt="thumb" />
                    </div>
                    {loginUserInfo?.socialProfile?.discord && loginUserInfo?.socialProfile?.twitter && loginUserInfo?.socialProfile?.instagram &&
                        < VerifiedIcon className="abs-icon" width="16" height="16" />}
                </div>
                <div>
                    <p className='font16pxBold lightmode-text-primary'>{loginUserInfo?.name ? loginUserInfo?.name : loginUserInfo?.vaccount}</p>
                    <div className='d-flex gap-10 mt-10'>
                        {loginUserInfo?.socialProfile?.discord &&
                            <button className='btn p-0'>
                                <DiscordIcon />
                            </button>}
                        {loginUserInfo?.socialProfile?.twitter &&
                            <button className='btn p-0 '>
                                <TwitterIcon />
                            </button>}
                        {loginUserInfo?.socialProfile?.instagram &&
                            <button className='btn p-0'>
                                <InstagramIcon />
                            </button>}
                    </div>
                </div>
                <div className='d-flex ml-auto'>
                    <button className='btn emptyWalletBlock mt-20' onClick={handleGoToWalletPage}>
                        <div className='d-flex align-items-center'>
                            <EmptyWallet />
                            <p className='ml-10 font12px hotpink-clr'>$ {loginUserInfo?.balance ? PRICE_FORMAT(loginUserInfo?.balance) : 0}</p>
                        </div>
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default UserProfileDetails

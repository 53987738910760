import React, { Fragment } from 'react'
import { SilverExpCoinIcon, BronzeExpCoinIcon, GoldTierIcon } from '../../helpers/svgConst'
import offerImage from "../../images/CollectionImage/offerImage.png"
import SecondaryButton from '../../components/Common/SecondaryButton';
import GlanceImage from '../Common/GlanceImage';


const TiersAndBenefits = ({ title }) => {
    return (
        <Fragment>
            <div className='main-white-card mt-15'>
                <div className='d-flex justify-content-between align-items-center'>
                    <GoldTierIcon />
                    <p className='font10px lightmode-text-light-gray'>Upgrade to gold with</p>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-10 bdr-btm'>
                    <p className='font16pxSemiBold lightmode-text-marigold'>{title}</p>
                    <div className='d-flex gap-10'>
                        <div className='silverExpBlock ml-0 d-flex ml-auto px-2'>
                            <p className='font8pxSemiBold mr-1 silver-text'>+590</p>
                            <SilverExpCoinIcon />
                        </div>
                        <div className='bronzeExpBlock ml-0 d-flex ml-auto px-2'>
                            <p className='font8pxSemiBold mr-1 bronze-text'>+590</p>
                            <BronzeExpCoinIcon />
                        </div>
                    </div>
                </div>
                <p className='font12px'>Comes with premium benefits highlighted below. You can also claim these benefits by playing game
                    and participating in our giveaways.</p>
                <div className="mt-30 carousel-grid">
                    <div className="carousel-sec grayBG p-10">
                        <div className="carousel-image-block">
                            {/* <GlanceImage src={imageFour} alt="4" /> */}
                        </div>
                        <p className="font12px mt-2 lightmode-text-blue">
                            Running Shoes worth $ 9999/-
                        </p>
                        <p className="font10px mt-1 overflowText">
                            Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...
                        </p>
                    </div>
                    <div className="carousel-sec grayBG p-10">
                        <div className="carousel-image-block">
                            {/* <GlanceImage src={imageFive} alt="4" /> */}
                        </div>
                        <p className="font12px mt-2 lightmode-text-blue">
                            Running Shoes worth $ 9999/-
                        </p>
                        <p className="font10px mt-1 overflowText">
                            Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...
                        </p>
                    </div>

                    <div className="carousel-sec grayBG p-10">
                        <div className="carousel-image-block">
                            {/* <GlanceImage src={imageSix} alt="4" /> */}
                        </div>
                        <p className="font12px mt-2 lightmode-text-blue">
                            Running Shoes worth $ 9999/-
                        </p>
                        <p className="font10px mt-1 overflowText">
                            Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...Lorem ipsum dolor sit amet, conse ctetur adipiscing elit. Morbiali quam...
                        </p>
                    </div>
                </div>
                <SecondaryButton heading="View All" className="mt-15" />
            </div>
        </Fragment>
    )
}

export default TiersAndBenefits
import * as React from "react";
import { Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMoreIcon } from "../../helpers/svgConst";

const LightmodeAccordian = (props) => {
    const { heading, children, className, type } = props;
    return (
        <Fragment>
            {type === "market" ?
                <Fragment>
                    <Accordion
                        className={`main-white-card br-15 mt-20 pt-10 pb-10 ${className}`}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            className="pr-2 pl-0"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h2 className="font12px lightmode-text-blue">{heading}</h2>
                        </AccordionSummary>
                        <AccordionDetails>{children}</AccordionDetails>
                    </Accordion>
                </Fragment>

                :
                <Fragment>
                    <Accordion
                        className={`main-white-card mt-20 pt-10 pb-10 ${className}`}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            className="pr-2 pl-0"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h2 className="font24pxBold lightmode-text-blue">{heading}</h2>
                        </AccordionSummary>
                        <AccordionDetails>{children}</AccordionDetails>
                    </Accordion>
                </Fragment>
            }
        </Fragment>
    );
};

export default LightmodeAccordian
// all offer listing when user is not owner
import React, { Fragment } from 'react'
import "./TokenDetailStyles.css"
import { DATE_FORMAT, PRICE_FORMAT } from "../../constants/commonFunc";

const PublicOffers = ({ data }) => {

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center pl-10">
                <p className="font10pxSemiBold lightmode-text-blue mb-15">Price</p>
                <p className="font10pxSemiBold lightmode-text-blue mb-15">From</p>
                <p className="font10pxSemiBold lightmode-text-blue mb-15">Time</p>
                <p className="hidden"></p>
            </div>
            {data && data.length > 0 && data?.map((key, index) => {
                const formatDate = DATE_FORMAT(key?.created)
                return (
                    <Fragment key={index}>
                        <div className={`d-flex justify-content-between align-items-center w-100 mt-1 pt-15 pb-15 `}>
                            <p className="font12px ml-15">$ {PRICE_FORMAT(key?.price)}</p>
                            <p className="font12px nftNameOverflow">{key?.vaccount}</p>
                            <p className="font12px">
                                {`${formatDate?.day} ${formatDate.monthShort} ${formatDate?.year} `}
                            </p>
                            <p> &nbsp;</p>
                        </div>
                    </Fragment>
                )
            })}

        </Fragment>
    )
}

export default PublicOffers

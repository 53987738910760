// Marketplace filter component
import {changeURLLastStringMarketplace, changeURLLastStringMarketplaceMarketplace, GET_TIER_SCHEMA_TEXT} from "../../constants/commonFunc";
import {MARKETPLACE_CARDS_API, MARKETPLACE_NOT_FOR_SALE_API} from "../../helpers/ApisPath";
import FilterList from "./FilterList";
import React, {Fragment, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {handleChangeTierSchemaValueAction} from "../../redux/actions/commonAction";
import {MARKETPLACE_PAGE_TIER_SCHEMA} from "../../redux/action-types/common.type";
import {
    ChangeMarketplaceAPIPath,
    ChangeMarketplacePricingAction,
    MarketplaceAPICallStart
} from "../../redux/actions/marketplaceActions";

const ActiveFilters = (props) => {

    const apiCallOnClose = props?.type === "page"
    const dispatch = useDispatch()
    const PRICE_FILTER = useSelector((state) => state?.marketplace?.price)
    const schemaOptionsList = useSelector((state) => state?.marketplace?.schemaList)
    const API_FULL_PATH = useSelector((state) => state?.marketplace?.apiFullPath)
    const activeSchema = useSelector((state) => state?.common?.marketPlacePageTierSchema)

    const handleSchemaClear = useCallback(() => {
        dispatch(handleChangeTierSchemaValueAction(MARKETPLACE_PAGE_TIER_SCHEMA, ""))
        if (apiCallOnClose) {
            dispatch(MarketplaceAPICallStart(true))
        }
    }, [props?.schemaOnCloseApiCall])

    const handleApiPathClose = (path) => {
        const apiPath = changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, path)
        dispatch(ChangeMarketplaceAPIPath(apiPath))
        if (apiCallOnClose) {
            dispatch(MarketplaceAPICallStart(true))
        }
    }

    const handlePriceClear = () => {
        dispatch(ChangeMarketplacePricingAction({min: 0, max: null}))
        if (apiCallOnClose) {
            dispatch(MarketplaceAPICallStart(true))
        }
    }

    return (
        <Fragment>
            {API_FULL_PATH === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2) &&
            <FilterList title="Live Auction"/>
            }
            {API_FULL_PATH === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4) &&
            <Fragment>
                <FilterList title="Buy Now"/>
            </Fragment>
            }
            {API_FULL_PATH === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 1) &&
            <Fragment>
                <FilterList title="Buy Now" handleClose={() => handleApiPathClose(2)}/>
                <FilterList title="Live Auction" handleClose={() => handleApiPathClose(4)}/>
            </Fragment>
            }
            {API_FULL_PATH === MARKETPLACE_NOT_FOR_SALE_API &&
            <FilterList title="Not For Sale"/>
            }
            {!!activeSchema &&
            <FilterList title={GET_TIER_SCHEMA_TEXT(schemaOptionsList, activeSchema)}
                        handleClose={handleSchemaClear}/>
            }
            {(PRICE_FILTER?.min > 0 || !!PRICE_FILTER?.max) &&
            <FilterList title={`${PRICE_FILTER?.min} ≤ Price ≤ ${PRICE_FILTER?.max}`}
                        handleClose={handlePriceClear}/>
            }

            {}
        </Fragment>
    )
}

export default ActiveFilters

import React, { Fragment } from "react";
import { CoinShadow } from "../../helpers/svgConst";
import dogeIcon from "../../images/LoginImage/Doge.png";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GlanceImage from "../Common/GlanceImage";
import Paths from "../../helpers/Paths";

const AllDone = () => {

    const userInfo = useSelector(state => state?.user?.initiateLoginData)
    const navigate = useNavigate()

    const handleGoToCollection = () => {
        navigate(Paths.MoreCollectionsPage,{replace: true})
    }

    return (
        <Fragment>
            <section className="allDoneContainer">
                <div className="allDoneBlock">
                    <div className="text-center">
                        <div className="nftCoinBlock">
                            <GlanceImage src={dogeIcon} alt="doge" />
                        </div>
                        <CoinShadow className="mt-30" />
                        <div className="mt-35">
                            {userInfo?.name &&
                                <p className="font24pxBold gold-clr">{userInfo?.name},</p>
                            }
                            <p className="font24pxBold gold-clr">You are all set!</p>
                        </div>
                    </div>
                    <div className="pi-10 w-100 mt-auto">
                        <PrimaryButton heading="Let’s buy some NFTs" onClick={handleGoToCollection} />
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default AllDone;

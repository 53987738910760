import React, { Fragment, useCallback, useState } from "react"
import SubHeader from "../../components/Common/SubHeader";
import PrimaryButton from "../../components/Login/PrimaryButton";
import useRazorpay from "react-razorpay";
import { RAZORPAY_KEY } from "../../constants/envConstants";
import USER_HELPER from "../../helpers/apis/userHelper";
import {
    GET_ALL_QUERY_PARAMS,
    GET_ERROR_TOAST_MSG,
    HANDLE_ONLY_NUMBER_INPUT,
    PRICE_FORMAT,
    RAZORPAY_IMAGE,
    SEND_PRICE_FORMAT
} from "../../constants/commonFunc";
import http from "../../helpers/Interceptors"
import { CREATE_ADD_TOKEN_ORDER_API, HANDLE_CAPTURE_PAYMENT_API, INITATE_PAYMENT_API } from "../../helpers/ApisPath";
import Paths from "../../helpers/Paths";
import { useLocation, useNavigate } from "react-router-dom";
import { AmountIcon } from "../../helpers/svgConst";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const DepositMoney = () => {

    const location = useLocation()
    const queryParam = GET_ALL_QUERY_PARAMS(location?.search);
    const AMOUNT_QUERY = !!queryParam?.amount ? queryParam?.amount.replace(/,/g, "") : 0
    const Razorpay = useRazorpay();
    const [inputAmount, setInputAmount] = useState(AMOUNT_QUERY)
    const { loginUserInfo } = USER_HELPER()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onSuccessRedirectURL = useSelector((state) => state?.common?.paymentOnSuccessRedirectURL)
    const [errorMessage, setErrorMessage] = useState({ state: false, message: "" })

    const handleCreateOrder = (amount) => {
        const formData = {
            amount: parseFloat(amount)
        }
        return http.post(CREATE_ADD_TOKEN_ORDER_API, formData)
            .then((res) => {
                return res
            }).catch((err) => {
                return err
            })
    }

    const handleInitiatePayment = (orderId) => {
        // initiate payment api
        if (!orderId) return
        const paymentFor = 1
        const apiPath = INITATE_PAYMENT_API + orderId + "/" + paymentFor + "/RAZ"
        return http.post(apiPath).then((res) => {
            return res
        }).catch((err) => {
            return err
        })
    }

    const handleCapturePayment = (rzpInitiatedId, rzpResponse, amount) => {
        // capture amount api call and if success then redirect
        const formData = {
            orderId: rzpResponse.razorpay_order_id,
            transactionId: rzpResponse.razorpay_payment_id
        }
        const apiPath = HANDLE_CAPTURE_PAYMENT_API + rzpInitiatedId
        setLoading(true)
        http.post(apiPath, formData).then((res) => {
            if (onSuccessRedirectURL) {
                toast.success("Amount added to wallet successfully")
                navigate(onSuccessRedirectURL, { replace: true })
            } else {
                navigate(Paths.WalletPage + "?amount=" + PRICE_FORMAT(amount) + "&type=success", { replace: true })
            }
        }).catch((err) => {
            console.err("err capture", err)
            const message = GET_ERROR_TOAST_MSG(err)
            toast.error("Failed to capture payment" + message)
            setErrorMessage({ state: false, message: "Failed to capture payment" + message })
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChangeInputAmount = (e) => {
        setInputAmount(e.target.value)
    }


    const rupeesSignPos = (55 + (inputAmount ? inputAmount.toString().length * 4 : 1));


    const handlePayment = useCallback(async (amount) => {
        setErrorMessage({ state: false, message: "" })
        if (parseFloat(amount) === 0) {
            setErrorMessage({ state: true, message: "Please enter amount greater than zero" })
            return
        }

        setLoading(true)
        const orderRes = await handleCreateOrder(amount);
        const orderId = orderRes.data?.data?.id;
        if (!orderId) {
            setErrorMessage({ state: true, message: "Create Order Id not found" })
            setLoading(false)
            return
        }

        const initiatePaymentRes = await handleInitiatePayment(orderId)
        // this id will go to capture api id
        const rzpPaymentApiResId = initiatePaymentRes.data?.data?.paymentResult?.id
        const rzpPaymentInitiateId = initiatePaymentRes.data?.data?.paymentResult?.paymentDetail?.orderId;
        if (!rzpPaymentInitiateId) {
            console.log("failed initate order id", orderRes)
            setErrorMessage({ state: true, message: "Failed to initiate order" })
            setLoading(false)
            return
        }

        const options = {
            key: RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
            amount: inputAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "OW3NS",
            description: "Deposit Money",
            image: RAZORPAY_IMAGE,
            order_id: rzpPaymentInitiateId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                handleCapturePayment(rzpPaymentApiResId, response, amount)
            },
            modal: {
                "ondismiss": function () {
                    setErrorMessage({ state: true, message: "Payment cancelled" })
                    setLoading(false)
                }
            },
            prefill: {
                name: loginUserInfo?.name ? loginUserInfo?.name : "",
                email: loginUserInfo?.email ? loginUserInfo?.email : "",
                contact: loginUserInfo?.phone ? loginUserInfo?.phone : "",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();

        rzpay.on("payment.failed", function (response) {
            setErrorMessage({ state: true, message: "Payment failed" })
            console.log("error code", response.error);
            setLoading(false)
        });
    }, [Razorpay]);

    return (
        <Fragment>
            <section className='lightMode'>
                <div className="pi-15">
                    <SubHeader />
                    <div className='mt-35'>
                        <p className='font24pxBold lightmode-text-blue'>My Wallet</p>
                    </div>
                    <div className="white-card grayBorder mt-40 p-20">
                        <div className="d-flex align-items-center">
                            <AmountIcon className="mr-2" />
                            <p className="font16px lightmode-text-blue">Enter the amount</p>
                        </div>
                        <p className="font12px mt-15">Enter the amount you want to add from the selected card to the
                            wallet.</p>
                        <div className="w-100 mt-40 mb-40 d-flex align-items-center">
                            <span className="font40px lightmode-text-blue priceIconDeposit"
                                style={{ right: `${rupeesSignPos}%` }}>$</span>
                            <input type="number"
                                onChange={handleChangeInputAmount}
                                pattern="\d*"
                                onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                className=" transparentAmountInput font40px lightmode-text-blue text-center"
                                value={inputAmount}
                                onWheel={(e) => e.target.blur()} />
                        </div>

                    </div>
                    {errorMessage?.state &&
                        <p className="form-error">
                            {errorMessage.message}
                        </p>
                    }
                    <PrimaryButton primaryClassName="text-center" isLoading={loading}
                        onClick={() => handlePayment(SEND_PRICE_FORMAT(inputAmount))}
                        heading="Add to wallet" />
                </div>
            </section>
        </Fragment>
    )
}

export default DepositMoney

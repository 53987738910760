import React, { Fragment, useEffect, useState } from 'react'
import OwensHeader from "../../components/Common/OwensHeader"
import CardOfferListDetailPage from '../../components/TokenDetail/CardOffersListDetailPage'
import { useParams } from "react-router-dom";
import { API_FETCH_COMPLETE, API_FETCH_START } from "../../constants/commonFunc";
import InfiniteScroll from "react-infinite-scroll-component";
import CARD_HELPER from "../../helpers/apis/cardHelper";


const OfferHistory = () => {

    const PAGE_LIMIT = 10
    const { cardId } = useParams()
    const { getAllOfferByCardId, getCardFullInfoById, getAllSpecialOfferByCardId } = CARD_HELPER()
    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState("")
    const [cardInfo, setCardInfo] = useState({})

    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (data.length === 0) return 1
        return data
            ? Math.ceil(data.length / PAGE_LIMIT) + 1
            : 1;
    }


    const getOfferHistory = async (reloadSearch = true) => {
        const unlistedNFT = !cardInfo?.marketplaceId && !cardInfo?.listingType
        setLoading(API_FETCH_START)
        const apiPage = getPageNo(reloadSearch)
        let apiRes = {};
        if (!!cardInfo?.marketplaceId) {
            apiRes = await getAllOfferByCardId(cardInfo?.marketplaceId, apiPage, PAGE_LIMIT)
        } else if (unlistedNFT) {
            apiRes = await getAllSpecialOfferByCardId(cardInfo?.id, apiPage, PAGE_LIMIT)
        }

        if (apiRes?.status === 200) {
            let mergedFinalResult = []
            let apiTransactionList = []
            const apiData = apiRes?.data?.data
            if (unlistedNFT) {
                apiTransactionList = apiData?.allSpecialOffers
            } else {
                apiTransactionList = apiData?.allOffers || []
            }
            mergedFinalResult = [...data, ...apiTransactionList];
            console.log("merge resilt", mergedFinalResult)
            setData(mergedFinalResult);
            setTotalCount(apiData?.count)
        }
        setLoading(API_FETCH_COMPLETE)

    }

    const fetchMoreItems = () => {
        if (data.length < totalCount) {
            getOfferHistory(false);
        }
    }

    const getCardFullInfo = async () => {
        setLoading(API_FETCH_START)
        const res = await getCardFullInfoById(cardId)
        setCardInfo(res?.data?.data);

    }

    useEffect(() => {
        getCardFullInfo()
    }, [])

    useEffect(() => {
        getOfferHistory()
    }, [cardInfo])

    return (
        <Fragment>
            <section className='lightMode'>
                <OwensHeader />
                <div className='pi-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-40'>Offers</h1>
                    <h3 className='font10px lightmode-text-gray mt-2'>{cardInfo?.collectionDisplayName} | {cardInfo?.collectionDisplayName}</h3>
                    <div className='main-white-card mt-20'>

                        <InfiniteScroll
                            next={fetchMoreItems}
                            hasMore={data.length < totalCount}
                            dataLength={data.length}
                            loader={false}>
                            <CardOfferListDetailPage isLoading={loading} offerList={data} cardInfo={cardInfo} />
                        </InfiniteScroll>


                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default OfferHistory

import React, { Fragment, useEffect } from 'react'
import { API_BASE_URL, OPERATOR_ID } from '../../constants/envConstants'
import image from "../../images/CollectionImage/background.png"
import PrimaryButton from '../Login/PrimaryButton'
import http from "../../helpers/Interceptors";
import { useState } from 'react';
import { GET_BUY_PACK_LIST_API, HANDLE_BUY_PRIMARY_FLOW } from '../../helpers/ApisPath';
import { toast } from 'react-toastify';
import { changeURLLastString, GET_ERROR_TOAST_MSG } from '../../constants/commonFunc';
import { useSelector } from 'react-redux';
import Paths from '../../helpers/Paths';
import USER_HELPER from '../../helpers/apis/userHelper';
import { Box, Divider, SwipeableDrawer } from '@mui/material';
import { DrawerLine } from '../../helpers/svgConst';
import SecondaryButton from '../Common/SecondaryButton';
import LightmodeAccordian from '../Common/LightmodeAccordian';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";




const BuyPacks = ({ collectionId }) => {

    const { loginUserInfo } = USER_HELPER()
    const navigate = useNavigate()
    const [buyPackTemplate, setBuyPackTemplate] = useState([])
    const [qtyInput, setQtyInput] = useState("1");
    const [loading, setLoading] = useState(false)
    const { isUserLogin, RedirectToLoginScreen } = USER_HELPER();
    const [buyState, setBuyState] = useState({
        bottom: false,
    });

    const getBuyPacks = () => {

        const apiPath = GET_BUY_PACK_LIST_API + "/" + OPERATOR_ID + "?page=1&limit=20&collectionId=" + collectionId
        http.get(apiPath)
            .then((res) => {
                setBuyPackTemplate(res.data.data.primarySales)
            }).catch((err) => {
                console.error('buy pack err', err)
            })
    }

    const handleOnChange = (e, index) => {
        e.preventDefault();
        const inputArr = [];
        inputArr[index] = e.target.value;
        setQtyInput(inputArr);
        console.log("qty", inputArr);
    };


    const handleBuyTemplate = (templateId, count, price) => {

        setBuyState("bottom", false)
        if (!isUserLogin) {
            RedirectToLoginScreen(changeURLLastString(Paths.CollectionPage, collectionId))
            return
        }
        setLoading(true)
        const formData = {
            "templateId": templateId,
            "totalBuy": parseInt(count)
        }
        console.log('buy pack form data', formData)
        http.post(HANDLE_BUY_PRIMARY_FLOW, formData)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success("Bought Successfully");
                    toggleDrawer("bottom", false)
                }
            }).catch((err) => {
                const msg = GET_ERROR_TOAST_MSG(err)
                toast.error("Error" + msg);
                toggleDrawer("bottom", false)

            }).finally(() => {
                setLoading(false)
                toggleDrawer("bottom", false)
            })
    }



    useEffect(() => {
        getBuyPacks()
    }, [collectionId])



    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setBuyState({ ...buyState, [anchor]: open });
    };

    const AskConfirmation = (anchor, templateId, amount, price) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel mb-25"
            >

                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>
                <div className='p-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Buy Pack</h1>
                    <Divider />


                    <p className="font16pxBold mt-4 mb-3 lightmode-text-blue">Do you want to purchase this pack?</p>
                    <div className='d-flex'>
                        <SecondaryButton heading="Cancel" className="w-100" arrow={false} onClick={toggleDrawer("bottom", false)} />
                        <PrimaryButton
                            heading="Buy"
                            primaryClassName="mt-15"
                            onClick={() => handleBuyTemplate(templateId, amount, price)}
                        />
                    </div>

                </div>
            </Box>
        )
    }


    var settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        centerPadding: "1%"
    };

    return (
        <Fragment>

            {buyPackTemplate && buyPackTemplate.length > 0 &&
                <LightmodeAccordian heading="Buy Pack">

                    <div className="main-white-card p-0">
                        <Slider {...settings}>
                            {buyPackTemplate.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div className='buypackBG '>
                                            <div className="buyPacksImageBlock">
                                                <img src={key?.image} alt="hello" />
                                            </div>

                                            <p className="font16pxBold lightmode-text-blue mt-20">{key?.name}</p>
                                            <p className="font12px  mt-1 threeLinesOverflowText">{key?.description}</p>
                                            <div className='buyPackPriceBlock mt-15 align-items-center'>
                                                <p className="font12pxSemiBold lightmode-text-blue">Price</p>
                                                <label htmlFor="quantity" className="font12pxSemiBold lightmode-text-blue">Quantity</label>
                                                <div>
                                                    <p className='font20pxBold lightmode-text-blue'>$ {Math.floor(key?.price)}</p>
                                                </div>
                                                <div>

                                                    <input
                                                        id="quantity"
                                                        className="w-100 listInput py-2"
                                                        placeholder='1'
                                                        onChange={(e) => handleOnChange(e, index)} />
                                                </div>
                                            </div>

                                            <PrimaryButton heading="Buy Now" primaryClassName="mt-25 mb-2" onClick={toggleDrawer("bottom", true)} />
                                            <SwipeableDrawer
                                                anchor={"bottom"}
                                                open={buyState["bottom"]}
                                                onClose={toggleDrawer("bottom", false)}
                                                onOpen={toggleDrawer("bottom", true)}
                                                className="swipableDrawerBlock"

                                            >
                                                {AskConfirmation("bottom", key?.templateId, qtyInput[index] ? qtyInput[index] : 1, key?.price)}
                                            </SwipeableDrawer>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </Slider>

                    </div>

                </LightmodeAccordian>
            }


        </Fragment>
    )
}

export default BuyPacks
import React, { Fragment } from 'react'
import ShiedlIcon from "../../images/WalletImage/shield.png"
import PrimaryButton from "../../components/Login/PrimaryButton"
import { EmptyWallet } from '../../helpers/svgConst'
import { useLocation, useNavigate } from 'react-router-dom'
import Paths from "../../helpers/Paths";
import { PRICE_FORMAT } from '../../constants/commonFunc'

const WithdrawMoneySucess = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const GoTOWallet = () => {
        navigate(Paths.WalletPage, { replace: true })
    }

    const amount = location?.state?.price

    return (
        <Fragment>
            <section className='lightMode '>
                <div className='withdrawDoneBlock pi-15'>
                    <div className='shieldImageBlock'>
                        <img src={ShiedlIcon} alt="thumb shield" />
                    </div>
                    <h1 className='font24pxSemiBold lightmode-text-marigold mt-35'>Request received</h1>
                    <p className='font16px mt-1 w-80 mx-auto'>$ {PRICE_FORMAT(amount)}/- will be credited to your registered bank account</p>
                    <PrimaryButton
                        icon={<EmptyWallet color="white" />}
                        heading="Go back wallet"
                        primaryClassName="gap-10 w-fit mx-auto mt-50 py-3 px-4"
                        onClick={GoTOWallet} />
                    <p className='font10px lightmode-text-midnight-gray mt-70'>For security reasons, withdrawals will not be allowed for the next 24 hours when you change your login credentials/phone number.</p>
                    <p className='font10px lightmode-text-midnight-gray mt-20'>For more querie please contact support.</p>
                </div>
            </section>
        </Fragment>
    )
}

export default WithdrawMoneySucess
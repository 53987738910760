/* File is used for marketplace filters, api constants are
 All = 1,
 Auction = 2,
 TradePool = 3,
 TokenMarket = 4,
*/

import React, { Fragment, useEffect, useState } from 'react'
import { CrossIcon, GoBackIcon, GoldExpCoinIcon, } from '../../helpers/svgConst'
import LightmodeAccordian from '../Common/LightmodeAccordian'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from "react-redux";
import { changeURLLastStringMarketplace, HANDLE_ONLY_NUMBER_INPUT } from "../../constants/commonFunc";
import { MARKETPLACE_CARDS_API, MARKETPLACE_NOT_FOR_SALE_API, MARKETPLACE_ONCHAIN_CARDS } from "../../helpers/ApisPath";
import {
    ChangeMarketplaceAPIPath,
    ChangeMarketplacePricingAction,
    MarketplaceAPICallStart,
    SetMarketplacePointsRange,
    ShowHideMarketplaceFiltersListAction
} from "../../redux/actions/marketplaceActions";
import PrimaryButton from "../Login/PrimaryButton";
import CommonSearch from '../Common/CommonSearch';
import { handleChangeTierSchemaValueAction } from "../../redux/actions/commonAction";
import { MARKETPLACE_PAGE_TIER_SCHEMA } from "../../redux/action-types/common.type";
import ActiveFilters from "./ActiveFilters";
import MarketplaceFilterRadioBtn from './MarketplaceFilterRadioBtn';
import { RadioGroup } from '@mui/material';
import GlanceCheckbox from '../Common/GlanceCheckbox';
import { AirbnbSlider, IOSSwitch } from '../../helpers/MuiHelpers';
import useDebounce from '../../helpers/Hooks/useDebounce';

const MarketplaceFilter = () => {

    const dispatch = useDispatch()
    const schemaOptionsList = useSelector((state) => state?.marketplace?.schemaList)
    const API_FULL_PATH = useSelector((state) => state?.marketplace?.apiFullPath)
    const PRICE_FILTER = useSelector((state) => state?.marketplace?.price)
    const activeSchema = useSelector((state) => state?.common?.marketPlacePageTierSchema)
    const showPointsFilter = useSelector((state) => state?.marketplace?.showPointsFilter)
    const pointsRange = useSelector((state) => state?.marketplace?.pointsRange)

    const [localActiveSchema, setLocalActiveSchema] = useState(activeSchema)
    const [apiPath, setApiPath] = useState(API_FULL_PATH)
    const [price, setPrice] = useState(PRICE_FILTER)
    const [points, setPoints] = useState([])

    //added delay debounce for points range
    const debouncedPointsValue = useDebounce(points, 500)

    const handleChangePoints = (event) => {
        setPoints(event.target.value)
    }

    const apiTypes = [
        { name: "Buy Now", value: changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4) },
        { name: "Live auction", value: changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2) },
        { name: "Not for sale", value: MARKETPLACE_NOT_FOR_SALE_API },
        { name: "Most Favorited", value: "" },
        { name: "OnChain Assets", value: MARKETPLACE_ONCHAIN_CARDS },
    ]

    const handleCloseModal = () => {
        dispatch(ShowHideMarketplaceFiltersListAction(false))
    }


    const handleApplyFilters = () => {
        dispatch(ChangeMarketplaceAPIPath(apiPath))
        dispatch(ChangeMarketplacePricingAction(price))
        dispatch(MarketplaceAPICallStart(true))
        dispatch(handleChangeTierSchemaValueAction(MARKETPLACE_PAGE_TIER_SCHEMA, localActiveSchema))
        handleCloseModal()
    }

    const handleClearAllFilter = () => {
        dispatch(ChangeMarketplacePricingAction({ min: 0, max: null }))
        dispatch(handleChangeTierSchemaValueAction(MARKETPLACE_PAGE_TIER_SCHEMA, ""))
        dispatch(MarketplaceAPICallStart(true))
        handleCloseModal()
    }

    const handleSelectMultipleBuyNowAndAuction = (value) => {
        if (value === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4) &&
            apiPath === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2)) {
            setApiPath(changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 1))
        }
        if (value === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2) &&
            apiPath === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4)) {
            setApiPath(changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 1))
        }

    }


    const handleFilterApiPathChange = (value, checkedState) => {

        if (value === MARKETPLACE_ONCHAIN_CARDS) {
            setApiPath(value)
        }
        else if (value === MARKETPLACE_NOT_FOR_SALE_API) {
            setApiPath(value)
        } else if (apiPath === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 1)) {
            if (checkedState && value === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4)) {
                setApiPath(changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2))
            } else if (checkedState && value === changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 2)) {
                setApiPath(changeURLLastStringMarketplace(MARKETPLACE_CARDS_API, 4))
            }
        } else if (apiPath === MARKETPLACE_NOT_FOR_SALE_API) {
            setApiPath(value)
        } else {
            handleSelectMultipleBuyNowAndAuction(value)
        }
    }


    useEffect(() => {
        if (debouncedPointsValue && debouncedPointsValue.length >= 2) {
            const pointsRanges = { min: debouncedPointsValue[0], max: debouncedPointsValue[1] }
            dispatch(SetMarketplacePointsRange(pointsRanges))
        }
    }, [debouncedPointsValue])

    return (
        <Fragment>
            <div className="pi-15 pt-30">
                <div className="d-flex justify-content-between align-items-center">
                    <button className="btn p-1" onClick={handleCloseModal}>
                        <GoBackIcon />
                    </button>
                    <button className="font12px lightmode-text-purple d-flex align-items-center gap-10 btn p-1"
                        onClick={handleClearAllFilter}>
                        Clear All <CrossIcon />
                    </button>
                </div>
                <h1 className="font16pxSemiBold lightmode-text-blue mt-40">Filters</h1>
                <div className="marketFilterScrollBlock mt-3">
                    <ActiveFilters type="filter" />
                </div>
                <div className="mt-10 pos-rel">
                    <CommonSearch />
                </div>

                <div className="main-white-card mt-10 p-10">

                    <FormGroup>

                        {apiTypes.length > 0 && apiTypes.map((key) => {
                            const checkedState = (apiPath === key?.value) || (apiPath === changeURLLastStringMarketplace(key?.value, 1))
                            return (
                                <FormControlLabel
                                    key={key?.value}
                                    checked={checkedState}
                                    labelPlacement="start"
                                    control={<IOSSwitch sx={{ marginBlock: "3px", marginRight: "15px" }} />}
                                    label={key?.name}
                                    className="MarketLabel"
                                    onChange={() => handleFilterApiPathChange(key?.value, checkedState)}
                                />
                            )
                        })}

                    </FormGroup>
                </div>
                {/* <LightmodeAccordian heading="Filter Heroes and Tanks" type="market" secondaryClassName="marketAccordian">
                    <RadioGroup className='marketFilterScrollBlock mt-20 d-flex flex-row gap-10 align-items-center'>
                        <MarketplaceFilterRadioBtn title="Heros" />
                        <MarketplaceFilterRadioBtn title="Tanks" />
                        <MarketplaceFilterRadioBtn title="Weapons" />
                    </RadioGroup>
                    <div className='mt-20 mb-10 pl-1'>
                        <GlanceCheckbox label="Tank sub category 1" id="1" />
                        <GlanceCheckbox label="Tank sub category 2" id="2" />
                        <GlanceCheckbox label="Tank sub category 3" id="3" />
                        <GlanceCheckbox label="Tank sub category 4" id="4" />
                    </div>
                </LightmodeAccordian> */}

                {showPointsFilter &&
                    <div className=" mt-10">
                        <LightmodeAccordian heading="Points Range" type="market" secondaryClassName="marketAccordian">

                            <AirbnbSlider
                                className='mt-10'
                                valueLabelDisplay="auto"
                                getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                defaultValue={[pointsRange?.min, pointsRange?.max]}
                                onChange={(e) => handleChangePoints(e)}

                            />

                            <div className="d-flex gap-15 justify-content-between align-items-center">
                                <div>
                                    <p className="font10px mb-10 ">Lowest</p>
                                    <input className="listInput w-100" name="lowest"
                                        value={pointsRange?.min}
                                        type="number"
                                        placeholder="$"
                                        onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                        pattern="\d*"
                                        onChange={(e) => dispatch(SetMarketplacePointsRange({ min: e.target.value, max: price?.max }))}
                                        onWheel={(e) => e.target.blur()} />
                                </div>
                                <div>
                                    <p className="font10px mb-10 ">Highest</p>
                                    <input className="listInput w-100" name="highest"
                                        type="number"
                                        value={pointsRange?.max}
                                        placeholder="$"
                                        onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                        pattern="\d*"
                                        onChange={(e) => dispatch(SetMarketplacePointsRange({ max: e.target.value, min: price?.min }))}
                                        onWheel={(e) => e.target.blur()} />
                                </div>
                            </div>
                        </LightmodeAccordian>
                    </div>
                }
                <div className=" mt-10">
                    <LightmodeAccordian heading="Price Range" type="market" secondaryClassName="marketAccordian">
                        <div className="d-flex gap-15 mt-20 justify-content-between align-items-center">
                            <div>
                                <p className="font10px mb-10 ">Lowest</p>
                                <input className="listInput w-100" name="lowest"
                                    value={price?.min}
                                    type="number"
                                    placeholder="$"
                                    onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                    pattern="\d*"
                                    onChange={(e) => setPrice({ min: e.target.value, max: price?.max })}
                                    onWheel={(e) => e.target.blur()} />
                            </div>
                            <div>
                                <p className="font10px mb-10 ">Highest</p>
                                <input className="listInput w-100" name="highest"
                                    type="number"
                                    value={price?.max}
                                    placeholder="$"
                                    onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                    pattern="\d*"
                                    onChange={(e) => setPrice({ max: e.target.value, min: price?.min })}
                                    onWheel={(e) => e.target.blur()} />
                            </div>
                        </div>
                    </LightmodeAccordian>


                </div>
                <div className=" mt-10 pb-150">
                    <LightmodeAccordian heading="Tiers" type="market" secondaryClassName="marketAccordian">
                        <div className="marketFilterScrollBlock mt-20 d-flex gap-10 align-items-center">
                            {schemaOptionsList.length > 0 && schemaOptionsList.map((schema) => {
                                return (
                                    <button
                                        onClick={() => setLocalActiveSchema(schema?.id)}
                                        className={`btn font12px marketFilterBtn px-3 nowrap py-2 ${localActiveSchema === schema.id ? "active gold-text" : "silver-text"}`}>
                                        <GoldExpCoinIcon className="mr-1" />
                                        {schema?.schemaDisplayName}
                                    </button>
                                )
                            })}

                        </div>
                        {/* <div className="mt-30">
                            <input
                                checked={primaryCheck}
                                onClick={handleChangeTerms}
                                className="styled-checkbox"
                                id="primary"
                                type="checkbox"
                                value="value1"
                            />
                            <label htmlFor="primary" className="font12px mb-0 mt-15 w-100">Primary</label>
                            <input
                                checked={secondaryCheck}
                                onClick={handleChangeTerms}
                                className="styled-checkbox"
                                id="secondary"
                                type="checkbox"
                                value="value2"
                            />
                            <label htmlFor="secondary" className="font12px mb-0 mt-15 w-100">Secondary</label>
                        </div>*/}
                    </LightmodeAccordian>

                </div>
            </div>

            <div className="twoGridButtonBlock main-white-card br-0 fixedButton left-0 right-0">
                <button className="btn font12px w-100 lightmode-text-marigold widthdrawBtn" onClick={handleCloseModal}>Cancel
                </button>
                <PrimaryButton heading="Apply" primaryClassName="font16px" onClick={handleApplyFilters} />
            </div>

        </Fragment>
    )
}

export default MarketplaceFilter

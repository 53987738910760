import { TRANSACTION_CARD_FULL_INFO } from "../action-types/collection.type";


const initialState = {
    transactionCardFullInfo: {}
}

const collectionDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION_CARD_FULL_INFO:
            return {
                ...state,
                transactionCardFullInfo: action.payload
            }
        default:
            return state
    }
}

export default collectionDetailReducer

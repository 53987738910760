import React, { Fragment, useEffect, useState } from 'react'
import "../../components/DirectBuy/DirectBuyStyles.css"
import PurchasedCard from '../../components/DirectBuy/PurchasedCard'
import { ClipboardIcon } from '../../helpers/svgConst'
import SecondaryButton from "../../components/Common/SecondaryButton"
import { useLocation, useNavigate } from 'react-router-dom'
import CollectionNFTCard from '../../components/Common/CollectionNFTCard'
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    changeURLLastString,
    GET_ALL_QUERY_PARAMS, PRICE_FORMAT
} from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import PrimaryButton from '../../components/Login/PrimaryButton'
import { useDispatch, useSelector } from "react-redux";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import UpdatesNotification from '../../components/Common/UpdatesNotification'
import {
    DirectBuyNFTRedirectAction,
    ListYourNFTAction,
    MakeAnOfferRedirectAction
} from "../../redux/actions/cardDetailActions";
import ShareDrawer from "../../components/Common/ShareDrawer";
import USER_HELPER from '../../helpers/apis/userHelper'

const NFTDirectBuySuccess = () => {

    const directByNFTInfo = useSelector(state => state?.cardDetail?.directBuyNFT)
    const makeAnOfferNFTInfo = useSelector((state) => state?.cardDetail?.makeAnOfferNFT)
    const listYourNFTInfo = useSelector((state) => state?.cardDetail?.listYourNFT)

    const location = useLocation()
    const queryParam = GET_ALL_QUERY_PARAMS(location?.search)
    const QUERY_TYPE = queryParam?.type

    const { getUserDetails } = USER_HELPER()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState("")
    const [cardsList, setCardsList] = useState({})

    const { getNFTCardsByCollectionId } = COLLECTION_HELPER()

    const getNftInfo = (type) => {
        if (!type) return {}
        if (type === "buy") {
            return directByNFTInfo
        } else if (type === "list") {
            return listYourNFTInfo
        } else if (type === "offer") {
            return makeAnOfferNFTInfo
        }
        return {}
    }

    const cardInfo = getNftInfo(QUERY_TYPE)


    const handleGoToCollectionPage = () => {
        dispatch(ListYourNFTAction({}))
        dispatch(DirectBuyNFTRedirectAction({}))
        dispatch(MakeAnOfferRedirectAction({}))
        navigate(changeURLLastString(Paths.CollectionPage, cardInfo?.collectionId))
    }

    const handleGoToMyCollection = () => {
        dispatch(ListYourNFTAction({}))
        dispatch(DirectBuyNFTRedirectAction({}))
        dispatch(MakeAnOfferRedirectAction({}))
        navigate(Paths.ProfilePage)
    }

    useEffect(() => {
        if (!cardInfo?.collectionId) return

        async function getCardsList() {
            setLoading(API_FETCH_START)
            const res = await getNFTCardsByCollectionId(cardInfo?.collectionId)
            if (res?.status === 200) {
                setCardsList(res?.data?.data)
            }
            setLoading(API_FETCH_COMPLETE)
        }

        getCardsList()
        // updating balance here
        getUserDetails()
    }, [cardInfo])

    return (
        <Fragment>

            <Fragment>
                <section className="newFettiBG" />
                <section className='lightMode kycBG'>
                    <div className='kycSubBG pt-100'>
                        <div className='pi-15'>

                            <div className='final-card-block'>
                                <PurchasedCard data={cardInfo} />
                            </div>

                            <p className='font24pxSemiBold lightmode-text-marigold text-center mt-20'>Congrats!</p>
                            {QUERY_TYPE === "list" &&
                                <p className='font14px lightmode-text-blue text-center mt-10'>
                                    Your NFT is listed for sale
                                </p>
                            }
                            {QUERY_TYPE === "buy" &&
                                <p className='font14px lightmode-text-blue text-center mt-10 mw-270 mx-auto'>
                                    You have successfully bought {cardInfo?.name} NFT
                                    for $ {PRICE_FORMAT(cardInfo?.listedPrice)}. Go to your collection page and enjoy the benefits!
                                </p>
                            }

                            {QUERY_TYPE === "offer" &&
                                <p className='font14px lightmode-text-blue text-center mt-10 mw-270 mx-auto'>
                                    You have made an offer of $ {PRICE_FORMAT(cardInfo?.offeredPrice)} on
                                    this NFT. If the current holder accepts your offer, it will be automatically added to
                                    your collection.
                                </p>
                            }


                            <ShareDrawer cardId={cardInfo?.id} />

                            <PrimaryButton icon={<ClipboardIcon className="mr-2" />}
                                heading="Go back to My Collection"
                                primaryClassName="mt-30 w-fit py-3 px-4 mx-auto font12px"
                                onClick={handleGoToMyCollection} />

                            <UpdatesNotification />



                            <div className='main-white-card mt-25'>
                                <div className='d-flex justify-content-between align-items-center mb-15'>
                                    <p className='font16pxSemiBold lightmode-text-blue'>Recommended for you</p>
                                    <p className='font12px lightmode-text-marigold'>{cardsList?.count} NFTs</p>
                                </div>
                                <div className='NFT-card-sec'>
                                    <CollectionNFTCard data={cardsList?.lists} loading={loading} />
                                </div>

                                <SecondaryButton heading="View complete collection" className="mt-25"
                                    onClick={handleGoToCollectionPage} />
                            </div>
                        </div>

                    </div>
                </section>
            </Fragment>

            {/*<div className="confettiBG">

            </div>


            <section className='lightMode pi-10 confirmPageFlex gradientBG pb-20'>

                <div className='confirmPageGrid'>
                    <h1 className='font24pxSemiBold lightmode-text-blue'>Congrats!</h1>
                    <p className='font12px lightmode-text-midnight-blue mt-1'>
                        You own {cardInfo?.name} NFT
                    </p>

                    <PurchasedCard data={cardInfo} />
                    <ShareDrawer />
                </div>


                <PrimaryButton icon={<ClipboardIcon className="mr-2" />} heading="Go to My Collection"
                    primaryClassName="mt-60 w-fit py-3 px-4 mx-auto font12px"
                    onClick={handleGoToMyCollection} />

                <UpdatesNotification />

                <div className='main-white-card purchaseOfferGrid mt-20'>
                    <div className='purchaseOfferImageBlock'>
                        <GlanceImage src={OfferImage} alt="Thumb" />
                    </div>
                    <div>
                        <p className='font12pxMedium lightmode-text-blue'>Upgrade your Silver NFTs to Gold tier and
                            get extra benefits!</p>
                        <button className={`btn p-0 font12px  lightmode-text-marigold`} onClick={() => navigate(Paths.TierPage)}>
                            Learn how
                            <ArrowRightIcon stroke="#E48840" className="ml-2" />
                        </button>
                    </div>
                </div>
                <div className='main-white-card mt-25'>
                    <div className='d-flex justify-content-between align-items-center mb-15'>
                        <p className='font16pxSemiBold lightmode-text-blue'>Recommended for you</p>
                        <p className='font12px lightmode-text-marigold'>20 NFTs</p>
                    </div>
                    <div className='NFT-card-sec'>
                        <CollectionNFTCard data={cardsList?.lists} loading={loading} />
                    </div>

                    <SecondaryButton heading="View complete collection" className="mt-25"
                        onClick={handleGoToCollectionPage} />
                </div>

            </section>*/}


        </Fragment>
    )
}

export default NFTDirectBuySuccess

import {PAYMENT_SUCCESS_REDIRECT_URL} from "../action-types/common.type";

export const handleChangeTierSchemaValueAction = (type, schemaValue) => {
    return {
        type: type,
        schemaValue
    }
}
export const SetPaymentSuccessRedirectURL = (url) => {
    return {
        type: PAYMENT_SUCCESS_REDIRECT_URL,
        url
    }
}


import { INITIATE_LOGIN, REDIRECT_AFTER_LOGIN, SET_METAMASK_ADDRESS, USER_LOGIN_SUCCESS } from "../action-types/userAction.types";

const initialState = {
    isLogin: false,
    userInfo: {}, // you will get all info related to user
    initiateLoginData: {},
    redirectAfterLogin: "",
    metaMaskAddress: ""

}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLogin: true,
                userInfo: action.payload
            }
        case INITIATE_LOGIN:
            return {
                ...state,
                initiateLoginData: action.data
            }
        case REDIRECT_AFTER_LOGIN:
            return {
                ...state,
                redirectAfterLogin: action.url
            }
        case SET_METAMASK_ADDRESS:
            return {
                ...state,
                metaMaskAddress: action?.address
            }

        default:
            return state
    }
}

export default userReducer

import React, { Fragment, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import Loader from "react-loader-spinner";
import ShopCardWithoutLink from "./ShopCard"
import { API_BASE_URL } from "../../constants/envConstants";
import Paths from "../../helpers/Paths";
import PrimaryButton from "../../components/Login/PrimaryButton";


const UnpackCards = () => {

    const params = useParams()
    const ASSET_ID = params.assetId
    const PACK_NAME = params.packName
    const COLLECTION_ID = params?.collectionId

    const vidRef = useRef(null);
    const history = useNavigate()

    const queryParam = history?.location?.search

    const urlSearchParams = new URLSearchParams(queryParam);
    const finalQueryParam = Object.fromEntries(urlSearchParams.entries());


    const [errorMessage, setErrorMessage] = useState('')
    const [finalMintedData, setFinalMintedData] = useState([])
    const [showCards, setShowCards] = useState(false)

    const [loader, setLoader] = useState(true)
    const [successMessage, setSuccessMessage] = useState("")


    const getCollectionName = () => {
        return COLLECTION_ID
    }

    const getVideoUrl = () => {

        if (PACK_NAME === 'promo-pack-t20') {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/card-pack-logo.mp4"
        } else if (PACK_NAME === 'summer-promo-pack-t20') {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/card-unpack2.mp4"
        } else if (PACK_NAME === '2022-series-promo-pack') {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/card-unpack2.mp4"
        }
        else if (PACK_NAME === "summer-series-t20-pack") {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/card-unpack.mp4"
        } else if (PACK_NAME === "genesis-t20-team-pack") {
            return "https://owens.sfo3.cdn.digitaloceanspaces.com/team-opening.mp4"
        } else if (PACK_NAME === "mints-raffle-ticket") {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/card-unpack2.mp4"
        } else if (PACK_NAME === "stoney-baloney-pack") {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/stoney-unpacking.mp4"
        } else {
            return "https://ow3ns.sfo3.digitaloceanspaces.com/loader_video.mp4"
        }
    }

    const handlePlayVideo = () => {
        vidRef.current.play();
    };


    const unpackCards = () => {
        if (!ASSET_ID) return
        setErrorMessage('')
        setLoader(true)
        axios.post(API_BASE_URL + 'card/unpack/' + ASSET_ID)
            .then((res) => {
                if (res.status === 200) {
                    const apiData = res?.data;

                    const mintedApiResult = apiData?.cardDetails;
                    setFinalMintedData(mintedApiResult)
                    // addToast(res.data?.data?.message ? res.data?.data?.message : "Success", {
                    //     appearance: 'success',
                    //     autoDismiss: true
                    // })
                    if (PACK_NAME === "gno-city-starter-pack-10-nfts") {
                        setSuccessMessage("Mint Successfully completed")
                    }
                }
            }).catch((err) => {
                // addToast(err?.response?.data?.message ? err?.response?.data?.message : "Failed", {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
                setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : "Failed")
            }).finally(() => {
                setLoader(false)
            })
    }




    useEffect(() => {
        window.scroll(0, 0)
        unpackCards()

    }, [])



    // useEffect(() => {

    //     const dummy = { "message": "Successfully unpacked", "cardDetails": [{ "id": "ca9664a4-536d-4eeb-a70c-be757f1b2235", "name": "Tilak Varma", "image": "https://gateway.pinata.cloud/ipfs/QmfL75kWmuvyRsxTqCvUKj3X7EavBkSX73qKG5U3yg5hoh", "video": "", "assetId": "1099683823147" }, { "id": "e9a9dbba-25da-4035-a310-edf0a77377ff", "name": "Yash Dayal", "image": "https://gateway.pinata.cloud/ipfs/QmYByM6fyskvkQZ8w9jWsTQCVUa38QPpQyeuVqvQdR5Hq3", "video": "", "assetId": "1099683955826" }, { "id": "44830e51-4c4b-44c5-890b-4a7455659d3f", "name": "Abhishek Sharma", "image": "https://gateway.pinata.cloud/ipfs/Qmbj38pejMWk1VjPtFPY69m44UNrHuUFVhBrF8WAjYWkcs", "video": "", "assetId": "1099681387714" }] }
    //     setFinalMintedData(dummy?.cardDetails)
    // }, [])


    return (
        <Fragment>
            <section className="lightMode pt-20 mb-50  pi-15">

                {(loader) && !showCards &&
                    <div className="col-md-12 d-flex justify-content-center loaderCenterPadding mt-5">
                        <Loader color={"#807f7f"} type="Oval" width={60}
                            height={60} />
                    </div>
                }

                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="row">
                            {!loader && !showCards &&
                                <video
                                    ref={vidRef}
                                    className="unbox__openerAnimation"
                                    autoPlay={true}
                                    muted={true}
                                    onClick={() => handlePlayVideo(true)}
                                    onEnded={() => setShowCards(true)}
                                >
                                    <source src={getVideoUrl()} type="video/mp4" />
                                </video>
                            }

                            {!loader && showCards &&
                                <Fragment>
                                    <div className="col-md-12">
                                        <h5 className="text-center mt-5">
                                            Unpacked Cards
                                        </h5>
                                    </div>


                                    <div className="col-md-12 mt-5">
                                        <div className="row justify-content-center">
                                            <ShopCardWithoutLink shopData={finalMintedData} fullPathImage={true} />
                                        </div>
                                    </div>


                                    {!loader && finalMintedData.length === 0 &&
                                        <div className="col-md-12 mt-5">
                                            <div className="d-flex justify-content-center">
                                                {!errorMessage &&
                                                    <p className="alert alert-warning">
                                                        Your card is minted successfully,
                                                        but Atomic api is taking too much time to load the
                                                        data, so go back and check My Collection
                                                    </p>
                                                }
                                                {errorMessage &&
                                                    <p className="alert alert-danger">
                                                        {errorMessage}
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    }

                                </Fragment>

                            }


                            {successMessage &&
                                <div className="col-md-12 mt-5 mb-5">
                                    <p className="alert alert-success">
                                        {successMessage}
                                    </p>
                                </div>
                            }

                            <div className="col-md-12 mt-5 mb-2">
                                {PACK_NAME !== "mints-raffle-ticket" &&
                                    <PrimaryButton heading="Open More Packs" primaryClassName="my-2"
                                        onClick={() => history(Paths.ProfilePage + "?tab=4")} />
                                }
                                <PrimaryButton
                                    heading="My Collection" primaryClassName="my-2"
                                    onClick={() => history(Paths.ProfilePage + "?tab=0")} />
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default UnpackCards

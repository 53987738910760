import React, { Fragment, useEffect, useState } from 'react'
import "../../components/Settings/SettingsStyles.css"
import { FormControlLabel, Switch } from '@mui/material'
import { DiscordIcon, GoBackIcon, InstagramIcon, TwitterIcon, UploadFileIcon } from '../../helpers/svgConst'
import Thumb from "../../images/LoginImage/AsianMan.png"
import { styled } from '@mui/material/styles';
import PrimaryButton from '../../components/Login/PrimaryButton'
import USER_HELPER from '../../helpers/apis/userHelper'
import Dropzone from 'react-dropzone';
import GlanceImage from '../../components/Common/GlanceImage'
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UPDATE_PROFILE_API, UPLOAD_PROFILE_IMAGE_API } from '../../helpers/ApisPath'
import http from "../../helpers/Interceptors"
import Paths from '../../helpers/Paths'
import { IOSSwitch } from '../../helpers/MuiHelpers'




const UserProfileSetting = () => {

    const { handleGoBack, getUserDetails, loginUserInfo } = USER_HELPER()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [imagePath, setImagePath] = useState("")
    const [errorMessage, setErrorMessage] = useState({ status: false, message: "" })
    const [loading, setLoading] = useState(false)
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [imageErrorMessage, setImageErrorMessage] = useState("")
    const [file, setFile] = useState([])

    const initialSchema = {
        name: "",
        discord: "",
        twitter: "",
        instagram: "",

    };

    const formValidationSchema = Yup.object({
        name: Yup.string().required("Name is required").matches(/^[aA-zZ\s]+$/, "Invalid characters in the name. Only A-Z allowed."),
    });




    const handleImageChange = (fileInfo) => {
        setImageErrorMessage("")
        setFile(fileInfo.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFile(fileInfo)
    }

    const handleFileUpload = () => {
        if (!file && file.length < 1) return
        setImageUploadLoading(true)
        const formData = new FormData()

        if (!!file) {
            formData.append(
                "photo",
                file[0],
                file[0].name
            )
        }

        http.post(UPLOAD_PROFILE_IMAGE_API, formData).then((res) => {
            console.log("imageres", res)
            setImagePath(res.data?.data?.s3link)
        }).catch((err) => {
            console.error("Err", err)
        }).finally(() => {
            setImageUploadLoading(false)
        })
    }

    const handleSubmit = (values) => {
        setErrorMessage({ status: false, message: "" })
        setLoading(true)
        console.log('imagepath', imagePath)
        const formData = {
            "name": values.name,
            "profilePic": imagePath,
            "socialProfile": {
                "discord": values.discord,
                "twitter": values.twitter,
                "instagram": values.instagram,
            }
        }

        http.post(UPDATE_PROFILE_API, formData)
            .then((res) => {
                if (res.status === 200) {
                    // dispatch(InitiateLogin(res?.data?.data))
                    setTimeout(() => {
                        navigate(Paths.ProfilePage)
                        getUserDetails()
                    }, 200)
                }
            }).catch((err) => {
                setErrorMessage({ status: true, message: "Failed to update profile info" })
            }).finally(() => {
                setLoading(false)
            })
    };

    useEffect(() => {
        if (file.length === 0) return
        if (!!file[0].size) {
            let finalSize = ((file[0].size / 1024) / 1024)
            if (finalSize > 2) {
                setImageErrorMessage("Couldn’t upload! File size should not be more than 2 MB.")
                return
            }
        }
        handleFileUpload()
    }, [file])

    useEffect(() => {
        getUserDetails()
        console.log('details', loginUserInfo)
    }, [])

    return (
        <Fragment>
            <section className='lightMode'>
                <div className='pi-15 pt-25'>
                    <button className='btn' onClick={handleGoBack}>
                        <GoBackIcon />
                    </button>
                    <div className='main-white-card mt-20 p-20 pt-35'>
                        <div className='settingThumbBlock'>
                            <GlanceImage
                                src={(file && file.length > 0) ? file[0].preview : loginUserInfo?.profilePic ? loginUserInfo?.profilePic : Thumb}
                                alt="thumb" />
                        </div>
                        <Dropzone onDrop={acceptedFiles => handleImageChange(acceptedFiles)}
                            maxFiles={1}
                            multiple={false}
                            accept={{ 'image/*': [] }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <button
                                            className="btn uploadBtn mx-auto mt-25 lightmode-text-secondary d-flex align-items-center"
                                            disabled={imageUploadLoading}>
                                            <UploadFileIcon className="mr-2" />
                                            {file.length > 0 || loginUserInfo?.profilePic ? "Change Profile Photo" : "Upload profile photo"}
                                        </button>

                                        {imageUploadLoading &&
                                            <p className="gold-clr mt-2 font10px">
                                                Please wait uploading...
                                            </p>
                                        }

                                        {imageErrorMessage &&
                                            <p className="form-error w-80">
                                                {imageErrorMessage}
                                            </p>
                                        }

                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <Formik initialValues={initialSchema} validationSchema={formValidationSchema}
                            onSubmit={handleSubmit}>
                            <Form>
                                <label htmlFor='Name' className='font10px d-block mt-50'>First and Last Name</label>
                                <Field
                                    type="text"
                                    id='Name'
                                    name="name"
                                    placeholder='First and Last Name'
                                    className='listInput lightmode-text-blue w-100 ' />
                                <p className="form-error">
                                    <ErrorMessage name="name" />
                                </p>
                                <label htmlFor='discord' className='font10px d-block mt-15'>Discord</label>
                                <div className='pos-rel'>
                                    <DiscordIcon className="settingAbsIcon" />
                                    <Field
                                        type="text"
                                        id='discord'
                                        name='discord'
                                        placeholder='Discord'
                                        className='listInput lightmode-text-blue w-100 pl-50' />
                                </div>
                                <label htmlFor='twitter' className='font10px d-block mt-15'>Twitter</label>
                                <div className='pos-rel'>
                                    <TwitterIcon className="settingAbsIcon" />
                                    <Field
                                        type="text"
                                        id='twitter'
                                        name='twitter'
                                        placeholder='Twitter'
                                        className='listInput lightmode-text-blue w-100 pl-50' />
                                </div>
                                <label htmlFor='instagram' className='font10px d-block mt-15'>Instagram</label>
                                <div className='pos-rel'>
                                    <InstagramIcon className="settingAbsIcon" />
                                    <Field
                                        type="text"
                                        id='instagram'
                                        name='instagram'
                                        placeholder='Instagram'
                                        className='listInput lightmode-text-blue w-100 pl-50' />
                                </div>
                                <div className='pos-rel'>
                                    <div className='bottomBtnBlock fixedButton left-0 right-0 p-15'>
                                        <PrimaryButton type="submit" heading="Save changes" primaryClassName='font16px' />
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                    {/* <div className='main-white-card mt-15 pb-100'>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ marginBlock: "3px", marginRight: "15px" }} defaultChecked />}
                            label="Allow offers on unlisted NFTs"
                            labelPlacement="start"
                            className="MarketLabel"
                        />


                    </div> */}

                </div>
            </section>
        </Fragment>
    )
}

export default UserProfileSetting
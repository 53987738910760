// File is used for Share functionality
import React, { Fragment, useState } from 'react'
import {
    DrawerLine,
    FacebookShareIcon,
    LinkIcon,
    ShareIcon,
    TelegramShareIcon,
    TwitterShareIcon
} from "../../helpers/svgConst";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { changeURLLastString } from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import { WEB_URL } from "../../constants/envConstants";
import { toast } from 'react-toastify';

const ShareDrawer = ({ cardId, closeFunc }) => {

    const sharableUrl = WEB_URL + changeURLLastString(Paths.TokenDetailPage, cardId)
    const [state, setState] = useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleCopy = () => {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(sharableUrl)
        toast.success("Link successfully copied");
    }

    const ShareTray = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel mb-25"
            >

                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>
                <div className='p-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Share on your socials!</h1>
                    <Divider />
                    <label htmlFor='link' className='font10px mt-25'>Click and copy share link</label>
                    <div className='pos-rel listInput'>
                        <input type="url" placeholder='http://Owens.app/Tigershroff_...' value={sharableUrl}
                            className='border-0 bg-white linkShareOverflowText' />
                        <button className='btn p-0 linkAbsBtn' onClick={handleCopy}>
                            <LinkIcon />
                        </button>
                    </div>
                </div>


                <div className='pl-15 mt-20 shareIconsBlock'>
                    <button className='btn'>
                        <FacebookShareButton url={sharableUrl}>
                            <FacebookShareIcon />
                        </FacebookShareButton>

                    </button>
                    <button className='btn'>
                        <WhatsappShareButton url={sharableUrl}>
                            <WhatsappIcon style={{ borderRadius: 50 }} />
                        </WhatsappShareButton>
                    </button>
                    <button className='btn'>
                        <TwitterShareButton url={sharableUrl}>
                            <TwitterShareIcon />
                        </TwitterShareButton>
                    </button>
                    <button className='btn'>
                        <TelegramShareButton url={sharableUrl}>
                            <TelegramShareIcon />
                        </TelegramShareButton>
                    </button>
                </div>

            </Box>
        )
    }


    return (
        <Fragment>
            <div className='d-flex justify-content-center align-items-center  mt-20'>
                <button className='btn font12pxMedium lightmode-text-secondary d-flex align-items-center'
                    onClick={toggleDrawer("bottom", true)}>
                    <ShareIcon className="mr-2" /> Share on socials
                </button>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={state["bottom"]}
                    onClose={toggleDrawer("bottom", false)}
                    onOpen={toggleDrawer("bottom", true)}
                    className="swipableDrawerBlock"
                >
                    {ShareTray("bottom")}
                </SwipeableDrawer>

            </div>
        </Fragment>
    )
}

export default ShareDrawer


import React, { Fragment, useEffect, useState } from 'react'
import SubHeader from "../../components/Common/SubHeader"
import { AmountIcon, EmptyWallet, FlashIcon, InfoIcon } from '../../helpers/svgConst'
import CheckoutListView from './CheckoutListView'
import PrimaryButton from "../../components/Login/PrimaryButton"
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import http from "../../helpers/Interceptors"
import { changeURLLastString, GET_ERROR_TOAST_MSG, PRICE_FORMAT } from "../../constants/commonFunc";
import { DIRECT_BUY_NFT_API } from "../../helpers/ApisPath";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { useDispatch, useSelector } from "react-redux";
import USER_HELPER from "../../helpers/apis/userHelper";
import { SetPaymentSuccessRedirectURL } from "../../redux/actions/commonAction";

const DirectBuyNFT = () => {

    const cardInfo = useSelector(state => state?.cardDetail?.directBuyNFT)
    const daysForRent = useSelector(state => state?.cardDetail?.daysForRent)
    const marketPlaceId = cardInfo?.marketplaceId
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ state: false, message: "" })
    const [walletChecked, setWalletChecked] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isUserLogin, getUserDetails, loginUserInfo } = USER_HELPER()

    const handleToggleCheckbox = () => {
        setWalletChecked(prevState => !prevState)
    }

    const handleGoToDepositPage = () => {
        const url = changeURLLastString(Paths.DirectBuyNFTPage, cardInfo?.id)
        dispatch(SetPaymentSuccessRedirectURL(url))
        const requiredAmount = PRICE_FORMAT(cardInfo?.listedPrice - loginUserInfo?.balance)
        navigate(Paths.DepositMoneyPage + "?amount=" + requiredAmount, { replace: true })
    }

    const handleDirectBuyCard = () => {
        setErrorMessage({ status: false, message: "" })
        if (!walletChecked) {
            setErrorMessage({ state: true, message: "Please select the wallet" })
            return
        }
        setLoading(true)
        const apiPath = changeURLLastString(DIRECT_BUY_NFT_API, marketPlaceId)
        http.post(apiPath)
            .then((res) => {
                if (res.status === 200) {
                    navigate(Paths.NFTDirectBuySuccessPage + "?type=buy", { replace: true })
                }
            }).catch((err) => {
                const errorMsg = GET_ERROR_TOAST_MSG(err)
                console.error("err", err)
                setErrorMessage({ state: true, message: "Failed to purchase NFT " + errorMsg })
            }).finally(() => {
                setLoading(false)
            })
    }

    const handleRentCard = () => {
        setErrorMessage({ status: false, message: "" })
        if (!walletChecked) {
            setErrorMessage({ state: true, message: "Please select the wallet" })
            return
        }
        const formData = {
            numberOfDays: daysForRent
        }
        setLoading(true)
        const apiPath = changeURLLastString(DIRECT_BUY_NFT_API, marketPlaceId)
        http.post(apiPath, formData)
            .then((res) => {
                if (res.status === 200) {
                    navigate(Paths.NFTDirectBuySuccessPage + "?type=buy", { replace: true })
                }
            }).catch((err) => {
                const errorMsg = GET_ERROR_TOAST_MSG(err)
                console.error("err", err)
                setErrorMessage({ state: true, message: "Failed to purchase NFT " + errorMsg })
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if ((cardInfo?.vaccount === loginUserInfo?.vaccount)) {
            navigate(changeURLLastString(Paths.TokenDetailPage, cardInfo?.id))
        }
        getUserDetails()
    }, [cardInfo])



    return (
        <Fragment>
            <section className='lightMode'>
                <div className='pi-15'>
                    <SubHeader />
                    <h1 className='font24pxSemiBold lightmode-text-blue mt-40 mb-15'>Checkout</h1>
                    <CheckoutListView data={cardInfo} />
                    <div className='main-white-card d-flex p-10 justify-content-between align-items-center'>
                        <p className='font10px lightmode-text-marigold'>#{cardInfo?.mintNumber}/{cardInfo?.totalMint}</p>
                        <p className='font14pxSemiBold lightmode-text-blue text-right'>Price:
                            $ {PRICE_FORMAT(cardInfo?.listedPrice, false)}</p>
                    </div>
                    {/* This below code is comment for bug fix */}

                    <div className=' d-flex gap-10 align-items-center mt-25'>
                        <FlashIcon />
                        <p className='font14px lightmode-text-blue'>Recommended method</p>
                        <InfoIcon />
                    </div>
                    <div className='main-white-card p-15 mt-10'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-10'>
                                <EmptyWallet color="#262476" />
                                <p className='font16px lightmode-text-blue'>Wallet</p>
                            </div>
                            {cardInfo?.listingType !== 6 &&
                                <p className='font14px lightmode-text-marigold ml-auto'>Pay
                                    $ {PRICE_FORMAT(cardInfo?.listedPrice, false)}</p>}
                            {cardInfo?.listingType === 6 &&
                                <p className='font14px lightmode-text-marigold ml-auto'>Pay
                                    $ {PRICE_FORMAT((cardInfo?.listedPrice * daysForRent), false)}</p>}
                            <FormGroup>
                                <Checkbox
                                    checked={walletChecked}
                                    onChange={handleToggleCheckbox}
                                    sx={{
                                        color: "#20A03C",
                                        '&.Mui-checked': {
                                            color: "#20A03C",
                                        },
                                    }} />
                            </FormGroup>
                        </div>
                        {cardInfo?.listingType !== 6 && loginUserInfo?.balance < cardInfo?.listedPrice &&
                            // When balance is low and you try to purchase an NFT
                            <PrimaryButton primaryClassName="mt-25"
                                heading={`Add $ ${PRICE_FORMAT(cardInfo?.listedPrice - loginUserInfo?.balance)} to the wallet`}
                                onClick={handleGoToDepositPage}
                                isLoading={loading}
                                icon={<AmountIcon color="#FFF" className="mr-2" />} />
                        }

                        {cardInfo?.listingType === 6 &&
                            //This is for renting the nft
                            <PrimaryButton primaryClassName="mt-25"
                                heading={`Pay  $ ${PRICE_FORMAT((cardInfo?.listedPrice * daysForRent), false)}`}
                                onClick={handleRentCard}
                                isLoading={loading}
                                icon={<AmountIcon color="#FFF" className="mr-2" />} />
                        }

                        {cardInfo?.listingType === 6 && loginUserInfo?.balance <= (cardInfo?.listedPrice * daysForRent) &&
                            // When Balance is enough in your account in case of renting
                            <PrimaryButton primaryClassName="mt-25"
                                heading={`Add $ ${PRICE_FORMAT((cardInfo?.listedPrice * daysForRent) - loginUserInfo?.balance)} to the wallet`}
                                onClick={handleRentCard}
                                isLoading={loading}
                                icon={<AmountIcon color="#FFF" className="mr-2" />} />
                        }


                        {cardInfo?.listingType !== 6 && loginUserInfo?.balance >= cardInfo?.listedPrice &&
                            // When Balance is enough in your account
                            <PrimaryButton primaryClassName="mt-25"
                                heading={`Pay  $ ${PRICE_FORMAT(cardInfo?.listedPrice, false)}`}
                                onClick={handleDirectBuyCard}
                                isLoading={loading}
                                icon={<AmountIcon color="#FFF" className="mr-2" />} />
                        }

                        {errorMessage?.state &&
                            <p className="form-error mt-3">
                                {errorMessage?.message}
                            </p>
                        }

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default DirectBuyNFT

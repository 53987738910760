import React, { Fragment } from 'react'
import "../Collection/CollectionStyles.css"
import { VerifiedIcon } from '../../helpers/svgConst'
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import {
    API_FETCH_COMPLETE,
    changeURLLastString,
    CheckWAXImage,
    PRICE_FORMAT,
    TIME_RENDERER
} from "../../constants/commonFunc";
import NFTSkeleton from "../../components/Common/Skeleton/NFTSkeleton"
import GlanceImage from './GlanceImage';
import LikeUnlikeButton from "./LikeUnlikeButton";
import { NFT_CARD } from "../../constants/envConstants";
import Countdown from "react-countdown";


const CollectionNFTCard = ({ loading, data }) => {

    const navigate = useNavigate()
    const handleGoToNFTDetailPage = (cardId) => {
        navigate(changeURLLastString(Paths.TokenDetailPage, cardId))
    }

    return (
        <Fragment>


            {!!data && data.length > 0 && data.map((prod) => {
                return (
                    <Fragment key={prod?.cardId}>
                        <div className='pos-rel' onClick={() => handleGoToNFTDetailPage(prod?.cardId)}>

                            <LikeUnlikeButton type={NFT_CARD} id={prod?.cardId} />

                            <div className='pos-rel'>
                                <div className='NFTSecImageBlock'>
                                    <GlanceImage src={CheckWAXImage(prod?.image)} alt="thumb" />
                                </div>

                                {/* This below code is for auction cards */}
                                {prod?.expiryTime && prod?.listingType === 2 &&
                                    <div className='auction-text'>
                                        <p className='font8pxBold text-white'>Auction Ends in:</p>
                                        <p className='font8pxBold nowrap text-white'>
                                            <Countdown
                                                date={prod?.expiryTime}
                                                zeroPadDays={1}
                                                renderer={TIME_RENDERER}
                                            />
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className='NFTSecDetails'>
                                <p className='nftNameOverflow max-w-120 mb-2 font10px '>
                                    {prod?.name}
                                </p>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <VerifiedIcon width={15} height={15} className="mr-1" />
                                        <p className='font12px lightmode-text-blue'>#{prod?.mintNumber}
                                            {/* <span className='font8px lightmode-text-gray'>/9999</span> */}
                                        </p>
                                    </div>
                                    {!!prod?.listedPrice &&
                                        <p className='font12px lightmode-text-blue'>$ {PRICE_FORMAT(prod?.listedPrice)}</p>
                                    }
                                </div>
                                {/* <Divider className='mt-10 mb-10' />
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='goldExpBlock ml-0 d-flex'>
                                        <p className='font8pxSemiBold mr-1 lightmode-text-secondary'>+590</p>
                                        <GoldExpCoinIcon />
                                    </div>
                                    <button className='btn p-0'>
                                        <MoreMenuIcon />
                                    </button>
                                </div> */}
                            </div>

                        </div>
                    </Fragment>
                )
            })}


            {(loading !== API_FETCH_COMPLETE) &&
                <NFTSkeleton imageHeight={80} className={"mb-4"} />
            }


        </Fragment>
    )
}

export default CollectionNFTCard

// NFT Listing Component based on Type Auction Based or Fixed Price
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../helpers/Interceptors";
import { GET_NFT_LISTING_FEES_API, LIST_YOUR_NFT_API } from "../../helpers/ApisPath";
import { changeURLLastString, SEND_PRICE_FORMAT } from "../../constants/commonFunc";
import PrimaryButton from "../Login/PrimaryButton";
import NFTListingFeeInfo from "./NFTListingFeeInfo";
import NFTListViewDesign from "./NFTListViewDesign";
import USER_HELPER from "../../helpers/apis/userHelper";
import Paths from "../../helpers/Paths";
import { useSelector } from "react-redux";
import { DiscountIcon } from "../../helpers/svgConst";

const NFTListingForm = ({ type }) => {

    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [price, setPrice] = useState("")
    const [feesData, setFeesData] = useState({})
    const [fees, setFees] = useState(0)
    const CARD_ID = params?.cardId
    const { handleGoBack, loginUserInfo } = USER_HELPER()
    const [errorMessage, setErrorMessage] = useState({ state: false, message: "" })
    const navigate = useNavigate()
    const listNFTInfo = useSelector((state) => state?.cardDetail?.listYourNFT)
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")

    const handleGoToListSuccessPage = () => {
        const path = Paths.NFTDirectBuySuccessPage
        navigate(path + "?type=list", { state: true })
    }

    const getDeductedNFTPrice = useMemo(() => {
        if (!price) return 0
        if (price) {
            return (price - ((price * fees) / 100))
        }
    }, [fees, price])


    const handleListNFT = () => {
        if (!price) {
            setErrorMessage({ state: true, message: "Please enter price to list your nft" })
            return
        }
        if (!CARD_ID) {
            setErrorMessage({ state: true, message: "card not found" })
            return
        }


        setErrorMessage({ state: false, message: "" })
        const priceFormatted = SEND_PRICE_FORMAT(price);
        let formData = {
            "cardId": CARD_ID,
            "price": parseFloat(priceFormatted),
            "listingType": type === "FIXED_PRICE" ? 4 : type === "AUCTION" ? 2 : 6   //
        }


        if (type === "AUCTION") {
            if (!date) {
                setErrorMessage({ state: true, message: "Please select expiry date" })
                return
            }
            if (!time) {
                setErrorMessage({ state: true, message: "Please select expiry time" })
                return
            }

            try {
                const formatDate = new Date(date + "T" + time)
                const expiryTime = {
                    "expiryTime": new Date(formatDate).toISOString()
                }
                formData = {
                    ...formData,
                    ...expiryTime
                }
            } catch (e) {
                setErrorMessage({
                    state: true,
                    message: "Time Format issue"
                })
                return
            }

        }

        // if (type === "RENT") {
        //     if (!date) {
        //         setErrorMessage({ state: true, message: "Please select expiry date" })
        //         return
        //     }

        //     try {
        //         const formatDate = new Date(date)
        //         const expiryTime = {
        //             "expiryTime": new Date(formatDate).toISOString()
        //         }
        //         formData = {
        //             ...formData,
        //             ...expiryTime
        //         }
        //     } catch (e) {
        //         setErrorMessage({
        //             state: true,
        //             message: "Time Format issue"
        //         })
        //         return
        //     }
        // }

        setLoading(true)
        http.post(LIST_YOUR_NFT_API, formData)
            .then((res) => {
                if (res.status === 200) {
                    handleGoToListSuccessPage()
                }
            }).catch((err) => {
                setErrorMessage({ state: true, message: "Failed to list nft " + err?.response?.data?.message })
            }).finally(() => {
                setLoading(false)
            })
    }


    const handleGetListingFees = () => {
        if (!listNFTInfo?.collectionId) return
        const apiPath = changeURLLastString(GET_NFT_LISTING_FEES_API, listNFTInfo?.collectionId)
        http.get(apiPath).then((res) => {
            const apiData = res?.data?.data
            setFeesData(apiData)
            const { marketFee, collectionFee } = apiData;
            const totalFeePercentage = parseFloat(marketFee) + parseFloat(collectionFee)
            setFees(totalFeePercentage)
        }).catch((err) => {
            console.error("Error fees", err)
        })
    }

    const handleDateChange = (e) => {
        const date = e.target.value
        const currentDate = new Date()
        const currentTime = new Date(currentDate.toDateString()).getTime()
        const inputDateTime = new Date(date).getTime()

        console.log({ currentTime }, { inputDateTime }, currentTime > inputDateTime)
        if (inputDateTime > currentTime) {
            const formattedDate = new Date(e.target.value).toISOString().split("T")[0]
            setDate(formattedDate)
        }
    }

    useEffect(() => {
        if (!!listNFTInfo) {
            handleGetListingFees()
        }
    }, [listNFTInfo])


    return (
        <Fragment>

            <div className="d-flex flex-column">

                <h2 className='mt-40 mb-15 font14pxSemiBold lightmode-text-midnight-blue'>
                    {type === "FIXED_PRICE" ? "Set a fixed selling price for your NFT" : "Set an auction price for your NFT"}

                </h2>
                <NFTListViewDesign shopData={[listNFTInfo]} />

                {type === "FIXED_PRICE" &&
                    <Fragment>
                        <div className='main-white-card nftListForm align-items-center'>
                            <p className='font12px lightmode-text-midnight-blue '>
                                Set a price at which you would prefer to
                                sell this NFT
                            </p>
                            <div
                                className='lightmode-text-secondary d-flex align-items-center justify-content-center listInput'>
                                <span className="">$</span>
                                <input type="number"
                                    pattern="\d*"
                                    className='inputBlock w-100 lightmode-text-secondary font16pxBold'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
                {type === "AUCTION" &&
                    <Fragment>
                        <div className='main-white-card '>
                            <div className="nftListForm align-items-center">
                                <div className="">
                                    <p className="font12px lightmode-text-midnight-blue">Set a minimum price to kickstart
                                        your auction.</p>
                                    <p className="font12px lightmode-text-midnight-gray">People won't be able to bid below
                                        this amount</p>
                                </div>
                                <div
                                    className='lightmode-text-secondary d-flex align-items-center justify-content-center listInput'>
                                    <span className="">$</span>
                                    <input type="number"
                                        pattern="\d*"
                                        className='inputBlock w-100 lightmode-text-secondary font16pxBold'
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                            </div>
                            <p className="font12px lightmode-text-midnight-blue mt-15">Set expiration date and time for this
                                auction</p>
                            <p className="font12px lightmode-text-midnight-gray">Currently, auction time for all the listing
                                are set to 24 hours </p>
                            <div className="auctionDateGrid mt-15">
                                <div className="date">
                                    <p className="font10px mb-1">Date</p>


                                    <input type="date" className="listInput w-100 p-10"
                                        placeholder="DD/MM/YY"
                                        min={new Date().toISOString().split('T')[0]}
                                        pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                        value={date} onChange={(e) => handleDateChange(e)} />
                                </div>
                                <div className="time">
                                    <p className="font10px mb-1">Time (24 hrs clock) </p>
                                    <input type="time"
                                        step='3600'
                                        max='23:00' min='00:00'
                                        className="listInput w-100 p-10" placeholder="HH/MM"
                                        value={time}
                                        onChange={(e) => setTime(e.target.value.toString().split(":")[0].toString() + ":00")}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {type === "RENT" &&
                    <Fragment>
                        <div className='main-white-card nftListForm align-items-center'>
                            <p className='font12px lightmode-text-midnight-blue '>
                                Set a price at which you would prefer to
                                Rent this NFT per day
                            </p>
                            <div
                                className='lightmode-text-secondary d-flex align-items-center justify-content-center listInput'>
                                <span className="">$</span>
                                <input type="number"
                                    pattern="\d*"
                                    className='inputBlock w-100 lightmode-text-secondary font16pxBold'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            {/* <p className='font12px lightmode-text-midnight-blue '>
                                Set the rent expiry time
                            </p>
                            <div className="">
                                <div className="date">
                                    <input type="date" className="listInput w-100 p-10"
                                        placeholder="DD/MM/YY"
                                        min={new Date().toISOString().split('T')[0]}
                                        pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                        value={date} onChange={(e) => handleDateChange(e)} />
                                </div> 
                            </div>*/}
                        </div>
                    </Fragment>
                }
                <div className="main-white-card mt-10 p-15">
                    <NFTListingFeeInfo heading="Marketplace Fee" fees={feesData?.marketFee} />
                    <NFTListingFeeInfo heading="Collection Fee" fees={feesData?.collectionFee} />
                </div>

                {(type === "FIXED_PRICE" || type === "AUCTION") && price &&
                    <div className="main-white-card mt-10 d-flex gap-10 align-items-center">
                        <DiscountIcon />
                        <p className='font12px lightmode-text-blue'>
                            You will get minimum $ {getDeductedNFTPrice} from this sale
                        </p>
                    </div>
                }

                <p className="mt-25 font10px lightmode-text-light-gray text-center w-60 mx-auto">
                    The fixed selling price for the selected NFT is $ {price}
                    {type === "AUCTION" && date && time && `and the offer will expire on ${date} at ${time} IST.`}

                </p>

                <p className='font10px lightmode-text-light-gray text-center mt-10'>Click “Confirm” to list your
                    NFT.</p>

                {errorMessage?.state &&
                    <p className="form-error mt-4">
                        {errorMessage?.message}
                    </p>
                }
                <div className='primaryBtnBlockBottom mt-50 mb-20'>

                    <PrimaryButton heading="Continue"
                        onClick={handleListNFT}
                        isLoading={loading} />

                </div>
            </div>

        </Fragment>
    )

}

export default NFTListingForm


import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/css/adminv2.css";

const AdminSidebar = () => {
  const selector = useSelector((state) => state)
  const url = window.location.pathname;
  console.log("selector", selector);

  const [isOpen, setIsOpen] = useState(false)

  const toggleShowHideBox = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Fragment>
      <div className="admin--sidebar">
        {/* <Link
          to="/adminv2/adminCollection"
          className={`btn text-left mt-4 adminSidebarBtn ${url === "/adminv2/adminCollection" ? "activeTab" : ""
            }`}
        >
          Collections
        </Link>
        <Link
          to="/adminv2/adminTeams"
          className={`btn text-left adminSidebarBtn ${url === "/adminv2/adminTeams" ? "activeTab" : ""
            }`}
        >
          Teams
        </Link>
        <Link
          to="/adminv2/adminProjects"
          className={`btn text-left adminSidebarBtn ${url === "/adminv2/adminProjects" ? "activeTab" : ""
            }`}
        >
          Projects
        </Link> */}
        <>
          <Link
            to="/analytics"
            className={`btn text-left adminSidebarBtn lightmode-text-blue ${url === "/analytics" ? "activeTab" : ""
              }`}
          >
            Analytics
          </Link>

          <a className="btn text-left adminSidebarBtn adminDropBtn mb-0 pb-0 lightmode-text-blue" onClick={() => toggleShowHideBox()}>Reports</a>
          <div className={` ${isOpen ? "dropdown-content" : "d-none"}`}>
            <Link to="/admin/collection-details" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/collection-details" ? "activeTab" : ""} "`}>Collection Details</Link>
            <Link to="/admin/wallet-details" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/wallet-details" ? "activeTab" : ""} "`}>Wallet Details</Link>
            <Link to="/admin/dashboard-details" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/dashboard-details" ? "activeTab" : ""} "`}>Dashboard Details</Link>
            <Link to="/admin/collection-wise-stats" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/collection-wise-stats" ? "activeTab" : ""} "`}>Collection Wise Stats</Link>
            <Link to="/admin/user-tracking" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/user-tracking" ? "activeTab" : ""} "`}>User Tracking</Link>
            <Link to="/admin/day-wise-user-registration" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/day-wise-user-registration" ? "activeTab" : ""} "`}>Daily User Registrations</Link>
            <Link to="/admin/day-wise-payIn-PayOut" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/day-wise-payIn-PayOut" ? "activeTab" : ""} "`}>Daily Pay Details</Link>
            <Link to="/admin/collection-wise-fee" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/collection-wise-fee" ? "activeTab" : ""} "`}>Collection Wise Fee</Link>
            <Link to="/admin/daily-active-users" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/daily-active-users" ? "activeTab" : ""} "`}>Daily Active Users</Link>
            <Link to="/admin/day-wise-marketplace-stats" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/day-wise-marketplace-stats" ? "activeTab" : ""} "`}>Daily Marketplace Stats</Link>
            <Link to="/admin/day-wise-fee-collection" className={`btn text-left fs-15 adminSidebarBtn m-0 p-0 lightmode-text-blue ${url === "/admin/day-wise-fee-collection" ? "activeTab" : ""} "`}>Daily Fee Collection</Link>
          </div>
        </>
      </div>
    </Fragment>
  );
};

export default AdminSidebar;

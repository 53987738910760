import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { API_BASE_URL } from '../../../../constants/envConstants';

import TableDataGrid from '../DataGrid/DataGrid';

const DashboardDetails = () => {


    const [loading, setLoading] = useState(false)
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])

    const tableColumns = [


        {
            field: 'totalCollections',
            headerName: 'Total Collection',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'totalListing',
            headerName: 'TotalListing',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'totalPayIn',
            headerName: 'Total pay-in',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'totalPayout',
            headerName: 'Total Pay-out',
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'totalSoldAmount',
            headerName: 'TotalSoldAmount ',
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'totalbalance',
            headerName: 'Total Balance',
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'totalcards',
            headerName: 'TotalCards',
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'totallockedbalance',
            headerName: 'TotalLockedBalance',
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'totalusers',
            headerName: 'Total Users',
            minWidth: 200,
            flex: 1,

        },


    ]

    const maxPage = Math.ceil(maxResult / pageLimit)

    const getDashboardDetails = () => {
        setLoading(true)
        axios

            .get(API_BASE_URL + "reports/dashboard-details")
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.dasboardDetail


                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                totalCollections: !!key?.totalCollections ? parseFloat(key?.totalCollections) : 0,
                                totalListing: !!key?.totalListing ? parseFloat(key?.totalListing) : 0,
                                totalPayIn: !!key?.totalPayIn ? parseFloat(key?.totalPayIn) : 0,
                                totalPayout: !!key?.totalPayout ? parseFloat(key?.totalPayout) : 0,
                                totalSoldAmount: !!key?.totalSoldAmount ? parseFloat(key?.totalSoldAmount) : 0,
                                totalcards: !!key?.totalcards ? parseFloat(key?.totalcards) : 0,
                                totalbalance: !!key?.totalbalance ? parseFloat(key?.totalbalance) : 0,
                                totallockedbalance: !!key?.totallockedbalance ? parseFloat(key?.totallockedbalance) : 0,
                                totalusers: !!key?.totalusers ? parseFloat(key?.totalusers) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    console.log('finaldata', finalData)
                    setMaxResult(res.data.data.schemaCount)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setTableData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getDashboardDetails()
    }, [])


    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">Dashboard Details</h2>
                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" />
                </div>
                <div className="d-flex justify-content-center mt-5 mb-3">
                </div>
            </div>
        </Fragment >
    )
}

export default DashboardDetails

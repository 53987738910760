import React, {Fragment} from 'react'
import "../Collection/CollectionStyles.css"
import {PRICE_FORMAT} from "../../constants/commonFunc";

const CollectionDetails = ({data, className}) => {
    return (
        <Fragment>
            <div className={`white-card ${className}`}>
                <div className='collectionDetailsGrid inside-white-card p-15 text-center'>
                    <p className='font12px lightmode-text-blue'>Items: <span
                        className='font14pxBold'>{data?.totalCards}</span></p>
                    <p className='font12px lightmode-text-blue middleBorder'>Owners: <span
                        className='font14pxBold'>{data?.totalOwners}</span></p>
                    <p className='font12px lightmode-text-blue'>Traded: <span
                        className='font14pxBold'>{PRICE_FORMAT(data?.totalSoldAmount)}</span></p>
                </div>
            </div>
        </Fragment>
    )
}

export default CollectionDetails

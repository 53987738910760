// All the KYC related common functions written here
import http from "../Interceptors";
import { GET_KYC_INFO_API } from "../ApisPath";
import { handleChangeKYCStepAction } from "../../redux/actions/kycAction";
import { useDispatch } from "react-redux";

const KYCHelper = () => {

    const dispatch = useDispatch()

    // get the KYC information and store in redux state
    const GetKYCFullDetails = () => {
        http.get(GET_KYC_INFO_API)
            .then((res) => {
                if (res?.status === 200) {
                    dispatch(handleChangeKYCStepAction(res?.data?.data))
                }

            }).catch((err) => {
                return err
            })
    }

    return { GetKYCFullDetails }
}

export default KYCHelper

// File is used for Global img src replacement, so we can add onerror or lazy load in future
import noImagePreview from "../../images/Common/noImagePreview.jpg"

const GlanceImage = (props) => {
    return (
        <img {...props} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = noImagePreview;
        }} />
    )
}

export default GlanceImage

import React, {Fragment, useEffect, useState} from 'react'
import {CameraIcon, GreenTick, HeaderCrossIcon, KYCSelfieIcon, RedCross} from '../../helpers/svgConst'
import ImageBG from "../../images/CollectionImage/background.png"
import GlanceImage from "../Common/GlanceImage"
import PrimaryButton from '../Login/PrimaryButton'
import {useDropzone} from "react-dropzone";
import http from "../../helpers/Interceptors";
import {UPLOAD_VERIFY_FACE_API} from "../../helpers/ApisPath";
import {changeURLLastString, GET_ERROR_TOAST_MSG} from "../../constants/commonFunc";
import {useNavigate} from 'react-router-dom'
import KYCHelper from "../../helpers/apis/KYCHelper";
import {useSelector} from "react-redux";
import Paths from "../../helpers/Paths";
import GlanceLoader from "../../helpers/Loader";

const KYCVerifySelfie = () => {

    const navigate = useNavigate()
    const {GetKYCFullDetails} = KYCHelper()
    const isAadharVerified = useSelector((state) => state?.KYC?.kycInfo?.aadhaarVerified)
    const isPanVerified = useSelector((state) => state?.KYC?.kycInfo?.panVerified)
    const isFaceVerified = useSelector((state) => state?.KYC?.kycInfo?.faceMatched)

    const [redirectErr, setRedirectErr] = useState("")

    const [state, setState] = React.useState({bottom: false,});
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [imageErrorMessage, setImageErrorMessage] = useState("")
    const [imageSuccessMessage, setImageSuccessMessage] = useState("")
    const [file, setFile] = useState([])
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            handleAadharImageChange(acceptedFiles)
        }
    });


    const handleAadharImageChange = (file) => {
        if (!file && file.length < 1) return
        setFile(file)
        setImageErrorMessage("")
        setImageUploadLoading(true)
        const formData = new FormData()

        if (!!file) {
            formData.append(
                "photo",
                file[0],
                file[0].name
            )
        }

        http.post(UPLOAD_VERIFY_FACE_API, formData)
            .then((res) => {
                if (res?.status === 200) {
                    setImageSuccessMessage(res?.data?.message)
                    GetKYCFullDetails()
                }
            }).catch((err) => {
            console.error("Error", err?.response?.data?.message)
            const errorMsg = GET_ERROR_TOAST_MSG(err)
            setImageErrorMessage(errorMsg ? errorMsg : "Failed to validate Aadhar card")
        }).finally(() => {
            setImageUploadLoading(false)
        })

    }

    const handleSuccessPage = () => {
        if (!isAadharVerified) {
            setRedirectErr("Aadhar card is not verified")
            navigate(changeURLLastString(Paths.KYCVerificationPage, 2))
            return
        }
        if (!isPanVerified) {
            setRedirectErr("Pan card is not verified")
            navigate(changeURLLastString(Paths.KYCVerificationPage, 2))
            return
        }
        if (!isFaceVerified) {
            setRedirectErr("Face is not verified as per Aadhar card")
            return
        }
        navigate(Paths.KYCCompletePage)
    }

    const handleClearImage = () => {
        setFile([]);
        setImageErrorMessage("")
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => file.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <Fragment>
            <div className='pi-15'>
                <div className='d-flex align-items-center gap-10 mt-25'>
                    <KYCSelfieIcon/>
                    <h1 className='font16px lightmode-text-blue'>Live selfie</h1>
                </div>
                <p className='mt-2 font12px'>Example</p>
                <div className='transactionDrawerImgBlock mt-2'>
                    <GlanceImage src={ImageBG}/>
                </div>
                <div className='d-flex mt-25 align-items-center'>
                    <GreenTick className="mr-2"/>
                    <p className='font12px w-100'>Take a selfie of yourself with a neutral expression</p>
                </div>
                <div className='d-flex align-items-center mt-1'>
                    <GreenTick className="mr-2"/>
                    <p className='font12px w-100'>Make sure your whole face is visible, centred and
                        your eyes are open</p>
                </div>
                <div className='d-flex align-items-center mt-1'>
                    <RedCross className="mr-2"/>
                    <p className='font12px w-100'>Do not crop your ID or screenshot of your ID</p>
                </div>
                <div className='d-flex align-items-center mt-1'>
                    <RedCross className="mr-2"/>
                    <p className='font12px w-100'>Do not hide or alter parts of your face (no hats/
                        beauty images/fitlers/headgear)</p>
                </div>
                <p className='font10px mt-30'>File size must be between 10KB & 5 MB in jpg/.jpeg/.png format</p>

                {(!file || !Array.isArray(file) || file.length === 0) &&
                <section>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <button className='btn addImageBtn lightmode-text-light-gray font12px mt-2'>
                            <CameraIcon/>
                            Add a Selfie
                        </button>
                    </div>
                </section>
                }

                {!!file && Array.isArray(file) && file.length > 0 &&
                <div className='main-white-card p-0 pos-rel mt-2'>
                    <button className='btn KYCcrossBtn' onClick={() => handleClearImage()}>
                        <HeaderCrossIcon/>
                    </button>
                    <div className='uploadSelfieBlock'>
                        <GlanceImage src={file[0].preview}/>
                    </div>
                </div>
                }

                {!!imageErrorMessage &&
                <Fragment>
                    <p className='font12px mt-25'>After recognition, your photo is:</p>
                    <p className='font12px mt-2'><RedCross className="mr-2"/>
                        {imageErrorMessage}
                    </p>
                </Fragment>
                }

                {!!imageSuccessMessage &&
                <Fragment>
                    <p className='font12px mt-25'>After recognition, your photo is:</p>
                    <p className='font12px mt-2'><GreenTick className="mr-2"/>{imageSuccessMessage}</p>
                </Fragment>
                }


            </div>
            {redirectErr &&
            <p className="col-md-12 form-error mt-4">
                {redirectErr}
            </p>
            }
            {imageUploadLoading &&
            <GlanceLoader color={"#25247b"} className="text-center overlay" width="55" height="55"/>
            }
            <div className='main-white-card br-0 mt-auto'>
                <PrimaryButton heading="Submit for verification" onClick={handleSuccessPage}/>
            </div>
        </Fragment>
    )
}

export default KYCVerifySelfie

import React, { Fragment, useEffect, useRef, useState } from "react";
import { API_FETCH_COMPLETE, changeURLLastString, CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import { MoreMenuIcon, VerifiedIcon, DrawerLine, FacebookShareIcon, LinkIcon, TelegramShareIcon, TwitterShareIcon } from "../../helpers/svgConst";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import Skeleton from '@mui/material/Skeleton';
import GlanceImage from "../Common/GlanceImage";
import LikeUnlikeButton from "../Common/LikeUnlikeButton";
import { NFT_CARD, WEB_URL } from "../../constants/envConstants";
import useOnClickOutside from "../../helpers/Hooks/useOnClickOutside";
import CARD_HELPER from "../../helpers/apis/cardHelper";
import USER_HELPER from "../../helpers/apis/userHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { MakeAnOfferRedirectAction, SetUserOfferInfoForCard } from "../../redux/actions/cardDetailActions";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, SwipeableDrawer } from "@mui/material";


const CardBasicInfoDetailPage = ({ loading, cardInfo }) => {

    const dispatch = useDispatch()
    const isLoading = (loading !== API_FETCH_COMPLETE)
    const navigate = useNavigate()
    const userOffer = useSelector((state) => state?.cardDetail?.userOfferForCard)
    const {
        handleCancelListing,
        removeBidFromCardByMarketplaceId,
        getOfferInfoForUserByCardId,
        removeBidFromCardByCardIdForUnlistedNFT,
        getSpecialOfferInfoForUserByCardId
    } = CARD_HELPER()
    const { loginUserInfo } = USER_HELPER();
    const sharableUrl = WEB_URL + window.location.pathname
    const [isOpen, setIsOpen] = useState(false)
    const handleToggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const ref = useRef(null)

    const handleSortMenuClickOutside = () => {
        setIsOpen(false);
    };

    const [state, setState] = useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleCopy = () => {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(sharableUrl)
        toast.success("Link successfully copied");
    }
    const ShareTray = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel mb-25"
            >

                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>
                <div className='p-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Share on your socials!</h1>
                    <Divider />
                    <label htmlFor='link' className='font10px mt-25'>Click and copy share link</label>
                    <div className='pos-rel listInput'>
                        <input type="url" placeholder='http://Owens.app/Tigershroff_...' value={sharableUrl}
                            className='border-0 bg-white linkShareOverflowText' />
                        <button className='btn p-0 linkAbsBtn' onClick={handleCopy}>
                            <LinkIcon />
                        </button>
                    </div>
                </div>


                <div className='pl-15 mt-20 shareIconsBlock'>
                    <button className='btn'>
                        <FacebookShareButton url={sharableUrl}>
                            <FacebookShareIcon />
                        </FacebookShareButton>

                    </button>
                    <button className='btn'>
                        <WhatsappShareButton url={sharableUrl}>
                            <WhatsappIcon style={{ borderRadius: 50 }} />
                        </WhatsappShareButton>
                    </button>
                    <button className='btn'>
                        <TwitterShareButton url={sharableUrl}>
                            <TwitterShareIcon />
                        </TwitterShareButton>
                    </button>
                    <button className='btn'>
                        <TelegramShareButton url={sharableUrl}>
                            <TelegramShareIcon />
                        </TelegramShareButton>
                    </button>
                </div>

            </Box>
        )
    }

    const handleNFTCancelListing = async () => {
        const res = await handleCancelListing(cardInfo?.marketplaceId)
        if (res?.status === 200) {
            toast.success("Cancel Listing Success")
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } else {
            toast.error("Cancel Listing Failed")
        }
    }

    const handleCancelOffer = async () => {
        // for listed NFT cancel bid
        const res = await removeBidFromCardByMarketplaceId(cardInfo?.marketplaceId)
        if (res?.status === 200) {
            toast.success("Successfully offer removed")
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            toast.error("Failed to remove offer")
        }
    }

    const handleCancelOfferForUnlistedNFT = async () => {
        // for listed NFT cancel bid
        const res = await removeBidFromCardByCardIdForUnlistedNFT(cardInfo?.id)
        if (res?.status === 200) {
            toast.success("Successfully offer removed")
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            toast.error("Failed to remove offer")
        }
    }

    const getOfferInfoForUser = async () => {
        //this function will fetch for both result
        // get offer info if is user made for listed nft
        let res = {}
        if (cardInfo?.listingType === 2 && cardInfo?.marketplaceId) {
            res = await getOfferInfoForUserByCardId(cardInfo?.id)
        }
        if (!cardInfo?.listingType && !cardInfo?.marketplaceId) {
            // for unlisted NFTs offer
            res = await getSpecialOfferInfoForUserByCardId(cardInfo?.id)
        }

        if (res?.status === 200) {
            let apiData = []

            if (cardInfo?.listingType === 2 && cardInfo?.marketplaceId) {
                apiData = res?.data?.data?.myOffers || []
            }
            if (!cardInfo?.listingType && !cardInfo?.marketplaceId) {
                apiData = res?.data?.data?.mySpecialOffers || []
            }

            dispatch(SetUserOfferInfoForCard(apiData))
        } else {
            dispatch(SetUserOfferInfoForCard([]))
        }
    }

    const handleUpdateOffer = () => {
        dispatch(MakeAnOfferRedirectAction(cardInfo));
        if (cardInfo?.listingType && cardInfo?.marketplaceId) {
            // listed nft update offer redirect
            navigate(changeURLLastString(Paths.ConfirmMakeAnOfferPage, cardInfo?.id) + "?type=updateOffer");
        }
        if (!cardInfo?.listingType && !cardInfo?.marketplaceId) {
            //Unlisted NFTs update offer redirect
            navigate(changeURLLastString(Paths.ConfirmMakeAnOfferPage, cardInfo?.id) + "?type=updatedOfferUnlisted");
        }
    }

    useEffect(() => {
        if (!!cardInfo?.id) {
            getOfferInfoForUser()
        }
    }, [cardInfo])

    useOnClickOutside(ref, handleSortMenuClickOutside);

    return (
        <Fragment>
            <div className='main-white-card p-0'>
                <div className='p-15 nftTokenHeroBlock '>
                    <div className='d-flex gap-10'>
                        <div className='tokenThumbImageBlock'>
                            {isLoading && <Skeleton variant="circular" width={40} height={40} />}
                            {!isLoading &&
                                <GlanceImage src={CheckWAXImage(cardInfo?.collectionImage)} alt="ImageThumb" />}

                        </div>
                        <div>
                            <h1 className='font14pxSemiBold lightmode-text-blue d-block'>
                                {isLoading && <Skeleton variant="text" />}
                                {!isLoading && cardInfo?.name}
                            </h1>
                            <VerifiedIcon width="11" height="11" className="mr-2" />
                            <p className='font10px lightmode-text-light-gray d-inline'>
                                #{cardInfo?.mintNumber}
                                {/* / {cardInfo?.totalMint} */}
                                | {cardInfo?.collectionDisplayName}
                            </p>
                        </div>
                    </div>
                    <div className="pos-rel" ref={ref}>
                        <button className='btn p-0' onClick={handleToggleDropdown}>
                            <MoreMenuIcon />
                        </button>
                        <div className={`${isOpen ? "tokenHeroAbsBtn d-flex flex-column" : "d-none"}`}>
                            {((cardInfo?.vaccount === loginUserInfo?.vaccount) && cardInfo?.marketplaceId) &&
                                <Fragment>
                                    <button className="btn font12px lightmode-text-blue" onClick={handleNFTCancelListing}>
                                        {(cardInfo?.listingType === 4 || cardInfo?.listingType === 6) && "Cancel Listing"}
                                        {cardInfo?.listingType === 2 && "End Sale"}
                                    </button>
                                </Fragment>
                            }
                            {((cardInfo?.vaccount !== loginUserInfo?.vaccount) && !!userOffer && userOffer.length > 0 && !!cardInfo?.listingType && !!cardInfo?.marketplaceId) &&
                                /* FOR LISTED NFTs*/
                                <div className="d-flex flex-column">
                                    <button className="btn font12px lightmode-text-blue" onClick={handleUpdateOffer}>
                                        Change Bid
                                    </button>

                                    <button className="btn font12px red-text" onClick={handleCancelOffer}>
                                        Cancel Bid
                                    </button>
                                </div>
                            }

                            {((cardInfo?.vaccount !== loginUserInfo?.vaccount) && !!userOffer && userOffer.length > 0 && !cardInfo?.listingType && !cardInfo?.marketplaceId) &&
                                /*FOR UNLISTED NFTs*/
                                <div className="d-flex flex-column">
                                    <button className="btn font12px lightmode-text-blue" onClick={handleUpdateOffer}>
                                        Change Bid
                                    </button>
                                    <button className="btn font12px red-text"
                                        onClick={handleCancelOfferForUnlistedNFT}>
                                        Cancel Bid
                                    </button>
                                </div>
                            }
                            <button className="btn font12px lightmode-text-blue" onClick={toggleDrawer("bottom", true)}>
                                Share
                            </button>
                            <SwipeableDrawer
                                anchor={"bottom"}
                                open={state["bottom"]}
                                onClose={toggleDrawer("bottom", false)}
                                onOpen={toggleDrawer("bottom", true)}
                                className="swipableDrawerBlock"
                            >
                                {ShareTray("bottom")}
                            </SwipeableDrawer>
                        </div>
                    </div>
                </div>
                <div className='NFTDetailHeroBlock pos-rel'>
                    <LikeUnlikeButton type={NFT_CARD} id={cardInfo?.id} />
                    {isLoading && <Skeleton variant="rectangular" height={330} />}
                    {!isLoading && <GlanceImage src={CheckWAXImage(cardInfo?.image)} alt="thumbnail" />}

                </div>
                <div className='d-flex justify-content-between align-items-center p-15 pt-20'>
                    {/* <div className='goldExpBlock ml-0 d-flex'>
                        <p className='font8pxSemiBold mr-1 lightmode-text-secondary'>+590</p>
                        <GoldExpCoinIcon />
                    </div> */}
                    <div />
                    {!!cardInfo?.listedPrice &&
                        <div>
                            <p className='font16px lightmode-text-blue d-flex align-items-center'>
                                {cardInfo?.listingType === 4 &&
                                    <span className='font12pxMedium lightmode-text-marigold mr-2'>
                                        Listed for fixed price:
                                    </span>}
                                {cardInfo?.listingType === 6 &&
                                    <span className='font12pxMedium lightmode-text-marigold mr-2'>
                                        Listed for rent price:
                                    </span>}
                                {cardInfo?.listingType === 2 &&
                                    <span className='font12pxMedium lightmode-text-marigold mr-2'>
                                        Highest bid:
                                    </span>}
                                $ {PRICE_FORMAT(cardInfo?.listedPrice, false)}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default CardBasicInfoDetailPage

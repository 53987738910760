import React, { Fragment } from 'react'
import EnterMobiletNumber from '../../components/Withdraw/EnterMobileNumber';
import FinalVerifyOTP from '../../components/Withdraw/FinalOTPVerification';
import WithdrawVerifyOTP from '../../components/Withdraw/WithdrawVerifyOTP';
import USER_HELPER from "../../helpers/apis/userHelper";
import { AmountIcon, BankIcon, GoBackIcon } from "../../helpers/svgConst";


const VerifyBankAccount = () => {

    const { loginUserInfo, handleGoBack } = USER_HELPER()


    return (
        <Fragment>
            <section className='lightMode'>
                <div className="pi-15 pt-70">
                    <button className='btn px-0 mb-40' onClick={handleGoBack}>
                        <GoBackIcon />
                    </button>
                    {/* <h1 className='font24pxSemiBold lightmode-text-blue '>Verify your bank account</h1> */}
                    {/* <EnterMobiletNumber /> */}
                    {/* <WithdrawVerifyOTP /> */}
                    <FinalVerifyOTP />
                </div>
            </section>
        </Fragment>
    )
}

export default VerifyBankAccount
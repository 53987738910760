//common axios interceptor file you can change with any library in future if needed
import axios from "axios";
import { deleteCookie, getCookie } from "../constants/commonFunc";


const onRequest = (config) => {
    //setting all headers
    let accessToken = getCookie("Authorization");
    config.headers = Object.assign({
        Authorization: accessToken
    }, config.headers);
    return config;
}

const onRequestError = (error) => {
    return Promise.reject(error);
}

const onResponse = (response) => {
    return response;
}

const onResponseError = (error) => {
    if (error?.response) {
        if (error?.response?.status === 401) {
            // if we get unauthorized access then we are trying to logout the user from the platform
            setTimeout(() => {
                deleteCookie("Authorization")
                localStorage.removeItem("user")
                window.open("/", "_self")
            }, 1000)
        }
    }
    return Promise.reject(error);
}


axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);


export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
}

import React, { Fragment } from "react";
import { API_FETCH_COMPLETE, changeURLLastString, CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import { VerifiedIcon } from "../../helpers/svgConst";
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import NFTSkeleton from "../Common/Skeleton/NFTSkeleton";
import GlanceImage from "../Common/GlanceImage";

const CollectionTemplate = ({ isLoading: loading, collectionList }) => {

    const navigate = useNavigate()

    const handleGoToCollection = (collectionId) => {
        navigate(changeURLLastString(Paths.CollectionPage, collectionId))
    }
    return (
        <Fragment>


            {collectionList && collectionList.length > 0 && collectionList.map((collection) => {
                return (
                    <Fragment key={collection?.id}>
                        <div className="coll-block" onClick={() => handleGoToCollection(collection?.id)}>
                            <div className='pos-rel'>
                                <div className="moreCollectionImageBlock">
                                    <GlanceImage src={CheckWAXImage(collection?.collectionCoverImage)} alt="thumb" />
                                </div>
                                <div className="moreCollectionThumbBlock">
                                    <GlanceImage src={CheckWAXImage(collection?.collectionImage)} alt="thumb" />
                                </div>
                            </div>
                            <div className='NFTSecDetails px-2 pos-rel'>
                                <p className="font8pxBold newTag text-white">New</p>
                                <div className="d-flex mt-20 ">
                                    {collection?.author &&
                                        <>
                                            <p className="font10px lightmode-text-light-blue nftNameOverflow text-capitalize">
                                                {collection?.author}
                                            </p>
                                            {collection?.isFeatured &&
                                                <VerifiedIcon width="11" height="11" className="ml-1" />
                                            }
                                        </>
                                    }
                                </div>
                                <p className="font14pxBold mt-1 lightmode-text-blue overflowText">
                                    {collection?.collectionDisplayName}
                                </p>
                                {/* <p className='font10pxMedium lightmode-text-marigold mt-2'>Floor: $ 2999</p> */}
                                {collection?.totalCards &&
                                    <p className='font10pxSemiBold lightmode-text-midnight-gray mt-1'>{collection?.totalCards} Items</p>}
                                <div className='tradeCountBlock mt-15'>
                                    <p className='font10pxMedium lightmode-text-blue text-center'>Traded: {" "}
                                        {PRICE_FORMAT(collection?.totalSoldAmount)}

                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            })
            }
            {(loading !== API_FETCH_COMPLETE) &&
                <NFTSkeleton imageHeight={80} className={"mb-4"} />
            }
        </Fragment>
    )


}

export default CollectionTemplate

import {Skeleton} from '@mui/material'
import React, {Fragment} from 'react'

const NFTSkeleton = ({count = 6, imageHeight = 180, className = ""}) => {

    const data = Array.apply(null, {length: count}).map(Number.call, Number)

    return (
        <Fragment>
            {data && data.length > 0 && data.map((key, index) => {
                return (
                    <Fragment key={index}>
                        <div className={`nftSkeleton mb-3 ${className}`}>
                            <Skeleton variant="rectangular" width={"100%"} height={imageHeight}/>
                            <Skeleton variant="text" height={20} className="mt-2"/>
                            <Skeleton variant="text" height={15} width={"80%"}/>
                        </div>
                    </Fragment>
                )
            })}

        </Fragment>
    )
}

export default NFTSkeleton

import React, { Fragment, useEffect, useState } from "react";
import LightmodeAccordian from "./LightmodeAccordian";
import SecondaryButton from "./SecondaryButton";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { API_FETCH_COMPLETE, API_FETCH_START, CheckWAXImage, } from "../../constants/commonFunc";
import { CARD_UTILITY_DETAILS_API } from "../../helpers/ApisPath";
import http from "../../helpers/Interceptors";
import GlanceImage from "./GlanceImage";
import NFTSkeleton from "./Skeleton/NFTSkeleton";


const WhatDoIGet = ({ idType, id }) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState("");
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {

    const getWhatDoIGetInfo = () => {
      if (!idType || !id) return
      setDataLoading(API_FETCH_START);
      let page = 1;
      let limit = 10;

      const queryParams = `?page=${page}&limit=${limit}&${idType}=${id}`;
      const apiPath = CARD_UTILITY_DETAILS_API + queryParams;

      http.get(apiPath)
        .then((res) => {
          if (res.status === 200) {
            const apiData = res?.data?.data?.result;
            setFetchedData(apiData);
          }
        })
        .catch((err) => {
          console.error("err activity loading", err);
        })
        .finally(() => {
          setDataLoading(API_FETCH_COMPLETE);
        });
    }

    getWhatDoIGetInfo()
  }, [id]);

  return (
    <Fragment>
      {(dataLoading === API_FETCH_COMPLETE) && fetchedData.length > 0 &&
        <LightmodeAccordian heading="What do I get?">
          {fetchedData
            .filter((item) => item.type === 1)
            .map((item, index) => (
              <div className="whatIGetSection mt-15 mb-30" key={index}>
                <div className="whatIgetImageBlock">
                  <GlanceImage src={CheckWAXImage(item.image)} alt="thumbnail" />
                </div>
                <div className="text-block">
                  <h4 className="font14pxBold lightmode-text-blue mb-1">
                    {item.title}
                  </h4>
                  <p className="font12px">{item.description}</p>
                </div>
              </div>
            ))}

          <div className="mb-30 carousel-grid">
            {fetchedData
              .filter((item) => item.type === 2)
              .map((item, index) => (
                <div className="carousel-sec" key={index}>
                  <div className="carousel-image-block">
                    <GlanceImage src={CheckWAXImage(item.image)} alt="4" />
                  </div>
                  <p className="font12px mt-2 lightmode-text-blue">{item.title}</p>
                  <p className="font10px mt-1 threeLinesOverflowText">{item.description}</p>
                </div>
              ))}
          </div>
          {(dataLoading !== API_FETCH_COMPLETE) &&
            <NFTSkeleton imageHeight={80} className={"mb-4"} />
          }

          <SecondaryButton
            heading="Play and earn more"
            className=""
          // onClick={() => navigate(Paths.TierPage)}
          />
        </LightmodeAccordian>
      }
    </Fragment>

  );
};

export default WhatDoIGet;

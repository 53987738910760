// All the card related common function written here
import http from "../../helpers/Interceptors"
import {
    ALL_OFFERS_API,
    CANCEL_NFT_LISTING,
    CANCEL_OFFER_API, CANCEL_OFFER_FOR_UNLISTED_NFT_API,
    GET_ALL_FAVOURITE_CARDS_API,
    GET_CARD_FULL_INFO_API,
    GET_MY_BIDS_API, GET_SPECIAL_OFFER_BY_USER_FOR_CARD_API,
    GET_UNLISTED_OFFER_LISTING_API,
    SET_LIKE_DISLIKE_CARD_API
} from "../ApisPath";
import { useDispatch, useSelector } from "react-redux";
import { SetAllWishlistedItems } from "../../redux/actions/cardDetailActions";
import { COLLECTION, NFT_CARD, OPERATOR_ID } from "../../constants/envConstants";
import { changeURLLastString } from "../../constants/commonFunc";

export const CARD_HELPER = () => {

    const dispatch = useDispatch()
    const favoriteIds = useSelector((state) => state?.cardDetail?.wishlistedItems?.getSelectedListIds)
    const WISHLIST_ITEMS_IDS = favoriteIds || []

    // get the favourite card of the login user, this function is called in App.js
    const getMyFavouriteCards = (page = 1, limit = 1000) => {
        /* SelectionFilterEnum {
             All = 1,Owned = 2, NotOwned = 3,
         } */
        const queryParam = `?page=${page}&limit=${limit}`
        const apiPath = GET_ALL_FAVOURITE_CARDS_API + "/1/1"
        return http.get(apiPath + queryParam)
            .then((res) => {
                const apiData = res?.data?.data
                dispatch(SetAllWishlistedItems(apiData))
                return apiData
            }).catch((err) => {
                return err
            })
    }

    // it will return nft is wishlisted by user or not
    const isHeartActive = (type, cardId) => {
        if (!cardId) return false
        if (type === NFT_CARD) {
            return WISHLIST_ITEMS_IDS.includes(cardId)
        }
        if (type === COLLECTION) {
            return WISHLIST_ITEMS_IDS.includes(cardId)
        }

    }

    //when user try to wishlist or de-wishlist
    const handleNFTLikeDisLike = (type, value, cardId) => {

        const typeValue = type === NFT_CARD ? 1 : 2
        const actionValue = value == 1 ? "true" : "false"

        const apiPath = `${SET_LIKE_DISLIKE_CARD_API}/${cardId}/${typeValue}/1/${actionValue}`
        return http.post(apiPath)
            .then((res) => {
                return res
            }).catch((err) => {
                return err
            })

    }

    // when user try to cancel listing of listed NFT -- either Fixed price or Auction based listing
    const handleCancelListing = (marketplaceId) => {
        if (!marketplaceId) return
        const apiPath = changeURLLastString(CANCEL_NFT_LISTING, marketplaceId)
        return http.post(apiPath)
            .then((res) => {
                return res
            }).catch((err) => {
                return err
            })
    }

    // get all offers history based on cardId
    const getAllOfferByCardId = (
        marketplaceId,
        page = 1,
        limit = 10
    ) => {
        if (!marketplaceId) return "";

        const apiPath =
            changeURLLastString(ALL_OFFERS_API, marketplaceId) +
            "?page=" +
            page +
            "&limit=" +
            limit
        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    const getAllSpecialOfferByCardId = (
        cardId,
        page = 1,
        limit = 10
    ) => {
        if (!cardId) return "";

        const apiPath =
            changeURLLastString(GET_UNLISTED_OFFER_LISTING_API, cardId) + "/" + OPERATOR_ID +
            "?page=" +
            page +
            "&limit=" +
            limit
        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    // get the full info of card or NFT
    const getCardFullInfoById = (cardId) => {
        const apiPath = changeURLLastString(GET_CARD_FULL_INFO_API, cardId);
        return http
            .get(apiPath)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    };

    // cancel offer for listed NFTs
    const removeBidFromCardByMarketplaceId = (marketplaceId) => {
        if (!marketplaceId) return
        const apiPath = changeURLLastString(CANCEL_OFFER_API, marketplaceId);
        return http
            .post(apiPath)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    };

    // cancel offer for Unlisted NFTs
    const removeBidFromCardByCardIdForUnlistedNFT = (specialOfferId) => {
        if (!specialOfferId) return
        const apiPath = changeURLLastString(CANCEL_OFFER_FOR_UNLISTED_NFT_API, specialOfferId);
        return http
            .post(apiPath)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    };

    // get the offer info if user made an offer for card Id when NFT is listed
    const getOfferInfoForUserByCardId = (cardId, page = 1, limit = 1) => {
        if (!cardId) return
        const apiParams = `?cardId=${cardId}&page=${page}&limit=${limit}`
        const apiPath = GET_MY_BIDS_API + apiParams
        return http
            .get(apiPath)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    }

    // get the offer info if user made an offer for card Id when NFT is not listed
    const getSpecialOfferInfoForUserByCardId = (cardId, page = 1, limit = 1) => {
        if (!cardId) return
        const apiParams = `?cardId=${cardId}&page=${page}&limit=${limit}`
        const apiPath = GET_SPECIAL_OFFER_BY_USER_FOR_CARD_API + apiParams
        return http
            .get(apiPath)
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    }

    return {
        WISHLIST_ITEMS_IDS,
        getCardFullInfoById,
        getMyFavouriteCards,
        isHeartActive,
        handleNFTLikeDisLike,
        handleCancelListing,
        getAllOfferByCardId,
        getAllSpecialOfferByCardId,
        getOfferInfoForUserByCardId,
        removeBidFromCardByMarketplaceId,
        removeBidFromCardByCardIdForUnlistedNFT,
        getSpecialOfferInfoForUserByCardId
    }

}

export default CARD_HELPER

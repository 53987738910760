import React, { Fragment, useState, useEffect } from 'react'
import Loader from "react-loader-spinner"
import TableDataGrid from '../DataGrid/DataGrid';
import axios from "axios"
import { API_BASE_URL } from '../../../../constants/envConstants';
import { getFormattedDate, getOneMonthBackDate } from '../../../../constants/commonFunc';
;



const UserTracking = () => {

    const [loading, setLoading] = useState(false)
    const [collectionList, setCollectionList] = useState([])
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const [userData, setUserData] = useState('')
    const date = new Date()

    const [startDate, setStartDate] = useState(getOneMonthBackDate(date))
    const [endDate, setEndDate] = useState(getFormattedDate(date))

    const tableColumns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'lockedbalance',
            headerName: 'Locked Balance',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'totalCardListed',
            headerName: 'Total Card Listed',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'totalCardOwned',
            headerName: 'Total Card Owned',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'totalMoneyRecieved',
            headerName: 'Total Money Recieved',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'totalMoneySent',
            headerName: 'Total Money Sent',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'totalPayIn',
            headerName: 'Total Pay-In',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'totalPayOut',
            headerName: 'Total Pay-Out',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'totalSoldCard',
            headerName: 'Total Sold Card',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'walletbalance',
            headerName: 'Wallet Balance',
            minWidth: 200,
            flex: 1,
        },
    ]


    const getUserTrackingDetails = () => {
        setLoading(true)
        axios
            .get(API_BASE_URL + "reports/user-tracking?startDatetime=" + startDate + "&endDatetime=" + endDate + "&data=" + userData)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.userTrackingData
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                totalAuctionListed: !!key?.totalAuctionListed ? parseFloat(key?.totalAuctionListed) : 0,
                                totalAuctionSold: !!key?.totalAuctionSold ? parseFloat(key?.totalAuctionSold) : 0,
                                totalCancelledListing: !!key?.totalCancelledListing ? parseFloat(key?.totalCancelledListing) : 0,
                                totalListing: !!key?.totalListing ? parseFloat(key?.totalListing) : 0,
                                totalSold: !!key?.totalSold ? parseFloat(key?.totalSold) : 0,
                                totalSoldAmount: !!key?.totalSoldAmount ? parseFloat(key?.totalSoldAmount) : 0,
                                totalTokenMarketListed: !!key?.totalTokenMarketListed ? parseFloat(key?.totalTokenMarketListed) : 0,
                                totalTokenMarketSold: !!key?.totalTokenMarketSold ? parseFloat(key?.totalTokenMarketSold) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    console.log('final data', finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setTableData([])
                // addToast('failed to remove' + err?.response?.data?.message, {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getUserTrackingDetails()
    }, [])

    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">User Tracking Details</h2>
                <div className=" mw-800 mb-5">
                    <label className="headingattribute labelTxt">Email or phone</label>
                    <input
                        type="text"
                        onChange={(e) => setUserData(e.target.value)}
                        placeholder="Enter email or phone number"
                        className="adminInput lightShadow"
                    />


                </div>

                <div className=" mw-800 mb-5">
                    <div className="d-flex justify-content-between">
                        <div>
                            <label htmlFor='startDate'>Enter start date</label>
                            <input
                                id="startDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor='endDate'>Enter end date</label>
                            <input
                                id="endDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>

                    </div>

                    <button type="submit"
                        onClick={() => getUserTrackingDetails()}
                        className="newBlueBtn btn fs-20 px-5 py-3 mt-4">
                        {loading ?
                            <div className="text-center">
                                <Loader color={"#ffffff"} type="Oval" width={45} height={25} />
                            </div> : "Submit"}
                    </button>


                </div>

                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" />
                </div>
            </div>
        </Fragment>
    )
}

export default UserTracking
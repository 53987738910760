/* File is used for showing all tier or schema in all
*places like collection or marketplace
* */

import React, {Fragment, useCallback, useRef, useState} from 'react'
import {AllTierIcon, ArrowDownIcon, BronzeTierIcon, GoldTierIcon, SilverTierIcon} from '../../helpers/svgConst'
import {COLLECTION, MARKETPLACE, NFT_CARD} from "../../constants/envConstants";
import {useDispatch} from "react-redux";
import {
    COLLECTION_PAGE_TIER_SCHEMA,
    MARKETPLACE_PAGE_TIER_SCHEMA,
    TOKEN_DETAIL_PAGE_TIER_SCHEMA
} from "../../redux/action-types/common.type";
import {handleChangeTierSchemaValueAction} from "../../redux/actions/commonAction";
import {GET_TIER_SCHEMA_TEXT} from "../../constants/commonFunc";
import {MarketplaceAPICallStart} from "../../redux/actions/marketplaceActions";
import useOnClickOutside from '../../helpers/Hooks/useOnClickOutside';

const AllTierMenu = ({className = "", schemaList, page = "", schemaValue = ""}) => {

    const dispatch = useDispatch()
    const ref = useRef()
    const [tierMenuOpen, setTierMenuOpen] = useState(false)
    const handleChangeTierMenuFilter = () => {
        setTierMenuOpen(prevState => !prevState)
    }

    const handleChangeSchema = (schemaValue) => {
        if (!page) return
        if (page === COLLECTION) {
            dispatch(handleChangeTierSchemaValueAction(COLLECTION_PAGE_TIER_SCHEMA, schemaValue))
        }
        if (page === NFT_CARD) {
            dispatch(handleChangeTierSchemaValueAction(TOKEN_DETAIL_PAGE_TIER_SCHEMA, schemaValue))
        }
        if (page === MARKETPLACE) {
            dispatch(handleChangeTierSchemaValueAction(MARKETPLACE_PAGE_TIER_SCHEMA, schemaValue))
            dispatch(MarketplaceAPICallStart(true))
        }
        handleChangeTierMenuFilter()
    }

    const getTierIcon = (schema) => {
        return <GoldTierIcon className="mr-2"/>

        if (schema.toLowerCase() === 'gold') {
            return <GoldTierIcon className="mr-2"/>
        } else if (schema.toLowerCase() === "silver") {
            return <SilverTierIcon className="mr-2"/>
        } else if (schema.toLowerCase() === "bronze") {
            return <BronzeTierIcon className="mr-2"/>
        } else {
            return <AllTierIcon/>
        }
    }
    const getTierColor = (schema) => {
        return "gold-text";
        if (schema.toLowerCase() === 'gold') {
            return "gold-text"
        } else if (schema.toLowerCase() === "silver") {
            return "silver-text"
        } else if (schema.toLowerCase() === "bronze") {
            return "bronze-text"
        } else {
            return "lightmode-text-marigold"
        }
    }

    const handleTierMenuClickOutside = useCallback(() => {
        if (tierMenuOpen) {
            setTierMenuOpen(false);
        }
    }, [tierMenuOpen]);

    useOnClickOutside(ref, handleTierMenuClickOutside);


    return (
        <Fragment>

            {Array.isArray(schemaList) && schemaList.length > 0 &&
            <Fragment>
                <div ref={ref}>
                    <button className={`btn font12px TierBtnBlock ${getTierColor(schemaValue)}`}
                            onClick={handleChangeTierMenuFilter}>
                        {getTierIcon(schemaValue)}
                        {!!schemaValue ? GET_TIER_SCHEMA_TEXT(schemaList, schemaValue) : "All Tiers"}
                        <ArrowDownIcon className="ml-4"/>
                    </button>

                    <div className={`allTierDropdownMenu ${className} ${tierMenuOpen ? "" : "d-none"}`}>

                        {Array.isArray(schemaList) && schemaList.length > 0 && schemaList.map((schema, index) => {
                            return (
                                <Fragment key={index}>
                                    <button className={`btn nowrap font12px ${getTierColor(schema?.id)} text-left`}
                                            onClick={() => handleChangeSchema(schema?.id)}>
                                        {getTierIcon(schema)}
                                        {schema?.schemaDisplayName}
                                    </button>
                                </Fragment>
                            )
                        })}

                    </div>
                </div>
            </Fragment>
            }

            {/*this is used for right side sort icon*/}
            {Array.isArray(schemaList) && schemaList.length === 0 &&
            <div/>
            }

        </Fragment>
    )
}

export default AllTierMenu

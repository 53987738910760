import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import UserNFTTemplate from "../Common/UserNFTTemplate";
import { API_FETCH_COMPLETE, API_FETCH_START, GET_SORT_BY_PARAMS, GET_SORT_LABEL } from "../../constants/commonFunc";
import http from "../../helpers/Interceptors";
import NFTSkeleton from "../Common/Skeleton/NFTSkeleton";
import { GetProfileDataActionChange } from "../../redux/actions/profileAction";
import { MY_LISTED_NFT_DATA, MY_LISTED_NFT_SORT_CHANGE, MY_PACKS_DATA } from "../../redux/action-types/profile.types";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Common/NoDataFound";
import { ArrowDownIcon } from "../../helpers/svgConst";
import SortMenu from "../Common/SortMenu";
import useOnClickOutside from "../../helpers/Hooks/useOnClickOutside";


const PackUnpackCards = ({ type, apiPath }) => {

    const dispatch = useDispatch()
    const ref = useRef()

    const PAGE_LIMIT = 10

    const activeSortValue = useSelector((state) => state?.profile?.activeMyListedNFTSort);
    const sortLabel = GET_SORT_LABEL(activeSortValue)

    const [collectionName, setCollectionName] = useState("")
    const [sortByName, setSortByName] = useState("")
    const [loading, setLoading] = useState("")
    const [productList, setProductList] = useState([])
    const [totalProduct, setTotalProduct] = useState(0)
    const [openSortMenu, setOpenSortMenu] = useState(false)


    const handleSortMenuClickOutside = useCallback(() => {
        if (openSortMenu) {
            setOpenSortMenu(false);
        }
    }, [ref, openSortMenu])

    useOnClickOutside(ref, handleSortMenuClickOutside);


    const getSortByParams = () => {
        return (sortByName && sortByName.toString().length === 2) ? ("&sortByType=" +
            sortByName[0] +
            "&sortBy=" +
            sortByName[1]) : ""
    }

    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (productList.length === 0) return 1
        return productList
            ? Math.ceil(productList.length / PAGE_LIMIT) + 1
            : 1;
    }

    const getOwnedCards = (reloadSearch = false) => {
        setLoading(API_FETCH_START)
        const sortParam = GET_SORT_BY_PARAMS(activeSortValue)
        const apiPage = getPageNo(reloadSearch)

        const apiPathFinal =
            apiPath +
            "?page=" +
            apiPage +
            "&limit=" +
            PAGE_LIMIT +
            "&onlyPack=" +
            true +
            sortParam;


        http.get(apiPathFinal)
            .then((res) => {
                let mergedFinalProducts = []
                const apiData = res?.data?.data;
                dispatch(GetProfileDataActionChange(MY_PACKS_DATA, apiData))
                const apiProductList = apiData.lists || [];
                const apiProductCount = apiData.count || 0;


                setTotalProduct(apiProductCount)

                //when data is selected with different filter
                if (reloadSearch) {
                    setProductList(apiProductList)
                } else {
                    // when data is selected with same filter but page is change
                    // only params are same
                    mergedFinalProducts = [...productList, ...apiProductList];
                    setProductList(mergedFinalProducts);
                }


            }).catch((err) => console.error("failed to fetch marketplace data", err))
            .finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })


    }

    const fetchMoreItems = () => {
        if (productList.length < totalProduct) {
            getOwnedCards(false);
        }
    }

    useEffect(() => {
        if (!!activeSortValue) {
            setProductList([])
            setTotalProduct(0)
            getOwnedCards(true);
        } else {
            getOwnedCards()
        }
    }, [activeSortValue]);

    return (
        <Fragment>

            <div className='d-flex justify-content-between align-items-center p-15 profileShadow whiteBG mb-10'>
                <div className="pos-rel d-flex w-100" ref={ref}>
                    <button className='btn p-0 font12px lightmode-text-marigold' onClick={() => setOpenSortMenu(true)} ref={ref}>
                        {sortLabel}
                        <ArrowDownIcon className="ml-1" />
                    </button>
                    {/* <button className='btn p-0'>
                    <FilterIcon />
                </button> */}
                    <SortMenu
                        page="profile"
                        sortActionType={MY_LISTED_NFT_SORT_CHANGE}
                        className={`left-0 ${openSortMenu ? "openSortBlock" : "closeSortBlock"}`}
                        handleSortMenuClickOutside={handleSortMenuClickOutside}
                        activeSortValue={activeSortValue}
                    />
                </div>
            </div>
            <div className="pi-15 min-vh-100">


                <InfiniteScroll
                    next={fetchMoreItems}
                    hasMore={productList.length < totalProduct}
                    dataLength={productList.length}
                    loader={(loading !== API_FETCH_COMPLETE) &&
                        <NFTSkeleton imageHeight={80} className={"mb-4"} />
                    }>

                    <UserNFTTemplate shopData={productList} type={type} />

                </InfiniteScroll>

                {(loading !== API_FETCH_COMPLETE) &&
                    <NFTSkeleton imageHeight={80} className={"mb-4"} />
                }


                {(loading === API_FETCH_COMPLETE) && productList.length === 0 &&
                    <NoDataFound text="You haven't listed any NFT" />
                }
            </div>
        </Fragment>
    )
}

export default PackUnpackCards

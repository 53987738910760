import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Fragment, useEffect, useState } from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { useLocation } from "react-router-dom"
import USER_HELPER from "./helpers/apis/userHelper";
import { getCookie } from "./constants/commonFunc";
import CARD_HELPER from "./helpers/apis/cardHelper";
import COLLECTION_HELPER from "./helpers/apis/collectionHelper";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { setMetaMaskAddress } from "./redux/actions/userActions";

function App(props) {

    const location = useLocation();
    const dispatch = useDispatch()
    const { isUserLogin, getUserDetails } = USER_HELPER() // all the user related functions written in user helper file
    const { getMyFavouriteCards } = CARD_HELPER() // all the card related info written in card helper
    const { getMyFavouriteCollection } = COLLECTION_HELPER() // all the collection related info written in collection helper file


    useEffect(() => {
        // checking cookie is present or not, if available get the user full information
        const isAuthCookie = getCookie("Authorization")
        if (!!isAuthCookie) {
            getUserDetails()
        }

        // checking metamask address and if present add in redux store
        const localMetaAddress = localStorage.getItem("metaMask")
        if (!!localMetaAddress) {
            dispatch(setMetaMaskAddress(localMetaAddress));
        }

    }, [])

    useEffect(() => {
        // scroll automatic on top when page routes changes
        window.scrollTo(0, 0);

    }, [location]);




    useEffect(() => {
        // checking if user is logged in, then we are fetching all the liked card and collection and user info
        if (isUserLogin) {
            getMyFavouriteCards()
            getMyFavouriteCollection()
            getUserDetails()
        }
        console.log("print env", process.env.REACT_APP_ENV)
    }, [isUserLogin])

    console.log("process env", process.env.REACT_APP_ENV)


    // wax cloud wallet integration code
    const [, setActiveUser] = useState(null);
    const [, setAccountName] = useState("");

    useEffect(() => {
        const {
            ual: { activeUser },
        } = props;
        if (activeUser && !props.ual.activeUser) {
            setActiveUser({ activeUser }, updateAccountName);
        } else if (activeUser && props.ual.activeUser) {
            setActiveUser(null) && setAccountName("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updateAccountName() {
        try {
            const userAccountName = await props.ual.activeUser.getAccountName();
            setAccountName(userAccountName);
        } catch (e) {
            console.warn(e);
        }
    }


    return (
        <Fragment>
            {isUserLogin && <PrivateRoutes {...props} />}
            <PublicRoutes />
            <ToastContainer />
        </Fragment>
    );
}

export default App;

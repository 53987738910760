import React, { Fragment, useEffect, useState } from 'react'
import CollectionTemplate from '../../components/Collection/CollectionTemplate'
import OwensHeader from '../../components/Common/OwensHeader'
import FilterList from '../../components/MarketPlace/FilterList'
import { API_FETCH_COMPLETE, API_FETCH_START } from '../../constants/commonFunc'
import COLLECTION_HELPER from '../../helpers/apis/collectionHelper'
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from '../../components/Common/NoDataFound'

const MoreCollections = () => {

    const PAGE_LIMIT = 10
    const [loading, setLoading] = useState(false)
    const [collectionList, setCollectionList] = useState([])
    const [totalCount, setTotalCount] = useState(0)

    const { getCollectionList } = COLLECTION_HELPER()


    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (collectionList.length === 0) return 1
        return collectionList
            ? Math.ceil(collectionList.length / PAGE_LIMIT) + 1
            : 1;
    }

    const getCollectionResultList = async (reloadSearch) => {
        setLoading(API_FETCH_START)
        const apiPage = getPageNo(reloadSearch)
        const apiRes = await getCollectionList(apiPage, PAGE_LIMIT);
        if (apiRes?.status === 200) {
            let mergedFinalResult = []
            const apiData = apiRes?.data?.data
            const apiTransactionList = apiData?.collectionList || []
            mergedFinalResult = [...collectionList, ...apiTransactionList];
            setCollectionList(mergedFinalResult);
            setTotalCount(apiData?.count)
        }
        setLoading(API_FETCH_COMPLETE)

    }

    const fetchMoreItems = () => {
        if (collectionList.length < totalCount) {
            getCollectionResultList(false);
        }
    }


    useEffect(() => {
        getCollectionResultList()
    }, [])


    return (
        <Fragment>
            <section className='lightMode'>
                <OwensHeader />
                <h1 className='font16pxSemiBold lightmode-text-blue mt-40 pi-15'>Curated collection for you</h1>
                {/* <div className="marketFilterScrollBlock mt-25 pl-15">
                    <FilterList title="Live Auction"/>
                    <FilterList title="Buy Now"/>
                    <FilterList title="Primary"/>
                    <FilterList title="Not for Sale"/>
                </div> */}
                <div className='pi-15'>
                    <div className='main-white-card mt-20 pl-10 pr-10'>
                        <InfiniteScroll
                            next={fetchMoreItems}
                            hasMore={collectionList.length < totalCount}
                            dataLength={collectionList.length}
                            loader={false}>
                            <div className="moreCollectionGrid">
                                <CollectionTemplate collectionList={collectionList} isLoading={loading} />
                            </div>
                        </InfiniteScroll>
                        {(loading === API_FETCH_COMPLETE) && collectionList?.length === 0 &&
                            <NoDataFound image='true' text="No Collections Found" />
                        }

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default MoreCollections

import React, { Fragment } from 'react'
import SubHeader from '../../components/Common/SubHeader'
import { ReceiptIcon } from '../../helpers/svgConst'
import PaymentHistoryDetail from "../../components/Wallet/PaymentHistoryDetail"



const PaymentHistory = () => {
    return (
        <Fragment>
            <section className='lightMode whiteBG'>
                <div className='pi-15'>
                    <SubHeader />
                    <div className='mt-20'>
                        <p className='font24pxBold lightmode-text-blue'>My Wallet</p>
                    </div>
                    <div className='paymentHistoryBlock mt-25'>
                        <p className='font12px lightmode-text-purple text-center'><ReceiptIcon className="mr-2" /> Payment History</p>
                        <PaymentHistoryDetail />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default PaymentHistory

import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../helpers/Paths'
import PrimaryButton from '../Login/PrimaryButton'
import ThumbIcon from "../../images/Common/kycDone.png"
import { useSelector } from 'react-redux'
import USER_HELPER from '../../helpers/apis/userHelper'

const LinkWalletSuccessPage = () => {


    const { getUserDetails } = USER_HELPER()
    const navigate = useNavigate()

    const metaMaskAddress = useSelector((state) => state?.user?.metaMaskAddress)

    useEffect(() => {
        getUserDetails()
    }, [])


    return (
        <Fragment>
            <section className="newFettiBG" />
            <section className='lightMode kycBG'>
                <div className='kycSubBG pt-100'>
                    <div className='pi-15'>

                        <div className='final-card-block'>
                            <img src={ThumbIcon} alt="sucess" />
                        </div>

                        <p className='font24pxSemiBold lightmode-text-marigold text-center mt-20'>Congrats!</p>

                        <p className='font14px lightmode-text-blue text-center mt-15'>
                            You have successfully linked your wallet. Your wallet Address is  <span className='lightmode-text-secondary'>{metaMaskAddress}</span>
                        </p>
                        <p className='font14px lightmode-text-blue text-center mt-0'>
                            You can see your on-chain cards in profile
                        </p>
                        <PrimaryButton heading="Go to profile" primaryClassName='mt-25' onClick={() => navigate(Paths.ProfilePage + "?tab=5")} />


                    </div>

                </div>
            </section>
        </Fragment>
    )
}

export default LinkWalletSuccessPage
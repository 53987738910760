import React, { Fragment, useEffect, useState } from "react"
import { CheckWAXImage, IMAGE_CDN } from "../../constants/commonFunc";
import "../../components/Common/CommonStyles.css"


const ShopCard = (props) => {
    const [data, setData] = useState([])
    const buy = "0"

    useEffect(() => {
        setData(props.shopData)
    }, [props?.shopData])

    return (
        <Fragment>
            {data &&
                data.length > 0 &&
                data.map((key, index) => {
                    return (
                        <Fragment key={index}>
                            <div className="main-white-card mt-4 " key={index}>

                                <div className="singleCardBlock cursor-pointer">
                                    {(key.mintNumber || key.template_mint) && (
                                        <div className="mintNumberCard">
                                            <p className="mintNoNew">
                                                #{key.mintNumber || key.template_mint}
                                            </p>
                                        </div>
                                    )}
                                    <div className="unpackCardImageBlock">
                                        {key.image && (
                                            <img
                                                effect="blur"
                                                alt={key.name}
                                                src={CheckWAXImage(key.image) + "?width=350"}
                                                className="cardImg"

                                            />
                                        )}

                                        {!key.image && key?.video && (
                                            <div className="abcd">
                                                <video
                                                    loop={true}
                                                    className="marketVideo"
                                                    autoPlay={true}
                                                    muted={true}
                                                >
                                                    <source src={key?.video} type="video/mp4" />
                                                </video>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <h5 className="font16pxSemiBold lightmode-text-blue mt-3 text-center">{key.name}</h5>
                                        <span className="lineStyle" />
                                    </div>

                                    {key.listedPrice && (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img
                                                src="/cardSmallIcon.png"
                                                alt="fs"
                                                className="smalIconCard m2Set"
                                            />
                                            <h5 className="cardSubHeading m2Set ml-2">
                                                $ {parseFloat(key.listedPrice).toFixed(2)}
                                            </h5>
                                        </div>
                                    )}

                                    {key?.market?.curren_bid_list && (
                                        <h4 className="currentBid">
                                            {key?.market?.curren_bid_list}
                                        </h4>
                                    )}

                                    {/* <div className="d-flex justify-content-center mt-3">
                                        <img
                                            src="/cardFooterImg.png"
                                            alt="hd"
                                            className="cardFooterImg"
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
        </Fragment >
    )
}

export default ShopCard

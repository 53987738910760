import React, { Fragment } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const dummyData = [
    { name: "Page A", value: 2400 },
    { name: "Page B", value: 1000 },
    { name: "Page C", value: 1500 },
    { name: "Page D", value: 2000 },
    { name: "Page E", value: 156 },
    { name: "Page F", value: 850 },
    { name: "Page G", value: 2100 },
    { name: "Page H", value: 1337 },
    { name: "Page I", value: 2150 }
];

const colors = ["#e74c3c"];

const BarRechart = ({ data, dataKey }) => {

    return (
        <Fragment>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart data={data}
                    barCategoryGap="3%">
                    <CartesianGrid strokeDasharray="2" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend iconType="circle" />
                    <Bar dataKey={dataKey}>
                        {data.length > 0 && data.map((entry, index) => (
                            <Cell fill={colors[0]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Fragment>
    );
}

export default BarRechart
import React, { Fragment, useEffect, useState } from 'react'
import GlanceImage from '../../components/Common/GlanceImage'
import SubHeader from '../../components/Common/SubHeader'
import PrimaryButton from '../../components/Login/PrimaryButton'
import { DrawerLine, TagIcon } from '../../helpers/svgConst'
import { useDispatch, useSelector } from "react-redux";
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    CheckWAXImage,
    GET_ALL_QUERY_PARAMS,
    GET_ERROR_TOAST_MSG,
    PRICE_FORMAT,
    SEND_PRICE_FORMAT
} from "../../constants/commonFunc";
import http from "../../helpers/Interceptors"
import { MAKE_AN_OFFER_API, MAKE_OFFER_UNLISTED_CARDS } from "../../helpers/ApisPath";
import { useLocation, useNavigate } from "react-router-dom";
import Paths from '../../helpers/Paths'
import { Box, Divider, SwipeableDrawer } from '@mui/material'
import { MakeAnOfferRedirectAction } from "../../redux/actions/cardDetailActions";
import { toast } from "react-toastify";
import CARD_HELPER from "../../helpers/apis/cardHelper";

const BuyAndMakeOffer = () => {


    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [price, setPrice] = useState("")
    const [loading, setLoading] = useState(API_FETCH_COMPLETE)
    const [errorMessage, setErrorMessage] = useState({})
    const cardFullInfo = useSelector((state) => state?.cardDetail?.makeAnOfferNFT)
    const { removeBidFromCardByMarketplaceId, removeBidFromCardByCardIdForUnlistedNFT } = CARD_HELPER()
    const userOffer = useSelector((state) => state?.cardDetail?.userOfferForCard)

    const queryParam = GET_ALL_QUERY_PARAMS(location?.search);
    const QUERY_TYPE = queryParam?.type ? queryParam?.type : ""

    const [openState, setOpenState] = useState({
        bottom: false,
    });

    const handleMakeAnOffer = () => {
        if (QUERY_TYPE === "updateOffer") {
            handleNFTCancelOfferAndUpdateNewOffer()
            return

        } else if (QUERY_TYPE === "updatedOfferUnlisted") {
            handleNFTCancelOfferAndUpdateNewOfferForUnlistedNFT()
            return
        }
        // if no update offer then simply make offer
        handleMakeAnOfferForListedAndUnlistedBoth()
    }

    const handleNFTCancelOfferAndUpdateNewOffer = async () => {
        // when user trying to make update offer and nft is listed in marketplace
        const res = await removeBidFromCardByMarketplaceId(cardFullInfo?.marketplaceId)
        if (res?.status === 200) {
            const finalRes = await handleMakeAnOfferForListedAndUnlistedBoth()
        } else {
            toast.error("Failed to update offer")
        }
    }


    const handleNFTCancelOfferAndUpdateNewOfferForUnlistedNFT = async () => {
        // when user trying to make update offer for unlisted NFTs in Marketplace
        const res = await removeBidFromCardByCardIdForUnlistedNFT(cardFullInfo?.id)
        if (res?.status === 200) {
            const finalRes = await handleMakeAnOfferForListedAndUnlistedBoth()
        } else {
            toast.error("Failed to update offer")
        }
    }


    const handleMakeAnOfferForListedAndUnlistedBoth = () => {

        if (parseFloat(price) < parseFloat(PRICE_FORMAT(cardFullInfo?.listedPrice, false))) {
            setErrorMessage("Price should be greater than or equal to listed price")
            return
        }
        setLoading(API_FETCH_START)
        setErrorMessage("")

        let formData = {}

        if (!!cardFullInfo?.marketplaceId) {
            formData = {
                marketplaceId: cardFullInfo?.marketplaceId,
                price: parseFloat(SEND_PRICE_FORMAT(price))
            }
        } else {
            formData = {
                cardId: cardFullInfo?.id,
                price: parseFloat(SEND_PRICE_FORMAT(price))
            }
        }

        const apiFinalPath = cardFullInfo?.marketplaceId ? MAKE_AN_OFFER_API : MAKE_OFFER_UNLISTED_CARDS

        // when nft is listed in marketplace then
        http.post(apiFinalPath, formData)
            .then((res) => {
                console.log("res", res)
                if (res?.status === 200) {
                    const offeredKey = {
                        offeredPrice: parseFloat(SEND_PRICE_FORMAT(price))
                    }
                    const mergedNFTInfo = { ...cardFullInfo, ...offeredKey }
                    dispatch(MakeAnOfferRedirectAction(mergedNFTInfo))
                    navigate(Paths.NFTDirectBuySuccessPage + "?type=offer", { replace: true })
                }
            }).catch((err) => {
                console.error("Error failed while nft make an offer", err)
                const errorMsg = GET_ERROR_TOAST_MSG(err)
                setErrorMessage("Failed to make offer " + errorMsg)

            }).finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenState({ ...openState, [anchor]: open });
    };

    const TakePhotoCapture = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel"
            >
                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>

                <div className='pi-15 pb-25'>
                    <div className='d-flex align-items-center gap-10 mb-10 mt-15'>
                        <TagIcon />
                        <h6 className='font16pxSemiBold lightmode-text-blue'>How offers work</h6>
                    </div>
                    <Divider className="mb-20" />
                    <p className='font12px'>If the current holder of this NFT accepts your offer, it will be
                        automatically added to your collection and we will notify you the same via e-mail. </p>
                    <ul className='mt-10 pl-25'>
                        <li className='font12px lightmode-text-blue mb-1'>
                            You won't be charged until the current holder of an NFT accepts your offer.
                        </li>
                        <li className='font12px lightmode-text-blue mb-1'>
                            As you are purchasing this NFT from a different holder (other than the artist) this will be
                            considered as a secondary sale.
                        </li>
                        <li className='font12px lightmode-text-blue mb-1'>
                            Owens is not charging any platform fee from the secondary sales.
                        </li>
                    </ul>
                </div>
            </Box>
        )
    }


    return (
        <Fragment>
            <section className='lightMode'>
                <div className='pi-15'>
                    <SubHeader />
                    <h1 className='font14pxSemiBold mt-45'>
                        {cardFullInfo?.listingType ? (cardFullInfo?.listingType === 2 ? "Make an offer for this NFT" : "Buy this NFT at a fixed price") : "Make an offer for this NFT"}

                    </h1>
                    <div className='collectionHeroImageBlock bor-rad-fix-down mx-auto mt-20'>
                        <GlanceImage src={CheckWAXImage(cardFullInfo?.image)} alt="thumbnail" />
                    </div>
                    <div
                        className='d-flex justify-content-between bg-white align-items-center p-15 bor-rad-fix-up pt-20'>
                        {/* <div className='goldExpBlock ml-0 d-flex'>
                            <p className='font8pxSemiBold mr-1 lightmode-text-secondary'>+590</p>
                            <GoldExpCoinIcon/>
                        </div> */}
                        <div className='ml-auto'>
                            <p className='font16px lightmode-text-blue d-flex align-items-center'>
                                {cardFullInfo?.listingType && cardFullInfo?.listingType !== 2 &&
                                    <span className='font12pxMedium lightmode-text-marigold mr-2'>
                                        Listed for fixed price:
                                    </span>
                                }
                                {cardFullInfo?.listedPrice &&
                                    <Fragment>
                                        $ {PRICE_FORMAT(cardFullInfo?.listedPrice, false)}
                                    </Fragment>
                                }
                            </p>
                        </div>
                    </div>

                    <div className='main-white-card nftListForm align-items-center'>
                        <div>
                            <p className='font12px lightmode-text-midnight-blue '>
                                Your offer
                            </p>
                            <p className='font12px lightmode-text-light-gray mt-2'>
                                Make an offer on this NFT
                            </p>
                        </div>
                        <div
                            className='lightmode-text-secondary d-flex align-items-center justify-content-center listInput'>
                            <span className="">$ </span>
                            <input type="number"
                                pattern="\d*"
                                placeholder={userOffer.length > 0 ? PRICE_FORMAT(userOffer[0]?.offeredPrice) : ""}
                                className='inputBlock w-100 lightmode-text-secondary font16pxBold pl-2'
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                    </div>
                    {/* Make an offer block below 🔽*/}
                    <div className='main-white-card mt-10 finalDetailBlock'>
                        <TagIcon />
                        <p className='font12px lightmode-text-blue'>If the current holder of this NFT accepts your
                            offer, it will be automatically added to your collection and we will notify you the same via
                            e-mail. <button className='btn p-0 font10px lightmode-text-marigold'
                                onClick={toggleDrawer("bottom", true)}>Click here for more info</button>
                        </p>
                    </div>

                    {/* Buy now block below 🔽*/}
                    {/* <div className='main-white-card mt-10 finalDetailBlock'>
                        <TagIcon/>
                        <p className='font12px lightmode-text-blue'>You are buying this NFT for the <span
                            className='font12pxSemiBold'>fixed price of $ 1250</span> as listed by the owner of this
                            NFT.
                            Once the transaction is completed, this NFT will be instantly added to your collection.</p>
                    </div> */}
                    {!!errorMessage && errorMessage.length > 0 &&
                        <p className='font12px form-error mt-20'>{errorMessage}</p>
                    }
                </div>
                {/* Buy now block below 🔽*/}
                {/* <div className='main-white-card mt-70'>
                    <PrimaryButton heading="Buy Now for $ 1250 " />
                </div> */}
                {/* Make an offer block below 🔽*/}
                <div className='main-white-card mt-70'>
                    <PrimaryButton heading="Make an offer" onClick={handleMakeAnOffer}
                        isLoading={loading !== API_FETCH_COMPLETE} />
                </div>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={openState["bottom"]}
                    onClose={toggleDrawer("bottom", false)}
                    onOpen={toggleDrawer("bottom", true)}
                    className="swipableDrawerBlock"
                >
                    {TakePhotoCapture("bottom")}
                </SwipeableDrawer>
            </section>
        </Fragment>
    )
}

export default BuyAndMakeOffer

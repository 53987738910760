import React, { Fragment } from 'react'
import "../../components/KYCVerification/KYCStyles.css"
import { GoBackIcon, StepperActiveIcon } from '../../helpers/svgConst'
import KYCPersonalDetails from '../../components/KYCVerification/KYCPersonalDetails'
import KYCIDProof from '../../components/KYCVerification/KYCIDProof'
import KYCVerifySelfie from "../../components/KYCVerification/KYCVerifySelfie"
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import USER_HELPER from '../../helpers/apis/userHelper'
import { useParams } from "react-router-dom";

const KYCVerification = () => {

    const { handleGoBack } = USER_HELPER()
    const params = useParams()

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#DBD0F5',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#9966CC',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderStyle: "dotted",
            borderBottomWidth: 0
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            outline: '1px solid #DBD0F5',
            outlineOffset: 2,
            backgroundColor: 'transparent',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <StepperActiveIcon className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    const steps = [
        'Personal Details',
        'ID Proof',
        'Live Selfie',
    ];

    const activeStep = parseInt(params?.kycStep);




    return (
        <Fragment>
            <section className='lightMode d-flex flex-column'>
                <div className='pi-15'>
                    <div className='d-flex align-items-center pt-30'>
                        <button className='btn p-1' onClick={handleGoBack}>
                            <GoBackIcon />
                        </button>
                        <Stack sx={{ width: '100%' }} spacing={4}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel className='stepper'
                                            StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                    </div>

                    <p className="font12pxMedium mt-30 lightmode-text-secondary">
                        Steps {activeStep} of 3
                    </p>
                </div>
                {activeStep === 1 &&
                    <KYCPersonalDetails />
                }
                {activeStep === 2 &&
                    <KYCIDProof />
                }
                {activeStep === 3 &&
                    <KYCVerifySelfie />
                }
            </section>
        </Fragment>
    )
}

export default KYCVerification

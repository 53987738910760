import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import { Wax } from '@eosdacio/ual-wax';
import { Scatter } from 'ual-scatter';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { WAX_APP_NAME, WAX_CHAIN_ID, WAX_HOST, WAX_PORT, WAX_PROTOCOL } from './constants/envConstants';

const waxChain = {
    chainId: WAX_CHAIN_ID,
    rpcEndpoints: [{
        protocol: WAX_PROTOCOL,
        host: WAX_HOST,
        port: WAX_PORT
    }]
}

const AppWithUAL = withUAL(App);
const appName = WAX_APP_NAME;
const scatter = new Scatter([waxChain], { appName: appName });
// const anchor = new Anchor([waxChain], { appName: appName });
const waxcloud = new Wax([waxChain], { appName: appName });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <UALProvider chains={[waxChain]} authenticators={[waxcloud, scatter]} appName={appName}>
        <Provider store={store}>
            <BrowserRouter>
                <AppWithUAL />
            </BrowserRouter>
        </Provider>
    </UALProvider>
);

reportWebVitals();

import { SwipeableDrawer } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react'
import { SearchIcon } from '../../helpers/svgConst';
import Box from "@mui/material/Box";
import CommonSearch from './CommonSearch';


const SearchDrawer = () => {

    const url = window.location.pathname;
    const ref = useRef()

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const HeaderList = (anchor) => {
        return (
            <Box
                role="presentation"
                className="pos-rel"
            >
                <section className="lightMode soraFont">
                    <div className="pi-15 pt-30" ref={ref}>

                        <CommonSearch isHeader="true" goBackFunc={toggleDrawer(anchor, false)} />
                    </div>
                </section>
            </Box>
        );
    };


    return (
        <Fragment>
            <button
                className="btn p-0 headerSearchIcon"
                onClick={toggleDrawer("right", true)}
            >
                <SearchIcon color={url === "/" ? "#004C75" : ""} />
            </button>
            <SwipeableDrawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
            >
                {HeaderList("right")}
            </SwipeableDrawer>
        </Fragment>
    )
}

export default SearchDrawer

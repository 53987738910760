import React, {Fragment, useEffect, useState} from "react";
import {CameraIcon, DrawerLine, GreenTick, RedCross, RefreshIcon} from "../../helpers/svgConst";
import Dropzone from "react-dropzone";
import http from "../../helpers/Interceptors";
import {UPLOAD_PAN_CARD_API} from "../../helpers/ApisPath";
import {GET_ERROR_TOAST_MSG} from "../../constants/commonFunc";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import KYCHelper from "../../helpers/apis/KYCHelper";

const UploadPanCard = () => {

    const {GetKYCFullDetails} = KYCHelper()
    const [state, setState] = React.useState({bottom: false,});
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [imageErrorMessage, setImageErrorMessage] = useState("")
    const [imageSuccessMessage, setImageSuccessMessage] = useState("")
    const [file, setFile] = useState([])


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };


    const handlePanImageChange = (file) => {
        if (!file && !Array.isArray(file) && file.length < 1) return
        setFile(file)
        setImageUploadLoading(true)
        const formData = new FormData()

        if (!!file) {
            formData.append(
                "photo",
                file[0],
                file[0].name
            )
        }

        http.post(UPLOAD_PAN_CARD_API, formData)
            .then((res) => {
                if (res?.status === 200) {
                    setImageSuccessMessage(res?.data?.message)
                    GetKYCFullDetails()
                }
            }).catch((err) => {
            console.error("Error", err?.response?.data?.message)
            const errorMsg = GET_ERROR_TOAST_MSG(err)
            setImageErrorMessage(errorMsg ? errorMsg : "Failed to validate Aadhar card")
        }).finally(() => {
            setImageUploadLoading(false)
        })

    }

    const handleImageSizeCheckAndUpload = (file) => {
        if (!Array.isArray(file) || file.length === 0) return
        if (!!file[0].size) {
            let finalSize = ((file[0].size / 1024) / 1024)
            if (finalSize > 2) {
                setImageErrorMessage("Couldn’t upload! File size should not be more than 2 MB.")
                return
            }
        }
        handlePanImageChange(file)
    }

    const handleImageChange = (fileInfo) => {
        setImageErrorMessage("")
        setFile(fileInfo.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        handleImageSizeCheckAndUpload(fileInfo)
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => file.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);


    const TakePhotoCapture = (anchor) => {
        return (
            <Box
                sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
                role="presentation"
                className="pos-rel"
            >
                <div className='text-center'>
                    <DrawerLine className="drawerLine"/>
                </div>

                <div className='pi-15 d-flex flex-column soraFont text-center'>

                    <Dropzone onDrop={acceptedFiles => handleImageChange(acceptedFiles)}
                              maxFiles={1}
                              multiple={false}
                              noDragEventsBubbling={true}
                              accept={{'image/*': []}}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <button
                                        onClickCapture={toggleDrawer("bottom", false)}
                                        className="btn font12px lightmode-text-blue mt-20"
                                        disabled={imageUploadLoading}>
                                        Take Photo
                                    </button>
                                </div>
                            </section>
                        )}
                    </Dropzone>


                    <button className=' btn font12px lightmode-text-light-gray mt-20 mb-20'
                            onClick={toggleDrawer(anchor, false)}>
                        Cancel
                    </button>
                </div>
            </Box>
        )
    }

    return (

        <Fragment>
            <p className="font12px mb-10 mt-30">Upload Pan Card</p>

            {!!file && Array.isArray(file) && file.length > 0 &&
            <img src={file[0].preview} alt="Pan" className="w-100"/>
            }
            {imageUploadLoading &&
            <p className="gold-clr mt-2 font10px">
                Please wait uploading...
            </p>
            }

            {(!file || !Array.isArray(file) || file.length === 0) &&
            <Dropzone onDrop={acceptedFiles => handleImageChange(acceptedFiles)}
                      maxFiles={1}
                      multiple={false}
                      noDragEventsBubbling={true}
                      accept={{'image/*': []}}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <button className='btn addImageBtn lightmode-text-light-gray font12px'>
                                <CameraIcon/>
                                Add a photo
                            </button>
                        </div>
                    </section>
                )}
            </Dropzone>

            }

            {!!imageErrorMessage && !imageUploadLoading &&
            <Fragment>
                <p className='font12px mt-25'>After recognition, your photo is:</p>
                <p className='font12px mt-2'><RedCross className="mr-2"/>
                    {imageErrorMessage}
                </p>
                <Dropzone onDrop={acceptedFiles => handleImageChange(acceptedFiles)}
                          maxFiles={1}
                          multiple={false}
                          noDragEventsBubbling={true}
                          accept={{'image/*': []}}
                >
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button className='btn uploadBtn d-flex gap-10 align-items-center lightmode-text-secondary mt-25 mx-auto mb-30'>
                                    <RefreshIcon/>
                                    Retake Photo
                                </button>
                            </div>
                        </section>
                    )}
                </Dropzone>

            </Fragment>
            }

            {!!imageSuccessMessage &&
            <Fragment>
                <p className='font12px mt-25'>After recognition, your photo is:</p>
                <p className='font12px mt-2'><GreenTick className="mr-2"/>{imageSuccessMessage}</p>
            </Fragment>
            }

            <SwipeableDrawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer("bottom", false)}
                onOpen={toggleDrawer("bottom", true)}
                className="swipableDrawerBlock"
            >
                {TakePhotoCapture("bottom")}
            </SwipeableDrawer>


        </Fragment>


    )
}

export default UploadPanCard

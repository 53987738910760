import { Fragment } from "react"
import LinkOnChainWallet from "./LinkOnChainWallet"

const UpdateOnChainWalletAddress = () => {
    return (
        <Fragment>
            <LinkOnChainWallet type="UPDATE" />
        </Fragment>
    )
}

export default UpdateOnChainWalletAddress
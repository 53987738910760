import { INITIATE_LOGIN, REDIRECT_AFTER_LOGIN, USER_LOGIN_SUCCESS } from "../action-types/userAction.types";
import { SET_ACTIVE_BANK_ACCOUNT } from "../action-types/withdraw.types";

const initialState = {
    activeBankAccount: {}

}

const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_BANK_ACCOUNT:
            return {
                ...state,
                activeBankAccount: action.payload
            }
        default:
            return state
    }
}

export default withdrawReducer

import React, { Fragment, useEffect, useState } from "react";
import LightmodeAccordian from "../../components/Common/LightmodeAccordian";
import OwensHeader from "../../components/Common/OwensHeader";
import { MoreTemplateIcon } from "../../helpers/svgConst";
import PrimaryButton from "../../components/Login/PrimaryButton";
import ActivityCard from "../../components/Common/ActivityCard";
import SecondaryButton from "../../components/Common/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { API_FETCH_COMPLETE, API_FETCH_START, changeURLLastString, } from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import CollectionNFTCard from "../../components/Common/CollectionNFTCard";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import CollectionTemplate from "../../components/Collection/CollectionTemplate";
import { GET_COLLECTION_ACTIVITY_API } from "../../helpers/ApisPath";
import http from "../../helpers/Interceptors";
import WhatDoIGet from "../../components/Common/WhatDoIGet";
import TierOffer from "../../components/Common/TierOffer";
import CollectionBasicInfo from "../../components/Collection/CollectionBasicInfo";
import AllTierMenu from "../../components/Common/AllTierMenu";
import { COLLECTION } from "../../constants/envConstants";
import { useSelector } from "react-redux";
import TransactionCardDetail from "../../components/Collection/TransactionCardDetail";
import NoDataFound from "../../components/Common/NoDataFound";
import CollectionDetails from "../../components/Common/CollectionDetails";
import BuyPacks from "../../components/Collection/BuyPacks";

const Collection = () => {
    const navigate = useNavigate();
    const params = useParams();
    const COLLECTION_ID = params?.collectionId;

    const activeSchema = useSelector((state) => state?.common?.collectionPageTierSchema)

    const [collectionList, setCollectionList] = useState([]);
    const [cardsList, setCardsList] = useState({});

    const [activityLoading, setActivityLoading] = useState("");
    const [collectionActivityData, setCollectionActivityData] = useState({});
    const [collectionInfo, setCollectionInfo] = useState({});
    const [collectionLoading, setCollectionLoading] = useState("");
    const [nftLoading, setNFTLoading] = useState("");
    const [collectionListLoading, setCollectionListLoading] = useState("");
    const [transactionLoading, setTransactionLoading] = useState("");
    const [collectionTransactionInfo, setCollectionTransactionInfo] = useState([]);
    const [schemaList, setSchemaList] = useState([])

    const handleGoToMarket = () => {
        navigate(Paths.MarketplacePage + "?collection=" + COLLECTION_ID);
    };

    const {
        getNFTCardsByCollectionId,
        getCollectionList,
        getCollectionFullInfoById,
        getCollectionTransactionsById,
    } = COLLECTION_HELPER();

    const getCollectionActivityList = () => {
        setActivityLoading(API_FETCH_START);
        let page = 1;
        let limit = 10;

        const queryParams = `?page=${page}&limit=${limit}`;
        const apiPath = changeURLLastString(
            GET_COLLECTION_ACTIVITY_API,
            COLLECTION_ID
        );
        http
            .get(apiPath + queryParams)
            .then((res) => {
                if (res.status === 200) {
                    const apiData = res?.data?.data;
                    setCollectionActivityData(apiData);
                }
            })
            .catch((err) => {
                console.error("err activity loading", err);
            })
            .finally(() => {
                setActivityLoading(API_FETCH_COMPLETE);
            });
    };

    const getCardsList = async () => {
        setCardsList({})
        setNFTLoading(API_FETCH_START);
        const res = await getNFTCardsByCollectionId(COLLECTION_ID, 1, 10, activeSchema);
        if (res?.status === 200) {
            const apiData = res?.data?.data
            setCardsList(apiData)
            if (!Array.isArray(schemaList) || schemaList.length === 0) {
                setSchemaList(apiData?.schemaList)
            }
        }
        setNFTLoading(API_FETCH_COMPLETE);
    }

    useEffect(() => {
        if (!!COLLECTION_ID) {
            async function handleCollectionList() {
                setCollectionListLoading(API_FETCH_START);
                const res = await getCollectionList();
                if (res?.status === 200) {
                    setCollectionList(res?.data?.data?.collectionsList);
                }
                setCollectionListLoading(API_FETCH_COMPLETE);
            }

            async function getCollectionFullInfo() {
                setCollectionLoading(API_FETCH_START);
                const res = await getCollectionFullInfoById(COLLECTION_ID);
                if (res?.status === 200) {
                    setCollectionInfo(res?.data?.data?.collectionData);
                }
                setCollectionLoading(API_FETCH_COMPLETE);
            }

            async function getCollectionTransactionInfo() {
                setTransactionLoading(API_FETCH_START);
                const res = await getCollectionTransactionsById(COLLECTION_ID);

                if (res?.status === 200) {
                    setCollectionTransactionInfo(res?.data?.data?.soldCards);
                }
                setTransactionLoading(API_FETCH_COMPLETE);
            }

            getCollectionFullInfo();
            handleCollectionList();
            getCollectionActivityList();
            getCollectionTransactionInfo();
            getCardsList()
        }
    }, [COLLECTION_ID]);

    useEffect(() => {
        if (!!activeSchema) {
            getCardsList()
        }
    }, [activeSchema])

    return (
        <Fragment>
            <OwensHeader />
            <section className="lightMode pi-15 LightGrayBG mb-50">
                <CollectionBasicInfo
                    isLoading={collectionLoading}
                    collectionInfo={collectionInfo}
                />

                <WhatDoIGet idType={"collectionId"} id={collectionInfo?.id} />
                {/* <TierOffer /> */}
                <BuyPacks collectionId={collectionInfo?.id} />

                <LightmodeAccordian heading="NFTs">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pos-rel">
                            <AllTierMenu
                                schemaValue={activeSchema}
                                schemaList={schemaList}
                                page={COLLECTION} />
                        </div>
                    </div>

                    <div className="NFT-card-sec mt-30">
                        <CollectionNFTCard data={cardsList?.lists} loading={nftLoading} />
                    </div>
                    {(nftLoading === API_FETCH_COMPLETE) && cardsList?.lists?.length === 0 &&
                        <NoDataFound image={true} text="We don’t have any NFTs in this tier." />
                    }
                    {nftLoading === API_FETCH_COMPLETE && cardsList?.count > 0 && (
                        <PrimaryButton
                            primaryClassName="mt-25"
                            heading={`View all ${cardsList?.count} NFTs`}
                            icon={<MoreTemplateIcon className="mr-2" />}
                            onClick={handleGoToMarket}
                        />
                    )}
                </LightmodeAccordian>

                <LightmodeAccordian heading="Transactions">
                    {collectionTransactionInfo.length > 0 &&
                        <CollectionDetails className="grayBorder" data={collectionInfo} />
                    }
                    <TransactionCardDetail data={collectionTransactionInfo} loading={transactionLoading} />

                    {collectionTransactionInfo.length > 0 &&
                        <SecondaryButton heading="View All Transactions" className="mt-25"
                            onClick={() => navigate(changeURLLastString(Paths.TransactionHistoryPage, collectionInfo?.id), { state: { name: collectionInfo?.collectionDisplayName } })} />
                    }
                </LightmodeAccordian>

                <LightmodeAccordian heading="Activity">
                    <ActivityCard data={collectionActivityData?.activity} loading={activityLoading} />
                    {collectionActivityData?.count > 0 && (
                        <SecondaryButton heading="View all Activities" className="mt-25"
                            onClick={() => navigate(changeURLLastString(Paths.ActivityHistoryPage, collectionInfo?.id))} />
                    )}
                </LightmodeAccordian>

                <LightmodeAccordian heading="More Collections">
                    <div className="moreCollectionGrid">
                        <CollectionTemplate
                            collectionList={collectionList}
                            isLoading={collectionListLoading}
                        />
                    </div>

                    {(collectionListLoading === API_FETCH_COMPLETE) && collectionList.length === 0 &&
                        <NoDataFound text="We don't have any collections in this tier." />
                    }
                    {collectionList.length > 0 &&
                        <SecondaryButton heading="Explore More" className="mt-25"
                            onClick={() => navigate(Paths.MoreCollectionsPage)} />
                    }
                </LightmodeAccordian>
            </section>
        </Fragment>
    );
};

export default Collection;

import React, { Fragment, useEffect, useState } from 'react'
import SubHeader from '../../components/Common/SubHeader'
import "../../components/Wallet/WalletStyles.css"
import PrimaryButton from "../../components/Login/PrimaryButton"
import {
    AddBalanceIcon,
    DrawerLine,
    KYCInfoIcon,
    PaymentHistoryBtn,
    ReceiptIcon,
    SecurityUserIcon,
    SmallAmountIcon,
    WithdrawIcon
} from '../../helpers/svgConst'
import USER_HELPER from '../../helpers/apis/userHelper'
import { useLocation, useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import { changeURLLastString, GET_ALL_QUERY_PARAMS, PRICE_FORMAT } from "../../constants/commonFunc";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Divider } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import KYCHelper from "../../helpers/apis/KYCHelper";
import LockedBalance from '../../components/Wallet/LockedBalance'


const Wallet = () => {

    const dispatch = useDispatch()
    const { loginUserInfo, getUserDetails, isUserLogin } = USER_HELPER()
    const { GetKYCFullDetails } = KYCHelper()
    const [openState, setOpenState] = useState(false)
    const navigate = useNavigate()
    const kycInfo = useSelector((state) => state?.kyc?.kycInfo)
    const isOpenWithdrawFlow = kycInfo?.aadhaarVerified && kycInfo?.panVerified && kycInfo?.faceMatched


    const handleGoToDepositPage = () => {
        navigate(Paths.DepositMoneyPage, { replace: true })
    }

    const handleGoToPaymentHistoryPage = () => {
        navigate(Paths.PaymentHistoryPage, { replace: true })
    }


    const location = useLocation()
    const params = GET_ALL_QUERY_PARAMS(location?.search)


    useEffect(() => {
        getUserDetails()
    }, [])


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenState({ ...openState, [anchor]: open });
    };


    const TakePhotoCapture = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel"
            >
                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>

                <div className='pi-15'>
                    <p className='font16pxSemiBold lightmode-text-blue mb-2 mt-2'><KYCInfoIcon className="mr-2" /> KYC Pending
                    </p>
                    <Divider className='mb-25' />


                    <p className='font12px mb-15'>Dear User,</p>
                    <p className='font12px mb-25'>Please complete your KYC with the 3-simple steps to withdraw money to
                        your bank account.</p>
                    <p className='font12px'>Requirements:</p>
                    <ul className='pl-25'>
                        <li className="font12px lightmode-text-blue mt-2">Personal Details</li>
                        <li className="font12px lightmode-text-blue mt-1">Government-issued ID Proof</li>
                        <li className="font12px lightmode-text-blue mt-1 mb-30">Facial Recognition</li>
                    </ul>
                    <PrimaryButton
                        icon={<SecurityUserIcon className="mr-2" />}
                        heading="Get started with KYC"
                        primaryClassName="font12px mb-40 pt-15 pb-15"
                        onClick={() => navigate(changeURLLastString(Paths.KYCVerificationPage, 1))} />
                </div>
            </Box>
        )
    }

    const handleWithdrawOpen = () => {
        // if kyc aadhar , pan and face matched then open withdraw form otherwise update kyc
        if (!isOpenWithdrawFlow) {
            // change not when you wanted to follow kyc flow
            navigate(Paths.WithdrawPage)
        } else {
            setOpenState({ ...openState, ["bottom"]: true });
        }

    }


    useEffect(() => {
        if (isUserLogin) {
            GetKYCFullDetails()
        }
    }, [isUserLogin])

    return (
        <Fragment>
            <section className='lightMode whiteBG'>
                <div className='fullHeightBlock pi-15'>
                    <SubHeader />
                    <div className='mt-20 mb-40'>
                        <h1 className='font24pxBold lightmode-text-blue'>My Wallet</h1>
                    </div>


                    {params?.type === "success" &&
                        <p className="alert font12px success-alert lightmode-text-green p-15 d-flex align-items-center">
                            <SmallAmountIcon className="mr-2" /> $ {params.amount} successfully added to your wallet!
                        </p>
                    }
                    <div className=' pos-rel'>
                        <div className='gradient-card p-25 text-white wallet-card-grid pos-rel-up-stack'>
                            <div className='wallet-card-sub-grid'>
                                <div>
                                    <p className='font12pxBold mb-1'>Current Balance</p>
                                    <p className='font24pxBold'>$
                                        <span className='font32pxBold ml-2'>
                                            {loginUserInfo?.balance ? ` ` + PRICE_FORMAT(loginUserInfo?.balance, true) : 0}
                                        </span>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className='walletBGBlur' />
                    </div>
                    <div className='wallet-bottom-btnbar mt-40 pos-rel-up-stack'>
                        <button onClick={handleWithdrawOpen}
                            className='btn font12px widthdrawBtn p-3 lightmode-text-marigold d-flex justify-content-center align-items-center'>
                            <WithdrawIcon className="mr-2" /> Withdraw
                        </button>
                        <PrimaryButton primaryClassName='font12px p-3 justify-content-center'
                            heading="Add Deposit"
                            onClick={handleGoToDepositPage}
                            icon={<AddBalanceIcon className="mr-2" />} />
                    </div>
                    {loginUserInfo?.lockedBalance > 0 &&
                        <LockedBalance data={loginUserInfo} />}
                    <SwipeableDrawer
                        anchor={"bottom"}
                        open={openState["bottom"]}
                        onClose={toggleDrawer("bottom", false)}
                        onOpen={toggleDrawer("bottom", true)}
                        className="swipableDrawerBlock"
                    >
                        {TakePhotoCapture("bottom")}
                    </SwipeableDrawer>
                    <div className='paymentHistoryBlock mt-auto'>
                        <button className='btn paymentSliderBtn' onClick={handleGoToPaymentHistoryPage}>
                            <PaymentHistoryBtn />
                            <p className='font12px historyAbsBtn lightmode-text-purple mt-15 text-center '><ReceiptIcon
                                className="mr-2" /> Payment History</p>
                        </button>
                        {/* <p className='font10pxBold lightmode-text-blue text-center pt-35 '>10th May, 2022</p> */}
                        {/* <div className='bdr-btm' /> */}
                    </div>
                </div>

            </section>
        </Fragment>
    )
}

export default Wallet

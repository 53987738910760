import Box from "@mui/material/Box";
import React, { Fragment } from "react";
import { zeroPad } from "react-countdown";

export const IMAGE_CDN = "https://ipfs.imgix.net/"
export const RAZORPAY_IMAGE = "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ty69wyedajobzylshplu"
export const USD_CONVERSION_RATE = 1
export const API_FETCH_COMPLETE = "API_FETCH_COMPLETE"
export const API_FETCH_START = "API_FETCH_START"


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
};

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// this is used for replacement of all wax image path by using cdn
export const CheckWAXImage = (img) => {
    if (!img) return "";

    if (img.includes("https://wax.atomichub.io")) {
        return img.replace("https://wax.atomichub.io", IMAGE_CDN);
    } else if (img.includes("https://wax.api.atomicassets.io/ipfs/")) {
        return img.replace("https://wax.api.atomicassets.io/ipfs", IMAGE_CDN);
    } else if (img.includes("https://gateway.pinata.cloud/ipfs/")) {
        return img.replace("https://gateway.pinata.cloud/ipfs", IMAGE_CDN);

    } else if (img.includes("https://ipfs.io/ipfs")) {
        return img.replace("https://ipfs.io/ipfs", IMAGE_CDN);
    } else if (img.includes("https://ipfs.atomichub.io/ipfs")) {
        return img.replace("https://ipfs.atomichub.io/ipfs", IMAGE_CDN);
    } else if (img.includes("https://gateway.pinata.cloud/ipfs")) {
        return img.replace("https://gateway.pinata.cloud/ipfs", IMAGE_CDN);
    }
    return img;
};

// change the url last string
export const changeURLLastString = (url, replaceKey, page = "") => {
    if (!url) return
    const splitUrl = url.split("/")
    const lastWordPos = url.split("/").length - 1
    const lastStr = splitUrl[lastWordPos]
    return url.replace(lastStr, replaceKey)
}

export const changeURLLastStringMarketplace = (url, replaceKey, page = "") => {
    if (!url) return
    const splitUrl = url.split("/")
    const lastWordPos = url.split("/").length - 1
    const lastStr = splitUrl[lastWordPos]
    return url.replace(/.$/, replaceKey)
}

export const CHANGE_URL_STRING = (url, replaceKey, replaceValue) => {
    if (!url) return
    return url.replace(replaceKey, replaceValue)
}

// get cookie by passing any key name
export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// to set cookie use this function and pass key and value and expiry days
export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    //setting a domain name in cookie if project is running on dev or production
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/; secure;";
}

export const deleteCookie = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

// use this date format function to get all the info related to any date
export const DATE_FORMAT = (date) => {
    //day,month and year calculation
    const dateObj = new Date(date);
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();

    //hour,minute and second calculation with am or pm
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let minutesTwoDigit = minutes.toString().length === 1 ? "0" + minutes : minutes
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? 0 + minutes : minutes;

    const monthShort = monthShortNames[month - 1]
    const monthLong = monthNames[month - 1]
    return { day, month, year, hours, minutes, minutesTwoDigit, ampm, monthShort, monthLong }
}

// api error response message formatting function
export const GET_ERROR_TOAST_MSG = (error) => {
    return (!!error?.response?.data?.message ? error?.response?.data?.message : "") + (!!error?.response?.data?.error ? error?.response?.data?.error : "")
}

// get all the queryparams
export const GET_ALL_QUERY_PARAMS = (searchQuery) => {
    const urlSearchParams = new URLSearchParams(searchQuery);
    return Object.fromEntries(urlSearchParams.entries());
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

export const PRICE_FORMAT = (price, exact = false) => {
    if (!price) return "0"
    // if (kFormat) {
    //     return kFormatter(parseFloat((Math.ceil((parseFloat(price) * USD_CONVERSION_RATE).toFixed(2)))))
    // }
    if (!exact) {
        return numberWithCommas(parseFloat((Math.ceil((parseFloat(price) * USD_CONVERSION_RATE).toFixed(2)))))
    }
    if (exact) {
        return numberWithCommas(parseFloat(((parseFloat(price) * USD_CONVERSION_RATE).toFixed(2))))
    }

}

// function is user where we try to send any price in any api
export const SEND_PRICE_FORMAT = (price) => {
    if (!price) return "0"
    return parseFloat(price / USD_CONVERSION_RATE).toFixed(4)

}

// all the sorting options variable
export const SORT_OPTIONS_LIST = [
    { name: "Price: high to low", value: "22" },
    { name: "Price: low to high", value: "21" },
    { name: "Most Favourited", value: "52" },
    { name: "Newest Listing", value: "12" },
    { name: "Oldest Listing", value: "11" },
];

export const GET_SORT_LABEL = (sortValue) => {
    if (!sortValue) return "Sort By : "
    const filterObj = SORT_OPTIONS_LIST.find((key) => {
        return key?.value === sortValue
    })
    if (!!filterObj) {
        return filterObj?.name
    }
    return "Sort By : No Value";
}

export const GET_SORT_BY_PARAMS = (activeSortValue) => {
    return (activeSortValue && activeSortValue.toString().length === 2) ? ("&sortByType=" +
        activeSortValue[0] +
        "&sortBy=" +
        activeSortValue[1]) : ""
}

// used for auction based nft to show remaining time in hours,minutes and second
export const TIME_RENDERER = ({ total, days, hours, minutes, seconds, milliseconds, completed, ...rest }) => {
    if (completed) {
        return "";
    } else {
        return (
            <Fragment>
                {!!days ? days + "d " : ""}
                {!!hours ? zeroPad(hours) + "h " : ""}
                {!!minutes ? zeroPad(minutes) + "m " : ""}
                {!!seconds ? zeroPad(seconds) + "s " : ""}
            </Fragment>
        );
    }
};


export const GET_TIER_SCHEMA_TEXT = (schemaList, schemaId) => {
    const filteredInfo = schemaList && schemaList.find((key) => {
        return key?.id === schemaId
    })

    if (!!filteredInfo && Object.keys(filteredInfo).length > 0) {
        return filteredInfo?.schemaDisplayName
    }

    return "All Tiers"
}

export const HANDLE_ONLY_NUMBER_INPUT = (evt) => {
    if (evt.key === 'e' || evt.key === 'E' || evt.key === "+" || evt.key === "-" || evt.key === ".") {
        evt.preventDefault()
    }
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const convertToSlug = (Text) => {
    return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
};

export const getOneMonthBackDate = (date) => {
    console.log('one-date', date)
    if (date.getMonth() == 0) {
        date.setMonth(11)
        date.setFullYear(date.getFullYear() - 1)
    }
    const formattedDate = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
    console.log('onemonthback', formattedDate)
    return formattedDate
}
export const getFormattedDate = (date) => {
    console.log('now-date', date)
    const formattedDate = `${date.getFullYear()}-${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
    console.log('format date', formattedDate)
    return formattedDate
}

export const CONTRACT_ADDRESS = '0x6A7c078efc0db621A306F58afe6eF359bC64B980'
export const ADMIN_ETH_ADDRESS = '0xB6e3974F93B9e5790Ae0a3f4Aea00c83bdD26bfc'

export const DepositABI = [
    {
        inputs: [
            { internalType: "string", name: "_name", type: "string" },
            { internalType: "string", name: "_symbol", type: "string" },
            { internalType: "string", name: "_basTokenUri", type: "string" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "approved",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            { indexed: false, internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                components: [
                    { internalType: "uint256", name: "points", type: "uint256" },
                    { internalType: "string", name: "externalLink", type: "string" },
                ],
                indexed: false,
                internalType: "struct AdidasGold.MutableSchema",
                name: "updatedData",
                type: "tuple",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "updatedAt",
                type: "uint256",
            },
        ],
        name: "MetaDataUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "templateId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "TemplateCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "from", type: "address" },
            { indexed: true, internalType: "address", name: "to", type: "address" },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [],
        name: "BASE_TEMPLATE_ID",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MAX_NFT_PER_TEMPLATE",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    { internalType: "string", name: "name", type: "string" },
                    { internalType: "string", name: "image", type: "string" },
                    { internalType: "string", name: "description", type: "string" },
                    { internalType: "uint256", name: "points", type: "uint256" },
                    { internalType: "string", name: "externalLink", type: "string" },
                ],
                internalType: "struct AdidasGold.Schema",
                name: "_newTemplate",
                type: "tuple",
            },
        ],
        name: "addNewTemplate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "baseURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_to", type: "address" },
            { internalType: "uint256", name: "_templateId", type: "uint256" },
            { internalType: "uint256", name: "_totalNFTs", type: "uint256" },
        ],
        name: "batchMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "currentTemplateId",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "getApproved",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
        name: "getMetaData",
        outputs: [
            {
                components: [
                    { internalType: "string", name: "name", type: "string" },
                    { internalType: "string", name: "image", type: "string" },
                    { internalType: "string", name: "description", type: "string" },
                    { internalType: "uint256", name: "points", type: "uint256" },
                    { internalType: "string", name: "externalLink", type: "string" },
                ],
                internalType: "struct AdidasGold.Schema",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "_templateId", type: "uint256" }],
        name: "getTemplateData",
        outputs: [
            {
                components: [
                    { internalType: "string", name: "name", type: "string" },
                    { internalType: "string", name: "image", type: "string" },
                    { internalType: "string", name: "description", type: "string" },
                    { internalType: "uint256", name: "points", type: "uint256" },
                    { internalType: "string", name: "externalLink", type: "string" },
                ],
                internalType: "struct AdidasGold.Schema",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "operator", type: "address" },
        ],
        name: "isApprovedForAll",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "metaData",
        outputs: [
            { internalType: "uint256", name: "points", type: "uint256" },
            { internalType: "string", name: "externalLink", type: "string" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_to", type: "address" },
            { internalType: "uint256", name: "_templateId", type: "uint256" },
        ],
        name: "mint",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "ownerOf",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "bytes", name: "_data", type: "bytes" },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "string", name: "_uri", type: "string" }],
        name: "setBaseTokenURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
        name: "supportsInterface",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "templateCount",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "templateData",
        outputs: [
            { internalType: "string", name: "name", type: "string" },
            { internalType: "string", name: "image", type: "string" },
            { internalType: "string", name: "description", type: "string" },
            { internalType: "uint256", name: "points", type: "uint256" },
            { internalType: "string", name: "externalLink", type: "string" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
        name: "tokenURI",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "totalMintedNFTs",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_tokenId", type: "uint256" },
            {
                components: [
                    { internalType: "uint256", name: "points", type: "uint256" },
                    { internalType: "string", name: "externalLink", type: "string" },
                ],
                internalType: "struct AdidasGold.MutableSchema",
                name: "_updateData",
                type: "tuple",
            },
        ],
        name: "updateMetadata",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];

import React, { Fragment } from 'react'
import OwensHeader from '../../components/Common/OwensHeader'
import TiersAndBenefits from '../../components/TiersAndBenefits/TiersAndBenefits';


const Tier = () => {
    return (
        <Fragment>
            <OwensHeader />
            <section className='lightMode pt-35 mb-50'>
                <div className='pi-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue'>Tiers and benefits</h1>
                    <h4 className='font10px lightmode-text-light-gray mt-1'>Workout Collection | Tiger Shroff</h4>
                    <TiersAndBenefits title="Gold Tier" />
                    <TiersAndBenefits title="Silver Tier" />
                    <TiersAndBenefits title="Bronze Tier" />
                </div>
            </section>
        </Fragment>
    )
}

export default Tier
import React, { Fragment, useEffect, useState } from "react";
import "../../components/TokenDetail/TokenDetailStyles.css";
import OwensHeader from "../../components/Common/OwensHeader";
import PrimaryButton from "../../components/Login/PrimaryButton";
import LightmodeAccordian from "../../components/Common/LightmodeAccordian";
import SecondaryButton from "../../components/Common/SecondaryButton";
import CollectionNFTCard from "../../components/Common/CollectionNFTCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from '@mui/material/Skeleton';
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    changeURLLastString,
    GET_ALL_QUERY_PARAMS,
} from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import { DirectBuyNFTRedirectAction, ListYourNFTAction, SetDaysForRent, } from "../../redux/actions/cardDetailActions";
import http from "../../helpers/Interceptors";
import { ALL_OFFERS_API, GET_CARD_FULL_INFO_API, GET_UNLISTED_OFFER_LISTING_API } from "../../helpers/ApisPath";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import USER_HELPER from "../../helpers/apis/userHelper";
import AcceptOfferBlock from "../../components/TokenDetail/AcceptOfferBlock";
import CardBasicInfoDetailPage from "../../components/TokenDetail/CardBasicInfoDetailPage";
import CardOfferListDetailPage from "../../components/TokenDetail/CardOffersListDetailPage";
import WhatDoIGet from "../../components/Common/WhatDoIGet";
import TierOffer from "../../components/Common/TierOffer";
import AllTierMenu from "../../components/Common/AllTierMenu";
import { NFT_CARD, OPERATOR_ID } from "../../constants/envConstants";
import NoDataFound from "../../components/Common/NoDataFound";
import { DrawerLine } from "../../helpers/svgConst";
import { Box, Divider, SwipeableDrawer } from "@mui/material";
import DepositBlockchain from "../../components/TokenDetail/DepositBlockchain";

const TokenDetail = () => {
    const navigate = useNavigate();
    const params = useParams();

    const activeSchema = useSelector((state) => state?.common?.tokenDetailPageTierSchema)
    const isLogin = useSelector((state) => state?.user?.isLogin);

    const location = useLocation();
    const queryParam = GET_ALL_QUERY_PARAMS(location?.search);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [offerLoading, setOfferLoading] = useState("")
    const [cardInfo, setCardInfo] = useState({});
    const [cardsList, setCardsList] = useState({});
    const [offerList, setOfferList] = useState([]);
    const [cardLoading, setCardLoading] = useState("");
    const [schemaList, setSchemaList] = useState([])
    const [daysForRent, setDaysForRent] = useState(0)

    const { getNFTCardsByCollectionId } = COLLECTION_HELPER();
    const { isUserLogin, loginUserInfo, RedirectToLoginScreen } = USER_HELPER();

    const CARD_ID = params.cardId;

    const [state, setState] = useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };




    const handleGoToCollectionPage = () => {
        navigate(Paths.MarketplacePage + "?collection=" + cardInfo?.collectionId);
    };


    const handleBuyCard = () => {
        dispatch(DirectBuyNFTRedirectAction(cardInfo));
        dispatch(SetDaysForRent());

        if (!isLogin) {
            RedirectToLoginScreen(changeURLLastString(Paths.DirectBuyNFTPage, cardInfo?.id))
        } else {
            navigate(changeURLLastString(Paths.DirectBuyNFTPage, cardInfo?.id));
        }
    };

    const handleRentCard = () => {
        dispatch(DirectBuyNFTRedirectAction(cardInfo));
        dispatch(SetDaysForRent(parseInt(daysForRent)));
        if (!isLogin) {
            RedirectToLoginScreen(changeURLLastString(Paths.DirectBuyNFTPage, cardInfo?.id))
        } else {
            navigate(changeURLLastString(Paths.DirectBuyNFTPage, cardInfo?.id));
        }
    };


    const handleListCard = () => {
        if (!isLogin) {
            RedirectToLoginScreen()
        } else {
            dispatch(ListYourNFTAction(cardInfo));
            navigate(changeURLLastString(Paths.ListingPage, cardInfo?.id));
        }
    };

    const getCardFullInfo = () => {
        setLoading(API_FETCH_START);
        const apiPath = changeURLLastString(GET_CARD_FULL_INFO_API, CARD_ID);
        http
            .get(apiPath)
            .then((res) => {
                if (res.status === 200) {
                    const apiData = res?.data?.data
                    setCardInfo(apiData);
                }
            })
            .catch((err) => {
                setCardInfo({});
            })
            .finally(() => {
                setLoading(API_FETCH_COMPLETE);
            });
    };

    const getCardAllOfferList = () => {
        setOfferLoading(API_FETCH_START)
        let apiPath = "";
        if (cardInfo?.marketplaceId) {
            // when NFT is listed in marketplace then fetch all offers listing
            apiPath = changeURLLastString(
                ALL_OFFERS_API,
                cardInfo?.marketplaceId
            ) + "?page=1&limit=1000";
        }
        if (!cardInfo?.marketplaceId && !cardInfo?.listingType) {
            // when NFT is not listed in the marketplace then fetch all offers
            apiPath = changeURLLastString(
                GET_UNLISTED_OFFER_LISTING_API,
                cardInfo?.id
            ) + "/" + OPERATOR_ID + "?page=1&limit=1000";
        }

        http
            .get(apiPath)
            .then((res) => {
                setOfferList(res?.data?.data);
            })
            .catch((err) => {
                console.error("Err", err);
                setOfferList([])
            })
            .finally(() => {
                setOfferLoading(API_FETCH_COMPLETE)
            });
    };

    const getCardsList = async () => {
        setCardsList({})
        setCardLoading(API_FETCH_START);
        const res = await getNFTCardsByCollectionId(cardInfo?.collectionId, 1, 10, activeSchema);
        if (res?.status === 200) {
            const apiData = res?.data?.data
            setCardsList(apiData)
            if (!Array.isArray(schemaList) || schemaList.length === 0) {
                setSchemaList(apiData?.schemaList)
            }
        }
        setCardLoading(API_FETCH_COMPLETE);
    }

    useEffect(() => {
        if (!cardInfo?.collectionId) {
            setOfferLoading(API_FETCH_COMPLETE)
            return;
        }
        if (Object.keys(cardInfo).length > 0) {
            getCardAllOfferList();
        }
    }, [cardInfo]);

    useEffect(() => {
        if (!cardInfo?.collectionId) return;
        if (Object.keys(cardInfo).length > 0) {
            setCardsList({})
            getCardsList()
        }
    }, [cardInfo, activeSchema])

    useEffect(() => {
        getCardFullInfo();
    }, [CARD_ID]);


    const DayOfRent = (anchor) => {
        return (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                className="pos-rel mb-25"
            >

                <div className='text-center'>
                    <DrawerLine className="drawerLine" />
                </div>
                <div className='p-15'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Days for rent</h1>
                    <Divider />
                    <label htmlFor='link' className='font10px mt-25'>Enter the days for renting the NFT</label>
                    <div className="d-flex gap-10 align-items-center">
                        <div className='pos-rel listInput w-100'>
                            <input type="number"
                                pattern="\d*"
                                onChange={(e) => setDaysForRent(e.target.value)}
                                className='inputBlock w-100 lightmode-text-primary font16pxSemiBold' />
                        </div>
                        <p className="font14px ml-2">Days</p>
                    </div>
                    <PrimaryButton
                        heading="Submit"
                        primaryClassName="mt-15"
                        onClick={handleRentCard}
                    />

                </div>
            </Box>
        )
    }



    return (
        <Fragment>
            <OwensHeader />
            <section className="lightMode pt-20 mb-50  pi-15">
                <CardBasicInfoDetailPage cardInfo={cardInfo} loading={loading} />

                {(loading !== API_FETCH_COMPLETE) &&
                    <Skeleton height={50} />
                }

                {(loading === API_FETCH_COMPLETE) &&
                    <Fragment>

                        {((cardInfo?.listingType === 6) && (cardInfo?.vaccount !== loginUserInfo?.vaccount) && cardInfo?.marketplaceId) && (
                            <Fragment>
                                <PrimaryButton
                                    heading="Rent Now"
                                    primaryClassName="mt-15"
                                    onClick={toggleDrawer("bottom", true)}
                                />
                                <SwipeableDrawer
                                    anchor={"bottom"}
                                    open={state["bottom"]}
                                    onClose={toggleDrawer("bottom", false)}
                                    onOpen={toggleDrawer("bottom", true)}
                                    className="swipableDrawerBlock"
                                >
                                    {DayOfRent("bottom")}
                                </SwipeableDrawer>
                            </Fragment>

                        )}


                        {((cardInfo?.listingType === 4) && (cardInfo?.vaccount !== loginUserInfo?.vaccount) && cardInfo?.marketplaceId) && (
                            <Fragment>
                                <PrimaryButton
                                    heading="Buy Now"
                                    primaryClassName="mt-15"
                                    onClick={handleBuyCard}
                                />
                            </Fragment>

                        )}

                        {(!cardInfo?.listingType || cardInfo?.listingType === 2) &&
                            <Fragment>
                                <AcceptOfferBlock data={cardInfo} offerList={offerList} />
                            </Fragment>

                        }

                        {!!loginUserInfo?.vaccount && cardInfo?.vaccount === loginUserInfo?.vaccount && cardInfo?.locked !== true &&
                            !cardInfo?.marketplaceId && (
                                <PrimaryButton
                                    heading="List for Sale"
                                    primaryClassName="mt-15"
                                    onClick={handleListCard}
                                />
                            )}


                    </Fragment>
                }

                <WhatDoIGet id={cardInfo?.id} idType="templateId" />
                <TierOffer />

                {(!cardInfo?.listingType || cardInfo?.listingType === 2) &&
                    <LightmodeAccordian heading="Offers">
                        <div className="templateTableGrid w-100 text-center">
                            <CardOfferListDetailPage
                                cardInfo={cardInfo}
                                offerList={cardInfo?.marketplaceId ? offerList?.allOffers : offerList?.allSpecialOffers}
                                isLoading={offerLoading} />

                        </div>
                    </LightmodeAccordian>
                }
                <LightmodeAccordian heading="NFTs">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pos-rel">
                            <AllTierMenu
                                schemaValue={activeSchema}
                                schemaList={schemaList}
                                page={NFT_CARD} />
                        </div>
                    </div>
                    <div className="NFT-card-sec mt-30">
                        <CollectionNFTCard data={cardsList?.lists} loading={cardLoading} />
                    </div>


                    {(cardLoading === API_FETCH_COMPLETE) && (!cardsList || !cardsList?.lists || cardsList?.lists?.length === 0) &&
                        <NoDataFound image={true} text="We don't have any NFTs in this tier." />
                    }
                    <SecondaryButton
                        heading="More from this collection"
                        className="mt-20"
                        onClick={handleGoToCollectionPage}
                    />
                </LightmodeAccordian>
            </section>
        </Fragment>
    );
};

export default TokenDetail;

import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmptyWallet,
  GoBackIcon,
  WhiteOwensLogo,
  HamburgerIcon,
  SearchIcon,
  OwensLogo,
  OwensText,
  HeaderCrossIcon,
  BigArrowUpIcon,
  LogoutIcon,
} from "../../helpers/svgConst";
import "../Collection/CollectionStyles.css";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import Paths from "../../helpers/Paths";
import USER_HELPER from "../../helpers/apis/userHelper";
import { changeURLLastString, PRICE_FORMAT } from "../../constants/commonFunc";
import SearchDrawer from "./SearchDrawer";
import CommonSearch from "./CommonSearch";

const OwensHeader = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;

  const { loginUserInfo, isUserLogin, makeUserLogout } = USER_HELPER();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenOnChain, setIsOpenOnChain] = useState(false);
  const handleToggleDropdownonChain = () => {
    setIsOpenOnChain(!isOpenOnChain);
  };

  const [state, setState] = useState({
    left: false,
  });



  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleGoToWalletPage = () => {
    if (isUserLogin) {
      navigate(Paths.WalletPage)
    } else {
      navigate(changeURLLastString(Paths.LoginPage, "mobile"))
    }
  }

  const handleProfileInsideClick = (tabCount) => {
    navigate(Paths.ProfilePage + "?tab=" + tabCount)
    toggleDrawer("right", false)
  }
  const handleProfileInsideClick2 = (tabCount) => {
    navigate(Paths.ConnectWallet + "?tab=" + tabCount)
    toggleDrawer("right", false)
  }
  const handleProfileInsideClick3 = (tabCount) => {
    navigate(Paths.OnChainWithdraw + "?tab=" + tabCount)
    toggleDrawer("right", false)
  }


  const HeaderList = (anchor) => {
    return (
      <Box
        role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        className="pos-rel"
      >
        <div className="lightMode pi-15 soraFont">
          <div className="d-flex justify-content-between align-items-center mb-10 pt-30">
            <div className="d-flex align-items-center gap-10 logoBlock">
              <OwensLogo />
              {/* <OwensText /> */}
            </div>
            <button className="btn p-0" onClick={toggleDrawer("right", false)}>
              <HeaderCrossIcon />
            </button>
          </div>

          <div className="mt-10 pos-rel">
            <CommonSearch goBackFunc={toggleDrawer(anchor, false)} />
          </div>

          <div className="navbar-links mt-30">
            <button
              className={`btn font24pxSemiBold  ${url === Paths.HomePage ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
              onClick={url === Paths.HomePage ? toggleDrawer("right", false) : () => navigate(Paths.HomePage)}
            >
              Home
            </button>
            <button
              className={`btn font24pxSemiBold mt-25 ${url === Paths.MoreCollectionsPage ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
              onClick={url === Paths.MoreCollectionsPage ? toggleDrawer("right", false) : () => navigate(Paths.MoreCollectionsPage)}
            >
              Explore all NFTs
            </button>

            <button
              className={`btn font24pxSemiBold d-flex justify-content-between align-items-center w-100 py-0 mt-25 lightmode-text-blue`}
              onClick={handleToggleDropdownonChain}
            >
              On Chain
              <BigArrowUpIcon
                stroke="#262476"
                className={`ml-auto ${isOpenOnChain ? "rotateOut" : "rotateIn"}`}
              />
            </button>
            <div
              className={`collapsible-header-drawer mb-0 ${isOpenOnChain ? "show" : "d-none"
                }`}
            >
              <button
                className={`btn font16px opacity-8 mt-15 pt-0 pb-1 ${url === (Paths.ConnectWallet) ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                onClick={() => navigate(Paths.ConnectWallet)}
              >
                On Chain Wallet
              </button>
              <button
                className={`btn font16px opacity-8 mt-15 pt-0 pb-1 ${url === (Paths.OnChainWithdraw) ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                onClick={() => navigate(Paths.OnChainWithdraw)}
              >
                Withdraw
              </button>
              <button
                className={`btn font16px opacity-8 mt-15 pt-0 pb-1 ${url === (Paths.UpdateOnChainWalletAddress) ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                onClick={() => navigate(Paths.UpdateOnChainWalletAddress)}
              >
                Update Address
              </button>
            </div>

            {isUserLogin && (
              <>
                <button
                  className={`btn font24pxSemiBold d-flex justify-content-between align-items-center w-100 py-0 mt-25 ${url === Paths.ProfilePage ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                  onClick={handleToggleDropdown}
                >
                  Profile
                  <BigArrowUpIcon
                    stroke="#262476"
                    className={`ml-auto ${isOpen ? "rotateOut" : "rotateIn"}`}
                  />
                </button>
                <div
                  className={`collapsible-header-drawer mb-0 ${isOpen ? "show" : "d-none"
                    }`}
                >
                  <button
                    className={`btn font16px opacity-8 mt-15 pt-0 pb-1 ${url === (Paths.ProfilePage + "?tab=0") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(0)}
                  >
                    Owned
                  </button>
                  <button
                    className={`btn font16px opacity-8 mt-20 py-1 ${url === (Paths.ProfilePage + "?tab=1") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(1)}
                  >
                    Listed for sale
                  </button>
                  <button
                    className={`btn font16px opacity-8 mt-20 py-1 ${url === (Paths.ProfilePage + "?tab=2") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(2)}
                  >
                    Wishlist
                  </button>
                  <button
                    className={`btn font16px opacity-8 mt-20 py-1 ${url === (Paths.ProfilePage + "?tab=3") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(3)}
                  >
                    My bids
                  </button>
                  <button
                    className={`btn font16px opacity-8 mt-20 py-1 ${url === (Paths.ProfilePage + "?tab=4") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(4)}
                  >
                    Packs
                  </button>
                  <button
                    className={`btn font16px opacity-8 mt-20 py-1 ${url === (Paths.ProfilePage + "?tab=4") ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => handleProfileInsideClick(5)}
                  >
                    Onchain Card
                  </button>
                  {/* <button
                    className={`btn font16px opacity-8 py-1 ${url === Paths.ProfilePage + "?tab=4" ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                    onClick={() => navigate(Paths.ProfilePage)}
                  >
                    Sold NFTs
                  </button> */}
                </div>
              </>
            )}

            <div className="d-flex justify-content-between align-items-center w-100 mt-25">
              <button
                className="btn font24pxSemiBold lightmode-text-blue"
                onClick={handleGoToWalletPage}
              >
                My Wallet
              </button>
              {isUserLogin &&
                <button
                  className="btn emptyWalletBlock"
                  onClick={() => navigate(Paths.WalletPage)}
                >
                  <div className="d-flex align-items-center">
                    <EmptyWallet />
                    <p className="ml-10 font12px hotpink-clr">
                      ${" "}
                      {loginUserInfo?.balance ? ` ` + PRICE_FORMAT(loginUserInfo?.balance, true) : 0}

                    </p>
                  </div>
                </button>
              }
            </div>

            <Divider />


            {isUserLogin && (
              <button
                className="btn font12px lightmode-text-marigold "
                onClick={() => makeUserLogout()}
              >
                <LogoutIcon className="mr-2" />
                Logout
              </button>
            )}
            <Divider className="mt-25 mb-25" />


            <div className="d-flex justify-content-around align-items-center w-100">
              <button className="btn font12px lightmode-text-blue opacity-3">
                Help
              </button>
              <div className="middleBorder">
                <button className="btn font12px lightmode-text-blue opacity-3 ">
                  Terms of Services
                </button>
              </div>
              <button className="btn font12px lightmode-text-blue opacity-3">
                FAQs
              </button>
            </div>
          </div>
        </div>
      </Box >
    );
  };

  return (
    <Fragment>
      <section className="headerPos">
        {url === "/" ? (
          // This is for Home Page
          <Fragment>
            <div className="homeHeader pt-30 p-15">
              <button className="btn p-0 pb-25 logoBlock" onClick={() => navigate("/")}>
                <WhiteOwensLogo />
                {/* <OwensText className="ml-2" color="white" /> */}
              </button>
              <div className="pos-rel headerRightBlockBtn">
                <SearchDrawer />
                <div>
                  <button
                    onClick={toggleDrawer("right", true)}
                    className="btn lightmode-text-marigold p-0"
                  >
                    <HamburgerIcon color="#004C75" />
                  </button>
                  <SwipeableDrawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                  >
                    {HeaderList("right")}
                  </SwipeableDrawer>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          // This is for Profile Page
          url === Paths.ProfilePage ? (
            <Fragment>
              <div >
                <button onClick={toggleDrawer("right", true)}
                  className="btn lightmode-text-marigold p-0"><HamburgerIcon /></button>
                <SwipeableDrawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={toggleDrawer("right", false)}
                  onOpen={toggleDrawer("right", true)}
                >
                  {HeaderList("right")}
                </SwipeableDrawer>


              </div>
            </Fragment>
          ) : (
            // This is for rest of the website
            <Fragment >
              <div className={`OwensHeaderSection pt-30`}>
                <button className="btn p-1" onClick={() => navigate(-1)}>
                  <GoBackIcon />
                </button>
                <button className="btn p-0 pb-25 smallLogoBlock" onClick={() => navigate("/")}>
                  <OwensLogo />
                </button>
                <div className="headerRightBlockBtn">
                  <SearchDrawer />
                  <div>
                    <button
                      onClick={toggleDrawer("right", true)}
                      className="btn lightmode-text-marigold p-0"
                    >
                      <HamburgerIcon />
                    </button>
                    <SwipeableDrawer
                      anchor={"right"}
                      open={state["right"]}
                      onClose={toggleDrawer("right", false)}
                      onOpen={toggleDrawer("right", true)}
                    >
                      {HeaderList("right")}
                    </SwipeableDrawer>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        )
        }
      </section >
    </Fragment >
  );
};

export default OwensHeader;

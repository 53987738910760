import {
    DIRECT_BUY_NFT,
    GET_ALL_WISHLISTED_COLLECTION,
    GET_ALL_WISHLISTED_ITEMS,
    GET_RENT_NFT_DAYS,
    LIST_YOUR_NFT,
    MAKE_AN_OFFER,
    SELECT_OFFER, SET_USER_OFFER_FOR_CARD
} from "../action-types/cardDetail.types";


export const DirectBuyNFTRedirectAction = (payload) => {
    return {
        type: DIRECT_BUY_NFT,
        payload
    }
}

export const MakeAnOfferRedirectAction = (payload) => {
    return {
        type: MAKE_AN_OFFER,
        payload
    }
}

export const ListYourNFTAction = (payload) => {
    return {
        type: LIST_YOUR_NFT,
        payload
    }
}

export const SelectOfferAction = (payload) => {
    return {
        type: SELECT_OFFER,
        payload
    }
}
export const SetAllWishlistedItems = (payload) => {
    return {
        type: GET_ALL_WISHLISTED_ITEMS,
        payload
    }
}
export const SetAllWishlistedCollection = (payload) => {
    return {
        type: GET_ALL_WISHLISTED_COLLECTION,
        payload
    }
}

export const SetUserOfferInfoForCard = (userOfferObj) => {
    return {
        type: SET_USER_OFFER_FOR_CARD,
        userOfferObj
    }
}

export const SetDaysForRent = (payload) => {
    return {
        type: GET_RENT_NFT_DAYS,
        payload
    }
}

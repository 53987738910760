import React, { Fragment } from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ArrowRightIcon } from "../../helpers/svgConst";


const WalletAccordian = (props) => {
    const { heading, children, className, primaryIcon, secondaryClassName } = props;
    return (
        <Fragment>
            <Accordion
                className={`main-white-card mt-10 pt-10 pb-10 ${className}`}
            >
                <AccordionSummary
                    className={`pr-2 pl-0 ${secondaryClassName ? secondaryClassName : "Mui-expanded"}`}
                    expandIcon={<ArrowRightIcon stroke="#AD3430" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {primaryIcon}
                    <h2 className="font16px mt-1 ml-2 lightmode-text-blue">{heading}</h2>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </Fragment>
    )
}

export default WalletAccordian
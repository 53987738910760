import React, { Fragment, useEffect, useState } from "react";
import GlanceImage from "../../components/Common/GlanceImage";
import LikeUnlikeButton from "../../components/Common/LikeUnlikeButton";
import { API_FETCH_COMPLETE, API_FETCH_START, CheckWAXImage, GET_SORT_BY_PARAMS } from "../../constants/commonFunc";

import OwensHeader from "../../components/Common/OwensHeader";
import NFTSkeleton from "../../components/Common/Skeleton/NFTSkeleton";
import { VerifiedIcon } from "../../helpers/svgConst";
import { GET_MY_VAULT_CARDS_API, WITHDRAW_CARD_API } from "../../helpers/ApisPath";
import http from "../../helpers/Interceptors";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Login/PrimaryButton";
import SecondaryButton from "../../components/Common/SecondaryButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { GetProfileDataActionChange } from "../../redux/actions/profileAction";
import { MY_OWN_LISTING_DATA, } from "../../redux/action-types/profile.types";
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/Common/NoDataFound";


const OnChainWithdraw = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const PAGE_LIMIT = 10
  const [loading, setLoading] = useState("")
  const [withDrawLoading, setWithDrawLoading] = useState(false)
  const [cardIds, setCardIds] = useState([])
  const [collectionName, setCollectionName] = useState("")
  const [productList, setProductList] = useState([])
  const [totalProduct, setTotalProduct] = useState(0)


  const handleWaxBlockChainSelected = (checkedStatus, data) => {
    const localIdsArr = [...cardIds]

    const findEthBlockChain = cardIds.filter((key) => {
      return key.blockchain === 2
    })

    // when eth blockchain is found and wax selected then remove eth blockchain
    if (findEthBlockChain.length > 0) {
      const emptyArr = []
      emptyArr.push(data)
      setCardIds(emptyArr)
    } else {
      console.log("no eth blockchain data found")
      // when wax is found and wax is selected
      if (checkedStatus) {
        localIdsArr.push(data)
        setCardIds(localIdsArr)
      } else {
        const removedcardIdsArr = localIdsArr.filter((key) => {
          return key?.cardId !== data?.cardId
        })
        setCardIds(removedcardIdsArr)
      }
    }
  }

  const handleSaveCardIds = (e, data) => {
    const checkedStatus = e.target.checked
    handleWaxBlockChainSelected(checkedStatus, data)
  }

  const getPageNo = (reloadSearch = false) => {
    if (reloadSearch) return 1
    if (productList.length === 0) return 1
    return productList
      ? Math.ceil(productList.length / PAGE_LIMIT) + 1
      : 1;
  }

  const getOwnedCards = (reloadSearch = false) => {
    setLoading(API_FETCH_START)

    const apiPage = getPageNo(reloadSearch)

    const apiPathFinal = GET_MY_VAULT_CARDS_API + "?page=" + apiPage + "&limit=" + PAGE_LIMIT + "&collectionId=" + collectionName

    http.get(apiPathFinal)
      .then((res) => {
        let mergedFinalProducts = []
        const apiData = res?.data?.data;
        dispatch(GetProfileDataActionChange(MY_OWN_LISTING_DATA, apiData))
        const apiProductList = apiData.lists || [];
        const apiProductCount = apiData.count || 0;

        setTotalProduct(apiProductCount)

        //when data is selected with different filter
        if (reloadSearch) {
          setProductList(apiProductList)
        } else {
          // when data is selected with same filter but page is change
          // only params are same
          mergedFinalProducts = [...productList, ...apiProductList];
          setProductList(mergedFinalProducts);
        }


      }).catch((err) => console.error("failed to fetch marketplace data", err))
      .finally(() => {
        setLoading(API_FETCH_COMPLETE)
      })


  }

  const fetchMoreItems = () => {
    if (productList.length < totalProduct) {
      getOwnedCards(false);
    }
  }

  const handleWithDraw = () => {

    if (cardIds.length === 0) {
      toast.error("No Cards selected",)
      return
    }
    setWithDrawLoading(true)
    const formData = {
      cardIds: cardIds.map((asset) => {
        return asset.cardId
      }), // array of cardids      
    }

    console.log("formData", formData)
    http.post(WITHDRAW_CARD_API, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message)
          console.log('card withdraw successfully', res)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }

      }).catch((err) => {
        console.error("failed to withdraw card", err)
        toast.error(err?.response?.data?.message)
        if (err?.response?.data?.message === "Error: wallet address not found") {
        }
      })
      .finally(() => {
        setWithDrawLoading(false)
        console.log('final statemnt', withDrawLoading)
      })

  }

  useEffect(() => {
    getOwnedCards()
  }, [])

  return (
    <Fragment>
      <OwensHeader />
      <div className="lightMode pi-15 LightGrayBG">
        <div className="d-flex w-100 justify-content-end align-items-center pt-4 mb-4">
          <p className="font14px lightmode-text-blue">Update Blockchain</p>
          <SecondaryButton heading="Submit" className="btn" onClick={() => navigate(Paths.UpdateOnChainWalletAddress)} />


        </div>
        <h1 className="font18pxBold lightmode-text-blue mb-3">Withdraw on-chain cards</h1>

        <InfiniteScroll
          next={fetchMoreItems}
          hasMore={productList.length < totalProduct}
          dataLength={productList.length}
          loader={(loading !== API_FETCH_COMPLETE) &&
            <NFTSkeleton imageHeight={80} className={"mb-4"} />
          }>
          <div className="NFT-card-sec">
            {!!productList &&
              productList.length > 0 &&
              productList.map((key, index) => {
                return (
                  <Fragment key={index}>
                    <div className='pos-rel'>
                      <label className="form-check-label checkboxContainer">
                        <input
                          className="cardCheckbox"
                          checked={cardIds.some(
                            function (el) {
                              return (el.cardId === key.cardId)
                            }
                          )}
                          type="checkbox"
                          onChange={(e) => handleSaveCardIds(e, key)}
                        />
                        <span className="checkmark" />
                        <div className='NFTSecImageBlock'>
                          <GlanceImage src={CheckWAXImage(key?.image)} alt="thumb" />
                        </div>
                      </label>
                      <div className='NFTSecDetails'>
                        <p className='nftNameOverflow max-w-120 mb-2 font12px '>
                          {key?.name}
                        </p>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex align-items-center'>
                            <VerifiedIcon width={15} height={15} className="mr-1" />
                            <p className='font12px lightmode-text-blue'>#{key?.mintNumber}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )
              })}
          </div>
        </InfiniteScroll>




        {/* <div className="NFT-card-sec">
          <OnchainWithdrawCard />
          <OnchainWithdrawCard />
        </div> */}
        {productList.length > 0 &&
          <PrimaryButton heading="Submit" isLoading={withDrawLoading} primaryClassName="mt-20" onClick={handleWithDraw} />
        }
        {(loading === API_FETCH_COMPLETE) && productList?.length === 0 &&
          <NoDataFound text="You don't have any cards" />
        }
      </div>
    </Fragment>
  );
};

export default OnChainWithdraw;

// File is used for Header search with suggestion Listing
import React, { Fragment, useEffect, useState } from 'react'
import GlanceImage from "./GlanceImage"
import { Skeleton } from '@mui/material';
import { API_FETCH_COMPLETE, API_FETCH_START, changeURLLastString } from "../../constants/commonFunc";
import { CrossIcon, GoBackIcon, SearchIcon } from '../../helpers/svgConst';
import Paths from "../../helpers/Paths";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../helpers/Hooks/useDebounce"
import { HEADER_SEARCH_CARD_API } from "../../helpers/ApisPath";
import http from "../../helpers/Interceptors"


const CommonSearch = ({ isHeader, goBackFunc }) => {


    const navigate = useNavigate()
    const [loading, setLoading] = useState(API_FETCH_COMPLETE)
    const [searchKeyWord, setSearchKeyWord] = useState("")
    const [searchResult, setSearchResult] = useState({})
    const [state, setState] = useState({
        left: false,
    });

    const debouncedValue = useDebounce(searchKeyWord, 500)

    const handleGoToDetailNFT = (cardId) => {
        navigate(changeURLLastString(Paths.TokenDetailPage, cardId))
    }
    const handleGoToCollection = (collectionId) => {
        navigate(changeURLLastString(Paths.CollectionPage, collectionId))
    }


    const getSearchByKeyWord = (inputText) => {
        if (inputText.toString().length <= 3) {
            setSearchResult([])
            return
        }
        setLoading(API_FETCH_START)
        const queryParams = `?searchKeyword=${inputText}&page=1&limit=5`
        const apiPath = HEADER_SEARCH_CARD_API + queryParams
        http.get(apiPath).then((res) => {
            if (res?.status === 200) {
                const apiRes = res?.data?.data
                setSearchResult(apiRes)
                console.log("search keyword result", apiRes)
            }

        }).catch((err) => {
            console.error("error", err)
        }).finally(() => {
            setLoading(API_FETCH_COMPLETE)
        })
    }

    useEffect(() => {
        getSearchByKeyWord(searchKeyWord)
    }, [debouncedValue])


    return (
        <Fragment>
            {isHeader &&
                <div className='d-flex justify-content-between align-items-center'>
                    <button className="btn px-0" onClick={goBackFunc}>
                        <GoBackIcon />
                    </button>
                    <div className='d-flex align-items-center'>
                        <button className='btn font12px lightmode-text-purple'
                            onClick={() => setSearchKeyWord("")}>
                            Clear All <CrossIcon className="ml-1" />
                        </button>
                    </div>
                </div>
            }
            <div className="mt-25 pos-rel main-white-card p-2">
                <input
                    value={searchKeyWord}
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                    type="text"
                    placeholder="I am looking for..."
                    className="enterNumber border-0 p-15"
                />
                <button className="btn p-0 navSearchIcon">
                    <SearchIcon />
                </button>

                {loading === API_FETCH_START &&
                    <Fragment>
                        <Skeleton height={40} className="mt-0" />
                        <Skeleton height={40} className="mt-0" />
                        <Skeleton height={40} className="mt-0" />
                        <Skeleton height={40} className="mt-0" />
                        <Skeleton height={40} className="mt-0" />
                    </Fragment>
                }

                {loading === API_FETCH_COMPLETE && Array.isArray(searchResult?.collections) && searchResult?.collections.length > 0 && searchResult?.collections.map((collection) => {
                    return (
                        <Fragment>
                            <div className='suggestion-block'
                                onClick={() => handleGoToCollection(collection?.collectionid)}>
                                <div className='buyerThumb'>
                                    <GlanceImage src={collection?.collectionImage}
                                        alt={collection?.collectionDisplayName} />
                                </div>
                                <p className='font14px'>
                                    {collection?.collectionDisplayName}
                                </p>
                            </div>
                        </Fragment>
                    )
                })}
                {loading === API_FETCH_COMPLETE && Array.isArray(searchResult?.cards) && searchResult?.cards.length > 0 && searchResult?.cards.map((card) => {
                    return (
                        <Fragment>
                            <div className='suggestion-block'
                                onClick={() => handleGoToDetailNFT(card?.cardId)}>
                                <div className='buyerThumb'>
                                    <GlanceImage src={card?.image} alt={card?.name} />
                                </div>
                                <p className='font14px'>
                                    {card?.name}
                                </p>
                            </div>
                        </Fragment>
                    )
                })}


                {(loading === API_FETCH_COMPLETE && Array.isArray(searchResult?.collections) &&
                    searchResult?.collections.length === 0 && Array.isArray(searchResult?.cards)
                    && searchResult?.cards.length === 0) &&
                    <h6 className="mt-4 mb-2 font16px w-60 mx-auto text-center">No Data found for this search keyword</h6>
                }


            </div>
        </Fragment>
    )
}

export default CommonSearch

import React, { Fragment, useEffect, useState } from "react";
import { UploadFileIcon } from "../../helpers/svgConst";
import userThumb from "../../images/LoginImage/AsianMan.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PrimaryButton from "./PrimaryButton";
import http from "../../helpers/Interceptors"
import { UPDATE_PROFILE_API, UPLOAD_PROFILE_IMAGE_API } from "../../helpers/ApisPath";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { InitiateLogin } from "../../redux/actions/userActions";
import Dropzone from 'react-dropzone';
import GlanceImage from "../Common/GlanceImage";

const PersonalizeAccount = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState({ status: false, message: "" })
    const [loading, setLoading] = useState(false)
    const [imagePath, setImagePath] = useState("")
    const [imageUploadLoading, setImageUploadLoading] = useState(false)
    const [imageErrorMessage, setImageErrorMessage] = useState("")

    const [file, setFile] = useState([])

    const handleFileUpload = () => {
        if (!file && file.length < 1) return
        setImageUploadLoading(true)
        const formData = new FormData()

        if (!!file) {
            formData.append(
                "photo",
                file[0],
                file[0].name
            )
        }

        http.post(UPLOAD_PROFILE_IMAGE_API, formData).then((res) => {
            console.log("res", res)
            setImagePath(res.data?.data?.ipfs)
        }).catch((err) => {
            console.error("Err", err)
        }).finally(() => {
            setImageUploadLoading(false)
        })


    }

    const initialSchema = {
        name: "",
        bio: "",
    };

    const formValidationSchema = Yup.object({
        name: Yup.string().required("Name is required").matches(/^[aA-zZ\s]+$/, "Invalid characters in the name. Only A-Z allowed."),
    });

    const handleSubmit = (values) => {
        setErrorMessage({ status: false, message: "" })
        setLoading(true)
        const formData = {
            "name": values.name,
            "phone": "",
            "twitter": "",
            "facebook": "",
            "telegram": "",
            "instagram": "",
            "bio": values.bio,
            "profilePic": imagePath
        }

        http.post(UPDATE_PROFILE_API, formData)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(InitiateLogin(res?.data?.data))
                    navigate("/login/all-done")
                }
            }).catch((err) => {
                setErrorMessage({ status: true, message: "Failed to update profile info" })
            }).finally(() => {
                setLoading(false)
            })
    };

    const handleSkip = () => {
        navigate("/login/all-done")
    }

    useEffect(() => {
        if (file.length === 0) return
        if (!!file[0].size) {
            let finalSize = ((file[0].size / 1024) / 1024)
            if (finalSize > 2) {
                setImageErrorMessage("Couldn’t upload! File size should not be more than 2 MB.")
                return
            }
        }
        handleFileUpload()
    }, [file])

    const handleImageChange = (fileInfo) => {
        setImageErrorMessage("")
        setFile(fileInfo.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFile(fileInfo)
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => file.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);


    return (
        <Fragment>
            <section className="mt-30">
                <div className="user-profile-block">
                    <div className="userThumbBlock">
                        <GlanceImage
                            src={(file && file.length > 0) ? file[0].preview : userThumb}
                            alt="thumb" />
                    </div>

                    <Dropzone onDrop={acceptedFiles => handleImageChange(acceptedFiles)}
                        maxFiles={1}
                        multiple={false}
                        accept={{ 'image/*': [] }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <button
                                        className="btn uploadBtn lightmode-text-secondary d-flex align-items-center"
                                        disabled={imageUploadLoading}>
                                        <UploadFileIcon className="mr-2" />
                                        {file.length > 0 ? "Change Profile Photo" : "Upload profile photo"}
                                    </button>

                                    {imageUploadLoading &&
                                        <p className="gold-clr mt-2 font10px">
                                            Please wait uploading...
                                        </p>
                                    }

                                    {imageErrorMessage &&
                                        <p className="form-error w-80">
                                            {imageErrorMessage}
                                        </p>
                                    }

                                </div>
                            </section>
                        )}
                    </Dropzone>

                </div>
                <div className="user-detail-block mt-40">
                    <Formik initialValues={initialSchema} validationSchema={formValidationSchema}
                        onSubmit={handleSubmit}>
                        <Form className="pos-rel">
                            <label htmlFor="name" className="font10px text-black mt-30">
                                Name
                            </label>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="First and Last name"
                                className="font16px user-input"
                            />
                            <p className="form-error">
                                <ErrorMessage name="name" />
                            </p>
                            <label htmlFor="bio" className="font10px text-black mt-30">
                                Bio
                            </label>
                            <Field
                                as="textarea"
                                type="text"
                                id="bio"
                                name="bio"
                                placeholder="Enter your bio (optional)"
                                className="font16px  user-input textarea"
                            />
                            <p className="textareaCount">(0/250)</p>
                            <p className="form-error">
                                <ErrorMessage name="bio" />
                            </p>

                            <div className={`primaryBtn-block mt-50 mb-30`}>
                                {/* <PrimarySkipButton onClick={handleSkip} /> */}
                                <PrimaryButton
                                    type="submit"
                                    heading="Continue"
                                />
                            </div>
                        </Form>
                    </Formik>

                </div>
            </section>
        </Fragment>
    );
};

export default PersonalizeAccount;

import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import axios from "axios"
import * as Yup from "yup"
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LoginSuccessAction } from "../../redux/actions/userActions"
import USER_HELPER from "../../helpers/apis/userHelper"
import { API_BASE_URL, OPERATOR_ID } from "../../constants/envConstants";
import { setCookie, validateEmail } from "../../constants/commonFunc";

const AdminLogin = () => {
    const { makeUserLogout } = USER_HELPER()
    const selector = useSelector((state) => state)
    console.log("selector", selector);

    const [email, setEmail] = useState()
    const [otpDiv, setOtpDiv] = useState(false)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const loginFoucs = localStorage.getItem("loginFoucs")
    const user = JSON.parse(localStorage.getItem("user"))
    console.log('user', user)

    // useEffect(() => {
    //     if (!user?.isAdmin) {
    //         localStorage.removeItem('user')
    //         // dispatch(makeUserLogout())
    //         // after logout remove auth token from Header
    //         delete axios.defaults.headers.common["Authorization"]
    //         navigate('/')
    //     }
    //     else {
    //         navigate('/analytics')
    //     }
    // }, [])
    // useEffect(() => {
    //     if (selector?.user?.isLogin) {
    //         navigate('/analytics')
    //     }
    //     else {
    //         navigate('/')
    //     }
    // }, [selector?.user?.isLogin])

    const formValidationSchema = Yup.object({
        email: Yup.string().email("Please enter a valid email").required("Email  is required"),
    })

    const otpValidationSchema = Yup.object({
        otp: Yup.string().required("OTP is required"),
    })

    const handleFormSubmit = async (values) => {
        // setLoading(true);
        const formData = {
            "loginType": 1,
            "data": values.email
        }

        setEmail(values.email)

        await axios
            .post(API_BASE_URL + "user/onboard/" + OPERATOR_ID, formData)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Verification code sent successfully")
                    setOtpDiv(true)
                }
            })
            .catch(function (error) {
                toast.error(error)
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    const otpFormSubmit = (values) => {
        // setLoading(true);
        const formData = {
            data: email,
            verificationCode: parseInt(values.otp),
            verificationCodeType: 1,
        }

        axios
            .post(API_BASE_URL + "user/verify-otp/" + OPERATOR_ID, formData)
            .then((response) => {
                if (response.status === 200) {
                    // going to hide otp div
                    setOtpDiv(false)
                    const apiData = response?.data?.data
                    if (response && response.data && !!apiData) {
                        //setting in localstorage all user info
                        window.localStorage.setItem("user", JSON.stringify(apiData))
                        toast.success("Login Success")
                        dispatch(LoginSuccessAction(apiData))
                        setCookie("Authorization", `Bearer ${apiData?.token}`, 30)
                        // if (apiData.isAdmin) {
                        navigate("/analytics")
                        // }
                        // else {
                        //     navigate("/")
                        // }
                    }

                } else {
                    toast.error("Verification code does not match")
                }
            })
            .catch((res) => {
                toast.error("Incorrect verification code")
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    return (
        <div className="margin-fix_login">
            <div>
                <h2 className="textAdminHeading mb-5"> Admin Login</h2>
            </div>
            <Formik
                enableReinitialize="true"
                initialValues={{
                    email: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
            >
                <Form className="">
                    <div className="row">
                        <div className="col-12 col-md-6 mr-0 p-0">
                            <Field
                                type="email"
                                name="email"
                                className={`${loginFoucs} adminInput lightShadow`}
                                style={{ borderRadius: "10px 0px 0px 10px", padding: "17px" }}
                                placeholder="Enter your email here..."
                            />
                        </div>
                        {!otpDiv && (
                            <div className="col-12 col-md-6 ml-0 p-0">
                                <button
                                    className=" btn firstSubmitBtn btn-lg"
                                    style={{ borderRadius: "0px 10px 10px 0px" }}
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                        <p className="text-danger text-left mt-1">
                            <ErrorMessage name="email" />
                        </p>
                    </div>
                </Form>
            </Formik>
            {/* )} */}

            {otpDiv && (
                <Formik
                    initialValues={{
                        otp: "",
                    }}
                    validationSchema={otpValidationSchema}
                    onSubmit={(values) => otpFormSubmit(values)}
                >
                    <Form>
                        <div className="row mt-3">
                            <div className="col-12 col-md-6 mr-0 p-0">
                                <Field
                                    type="text"
                                    name="otp"
                                    style={{ borderRadius: "10px 0px 0px 10px", padding: "29px" }}
                                    className="form-control adminInput lightShadow"
                                    placeholder="Enter your Verification Code here..."
                                />
                            </div>
                            <div className="col-12 col-md-6 ml-0 p-0 mt_0_fix">
                                <button className=" btn firstSubmitBtn btn-lg" type="submit" style={{ borderRadius: "0px 10px 10px 0px" }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    )
}

export default AdminLogin

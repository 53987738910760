import React, { Fragment } from 'react'
import FinalOTPVerification from '../../components/Withdraw/FinalOTPVerification';
import { GoBackIcon } from "../../helpers/svgConst";
import USER_HELPER from "../../helpers/apis/userHelper";


const FinalVerifyOTP = () => {

    const { handleGoBack } = USER_HELPER()


    return (
        <Fragment>
            <section className='lightMode'>
                <div className="pi-15 pt-70">
                    <button className='btn px-0 mb-40' onClick={handleGoBack}>
                        <GoBackIcon />
                    </button>
                    <FinalOTPVerification />
                </div>
            </section>
        </Fragment>
    )
}

export default FinalVerifyOTP
import React from "react";
import { Fragment } from "react";
import "../assets/css/adminv2.css";
import "../assets/css/adminCollection2.css";
import "../assets/css/blockchaincard.css";
import "../assets/css/createCollection.css";
import "../assets/css/createschema.css";
import "../assets/css/CreateTemplate.css";
import AdminSidebar from "../components/AdminSidebar";
import AdminRouter from "../components/AdminRouter/AdminRouter";
import { Outlet, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Login/PrimaryButton";

const AdminV2 = () => {

  const navigate = useNavigate()

  return (
    <Fragment>
      <div className="admin--layout">
        <div className="adminSidebar--block">
          <AdminSidebar />
        </div>
        <div className="admin--content pos-rel">
          {/* <AdminRouter /> */}
          {/* <p>Hello, Welcome to Admin panel</p>
          <p>To navigate to Admin panel, please login</p>
          <PrimaryButton heading="Login" onClick={navigate('/login')} /> */}
          <Outlet />
        </div>
      </div>
    </Fragment>
  );
};
export default AdminV2;

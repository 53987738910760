import {KYC_STEP_CHANGE} from "../action-types/kyc.types";

const initialState = {
    kycInfo: {}

}

const kycReducer = (state = initialState, action) => {
    switch (action.type) {
        case KYC_STEP_CHANGE:
            return {
                ...state,
                kycInfo: action.payload
            }


        default:
            return state
    }
}

export default kycReducer

import React, { Fragment } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const dummyData = [{ name: "network 1", value: 2 }, { name: "network 3", value: 4 }];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];



const PieRechart = ({ finalData, heading }) => {
    console.log('pi chairt ', finalData)

    const data = finalData.length > 0 && finalData.map((key) => {
        return {
            name: key?.collectionDisplayName,
            Total_Sold_Price: parseInt(key?.totalSoldPrice)
        }
    })
    console.log('data', data)



    return (
        <Fragment>
            <ResponsiveContainer width="100%" height={500}>
                <PieChart >
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius={125}
                        fill="#8884d8"
                        dataKey="Total_Sold_Price"
                        label={({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            value,
                            index
                        }) => {
                            console.log("handling label?");
                            const RADIAN = Math.PI / 180;
                            // eslint-disable-next-line
                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                            // eslint-disable-next-line
                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                            // eslint-disable-next-line
                            const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill="#8884d8"
                                    textAnchor={x > cx ? "start" : "end"}
                                    dominantBaseline="central"
                                >
                                    {data[index].name} ({value})
                                </text>
                            );
                        }}
                    >
                        {
                            data.length > 0 && data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>

                </PieChart>
            </ResponsiveContainer>

        </Fragment>
    )
}

export default PieRechart
import {
    MY_BIDS_NFT_DATA,
    MY_BIDS_NFT_SORT_CHANGE,
    MY_LISTED_NFT_DATA,
    MY_LISTED_NFT_SORT_CHANGE,
    MY_OWN_LISTING_DATA,
    MY_OWN_LISTING_SORT_CHANGE,
    MY_WISHLIST_NFT_DATA,
    MY_PACKS_DATA
} from "../action-types/profile.types";

const initialState = {
    myListData: {},
    myWishListData: {},
    myBidsData: {},
    myOwnedData: {},
    myPacksData: {},
    activeOwnListSort: null,
    activeMyBidsSort: null,
    activeMyListedNFTSort: null
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case MY_LISTED_NFT_DATA:
            return {
                ...state,
                myListData: action.payload
            }
        case MY_BIDS_NFT_DATA:
            return {
                ...state,
                myBidsData: action.payload
            }
        case MY_WISHLIST_NFT_DATA:
            return {
                ...state,
                myWishListData: action.payload
            }
        case MY_OWN_LISTING_DATA:
            return {
                ...state,
                myOwnedData: action.payload
            }
        case MY_PACKS_DATA:
            return {
                ...state,
                myPacksData: action.payload
            }
        case MY_OWN_LISTING_SORT_CHANGE: {
            return {
                ...state,
                activeOwnListSort: action.sortValue
            }
        }
        case MY_LISTED_NFT_SORT_CHANGE: {
            return {
                ...state,
                activeMyListedNFTSort: action.sortValue
            }
        }
        case MY_BIDS_NFT_SORT_CHANGE: {
            return {
                ...state,
                activeMyBidsSort: action.sortValue
            }
        }
        default:
            return state
    }
}

export default profileReducer

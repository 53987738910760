export const GetProfileDataActionChange = (type, payload) => {
    return {
        type: type,
        payload
    }
}

export const ChangeProfileSortAction = (type, sortValue) => {
    return {
        type: type,
        sortValue
    }
}

import React, { Fragment, useState } from "react"
import SubHeader from "../../components/Common/SubHeader";
import { PAYPAL_KEY } from "../../constants/envConstants";
import USER_HELPER from "../../helpers/apis/userHelper";
import {
    GET_ALL_QUERY_PARAMS,
    GET_ERROR_TOAST_MSG,
    HANDLE_ONLY_NUMBER_INPUT,
    PRICE_FORMAT,
    RAZORPAY_IMAGE,
    SEND_PRICE_FORMAT
} from "../../constants/commonFunc";
import http from "../../helpers/Interceptors"
import { CREATE_ADD_TOKEN_ORDER_API, HANDLE_CAPTURE_PAYMENT_API, INITATE_PAYMENT_API } from "../../helpers/ApisPath";
import Paths from "../../helpers/Paths";
import { useLocation, useNavigate } from "react-router-dom";
import { AmountIcon } from "../../helpers/svgConst";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PaypalTransactionFlow = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const queryParam = GET_ALL_QUERY_PARAMS(location?.search);
    const AMOUNT_QUERY = !!queryParam?.amount ? queryParam?.amount.replace(/,/g, "") : 0

    const [inputAmount, setInputAmount] = useState(AMOUNT_QUERY || 0)
    const { loginUserInfo } = USER_HELPER()
    const [loading, setLoading] = useState(false)
    const onSuccessRedirectURL = useSelector((state) => state?.common?.paymentOnSuccessRedirectURL)
    const [errorMessage, setErrorMessage] = useState({ state: false, message: "" })

    const rupeesSignPos = (55 + (inputAmount ? inputAmount.toString().length * 4 : 1));

    const hardCodedProcessFees = (amount) => {
        return parseFloat((amount * 0.035) + 0.49)
    }

    const FINAL_AMOUNT = inputAmount

    const FINAL_AMOUNT_WITH_PROCESSING_FEES = parseFloat(inputAmount) + hardCodedProcessFees(inputAmount)


    let owens_payment_id = ""

    const handleCreateOrder = (amount) => {
        setErrorMessage({ state: false, message: "" })
        if (amount > 0) {
            const formData = {
                amount: parseFloat(amount)
            }
            return http.post(CREATE_ADD_TOKEN_ORDER_API, formData)
                .then((res) => {
                    return res
                }).catch((err) => {
                    return err
                })
        } else {
            setErrorMessage({ state: true, message: "Please enter amount" })
            return
        }
    }

    const handleInitiatePayment = (orderId) => {
        // initiate payment api
        if (!orderId) return
        const paymentFor = 1
        const apiPath = INITATE_PAYMENT_API + orderId + "/" + paymentFor + "/PAL"
        return http.post(apiPath).then((res) => {
            return res
        }).catch((err) => {
            return err
        })
    }

    const handleCapturePayment = (paypalInitiatedId, formData, amount) => {
        // capture amount api call and if success then redirect
        const apiPath = HANDLE_CAPTURE_PAYMENT_API + paypalInitiatedId
        setLoading(true)
        http.post(apiPath, formData).then((res) => {
            if (!!onSuccessRedirectURL) {
                toast.success("Amount added to wallet successfully")
                navigate(onSuccessRedirectURL, { replace: true })
            } else {
                navigate(Paths.WalletPage + "?amount=" + PRICE_FORMAT(amount) + "&type=success", { replace: true })
            }
        }).catch((err) => {
            console.error("err capture", err)
            const message = GET_ERROR_TOAST_MSG(err)
            toast.error("Failed to capture payment " + message)
            setErrorMessage({ state: true, message: "Failed to capture payment" + message })
        }).finally(() => {
            setLoading(false)
        })
    }


    const handleChangeInputAmount = (e) => {
        setInputAmount(e.target.value)
    }



    return (
        <Fragment>
            <section className='lightMode'>
                <div className="pi-15">
                    <SubHeader />
                    <div className='mt-35'>
                        <p className='font24pxBold lightmode-text-blue'>My Wallet</p>
                    </div>
                    <div className="white-card grayBorder mt-40 p-20 mb-4">
                        <div className="d-flex align-items-center">
                            <AmountIcon className="mr-2" />
                            <p className="font16px lightmode-text-blue">Enter the amount</p>
                        </div>
                        <p className="font12px mt-15">Enter the amount you want to add from the selected card to the
                            wallet.</p>
                        <div className="w-100 mt-40 mb-40 d-flex align-items-center">
                            <span className="font40px lightmode-text-secondary priceIconDeposit"
                                style={{ right: `${rupeesSignPos}%` }}>$</span>
                            <input type="number"
                                onChange={handleChangeInputAmount}
                                pattern="\d*"
                                className=" transparentAmountInput font40px lightmode-text-secondary text-center"
                                value={inputAmount}
                            />
                        </div>

                    </div>

                    <PayPalScriptProvider

                        options={{
                            "client-id": PAYPAL_KEY,
                            components: "buttons",
                            "disable-funding": "card"
                        }}
                    >
                        <PayPalButtons
                            forceReRender={[FINAL_AMOUNT]}
                            style={{ layout: "vertical" }}
                            createOrder={(data, actions) => {
                                return actions.order
                                    .create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    currency_code: "USD",
                                                    value: parseFloat(FINAL_AMOUNT_WITH_PROCESSING_FEES).toFixed(2).toString(),
                                                },
                                            },
                                        ],
                                    })
                                    .then(async (orderId) => {

                                        // initate payemnt flow and create order here
                                        const orderRes = await handleCreateOrder(FINAL_AMOUNT);
                                        const orderIdFromRes = orderRes.data?.data?.id;
                                        if (!orderIdFromRes) {
                                            setErrorMessage({ state: true, message: "Create Order Id not found" })
                                            setLoading(false)
                                            return
                                        }

                                        const initiatePaymentRes = await handleInitiatePayment(orderIdFromRes)
                                        owens_payment_id = initiatePaymentRes?.data?.data?.paymentResult?.id
                                        if (!owens_payment_id) {
                                            setErrorMessage({ state: true, message: "Payment ID not found" })
                                            setLoading(false)
                                            return
                                        }
                                        return orderId;
                                    });
                            }}
                            onApprove={function (data, actions) {

                                return actions.order.capture().then((details) => {
                                    // Your code here after capture the order

                                    const transactionId = (details.purchase_units && details.purchase_units.length > 0
                                        && details.purchase_units[0].payments.captures && details.purchase_units[0].payments.captures.length > 0) ?
                                        details.purchase_units[0].payments.captures[0].id : ""

                                    const formDataFinal = {
                                        orderId: data.orderID,
                                        transactionId: transactionId
                                    }

                                    handleCapturePayment(owens_payment_id, formDataFinal, FINAL_AMOUNT)

                                });
                            }}
                        />
                    </PayPalScriptProvider>

                    {errorMessage?.state &&
                        <p className="form-error alert alert-danger">
                            {errorMessage?.message}
                        </p>
                    }
                </div>
            </section>
        </Fragment>
    )
}


export default PaypalTransactionFlow
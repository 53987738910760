import React, { Fragment, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { KYCUSerIcon } from '../../helpers/svgConst'
import PrimaryButton from '../Login/PrimaryButton'
import USER_HELPER from "../../helpers/apis/userHelper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import http from "../../helpers/Interceptors";
import { UPDATE_PROFILE_API } from "../../helpers/ApisPath";
import { InitiateLogin } from "../../redux/actions/userActions";
import {
    API_FETCH_COMPLETE,
    API_FETCH_START,
    changeURLLastString,
    GET_ERROR_TOAST_MSG
} from "../../constants/commonFunc";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";


const KYCPersonalDetails = () => {

    const dispatch = useDispatch()


    const navigate = useNavigate()


    const { loginUserInfo } = USER_HELPER()
    const [loading, setLoading] = useState(API_FETCH_COMPLETE)
    const [errorMessage, setErrorMessage] = useState("")

    const initialSchema = {
        name: loginUserInfo?.name ? loginUserInfo?.name : "",
        mobile: loginUserInfo?.phone ? loginUserInfo?.phone : "",
        email: loginUserInfo?.email ? loginUserInfo?.email : "",
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        mobile: Yup.string().required("Mobile number is required"),
        email: Yup.string().required("email is required"),
    });

    const handleSubmit = (values) => {
        setLoading(API_FETCH_START)
        const formData = {
            "name": values.name,
            ...(!loginUserInfo?.email ? { "email": values.email } : {})
        }
        http.post(UPDATE_PROFILE_API, formData)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(InitiateLogin(res?.data?.data))
                    navigate(changeURLLastString(Paths.KYCVerificationPage, 2))
                }
            }).catch((err) => {
                const errMsg = GET_ERROR_TOAST_MSG(err)
                setErrorMessage({ status: true, message: "Failed to update profile info" + errMsg })
            }).finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })
    }


    return (
        <Fragment>
            <div className='pi-15'>
                <div className='d-flex align-items-center gap-10 mt-25'>
                    <KYCUSerIcon />
                    <h1 className='font16px lightmode-text-blue'>Personal Details</h1>
                </div>
                <h6 className='font12px mt-10'>Enter your personal details. Keep it same, as mentioned on your
                    government-issued documents. </h6>
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={initialSchema}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <Form>
                    <div className='pi-15'>
                        <label htmlFor='name' className='font10px mt-25'>Name*</label>
                        <Field type="text" name='name' id='name' placeholder='First and Last name'
                            className="w-100 user-input" />
                        <label htmlFor='mobile' className='font10px mt-15'>Mobile number*</label>
                        <Field type="text" name='mobile' id='mobile' placeholder='Enter your mobile number'
                            disabled={true}
                            className="w-100 user-input" />
                        <label htmlFor='email' className='font10px mt-15'>Email Address*</label>
                        <Field type="text" name='email' id='email' placeholder='Enter your e-mail address'
                            disabled={!!loginUserInfo?.email}
                            className="w-100 user-input" />

                        {errorMessage?.status &&
                            <p className="form-error mt-4">
                                {errorMessage?.message}
                            </p>
                        }
                    </div>

                    <div className='main-white-card br-0 mt-auto'>
                        <PrimaryButton heading="Next - Upload ID" type="submit"
                            isLoading={loading !== API_FETCH_COMPLETE} />
                    </div>
                </Form>
            </Formik>

        </Fragment>
    )
}

export default KYCPersonalDetails

// No data found case for all NFTs
import React, {Fragment} from 'react'
import {useNavigate} from 'react-router-dom'
import NoDataImage from '../../images/Common/ghost.svg'
import SecondaryButton from './SecondaryButton'

const NoDataFound = ({image, text, goBack = false}) => {
    const navigate = useNavigate()
    return (
        <Fragment>
            <section className='noDataFoundBlock'>
                <div className='noDataCard'>
                    {image &&
                    <div className='noDataImageBlock mb-50'>
                        <img src={image ? NoDataImage : ""} alt="No Data Found"/>
                    </div>
                    }
                </div>
                <div className='mt-0'>
                    <p className='font16px lightmode-text-blue w-50 mx-auto text-center mb-10'>{text}</p>
                    {goBack && <SecondaryButton heading="Go Back" onClick={() => navigate(-1)}/>}
                </div>
            </section>
        </Fragment>
    )
}

export default NoDataFound

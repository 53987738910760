// Common Blue Button for all places
import React, {Fragment} from "react";
import "./LoginStyles.css";
import GlanceLoader from "../../helpers/Loader";

const PrimaryButton = ({
                           type,
                           heading,
                           primaryClassName = "",
                           isLoading,
                           icon,
                           onClick,
                           disabled = false
                       }) => {
    return (
        <Fragment>
            <button
                className={`btn primaryBtn d-flex justify-content-center align-items-center ${primaryClassName}`}
                onClick={onClick}
                disabled={disabled || isLoading}
                type={type ? type : "button"}
            >
                {isLoading &&
                <GlanceLoader color={"#fff"} className="text-center" width="24" height="24"/>
                }
                {!isLoading &&
                <Fragment>
                    {icon} {heading}
                </Fragment>

                }

            </button>
        </Fragment>
    );
};

export default PrimaryButton;

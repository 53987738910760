import React, { Fragment, useState, useEffect } from 'react'
import "../assets/css/analytics.css"
import AdminSidebar from '../../components/AdminSidebar'
import AnalyticsCard from '../components/AnalyticsCard'
import userIcon from "../assets/images/users.png"
import cardIcon from "../assets/images/card.png"
import collectionIcon from "../assets/images/collection.png"
import balanceIcon from "../assets/images/balance.png"
import revenueIcon from "../assets/images/revenue.png"
import listingIcon from "../assets/images/listing.png"
import payInIcon from "../assets/images/payIn.png"
import payOutIcon from "../assets/images/payOut.png"
import PieRechart from '../components/Charts/PieChart'
import BarRechart from '../components/Charts/BarChart'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import { MY_COLLECTION_API } from '../../helper/apiPaths'
import DoubleBarChart from '../components/Charts/DoubleBarChart'
import { API_BASE_URL, OPERATOR_ID } from '../../../constants/envConstants'

const Analytics = () => {

    const [loading, setLoading] = useState(false)
    const [collectionloading, setCollectionLoading] = useState(false)
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [dashboardData, setDashboardData] = useState([])
    const [collectionData, setCollectionData] = useState([])
    const [startDate, setStartDate] = useState(new Date('01 Sep 2022').toISOString().slice(0, 10))
    const [endDate, setEndDate] = useState(new Date('01 oct 2022').toISOString().slice(0, 10))
    const [userRegDetails, setUserRegDetails] = useState([])
    const [dayWisePayDetails, setDayWisePayDetails] = useState([])
    const [collectionList, setCollectionList] = useState([])
    const [collectionId, setCollectionId] = useState('')
    const [dayWiseListedingDetails, setDayWiseListingDetails] = useState([])


    const getDashboardDetails = () => {
        setLoading(true)
        axios
            .get(API_BASE_URL + "reports/dashboard-details")
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.dasboardDetail
                    setDashboardData(apiData[0])
                    setMaxResult(res.data.data.schemaCount)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setDashboardData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCollectionDetails = (pageNo = 1, limit = 10) => {
        setLoading(true)
        axios

            .get(API_BASE_URL + "reports/top-collections" + "?page=" + pageNo + "&limit=" + limit + "&startDatetime=" + startDate + "&endDatetime=" + endDate)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.topCollections

                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index
                            }
                        )
                    })
                    setCollectionData(finalData)
                    setMaxResult(res.data.data.schemaCount)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setCollectionData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getDayWiseUserRegistrations = () => {
        setLoading(true)
        axios.get(API_BASE_URL + "reports/day-wise-user-registrations" + "?startDatetime=" + startDate + "&endDatetime=" + endDate)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res?.data?.data?.daywiseUserRegistration
                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                date: key.date.toString()
                            }
                        )
                    })
                    setUserRegDetails(finalData)
                    console.log('finaldata', finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setUserRegDetails([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getDayWisePayDetails = () => {
        setLoading(true)
        axios.get(API_BASE_URL + "reports/day-wise-payin-payout" + "?startDatetime=" + startDate + "&endDatetime=" + endDate)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res?.data?.data?.dayWisePayInPayout
                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                maxpayins: parseFloat(key?.maxpayins),
                                maxpayouts: parseFloat(key?.maxpayouts),
                                totalpayin: parseFloat(key?.totalpayin),
                                totalpayout: parseFloat(key?.totalpayout),
                            }
                        )
                    })
                    setDayWisePayDetails(finalData)
                    console.log('finaldata', finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setDayWisePayDetails([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getDayWiseListing = () => {
        setLoading(true)
        axios
            .get(API_BASE_URL + "reports/day-wise-marketplace-stats?startDatetime=" + startDate + "&endDatetime=" + endDate + "&collectionId=" + collectionId)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.dayWiseMarketplaceReport
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                totalAuctionListed: parseFloat(key?.totalAuctionListed),
                                totalAuctionSold: parseFloat(key?.totalAuctionSold),
                                totalCancelledListing: parseFloat(key?.totalCancelledListing),
                                totalListing: parseFloat(key?.totalListing),
                                totalSold: parseFloat(key?.totalSold),
                                totalSoldAmount: parseFloat(key?.totalSoldAmount),
                                totalTokenMarketListed: parseFloat(key?.totalTokenMarketListed),
                                totalTokenMarketSold: parseFloat(key?.totalTokenMarketSold),
                            }
                        )
                    })
                    setDayWiseListingDetails(finalData)
                    console.log('final data', finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setDayWiseListingDetails([])
                // addToast('failed to remove' + err?.response?.data?.message, {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setCollectionLoading(true)
        axios
            .get(MY_COLLECTION_API + "/" + OPERATOR_ID + "?page=1&limit=100")
            .then((res) => {
                if (res.status === 200) {
                    console.log('collection-list', res.data)
                    setCollectionList(res.data.data.collectionsList)
                }
            })
            .catch((err) => {
                // setMaxResult(0);
                setCollectionList([])
                // addToast('failed to remove' + err?.response?.data?.message, {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
            })
            .finally(() => {
                setCollectionLoading(false)
            })

    }, [])

    const handleGetAllData = () => {
        getDashboardDetails()
        getCollectionDetails()
        getDayWiseUserRegistrations()
        getDayWisePayDetails()
        getDayWiseListing()
    }

    useEffect(() => {
        getDashboardDetails()
        getCollectionDetails()
        getDayWiseUserRegistrations()
        getDayWisePayDetails()
    }, [])
    useEffect(() => {

    }, [])
    useEffect(() => {
        getDayWiseListing()
    }, [])

    const userRegData = userRegDetails.length > 0 && userRegDetails.reverse().map((key) => {
        return {
            name: new Date(key?.date).toLocaleDateString(),
            total_User_Registered: key?.totaluserregistered
        }
    })
    const dayWisePayData = dayWisePayDetails.length > 0 && dayWisePayDetails.reverse().map((key) => {
        return {
            name: new Date(key?.date).toLocaleDateString(),
            total_payin: key?.totalpayin,
            total_payout: key?.totalpayout,
        }
    })

    const dayWiseListingData = dayWiseListedingDetails.length > 0 && dayWiseListedingDetails.reverse().map((key) => {
        return {
            name: new Date(key?.date).toLocaleDateString(),
            total_listing: key?.totalTokenMarketListed
        }
    })


    return (
        <Fragment>
            <div className="admin--layout">
                <div className="adminSidebar--block">
                    <AdminSidebar />
                </div>
                <div className="admin--content pos-rel">
                    <div className="CreateCollectionWrapper schema contianer">
                        <h2 className="CreateNewCollection newBlueColor">Analytics Panel</h2>
                        <p className="CollectionSubHeading mb-0">
                            All analytics are shown here.
                        </p>
                        {!loading && dashboardData && Object.keys(dashboardData).length > 0 &&
                            <div className='analytics-grid mt-4'>
                                <AnalyticsCard heading="Total collections" count={dashboardData?.totalCollections.toLocaleString("en")} image={collectionIcon} />
                                <AnalyticsCard heading="Total Users" count={dashboardData?.totalusers.toLocaleString("en")} image={userIcon} />
                                <AnalyticsCard heading="Total Cards" count={dashboardData?.totalcards.toLocaleString("en")} image={cardIcon} />
                                <AnalyticsCard heading="Total Listing" count={dashboardData?.totalListing.toLocaleString("en")} image={listingIcon} />
                                <AnalyticsCard heading="Total Payin" count={`$${Math.floor(dashboardData?.totalPayIn).toLocaleString("en")}`} image={payInIcon} />
                                <AnalyticsCard heading="Total Payout" count={`$${Math.floor(dashboardData?.totalPayout).toLocaleString("en")}`} image={payOutIcon} />
                                <AnalyticsCard heading="Total Revenue" count={`$${Math.floor(dashboardData?.totalSoldAmount).toLocaleString("en")}`} image={revenueIcon} />
                                <AnalyticsCard heading="Total Balance" count={`$${Math.floor(dashboardData?.totalbalance).toLocaleString("en")}`} image={balanceIcon} />
                            </div>}
                        {loading &&
                            <div className="text-center mt-5">
                                <Loader color={"#5a2450"} type="Oval" width={85} height={55} />
                            </div>}
                        <div className='bg-white mt-5 px-3 py-4 br-10'>
                            <div className="d-flex gap-10 align-items-center">
                                <div>
                                    <label htmlFor='startDate'>Enter start date</label>
                                    <input
                                        id="startDate"
                                        type="date"
                                        className="adminInput lightShadow"
                                        placeholder="DD/MM/YY"
                                        pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                        value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div>
                                    <label htmlFor='endDate'>Enter end date</label>
                                    <input
                                        id="endDate"
                                        type="date"
                                        className="adminInput lightShadow"
                                        placeholder="DD/MM/YY"
                                        pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                        value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                                <div className="">
                                    <label htmlFor='collection'>Enter collection</label>
                                    <select
                                        id='collection'
                                        type="select"
                                        onChange={(e) => setCollectionId(e.target.value)}
                                        placeholder="Select Collection"
                                        className="adminInput lightShadow w-100"
                                    >
                                        <option value="default">Select Collection</option>
                                        {collectionList && collectionList.length > 0 && collectionList?.map((collection, index) => (
                                            <Fragment key={index}>
                                                <option value={collection?.id}>{collection?.collectionName}</option>
                                            </Fragment>
                                        ))}
                                    </select>
                                </div>
                                <button className='btn newBlueBtn height-fit mt-3' onClick={handleGetAllData}>Submit</button>

                            </div>
                        </div>
                        <div className='mt-5 pt-4 whiteBGForm dataListViewGrid'>
                            <div className='chartGrid text-center row'>
                                <div className='col-12 col-md-6 adminGrayBG br-10'>
                                    <h6 className='fs-24 fw-500 text-blue mb-4 mt-5'>Top Collections</h6>
                                    <PieRechart finalData={collectionData} dataKey={"total_Sold_Price"} />
                                </div>
                                <div className='col-12 col-md-6 adminGrayBG br-10'>
                                    <h6 className='fs-24 fw-500 text-blue mb-4 mt-5'>Total user registered</h6>
                                    <BarRechart data={userRegData} dataKey={"total_User_Registered"} />
                                </div>
                                <div className='col-12 col-md-6 adminGrayBG br-10'>
                                    <h6 className='fs-24 fw-500 text-blue mb-4 mt-5'>Daily NFT listing</h6>


                                    <BarRechart data={dayWiseListingData} dataKey={"total_listing"} />
                                </div>
                                <div className='col-12 col-md-6 adminGrayBG br-10'>
                                    <h6 className='fs-24 fw-500 text-blue mb-4 mt-5'>Daily pay details</h6>
                                    <DoubleBarChart payinKey={"total_payin"} payoutKey={"total_payout"} data={dayWisePayData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Analytics
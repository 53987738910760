import {
    COLLECTION_PAGE_TIER_SCHEMA,
    MARKETPLACE_PAGE_TIER_SCHEMA,
    PAYMENT_SUCCESS_REDIRECT_URL,
    TOKEN_DETAIL_PAGE_TIER_SCHEMA
} from "../action-types/common.type";

const initialState = {
    collectionPageTierSchema: "",
    tokenDetailPageTierSchema: "",
    marketPlacePageTierSchema: "",
    paymentOnSuccessRedirectURL: ""
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case COLLECTION_PAGE_TIER_SCHEMA:
            return {
                ...state,
                collectionPageTierSchema: action.schemaValue
            }

        case TOKEN_DETAIL_PAGE_TIER_SCHEMA:
            return {
                ...state,
                tokenDetailPageTierSchema: action.schemaValue
            }
        case MARKETPLACE_PAGE_TIER_SCHEMA:
            return {
                ...state,
                marketPlacePageTierSchema: action.schemaValue
            }
        case PAYMENT_SUCCESS_REDIRECT_URL:
            return {
                ...state,
                paymentOnSuccessRedirectURL: action.url
            }

        default:
            return state
    }
}

export default commonReducer

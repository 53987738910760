import { Fragment } from "react"
import Loader from "react-loader-spinner"

// Common Loader for all places , just pass color and size and other params 
const GlanceLoader = (props) => {
    const { color, type, width, height, text, ...rest } = props
    return (
        <Fragment>
            <Loader
                color={color ? color : "#F38229"}
                type={type ? type : "Oval"}
                width={width ? width : 35}
                height={height ? height : 35}
                border={10}
                {...rest}
            />
            <p className="font10px lightmode-text-light-gray text-center mt-20">{text}</p>
        </Fragment>
    )
}
export default GlanceLoader

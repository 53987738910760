import React, { Fragment } from 'react'
import KYCCompleteThumb from "../../images/Common/kycDone.png"
import { ClipboardIcon } from "../../helpers/svgConst"
import PrimaryButton from "../../components/Login/PrimaryButton"
import { useNavigate } from "react-router-dom"
import Paths from "../../helpers/Paths"

const KYCComplete = () => {

    const navigate = useNavigate()
    return (
        <Fragment>
            <section className='lightMode kycBG'>
                <div className='kycSubBG pt-100'>
                    <div className='kyc-thumb-block'>
                        <img src={KYCCompleteThumb} alt="thumb" />
                    </div>
                    <h1 className='font24pxSemiBold lightmode-text-marigold text-center mt-10'>Verification request
                        submitted</h1>
                    <h6 className='font14px lightmode-text-blue mt-15 text-center w-75 mx-auto'>We will notify you once your KYC is done. Meanwhile, browse our collection of premium NFTs.</h6>
                    <PrimaryButton
                        icon={<ClipboardIcon className="mr-2" />}
                        primaryClassName="mt-20 w-fit mx-auto py-3 px-4"
                        heading="Browse Collections"
                        onClick={() => navigate(Paths.MoreCollectionsPage)} />
                </div>
            </section>
        </Fragment>
    )
}

export default KYCComplete
import React, { Fragment, useState } from 'react'
import { PRICE_FORMAT } from '../../constants/commonFunc'
import { LockedBalanceIcon } from '../../helpers/svgConst'
import SecondaryButton from '../Common/SecondaryButton'

const LockedBalance = ({ data }) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <Fragment>
            <div className='main-white-card lightBorder mt-30'>
                <div className='d-flex align-items-center gap-10' onClick={() => setIsOpen(!isOpen)}>
                    <LockedBalanceIcon />
                    <h2 className='font16px lightmode-text-blue'>Locked Balance</h2>
                    <p className='font12px lightmode-text-marigold ml-auto'>$ {PRICE_FORMAT(data?.lockedBalance, true)}</p>
                </div>
                {/* <div className={`${isOpen ? "" : "d-none"}`}>
                    <div className='mt-20 d-flex align-items-center justify-content-between'>
                        <div>
                            <p className='font10px lightmode-text-midnight-gray'>Collection Name</p>
                            <p className='font12px lightmode-text-midnight-blue'>#NFT 23039</p>
                        </div>
                        <p className='font12px lightmode-text-midnight-blue'>$ 2,054</p>
                    </div>
                    <div className='mt-20 d-flex align-items-center justify-content-between'>
                        <div>
                            <p className='font10px lightmode-text-midnight-gray'>Collection Name</p>
                            <p className='font12px lightmode-text-midnight-blue'>#NFT 23039</p>
                        </div>
                        <p className='font12px lightmode-text-midnight-blue'>$ 1,000</p>
                    </div>
                    <SecondaryButton heading="View All" className="mt-25" />
                </div> */}
            </div>
        </Fragment>
    )
}

export default LockedBalance
// File is used for collection like or Card like dislike functionality
import React, { Fragment, useEffect, useState } from 'react'
import { HeartFillIcon, HeartOutlineIcon } from '../../helpers/svgConst'
import CARD_HELPER from "../../helpers/apis/cardHelper";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import { COLLECTION, NFT_CARD } from "../../constants/envConstants";
import USER_HELPER from "../../helpers/apis/userHelper";

const LikeUnlikeButton = ({ type, id }) => {

    const { isUserLogin, RedirectToLoginScreen } = USER_HELPER()
    const { isHeartActive, handleNFTLikeDisLike, getMyFavouriteCards, WISHLIST_ITEMS_IDS } = CARD_HELPER()
    const { getMyFavouriteCollection, WISHLISTED_COLLECTION_IDS } = COLLECTION_HELPER()
    const [isLiked, setIsLiked] = useState(false)


    useEffect(() => {
        if (!!WISHLIST_ITEMS_IDS && WISHLIST_ITEMS_IDS.length > 0 && !!id) {
            if (type === NFT_CARD) {
                setIsLiked(WISHLIST_ITEMS_IDS.includes(id))
            }
            if (type === COLLECTION) {
                setIsLiked(WISHLISTED_COLLECTION_IDS.includes(id))
            }
        }
    }, [WISHLIST_ITEMS_IDS, id])

    const changedValue = isLiked ? "0" : "1"

    const handleCollectionLikeDisLike = () => {
        if (type === COLLECTION) {
            //not checking that liked success or not just changing the value and later confirming
            setIsLiked(!isLiked)
            const res = handleNFTLikeDisLike(COLLECTION, changedValue, id)
            if (res.status === 200) {
                getMyFavouriteCollection()
            }
        }
    }
    const handleCardLikeDisLike = async () => {
        if (type === NFT_CARD) {
            //not checking that liked success or not just changing the value and later confirming
            setIsLiked(!isLiked)
            const res = await handleNFTLikeDisLike(NFT_CARD, changedValue, id)
            if (res.status === 200) {
                await getMyFavouriteCards()
            }
        }
    }

    const handleLikeDisLike = (e) => {
        e.stopPropagation()
        console.log("is user login", isUserLogin)
        if (!isUserLogin) {
            RedirectToLoginScreen()
            return
        }
        handleCardLikeDisLike()
        handleCollectionLikeDisLike()
    }

    return (
        <Fragment>
            <button className='btn p-0 likeUnlikeBtn btnShadow' onClick={(e) => handleLikeDisLike(e)}>
                {isLiked ? <HeartFillIcon /> : <HeartOutlineIcon />}
            </button>
        </Fragment>
    )
}

export default LikeUnlikeButton

import React, { Fragment } from 'react'
import "../../components/Listing/ListingStyles.css"
import { VerifiedIcon } from "../../helpers/svgConst"
import { useNavigate } from "react-router-dom";
import { CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import GlanceImage from '../../components/Common/GlanceImage';

const CheckoutListView = ({ grayBG, data }) => {

    const navigate = useNavigate()

    const handleGoToDetailNFT = (cardId) => {
        navigate("/card/detail/" + cardId + "?type=list")
    }


    return (
        <Fragment>
            <Fragment>
                <div className={`main-white-card p-0 ${grayBG ? "grayBorder" : ""} mb-10`}>
                    <div className='inside-white-card white-list-block'>
                        <div className='listingImageBlock'>
                            <GlanceImage src={CheckWAXImage(data?.image)} alt="thumb" />
                        </div>
                        <div className='d-flex flex-column'>
                            <p className='font12px lightmode-text-midnight-blue extendedText max-w-160'>
                                {data?.name}
                            </p>
                            <div className='d-flex align-items-center mt-1'>
                                <VerifiedIcon width="12" height="12" />
                                <span className='font10px ml-1 lightmode-text-light-gray'>
                                    {data?.collectionDisplayName}
                                </span>
                            </div>
                            <p className='font10px mt-auto lightmode-text-light-gray'>
                                {/* Traded: <span className='lightmode-text-blue'>99k</span> */}
                                | Floor: <span
                                    className='lightmode-text-blue'>$ {PRICE_FORMAT(data?.listedPrice)}</span></p>
                        </div>
                        <div className='d-flex flex-column'>
                            {/* <div className='goldExpBlock d-flex'>
                                <p className='font8pxSemiBold mr-1 lightmode-text-secondary'> +590</p>
                                <GoldExpCoinIcon />
                            </div> */}

                        </div>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    )
}

export default CheckoutListView

import {
    DIRECT_BUY_NFT,
    GET_ALL_WISHLISTED_COLLECTION,
    GET_ALL_WISHLISTED_ITEMS,
    GET_RENT_NFT_DAYS,
    LIST_YOUR_NFT,
    MAKE_AN_OFFER,
    SELECT_OFFER,
    SET_USER_OFFER_FOR_CARD
} from "../action-types/cardDetail.types";


const initialState = {
    directBuyNFT: {},
    listYourNFT: {},
    selectedOffer: {},
    wishlistedItems: {},
    wishlistedCollection: {},
    makeAnOfferNFT: {},
    userOfferForCard: {},
    daysForRent: {},

}

const cardDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case DIRECT_BUY_NFT:
            return {
                ...state,
                directBuyNFT: action.payload
            }

        case LIST_YOUR_NFT:
            return {
                ...state,
                listYourNFT: action.payload
            }
        case SELECT_OFFER:
            return {
                ...state,
                selectedOffer: action.payload
            }
        case GET_ALL_WISHLISTED_ITEMS: {
            return {
                ...state,
                wishlistedItems: action.payload
            }
        }
        case GET_ALL_WISHLISTED_COLLECTION: {
            return {
                ...state,
                wishlistedCollection: action.payload
            }
        }
        case MAKE_AN_OFFER: {
            return {
                ...state,
                makeAnOfferNFT: action.payload
            }
        }
        // set if user made an offer for card either listed nft or not listed nft
        case SET_USER_OFFER_FOR_CARD: {
            return {
                ...state,
                userOfferForCard: action.userOfferObj
            }
        }

        case GET_RENT_NFT_DAYS: {
            return {
                ...state,
                daysForRent: action.payload
            }
        }

        default:
            return state
    }
}

export default cardDetailReducer

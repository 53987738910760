import React, { Fragment } from "react";
import "../Collection/CollectionStyles.css";
import ImageBG from "../../images/CollectionImage/background.png"
import { API_FETCH_COMPLETE, CheckWAXImage } from "../../constants/commonFunc";
import GlanceImage from "./GlanceImage";
import NoDataFound from "./NoDataFound";
import NFTSkeleton from "./Skeleton/NFTSkeleton";
import { Divider } from "@mui/material"
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";

const ActivityCard = ({ data, loading }) => {
    const { getCollectionTransactionType } = COLLECTION_HELPER()
    return (
        <Fragment>
            {data && data.length > 0 && data.map((key, index) => {
                return (
                    <Fragment key={index}>
                        <div className="activity-card mt-2">
                            <div className="activityImageBlock">
                                <GlanceImage src={key?.image ? CheckWAXImage(key?.image) : ImageBG} alt="thumb" />
                            </div>
                            <p className="font12px lightmode-text-blue w-60">{key?.vaccount} &nbsp;
                                {getCollectionTransactionType(key?.type).textDesc}{" "}
                                <span className="font12pxBold">{key?.name}</span>
                                {!!key?.mintNumber && <>
                                    #{key?.mintNumber}
                                </>}
                            </p>
                            {/* <p className="activityTimer lightmode-text-midnight-gray">5 mins ago</p> */}
                        </div>
                        <Divider className="mt-10 mb-10" />
                    </Fragment>
                )
            })
            }
            {(loading !== API_FETCH_COMPLETE) &&
                <NFTSkeleton imageHeight={0} className={"mb-4"} />
            }

            {(loading === API_FETCH_COMPLETE) && (!data || data?.length === 0) &&
                <NoDataFound text="We don't have any Activites in this tier." />
            }

        </Fragment>
    );
};

export default ActivityCard;

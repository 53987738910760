export const ENV = process.env.REACT_APP_ENV
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const OPERATOR_ID = 1
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_ID
export const COLLECTION = "COLLECTION"
export const NFT_CARD = "NFT_CARD"
export const MARKETPLACE = "MARKETPLACE"
export const WEB_URL = "https://develop.dk1jk75zhj4e4.amplifyapp.com"
// paypal test key
export const PAYPAL_KEY = "ARDBaAQfljje2Lq6P8v_kaTLe6A00rvSIEHl2Gt8L4Ps2Y1vIpSUqU9j64wYfY9wh91va7oGNar9jgaJ";

//paypal prod key
// export const PAYPAL_KEY = "ATVyyHPbADA7Gb3h8dhPw3vq38lLboSHZIyWUsuKdKdtPbevZsDcpGgwRMZ6ARPOtHDQGtUiVwRPivMD";

export const WAX_PROTOCOL = process.env.REACT_APP_WAX_PROTOCOL;
export const WAX_HOST = process.env.REACT_APP_WAX_HOST;
export const WAX_PORT = process.env.REACT_APP_WAX_PORT;
export const WAX_CHAIN_ID = process.env.REACT_APP_WAX_CHAIN_ID;
export const WAX_APP_NAME = process.env.REACT_APP_APP_NAME
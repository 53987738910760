import {
    MARKETPLACE_API_PATH_CHANGE,
    MARKETPLACE_PRICE_CHANGE,
    MARKETPLACE_SCHEMA_CHANGE,
    MARKETPLACE_SCHEMA_OPTIONS_LIST,
    MARKETPLACE_SORT_CHANGE,
    SET_MARKETPLACE_POINTS_RANGE,
    SHOW_MARKETPLACE_FILTERS,
    SHOW_MARKETPLACE_POINTS_RANGE,
    START_MARKETPLACE_API_CALL
} from "../action-types/marketplace.types";
import { changeURLLastString } from "../../constants/commonFunc";
import { MARKETPLACE_CARDS_API } from "../../helpers/ApisPath";

const initialState = {
    schemaList: [],
    apiCallStart: false,
    isVisibleFilters: false,
    sortValue: null,
    customSortText: "",
    filters: {},
    price: { min: 0, max: null },
    schemaFilter: "",
    apiFullPath: changeURLLastString(MARKETPLACE_CARDS_API, 1),
    showPointsFilter: false,
    pointsRange: { min: 0, max: null }
}

const marketplaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MARKETPLACE_FILTERS:
            return {
                ...state,
                isVisibleFilters: action.isVisible
            }
        case MARKETPLACE_SORT_CHANGE:
            return {
                ...state,
                customSortText: action.customSortText,
                sortValue: action.sortValue,
            }
        case MARKETPLACE_PRICE_CHANGE:
            return {
                ...state,
                price: action.price
            }
        case MARKETPLACE_SCHEMA_CHANGE:
            return {
                ...state,
                schema: action.payload
            }
        case MARKETPLACE_API_PATH_CHANGE:
            return {
                ...state,
                apiFullPath: action.apiPath
            }
        case START_MARKETPLACE_API_CALL:
            return {
                ...state,
                apiCallStart: action.apiCallStart
            }
        case MARKETPLACE_SCHEMA_OPTIONS_LIST:
            return {
                ...state,
                schemaList: action.payload
            }
        case SHOW_MARKETPLACE_POINTS_RANGE:
            return {
                ...state,
                showPointsFilter: action.isShow
            }
        case SET_MARKETPLACE_POINTS_RANGE:
            return {
                ...state,
                pointsRange: action.payload
            }
        default:
            return state
    }
}

export default marketplaceReducer

import React, { Fragment } from "react";
import "../../components/Login/LoginStyles.css";
import { GoBackIcon } from "../../helpers/svgConst";
import EnterOTPBlock from "../../components/Login/EnterOTPBlock";
import PersonalizeAccount from "../../components/Login/PersonalizeAccount";
import { useNavigate, useParams } from "react-router-dom";
import InputMobileNumber from "../../components/Login/InputNumber";
import AllDone from "../../components/Login/AllDone";
import Paths from "../../helpers/Paths";
import { changeURLLastString } from "../../constants/commonFunc";

const Login = () => {
    const navigate = useNavigate();
    const params = useParams();
    const getStepNumber = () => {
        if (params.type === "mobile") {
            return 1;
        } else if (params.type === "otp") {
            return 2;
        } else if (params.type === "setup-profile") {
            return 3;
        } else if (params.type === "all-done") {
            return 4;
        }
    };

    const handleGoBack = () => {
        if (params.type === "setup-profile") return
        if (params.type === "otp") {
            navigate(changeURLLastString(Paths.LoginPage, "mobile"), { replace: true })
        } else {
            navigate(-1);
        }
    };


    return (
        <Fragment>
            {params?.type !== "all-done" &&
                <section className="lightMode">
                    <div className="pi-15">
                        <button className={`btn p-0 mt-30 `} onClick={handleGoBack} >
                            <GoBackIcon />
                        </button>

                        <p className="font12pxMedium mt-30 lightmode-text-secondary">
                            Steps {getStepNumber()} of 3
                        </p>
                        {params?.type === "mobile" &&
                            <h1 className="font24pxSemiBold mt-10 lightmode-text-primary">
                                Let's setup your account
                            </h1>
                        }
                        {params?.type === "otp" &&
                            <h1 className="font24pxSemiBold mt-10 lightmode-text-primary">
                                Enter the OTP
                            </h1>
                        }
                        {params?.type === "setup-profile" &&
                            <h1 className="font24pxSemiBold mt-10 lightmode-text-primary">
                                Personalize your account
                            </h1>
                        }
                        {params?.type === "mobile" && <InputMobileNumber />}
                        {params?.type === "otp" && <EnterOTPBlock />}
                        {params?.type === "setup-profile" && <PersonalizeAccount />}
                    </div>
                </section>
            }
            {params?.type === "all-done" && <AllDone />}
        </Fragment >
    );
};

export default Login;

import PublicOffers from "./PublicOffers";
import OwnerOffers from "./OwnerOffers";
import React, { Fragment } from "react";
import USER_HELPER from "../../helpers/apis/userHelper";
import { API_FETCH_COMPLETE, changeURLLastString } from "../../constants/commonFunc";
import NFTSkeleton from "../Common/Skeleton/NFTSkeleton";
import NoDataFound from "../Common/NoDataFound";
import SecondaryButton from "../Common/SecondaryButton";
import Paths from "../../helpers/Paths";
import { useLocation, useNavigate } from "react-router-dom";


const CardOfferListDetailPage = ({ cardInfo, offerList, isLoading }) => {

    const location = useLocation()
    const navigate = useNavigate()
    const { loginUserInfo } = USER_HELPER()


    return (
        <Fragment>

            {(cardInfo?.marketplaceId || !cardInfo?.listingType) &&
                <Fragment>
                    {(cardInfo?.vaccount !== loginUserInfo?.vaccount) &&
                        <PublicOffers data={offerList} loading={isLoading} />
                    }
                    {(cardInfo?.vaccount === loginUserInfo?.vaccount) &&
                        <OwnerOffers data={offerList} loading={isLoading} />
                    }

                    {!!offerList && offerList?.length > 0 && location?.pathname.indexOf("/offer-history") === -1 &&
                        <SecondaryButton heading="View all offers" className="mt-15"
                            onClick={() => navigate(changeURLLastString(Paths.OfferHistoryPage, cardInfo?.id))} />
                    }

                </Fragment>
            }

            {(isLoading !== API_FETCH_COMPLETE) &&
                <NFTSkeleton imageHeight={0} className={"mb-4"} />
            }
            {(isLoading === API_FETCH_COMPLETE) && (!offerList || offerList?.length === 0) &&
                <NoDataFound text="There are no offers yet" />
            }

        </Fragment>
    )
}

export default CardOfferListDetailPage

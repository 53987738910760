import React, { Fragment } from 'react'
import { CrownIcon, VerifiedIcon } from '../../helpers/svgConst'
import GlanceImage from '../Common/GlanceImage'
import { changeURLLastString, CheckWAXImage } from '../../constants/commonFunc'
import { useNavigate } from 'react-router-dom'
import Paths from '../../helpers/Paths'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const SliderCard = ({ data }) => {

    const navigate = useNavigate()

    const handleGoToCollection = (collectionId) => {
        navigate(changeURLLastString(Paths.CollectionPage, collectionId))
    }

    return (
        <Fragment>
            <div className='pos-rel max-w-220 mx-auto ' onClick={() => handleGoToCollection(data?.id)}>
                <div className='rightHeartIcon rightTrendingSec'>
                    <CrownIcon /><p className='font10px lightmode-text-blue'>Trending</p>
                </div>
                <div className='HeroSliderImageBlock max-w-240'>
                    <GlanceImage src={CheckWAXImage(data?.collectionCoverImage)} alt="thumb" />

                </div>
                <div className='NFTSecDetails'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='font16pxSemiBold lightmode-text-blue'>{data?.collectionDisplayName}</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-10'>
                        {data?.author &&
                            <p className="font10px lightmode-text-midnight-gray ">{data?.author} <VerifiedIcon width="11" height="11" className="ml-2 mb-1" /></p>}
                        <p className="font10px lightmode-text-midnight-gray">Items: {data?.totalCards}</p>
                    </div>
                    <div className='text-center'>
                        <button className='btn font12px lightmode-text-marigold'>
                            View Collection
                        </button>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default SliderCard

import React, { Fragment } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const DoubleBarChart = ({ payoutKey, payinKey, data }) => {


    return (
        <Fragment>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tick={{ fontSize: 10, }}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={payinKey} fill="#8884d8" />
                    <Bar dataKey={payoutKey} fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </Fragment>
    )
}

export default DoubleBarChart
import InfiniteScroll from "react-infinite-scroll-component";
import UserNFTTemplate from "../Common/UserNFTTemplate";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { API_FETCH_COMPLETE, API_FETCH_START, changeURLLastString } from "../../constants/commonFunc";
import http from "../../helpers/Interceptors";
import NFTSkeleton from "../Common/Skeleton/NFTSkeleton";
import { GetProfileDataActionChange } from "../../redux/actions/profileAction";
import { MY_WISHLIST_NFT_DATA } from "../../redux/action-types/profile.types";
import { useDispatch } from "react-redux";
import NoDataFound from "../Common/NoDataFound";
import { NewSortIcon } from "../../helpers/svgConst";
import useOnClickOutside from "../../helpers/Hooks/useOnClickOutside";


const MyWishListedNFT = ({ type, apiPath }) => {

    const dispatch = useDispatch()
    const ref = useRef(null)
    const PAGE_LIMIT = 6
    const [collectionName, setCollectionName] = useState("")
    const [sortByName, setSortByName] = useState("")
    const [loading, setLoading] = useState("")
    const [productList, setProductList] = useState([])
    const [totalProduct, setTotalProduct] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const [selectionFilter, setSelectionFilter] = useState(1) // All 1, owend 2, not owened 3

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen)
    }
    const handleSortMenuClickOutside = () => {
        setIsOpen(false);
    };
    const handleSelectDropdown = (value) => {
        setSelectionFilter(value)
        handleSortMenuClickOutside()
    }


    const getSortByParams = () => {
        return (sortByName && sortByName.toString().length === 2) ? ("&sortByType=" +
            sortByName[0] +
            "&sortBy=" +
            sortByName[1]) : ""
    }

    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (productList.length === 0) return 1
        return productList
            ? Math.ceil(productList.length / PAGE_LIMIT) + 1
            : 1;
    }

    const getOwnedCards = (reloadSearch = false) => {
        setLoading(API_FETCH_START)
        const sortParam = getSortByParams()
        const apiPage = getPageNo(reloadSearch)
        const apiFinalUrl = changeURLLastString(apiPath, selectionFilter)

        const apiPathFinal =
            apiFinalUrl +
            "?page=" +
            apiPage +
            "&limit=" +
            PAGE_LIMIT +
            "&collectionId=" +
            collectionName +
            sortParam;


        http.get(apiPathFinal)
            .then((res) => {
                let mergedFinalProducts = []
                const apiData = res?.data?.data;
                dispatch(GetProfileDataActionChange(MY_WISHLIST_NFT_DATA, apiData))
                const apiProductList = apiData.selectedCards || [];
                const apiProductCount = apiData.count || 0;


                setTotalProduct(apiProductCount)

                //when data is selected with different filter
                if (reloadSearch) {
                    setProductList(apiProductList)
                } else {
                    // when data is selected with same filter but page is change
                    // only params are same
                    mergedFinalProducts = [...productList, ...apiProductList];
                    setProductList(mergedFinalProducts);
                }


            }).catch((err) => console.error("failed to fetch marketplace data", err))
            .finally(() => {
                setLoading(API_FETCH_COMPLETE)
            })


    }

    const fetchMoreItems = () => {
        if (productList.length < totalProduct) {
            getOwnedCards(false);
        }
    }

    useEffect(() => {
        setProductList([])
        getOwnedCards(true)
    }, [selectionFilter])

    useOnClickOutside(ref, handleSortMenuClickOutside);

    return (
        <Fragment>
            <div className='d-flex justify-content-between align-items-center pt-15 pb-40 mb-10  profileShadow whiteBG' >
                <div className="pos-rel d-flex w-100 justify-content-end" ref={ref}>
                    <button className='btn p-0 bidsAbsBtn top-0' onClick={handleToggleDropdown} >
                        <NewSortIcon />
                    </button>
                    <div className={`${isOpen ? "py-3 px-4 tokenHeroAbsBtn" : "d-none"}`}>
                        <button
                            className={`btn d-block font14px ${selectionFilter === 1 ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                            value="1"
                            onClick={() => handleSelectDropdown(1)}>All</button>
                        <button
                            className={`btn d-block font14px ${selectionFilter === 2 ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                            value="2"
                            onClick={() => handleSelectDropdown(2)}>Owned by me</button>
                        <button
                            className={`btn d-block font14px ${selectionFilter === 3 ? "lightmode-text-marigold" : "lightmode-text-blue"}`}
                            value="3"
                            onClick={() => handleSelectDropdown(3)}>Not owned by me</button>
                    </div>
                </div>
            </div>

            <div className="mt-2 pi-15 min-vh-100">
                <InfiniteScroll
                    next={fetchMoreItems}
                    hasMore={productList.length < totalProduct}
                    dataLength={productList.length}
                    loader={(loading !== API_FETCH_COMPLETE) &&
                        <NFTSkeleton imageHeight={80} className={"mb-4"} />
                    }>

                    <UserNFTTemplate shopData={productList} type={type} />
                </InfiniteScroll>

                {(loading !== API_FETCH_COMPLETE) &&
                    <NFTSkeleton imageHeight={80} className={"mb-4"} />
                }

                {(loading === API_FETCH_COMPLETE) && productList.length === 0 &&
                    <NoDataFound text="You haven't wishlisted any NFT" />
                }
            </div>
        </Fragment>
    )
}

export default MyWishListedNFT

import React, { Fragment } from "react";
import { VerifiedIcon } from "../../helpers/svgConst";
import { API_FETCH_COMPLETE, CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import Skeleton from "@mui/material/Skeleton";
import GlanceImage from "../Common/GlanceImage"
import { Divider } from "@mui/material";
import SecondaryButton from "../Common/SecondaryButton";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";


const CollectionBasicInfo = ({ isLoading, collectionInfo }) => {

    const navigate = useNavigate()

    const loading = isLoading !== API_FETCH_COMPLETE

    return (
        <Fragment>
            <div className='d-flex justify-content-between align-items-center pt-35 pb-15'>
                <div>
                    <h1 className='font16pxBold'>
                        {collectionInfo?.collectionDisplayName}
                    </h1>
                    {collectionInfo?.author &&
                        <div className="d-flex align-items-center mt-1">
                            <p className='font10px lightmode-text-light-gray'>{collectionInfo?.author}</p>
                            {collectionInfo.isFeatured &&
                                <VerifiedIcon width="11" height="11" className="ml-1" />
                            }
                        </div>}
                </div>
                {/* <div className="pos-rel">
                <button className="btn gradient-card minW-80 px-3 py-2 ">
                    <p className='font10px text-left'>Floor:</p>
                    <p className='font16pxSemiBold'>$ 1200</p>
                </button>
                <div className="floorBlurBG"/>
                </div> */}
            </div>
            <div className='collectionHeroImageBlock mx-auto'>
                {loading && <Skeleton variant="rectangular" height={330} />}
                {!loading && <GlanceImage src={CheckWAXImage(collectionInfo?.collectionCoverImage)} alt="Thumbnail" />}
            </div>

            {/* <div className='mt-15'>
                {loading && <Skeleton variant="text" />}
                {!loading && <p className={`font14px text-center`}>
                    {collectionInfo?.collectionDescription}
                </p>}
            </div> */}
            <div className="main-white-card mt-10">

                <div className='collectionDetailsGrid inside-white-card text-center mb-10'>
                    <p className='font12px lightmode-text-blue'>Items: <span
                        className='font14pxBold'>{collectionInfo?.totalCards}</span></p>
                    <p className='font12px lightmode-text-blue middleBorder'>Owners: <span
                        className='font14pxBold'>{collectionInfo?.totalOwners}</span></p>
                    <p className='font12px lightmode-text-blue'>Traded: <span
                        className='font14pxBold'>{PRICE_FORMAT(collectionInfo?.totalSoldAmount)}</span></p>
                </div>
                <Divider className="mb-15" />

                <p className='font14px text-center lightmode-text-blue'>
                    {collectionInfo?.collectionDescription}
                    {/* Welcome to the NFT collection of the Tiger Shroff. You can buy these NFTs from any of the following tiers. */}
                </p>
                {/* <div className="d-flex gap-10 align-items-center mt-15">
                    <p className="font12px listInput px-3 nowrap py-2 gold-text">
                        <GoldTierIcon width="15" height="15" className="mr-1" /> Gold
                    </p>
                    <p className="font12px listInput px-3 nowrap py-2 silver-text">
                        <SilverTierIcon width="15" height="15" className="mr-1" /> Silver
                    </p>
                    <p className="font12px listInput px-3 nowrap py-2 bronze-text">
                        <BronzeTierIcon width="15" height="15" className="mr-1" /> Bronze
                    </p>
                </div> */}
                <Divider className="mt-20" />
                <div className="d-flex justify-content-between align-items-center mt-15">
                    <p className="font12px lightmode-text-blue">Ownership of these NFT will give to some extra
                        benefits </p>
                    <SecondaryButton heading="View benefits" className="nowrap"
                    // onClick={() => navigate(Paths.TierPage)}
                    />
                </div>
            </div>

        </Fragment>
    )
}

export default CollectionBasicInfo

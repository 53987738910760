import React, { Fragment, useEffect, useState } from 'react'
import ActivityCard from '../../components/Common/ActivityCard'
import OwensHeader from "../../components/Common/OwensHeader"
import { useLocation, useParams } from "react-router-dom";
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import { API_FETCH_COMPLETE, API_FETCH_START } from "../../constants/commonFunc";
import InfiniteScroll from "react-infinite-scroll-component";

const ActivityHistory = () => {

    const location = useLocation()

    const PAGE_LIMIT = 10
    const { collectionId } = useParams()
    const { getCollectionActivityById } = COLLECTION_HELPER()
    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState("")

    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (data.length === 0) return 1
        return data
            ? Math.ceil(data.length / PAGE_LIMIT) + 1
            : 1;
    }


    const getActivityFullHistory = async (reloadSearch = true) => {
        setLoading(API_FETCH_START)
        const apiPage = getPageNo(reloadSearch)
        const apiRes = await getCollectionActivityById(collectionId, apiPage, PAGE_LIMIT)
        if (apiRes?.status === 200) {
            let mergedFinalResult = []
            const apiData = apiRes?.data?.data
            const apiTransactionList = apiData?.activity || []
            mergedFinalResult = [...data, ...apiTransactionList];
            setData(mergedFinalResult);
            setTotalCount(apiData?.count)
        }
        setLoading(API_FETCH_COMPLETE)

    }

    const fetchMoreItems = () => {
        if (data.length < totalCount) {
            getActivityFullHistory(false);
        }
    }

    useEffect(() => {
        getActivityFullHistory()
    }, [])


    return (
        <Fragment>
            <section className='lightMode'>
                <OwensHeader />
                <div className='pi-15 min-vh-100'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-40'>Activities</h1>
                    <div className='main-white-card mt-20'>
                        <InfiniteScroll
                            next={fetchMoreItems}
                            hasMore={data.length < totalCount}
                            dataLength={data.length}
                            loader={false}>
                            <ActivityCard data={data} loading={loading} />
                        </InfiniteScroll>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ActivityHistory

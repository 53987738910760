import React, { Fragment, useEffect, useState } from 'react'
import { CheckedId, KYCIDIcon, UncheckId } from '../../helpers/svgConst'
import PrimaryButton from '../Login/PrimaryButton'
import UploadAadharCard from "./UploadAadharCard";
import UploadPanCard from "./uploadPanCard";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import KYCHelper from "../../helpers/apis/KYCHelper";
import USER_HELPER from "../../helpers/apis/userHelper";
import { useNavigate } from "react-router-dom";
import { changeURLLastString } from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import GlanceLoader from "../../helpers/Loader";

const KYCIDProof = () => {

    const navigate = useNavigate()
    const { GetKYCFullDetails } = KYCHelper()
    const { isUserLogin } = USER_HELPER()
    const isAadharVerified = useSelector((state) => state?.KYC?.kycInfo?.aadhaarVerified)
    const isPanVerified = useSelector((state) => state?.KYC?.kycInfo?.panVerified)
    const [activeTab, setActiveTab] = useState(isAadharVerified ? 2 : 1)

    const handleChangeTab = (value) => {
        if (value === 1 && isAadharVerified) return
        if (value === 2 && isPanVerified) return
        setActiveTab(value)
    }

    const dispatch = useDispatch()

    const handleChangeStep = () => {
        navigate(changeURLLastString(Paths.KYCVerificationPage, 3))
    }

    useEffect(() => {
        if (isUserLogin) {
            GetKYCFullDetails()
        }
    }, [isUserLogin])

    console.log("kyc page render")

    return (
        <Fragment>
            <div className='pi-15'>
                <div className='d-flex align-items-center gap-10 mt-25'>
                    <KYCIDIcon />
                    <h1 className='font16px lightmode-text-blue'>ID Proof</h1>
                </div>
                <h6 className='font12px mt-10 mb-10'>Enter your personal details. Keep it same, as mentioned on your
                    government-issued documents. </h6>
                <p className='font12px mb-15'>Choose your valid Government-issued document.</p>

                <button
                    className={`btn lightmode-text-marigold mr-2 kycTabBtn ${(activeTab === 1 || isAadharVerified) ? "active  lightmode-text-marigold" : ""}`}
                    onClick={() => handleChangeTab(1)}>
                    {isAadharVerified ? <CheckedId color="#20A03C" /> : <UncheckId />}
                    Aadhar Card
                </button>
                <button
                    className={`btn lightmode-text-midnight-gray mx-2 kycTabBtn ${(isPanVerified || activeTab === 2) ? "active  lightmode-text-marigold" : ""}`}
                    onClick={() => handleChangeTab(2)}>
                    {isPanVerified ? <CheckedId color="#20A03C" /> : <UncheckId />}

                    Pan Card
                </button>


                {activeTab === 1 && !isAadharVerified &&
                    <UploadAadharCard activeTab={1} />
                }
                {activeTab === 2 && !isPanVerified &&
                    <UploadPanCard activeTab={2} />
                }


            </div>


            <div className='main-white-card br-0 mt-auto'>
                <PrimaryButton heading="Next - Live Selfie"
                    onClick={handleChangeStep}
                    disabled={(!isAadharVerified || !isPanVerified)} />
            </div>
        </Fragment>
    )
}

export default KYCIDProof

// Root reducer containing all the redux state file 
import { combineReducers } from 'redux'
import userReducer from "./userReducer";
import cardDetailReducer from "./cardDetailReducer";
import marketplaceReducer from "./marketplaceReducer";
import profileReducer from "./profileReducer";
import commonReducer from "./commonReducer";
import collectionDetailReducer from "./collectionReducer";
import kycReducer from "./kycReducer";
import withdrawReducer from './withdrawReducer';

//If you want to add any new state just add inside the rootReducer variable

const rootReducer = combineReducers({
    user: userReducer,
    cardDetail: cardDetailReducer,
    collectionDetail: collectionDetailReducer,
    marketplace: marketplaceReducer,
    profile: profileReducer,
    common: commonReducer,
    KYC: kycReducer,
    withdraw: withdrawReducer
})

export default rootReducer

import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { API_BASE_URL, OPERATOR_ID } from '../../../../constants/envConstants';
import Loader from "react-loader-spinner"

import TableDataGrid from '../DataGrid/DataGrid';
import { GET_COLLECTION_ACTIVITY_API, MY_COLLECTION_API } from '../../../helper/apiPaths';
import { getFormattedDate, getOneMonthBackDate } from '../../../../constants/commonFunc';

const DayWiseFeeCollection = () => {

    const [loading, setLoading] = useState(false)
    const [collectionloading, setCollectionLoading] = useState(false)
    const [collectionList, setCollectionList] = useState([])
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const [collectionId, setCollectionId] = useState('')
    const date = new Date()

    const [startDate, setStartDate] = useState(getOneMonthBackDate(date))
    const [endDate, setEndDate] = useState(getFormattedDate(date))

    const tableColumns = [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return (
                    <p>{new Date(params?.formattedValue).toLocaleString().split(',')[0]}</p>
                )
            },
        },
        {
            field: 'collectionDisplayName',
            headerName: 'Collection Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 170,
            flex: 1,
            hide: true,
        },
        {
            field: 'collectionFee',
            headerName: 'Collection Fee',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'marketplaceFee',
            headerName: 'Marketplace Fee',
            minWidth: 170,
            flex: 1,
        },
    ]

    useEffect(() => {
        setCollectionLoading(true)
        axios
            .get(MY_COLLECTION_API + "/" + OPERATOR_ID + "?page=1&limit=100")
            .then((res) => {
                if (res.status === 200) {
                    console.log('collection-list', res.data)
                    setCollectionList(res.data.data.collectionsList)
                }
            })
            .catch((err) => {
                // setMaxResult(0);
                setCollectionList([])
                // addToast('failed to remove' + err?.response?.data?.message, {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
            })
            .finally(() => {
                setCollectionLoading(false)
            })

    }, [])


    const getDayWiseFeeCollection = () => {
        setLoading(true)
        axios
            .get(API_BASE_URL + "reports/day-wise-fee-collection?startDatetime=" + startDate + "&endDatetime=" + endDate + "&collectionId=" + collectionId)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res.data.data.dayWiseCollectionFee
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                marketplaceFee: !!key?.marketplaceFee ? parseFloat(key?.marketplaceFee) : 0,
                                collectionFee: !!key?.collectionFee ? parseFloat(key?.collectionFee) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setTableData([])
                // addToast('failed to remove' + err?.response?.data?.message, {
                //     appearance: 'error',
                //     autoDismiss: true
                // })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getDayWiseFeeCollection()
    }, [])


    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">Daily Fee Collections</h2>
                <div className=" mw-800 mb-5">
                    <label className="headingattribute labelTxt">Collection</label>
                    <select
                        type="select"
                        onChange={(e) => setCollectionId(e.target.value)}
                        placeholder="Select Collection"
                        className="adminInput lightShadow"
                    >
                        <option value="default">Select Collection</option>
                        {collectionList && collectionList.length > 0 && collectionList?.map((collection, index) => (
                            <Fragment key={index}>
                                <option value={collection?.id}>{collection?.collectionName}</option>
                            </Fragment>
                        ))}
                    </select>
                </div>

                <div className=" mw-800 mb-5">
                    <div className="d-flex justify-content-between">
                        <div>
                            <label htmlFor='startDate'>Enter start date</label>
                            <input
                                id="startDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor='endDate'>Enter end date</label>
                            <input
                                id="endDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>

                    </div>

                    <button type="submit"
                        onClick={() => getDayWiseFeeCollection()}
                        className="newBlueBtn btn fs-20 px-5 py-3 mt-4">
                        {loading ?
                            <div className="text-center">
                                <Loader color={"#ffffff"} type="Oval" width={45} height={25} />
                            </div> : "Submit"}
                    </button>


                </div>

                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" />
                </div>
            </div>
        </Fragment>
    )
}

export default DayWiseFeeCollection
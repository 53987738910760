import React, {Fragment, memo} from 'react'
import {CrossIcon} from '../../helpers/svgConst'

const FilterList = ({title, handleClose}) => {
    return (
        <Fragment>
            <div className='filterList' onClick={handleClose}>
                <p className='font12px lightmode-text-purple'>{title}</p>
                <button className='btn p-0'><CrossIcon/></button>
            </div>
        </Fragment>
    )
}

export default memo(FilterList)

// all offer listing when user is owner
import React, { Fragment } from 'react'
import "./TokenDetailStyles.css"
import { DATE_FORMAT, PRICE_FORMAT } from "../../constants/commonFunc";
import { useDispatch, useSelector } from "react-redux";
import { SelectOfferAction } from "../../redux/actions/cardDetailActions";

const OwnerOffers = ({ data }) => {

    const selectedOffer = useSelector((state) => state?.cardDetail?.selectedOffer)
    const dispatch = useDispatch()

    const isSelectedOffer = (offerId) => {
        if (!selectedOffer) return false
        return selectedOffer?.id === offerId
    }

    const handleSelectOffer = (offer) => {
        dispatch(SelectOfferAction(offer))
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center pl-10">
                <p className="font10pxSemiBold lightmode-text-blue mb-15">Price</p>
                <p className="font10pxSemiBold lightmode-text-blue mb-15">From</p>
                <p className="font10pxSemiBold lightmode-text-blue mb-15">Time</p>
                <p className="hidden">Action</p>
            </div>
            {data && data.length > 0 && data?.map((key) => {
                const formatDate = DATE_FORMAT(key?.created)
                const selected = isSelectedOffer(key?.id)
                return (
                    <Fragment key={key?.created}>
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 mt-1 pt-15 pb-15 ${selected ? "selectedBtn" : ""}`}
                            onClick={() => handleSelectOffer(key)}>
                            <p className="font12px ml-15">$ {PRICE_FORMAT(key?.price)}</p>
                            <p className="font12px extendedText">{key?.vaccount}</p>
                            <p className="font12px">
                                {`${formatDate?.day} ${formatDate.monthShort} ${formatDate?.year}`}
                            </p>
                            <button className="btn font12px lightmode-text-marigold p-0 pr-2">
                                Accept
                            </button>
                        </div>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default OwnerOffers

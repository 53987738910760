// File is used for Sorting functionality in Marketplace or Profile Or NFT Page
import React, {Fragment, memo} from "react";
import "../MarketPlace/MarketplaceStyles.css";
import {useDispatch} from "react-redux";
import {ChangeMarketplaceSortingAction, MarketplaceAPICallStart} from "../../redux/actions/marketplaceActions";
import {ChangeProfileSortAction} from "../../redux/actions/profileAction";
import {SORT_OPTIONS_LIST} from "../../constants/commonFunc";

const SortMenu = ({page, sortActionType, className, handleSortMenuClickOutside, activeSortValue, customSortOptions = []}) => {

    const dispatch = useDispatch();

    const handleChangeSort = (e, customSortText = "") => {
        const changeValue = e.target.value
        if (page === "profile") {
            dispatch(ChangeProfileSortAction(sortActionType, changeValue))
        } else {
            dispatch(ChangeMarketplaceSortingAction(changeValue, customSortText));
            dispatch(MarketplaceAPICallStart(true))
        }
        handleSortMenuClickOutside()
    };


    return (
        <Fragment>
            <div className={`SortMenuBlock ${className}`}>
                {SORT_OPTIONS_LIST.length > 0 &&
                SORT_OPTIONS_LIST.map((sort, index) => {
                    return (
                        <Fragment key={index}>
                            <div className="mt-15 checkbox-box">
                                <input
                                    checked={sort?.value === activeSortValue}
                                    onChange={(e) => handleChangeSort(e)}
                                    className="styled-checkbox"
                                    id={`sortValue${sort?.value}`}
                                    type="checkbox"
                                    value={sort?.value}
                                />
                                <label htmlFor={`sortValue${sort?.value}`} className="font14px text-capitalize">
                                    {sort?.name}
                                </label>
                            </div>
                        </Fragment>
                    );
                })}

                {Array.isArray(customSortOptions) && customSortOptions.length > 0 &&
                customSortOptions.map((sort, index) => {
                    return (
                        <Fragment key={index}>
                            <div className="mt-15 checkbox-box ">
                                <input
                                    checked={"42" + sort === activeSortValue}
                                    onChange={(e) => handleChangeSort(e, sort)}
                                    className="styled-checkbox"
                                    id={`sortValue${sort}42`}
                                    type="checkbox"
                                    value={`42` + sort}
                                />
                                <label htmlFor={`sortValue${sort}42`} className="font14px text-capitalize">
                                    {sort} : high to low
                                </label>
                            </div>
                            <div className="mt-15 checkbox-box">
                                <input
                                    checked={"41" + sort === activeSortValue}
                                    onChange={(e) => handleChangeSort(e, sort)}
                                    className="styled-checkbox"
                                    id={`sortValue${sort}41`}
                                    type="checkbox"
                                    value={`41` + sort}
                                />
                                <label htmlFor={`sortValue${sort}41`} className="font14px text-capitalize">
                                    {sort} : low to high
                                </label>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default memo(SortMenu);

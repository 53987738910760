import React, { Fragment } from 'react'
import { VerifiedIcon } from '../../helpers/svgConst'
import { CheckWAXImage, PRICE_FORMAT } from "../../constants/commonFunc";
import GlanceImage from '../Common/GlanceImage';


const PurchasedCard = ({ data }) => {
    return (
        <Fragment>
            <div className='white-card mt-35'>
                <div className='inside-white-card p-0 '>
                    <div className='confirmedImageBlock'>
                        <GlanceImage src={CheckWAXImage(data?.image)} alt="thumb" />
                    </div>
                    <div className='purchase-content-box'>
                        <p className='font12pxMedium mt-10 nftNameOverflow'>{data?.name}</p>
                        <div className='d-flex gap-10 mt-1 align-items-center justify-content-between'>
                            <div className='d-flex gap-10 mt-1 align-items-center'>
                                <VerifiedIcon width="11" height="11" />
                                <p className='font10px lightmode-text-light-gray'>#{data?.mintNumber}</p>
                            </div>
                            {!!data?.listedPrice &&
                                <p className='font12px lightmode-text-blue'>${PRICE_FORMAT(data?.listedPrice)}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PurchasedCard

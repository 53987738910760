import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';



const TableDataGrid = ({ columns, rows, loading, customId, handlePageChange, activePage, count }) => {

    const [pageSize, setPageSize] = React.useState(10);

    function CustomToolbar() {
        return (
            <GridToolbarContainer className='justify-content-end'>
                <GridToolbarColumnsButton className="newBlueBtn px-4 py-2 mt-3 mr-3 mb-3" />
                <GridToolbarFilterButton className="newBlueBtn px-4 py-2 mt-3 mr-3 mb-3" />
                <GridToolbarDensitySelector className="newBlueBtn px-4 py-2 mt-3 mr-3 mb-3" />
                <GridToolbarExport printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                }} className="newBlueBtn px-4 py-2 mt-3 mr-3 mb-3" />
            </GridToolbarContainer>
        );
    }


    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                className='dataGridHeight'
                getRowId={(row) => customId ? row[customId] : row.id}
                loading={loading}
                columns={columns}
                rows={rows}
                paginationMode="server"
                rowCount={count}
                page={activePage}
                pageSize={pageSize}
                rowHeight={75}
                height={"100% !important"}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={handlePageChange}
                pagination
                components={{
                    Toolbar: CustomToolbar,
                }}

            />
        </Box>
    );
}
export default TableDataGrid
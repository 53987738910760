import React, { Fragment } from 'react'

const AnalyticsCard = ({ heading, count, image }) => {
    return (
        <Fragment>
            <div className='analyticsCard lightShadow'>
                <div className='analyticsCardInsideGrid'>
                    <div>
                        <p className='text-blue fs-16 nowrap fw-500 mb-1'>{heading}</p>
                        <p className='fs-30 fw-600 mb-1'>{count}</p>

                    </div>
                    <div className='text-center analyticsCardImageBlock'>
                        <img src={image} alt="graph" />
                    </div>
                </div>

            </div>
        </Fragment >
    )
}

export default AnalyticsCard
import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Paths from "./helpers/Paths";
import Profile from "./pages/ProfilePage/Profile";
import ListYourNFT from "./pages/ListingPage/ListYourNFT";
import Wallet from "./pages/WalletPage/Wallet";
import PaymentHistory from "./pages/WalletPage/PaymentHistory";
import DepositMoney from "./pages/WalletPage/DepositMoney";
import NFTDirectBuySuccess from "./pages/DirectBuyNFT/NFTDirectBuySuccess";
import DirectBuyNFT from "./pages/DirectBuyNFT/DirectBuyNFT";
import WithdrawMoney from "./pages/WithdrawPage/Withdraw";
import WithdrawMoneySucess from "./pages/WithdrawPage/WithdrawMoneySucess";
import VerifyBankAccount from "./pages/WithdrawPage/VerifyBankAccount";
import FinalVerifyOTP from "./pages/WithdrawPage/FinalVerifyOTP";
import BuyAndMakeOffer from "./pages/BuyAndMakeOffer/BuyAndMakeOffer";
import KYCVerification from "./pages/KYCVerificationPage/KYCVerification";
import KYCComplete from "./pages/KYCVerificationPage/KYCComplete";
import PaypalTransactionFlow from "./pages/WalletPage/PaypalDepositMoney";
import UnpackCards from "./pages/UnpackCards/Unpacking";
import WithDrawForm from "./pages/WithdrawPage/withdrawForm";
import LinkOnChainWallet from "./pages/OnchainWallet/LinkOnChainWallet";
import ConnectWallet from "./pages/OnchainWallet/WaxWallet";
import AdminV2 from "./adminV2/pages/adminV2";
import Analytics from "./adminV2/Analytics/pages/Analytics";
import AdminLogin from "./adminV2/pages/AdminLogin";
import CollectionDetails from "./adminV2/Analytics/components/Analytics/CollectionDetails";
import UserTracking from "./adminV2/Analytics/components/Analytics/UserTracking";
import DayWiseFeeCollection from "./adminV2/Analytics/components/Analytics/DayWiseFeeCollection";
import DayWiseMarketplaceStats from "./adminV2/Analytics/components/Analytics/DayWiseMarketplaceStats";
import DailyActiveUsers from "./adminV2/Analytics/components/Analytics/DailyActiveUsers";
import CollectionWiseFeeCollections from "./adminV2/Analytics/components/Analytics/CollectionWiseFeeCollections";
import DayWisePayInPayOut from "./adminV2/Analytics/components/Analytics/DayWisePayIn-PayOut";
import DayWiseUserRegistrations from "./adminV2/Analytics/components/Analytics/DayWiseUserRegistrations";
import CollectionWiseStats from "./adminV2/Analytics/components/Analytics/CollectionWiseStats";
import DashboardDetails from "./adminV2/Analytics/components/Analytics/DashboardDetails";
import WalletDetails from "./adminV2/Analytics/components/Analytics/WalletDetails";
import LinkWalletSuccessPage from "./components/OnchainComponents/LinkWalletSuccessPage";
import OnChainWithdraw from "./pages/OnchainWallet/OnChainWithdraw";
import UpdateOnChainWalletAddress from "./pages/OnchainWallet/updateOnChainWalletAddress";


// all private routes or logged in user routes defined here

const outes = (props) => {

    return (
        <Fragment>
            <Routes>
                <Route path={Paths.onChainSuccessPage} element={<LinkWalletSuccessPage />} />
                <Route path='/admin/*' element={<AdminV2 />} >
                    {/* <Route path='login' element={<AdminLogin />} /> */}
                    <Route path="collection-details" element={<CollectionDetails />} />
                    <Route path="user-tracking" element={<UserTracking />} />
                    <Route path="day-wise-fee-collection" element={<DayWiseFeeCollection />} />
                    <Route path="day-wise-marketplace-stats" element={<DayWiseMarketplaceStats />} />
                    <Route path="daily-active-users" element={<DailyActiveUsers />} />
                    <Route path="collection-wise-fee" element={<CollectionWiseFeeCollections />} />
                    <Route path="day-wise-payIn-PayOut" element={<DayWisePayInPayOut />} />
                    <Route path="day-wise-user-registration" element={<DayWiseUserRegistrations />} />
                    <Route path="collection-wise-stats" element={<CollectionWiseStats />} />
                    <Route path="dashboard-details" element={<DashboardDetails />} />
                    <Route path="wallet-details" element={<WalletDetails />} />

                    {/* <Route path={Paths.OnChain} element={<Onchainwallet activeUser={props?.ual?.activeUser} showModal={props?.ual?.showModal} logout={props?.ual?.logout}/>} /> */}
                </Route>
                <Route path={Paths.OnChainWithdraw} element={<OnChainWithdraw />} />
                <Route path={Paths.LinkOnChainWallet} element={<LinkOnChainWallet />} />
                <Route path={Paths.UpdateOnChainWalletAddress} element={<UpdateOnChainWalletAddress />} />
                <Route path={Paths.ConnectWallet} element={<ConnectWallet activeUser={props?.ual?.activeUser} showModal={props?.ual?.showModal} logout={props?.ual?.logout} />} />

                <Route path={Paths.analytics} element={<Analytics />} />
                <Route path={Paths.PaypalDeposit} element={<PaypalTransactionFlow />} />
                <Route path={Paths.NFTDirectBuySuccessPage} element={<NFTDirectBuySuccess />} />
                <Route path={Paths.DirectBuyNFTPage} element={<DirectBuyNFT />} />
                <Route path={Paths.ConfirmMakeAnOfferPage} element={<BuyAndMakeOffer />} />
                <Route path={Paths.KYCVerificationPage} element={<KYCVerification />} />
                <Route path={Paths.KYCCompletePage} element={<KYCComplete />} />
                <Route path={Paths.ListingPage} element={<ListYourNFT />} />
                <Route path={Paths.WalletPage} element={<Wallet />} />
                <Route path={Paths.PaymentHistoryPage} element={<PaymentHistory />} />
                {/* <Route path={Paths.DepositMoneyPage} element={<DepositMoney />} /> */}
                <Route path={Paths.DepositMoneyPage} element={<PaypalTransactionFlow />} />
                <Route path={Paths.WithdrawPage} element={<WithdrawMoney />} />
                <Route path={Paths.WithdrawForm} element={<WithDrawForm />} />
                <Route path={Paths.WithdrawMoneySucessPage} element={<WithdrawMoneySucess />} />
                <Route path={Paths.VerifyBankAccount} element={<VerifyBankAccount />} />
                <Route path={Paths.FinalVerifyOTP} element={<FinalVerifyOTP />} />
                <Route path={Paths.ProfilePage} element={<Profile />} />
                <Route path={Paths.UnpackingCards} element={<UnpackCards />} />
            </Routes>
        </Fragment>
    );
}
export default outes;

import React, { Fragment, useEffect, useState } from 'react'
import "../../components/Home/HomeStyles.css"
import { ClipboardIcon, GalleryIcon, LikeIcon, SmileysIcon } from '../../helpers/svgConst'
import PrimaryButton from "../../components/Login/PrimaryButton"
import LightmodeAccordian from "../../components/Common/LightmodeAccordian"
import { useNavigate } from 'react-router-dom';
import OwensHeader from '../../components/Common/OwensHeader';
import SliderCard from '../../components/Home/SliderCard';
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import { API_FETCH_COMPLETE, API_FETCH_START } from "../../constants/commonFunc";
import Paths from "../../helpers/Paths";
import CollectionTemplate from "../../components/Collection/CollectionTemplate";
import NoDataFound from '../../components/Common/NoDataFound'
import Slider from "react-slick";


const HomePage = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [collectionList, setCollectionList] = useState([])

    const { getCollectionList } = COLLECTION_HELPER()

    useEffect(() => {

        const getCollectionResultList = async () => {
            setLoading(API_FETCH_START)
            const res = await getCollectionList();
            if (res?.status === 200) {
                setCollectionList(res?.data?.data?.collectionsList)
            }
            setLoading(API_FETCH_COMPLETE)
        }

        getCollectionResultList()

    }, [])

    var settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '14%',
        className: "home-slider",
    };

    console.log("print")

    return (
        <Fragment>
            <section className='lightMode'>
                <div className='homepageHeroSection' />
                <OwensHeader />

                <Slider {...settings}>
                    {collectionList.length > 0 && collectionList.map((key, index) => {
                        return (
                            <SliderCard data={key} key={index} />
                        )
                    })}
                </Slider>


                <section className='pi-15 pb-30'>
                    <div className='homepageSubHeroSection pt-15 pos-rel-up-stack'>
                        <h2 className='font24pxSemiBold lightmode-text-midnight-blue'>Trade NFTs,</h2>
                        <h2 className='font32pxSemiBold lightmode-text-blue'>Get Rewards</h2>
                        <p className='font14px mt-15'>Drive engagement, build a community or reward loyalty for your brand using coming of age digital assets: NFT.</p>
                        <PrimaryButton heading="Explore trending  collections"
                            primaryClassName="mb-40 mt-25 max-w-300 mx-auto"
                            onClick={() => navigate(Paths.MoreCollectionsPage)} />
                    </div>
                    <LightmodeAccordian heading="How to use NFTs" className="gradientBG ">
                        <div className='mb-20'>
                            <GalleryIcon />
                            <h4 className='mt-15 font14pxSemiBold lightmode-text-blue'>Create digital memorabalia</h4>
                            <p className='font12px mt-1 w-95'>A digital memorabalia allows your greatest creation to live forever. Immutable, unique and rare: your NFT collection packs more than just memories.</p>
                        </div>
                        <div className='mb-20'>
                            <LikeIcon />
                            <h4 className='mt-15 font14pxSemiBold lightmode-text-blue'>Reward loyalty</h4>
                            <p className='font12px mt-1 w-95'>Share a piece of history with your brand loyalist by rewarding them with digital assets that bring real world benefits to them.</p>
                        </div>
                        <div className='mb-20'>
                            <SmileysIcon />
                            <h4 className='mt-15 font14pxSemiBold lightmode-text-blue'>Engage with community</h4>
                            <p className='font12px mt-1 w-95'>Get the conversation going about your brand! Encourage community engagement through exciting events, game play and interaction with your loyalists.</p>
                        </div>
                        <PrimaryButton heading="Learn more" icon={<ClipboardIcon className="mr-2" />}
                            primaryClassName="font12px w-fit mx-auto p-15 pl-35 pr-35" />
                    </LightmodeAccordian>
                    <LightmodeAccordian heading="Top Collections" className="mb-30">

                        <div className="moreCollectionGrid">
                            <CollectionTemplate collectionList={collectionList} isLoading={loading} />
                        </div>
                        {(loading === API_FETCH_COMPLETE) && collectionList.length === 0 &&
                            <NoDataFound text="We don't have any Collections." />
                        }
                        {collectionList.length > 0 &&
                            <PrimaryButton heading="View All Collections" icon={<ClipboardIcon className="mr-2" />}
                                primaryClassName="font12px w-fit mx-auto p-15 px-4 mt-30"
                                onClick={() => navigate(Paths.MoreCollectionsPage)} />
                        }
                    </LightmodeAccordian>
                </section>
            </section>
        </Fragment>
    )
}

export default HomePage

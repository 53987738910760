import { INITIATE_LOGIN, REDIRECT_AFTER_LOGIN, SET_METAMASK_ADDRESS, USER_LOGIN_SUCCESS } from "../action-types/userAction.types";

export const LoginSuccessAction = (payload) => {
    return {
        type: USER_LOGIN_SUCCESS,
        payload
    }
}

export const InitiateLogin = (data) => {
    return {
        type: INITIATE_LOGIN,
        data
    }
}

export const RedirectLoginAction = (url) => {
    return {
        type: REDIRECT_AFTER_LOGIN,
        url
    }
}

export const setMetaMaskAddress = (address) => {
    return {
        type: SET_METAMASK_ADDRESS,
        address
    }
}


import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../../../../constants/envConstants';

import TableDataGrid from '../DataGrid/DataGrid';
import Loader from 'react-loader-spinner';
import { getFormattedDate, getOneMonthBackDate } from '../../../../constants/commonFunc';

const CollectionWiseFeeCollections = () => {

    const [loading, setLoading] = useState(false)
    const [maxResult, setMaxResult] = useState(0)
    const [pageLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const date = new Date()

    const [startDate, setStartDate] = useState(getOneMonthBackDate(date))
    const [endDate, setEndDate] = useState(getFormattedDate(date))


    const tableColumns = [
        {
            field: 'collectionDisplayName',
            headerName: 'Collection Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'collectionFeeAmount',
            headerName: 'Collection Fee',
            minWidth: 170,
            flex: 1,
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 160,
            flex: 1,
            hide: true,
        },
        {
            field: 'marketplaceFeeAmount',
            headerName: 'Marketplace Fee',
            minWidth: 190,
            flex: 1,
        },
    ]

    const maxPage = Math.ceil(maxResult / pageLimit)


    const getCollectionWiseFeeDetails = () => {
        setLoading(true)
        axios.get(API_BASE_URL + "reports/collection-wise-fee-collection" + "?startDatetime=" + startDate + "&endDatetime=" + endDate)
            .then((res) => {
                if (res.status === 200) {
                    let apiData = res?.data?.data?.collectionWiseCollectionFee
                    console.log('api data', res)
                    let finalData = apiData.map((key, index) => {
                        return (
                            {
                                ...key, uniqueId: index,
                                collectionFeeAmount: !!key?.collectionFeeAmount ? parseFloat(key?.collectionFeeAmount) : 0,
                                marketplaceFeeAmount: !!key?.marketplaceFeeAmount ? parseFloat(key?.marketplaceFeeAmount) : 0,
                            }
                        )
                    })
                    setTableData(finalData)
                    console.log('finaldata', finalData)
                    setMaxResult(apiData.length)
                }
            })
            .catch((err) => {
                setMaxResult(0);
                setTableData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getCollectionWiseFeeDetails()
    }, [])

    return (
        <Fragment>
            <div className="CreateCollectionWrapper schema contianer">
                <h2 className="CreateNewCollection newBlueColor mb-4">Collection Wise Fee Collection</h2>
                <div className=" mw-800 mb-5">
                    <div className="d-flex justify-content-between">
                        <div>
                            <label htmlFor='startDate'>Enter start date</label>
                            <input
                                id="startDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div>
                            <label htmlFor='endDate'>Enter end date</label>
                            <input
                                id="endDate"
                                type="date"
                                className="adminInput lightShadow"
                                placeholder="DD/MM/YY"
                                pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                                value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>

                    </div>

                    <button type="submit"
                        onClick={() => getCollectionWiseFeeDetails()}
                        className="newBlueBtn btn fs-20 px-5 py-3 mt-4">
                        {loading ?
                            <div className="text-center">
                                <Loader color={"#ffffff"} type="Oval" width={45} height={25} />
                            </div> : "Submit"}
                    </button>

                </div>

                <div className="mt-3 w-100 table-scroll whiteBGForm p-1 data-grid-block">
                    <TableDataGrid loading={loading} columns={tableColumns} rows={tableData} customId="uniqueId" />
                </div>
            </div>
        </Fragment>
    )
}

export default CollectionWiseFeeCollections
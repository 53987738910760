import React, { useState, useEffect, Fragment } from 'react'
import OwensHeader from '../../components/Common/OwensHeader'
import PrimaryButton from '../../components/Login/PrimaryButton'
import linkImage from '../../images/WalletImage/wallet.png'
import http from "../../helpers/Interceptors"
import { useDispatch, useSelector } from 'react-redux'
import * as Web3 from "web3";
import { DrawerLine } from '../../helpers/svgConst'
import { Divider, FormControlLabel, Radio, RadioGroup, SwipeableDrawer } from '@mui/material'
import { Box } from '@mui/system'
import { toast } from 'react-toastify'
import '../../components/OnchainComponents/onchainwallet.css'
import USER_HELPER from '../../helpers/apis/userHelper'
import { useNavigate } from 'react-router-dom'
import Paths from '../../helpers/Paths'
import { setMetaMaskAddress } from '../../redux/actions/userActions'


const web3 = new Web3(Web3.givenProvider);


const LinkOnChainWallet = ({ type = "DEFAULT" }) => {

  const metaMaskAddress = useSelector((state) => state?.user?.metaMaskAddress)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [blockchainList, setBlockChainList] = useState([])
  const [selectedBlockchain, setSelectedBlockchain] = useState("")

  const { getUserDetails } = USER_HELPER()

  const [openState, setOpenState] = useState({
    bottom: false,
  });


  const signMessageSignature = async (message) => {
    const accountAddress = metaMaskAddress
    return await web3.eth.personal.sign(message, accountAddress).then((ans) => {
      console.log("signature address", ans)
      return ans
    });
  }

  const handleSignMessage = () => {
    if (!selectedBlockchain) {
      toast.error("Please select blockchain")
      return
    }
    // sign message api
    http.get("https://testapi.owens.market/blockchain/sign-message")
      .then((res) => {
        if (res?.status === 200) {
          const message = res?.data?.data?.message;
          console.log("message", message)
          handleVerifySignature(message)
        }
      })
  }



  const handleVerifySignature = async (message) => {
    // sign the message transaction for metamask wallet
    const signature = await signMessageSignature(message)
    const formData = {
      "signature": signature,
      "onChainAddress": metaMaskAddress,
      "blockchain": selectedBlockchain // 
    }
    console.log("formdata", formData)

    http.post("https://testapi.owens.market/blockchain/verify-signature", formData)
      .then((res) => {
        // const res =  getUserDetails();
        navigate(Paths.onChainSuccessPage)
      }).catch((err) => {
        console.log("failed", err)
        toast.error("Failed to update address " + err?.response?.data?.message)
      })

  }

  // link your onchain wallet api
  const getBlockChainList = () => {
    http.get("https://testapi.owens.market/blockchain/blockhains/1?page=1&limit=50")
      .then((res) => {
        if (res?.status === 200) {
          console.log("blockchain res", res?.data)
          setBlockChainList(res?.data?.data)
        }
      })
  }


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenState({ ...openState, [anchor]: open });
  };

  const connectToMetaMask = async () => {
    try {
      const { ethereum } = window;
      const { chainId } = ethereum;

      let metaMaskAddress = "0x1";

      if (chainId === metaMaskAddress) { // rinkeyby or mainnet
        if (!!ethereum) {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });

          dispatch(setMetaMaskAddress(accounts[0]));
          localStorage.setItem('metaMask', accounts[0])
          // const userInfo = await getUserDetails()
          // const onChainAddress = userInfo?.data?.data?.onChainAddress
          // // redirecting to link your onchain wallet page when user wallet is not linked 
          // if (!onChainAddress || onChainAddress.length === 0) {
          //   navigate(Paths.LinkOnChainWallet)
          // }
        }
      } else {
        alert(`Please select mainnet then connect`);
      }
    } catch (e) {
      console.log("something went wrong ", e);
    }
  };

  const handleUpdateOnChainAddress = () => {
    console.log("trigger")
    // first make default user logout 
    localStorage.removeItem("metaMask")
    dispatch(setMetaMaskAddress(""))

    // then connect with new address
    connectToMetaMask()
  }



  const ChooseBlockchain = (anchor, templateId, amount, price) => {
    return (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        className="pos-rel mb-25"
      >

        <div className='text-center'>
          <DrawerLine className="drawerLine" />
        </div>
        <div className='p-15'>
          <h1 className='font16pxSemiBold lightmode-text-blue mt-2 mb-2'>Choose Blockchain</h1>
          <Divider />
          <div className='mt-4'>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {blockchainList.map((key, index) => {
                return (
                  <Fragment key={index}>
                    <div className="" onClick={() => setSelectedBlockchain(key?.id)}>
                      <FormControlLabel value={key?.name} control={<Radio />} label={key?.name} className="font18px soraFont my-0" />
                    </div>
                  </Fragment>
                )
              })}
            </RadioGroup>

            <PrimaryButton primaryClassName='mt-4' heading="Confirm" onClick={() => { setOpenState({ ['bottom']: false });; handleSignMessage() }} />

          </div>
        </div>
      </Box>
    )
  }

  useEffect(() => {
    getBlockChainList()
  }, [])


  return (
    <Fragment>
      <OwensHeader />
      <div className="lightMode pi-15 LightGrayBG">
        <div className='linkImage pt-4'>
          <img src={linkImage} alt='connect on chain wallet' />
        </div>
        <h4 className="font20pxBold lightmode-text-blue pt-3 mb-3">{type === "UPDATE" ? "Update" : "Link"} onChain Wallet</h4>

        {type === "UPDATE" &&
          <PrimaryButton heading="Update your onChain Wallet"
            onClick={() => { setOpenState({ ['bottom']: true }); handleUpdateOnChainAddress() }} />
        }
        {type === "DEFAULT" &&
          <PrimaryButton heading="Link your onChain Wallet" onClick={toggleDrawer("bottom", true)} />
        }

        <SwipeableDrawer
          anchor={"bottom"}
          open={openState["bottom"]}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
          className="swipableDrawerBlock"

        >
          {ChooseBlockchain("bottom")}
        </SwipeableDrawer>
      </div>
    </Fragment>
  )
}

export default LinkOnChainWallet
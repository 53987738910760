import React, { Fragment, useEffect, useState } from 'react'
import TransactionCardDetail from '../../components/Collection/TransactionCardDetail'
import CollectionDetails from '../../components/Common/CollectionDetails'
import OwensHeader from "../../components/Common/OwensHeader"
import COLLECTION_HELPER from "../../helpers/apis/collectionHelper";
import { useLocation, useParams } from "react-router-dom";
import { API_FETCH_COMPLETE, API_FETCH_START } from "../../constants/commonFunc";
import InfiniteScroll from "react-infinite-scroll-component";

const TransactionHistory = () => {

    const location = useLocation()
    const { collectionId } = useParams()

    const PAGE_LIMIT = 10
    const { getCollectionTransactionsById, getCollectionFullInfoById } = COLLECTION_HELPER()
    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState("")
    const [collectionInfo, setCollectionInfo] = useState({})


    const getPageNo = (reloadSearch = false) => {
        if (reloadSearch) return 1
        if (data.length === 0) return 1
        return data
            ? Math.ceil(data.length / PAGE_LIMIT) + 1
            : 1;
    }


    const getTransactionFullHistory = async (reloadSearch = true) => {
        setLoading(API_FETCH_START)
        const apiPage = getPageNo(reloadSearch)
        const apiRes = await getCollectionTransactionsById(collectionId, apiPage, PAGE_LIMIT)
        if (apiRes?.status === 200) {
            let mergedFinalResult = []
            const apiData = apiRes?.data?.data
            const apiTransactionList = apiData?.soldCards || []
            mergedFinalResult = [...data, ...apiTransactionList];
            setData(mergedFinalResult);
            setTotalCount(apiData?.count)
        }
        setLoading(API_FETCH_COMPLETE)

    }

    const fetchMoreItems = () => {
        if (data.length < totalCount) {
            getTransactionFullHistory(false);
        }
    }

    useEffect(() => {
        getTransactionFullHistory()
    }, [])

    useEffect(() => {
        if (!!collectionId) {
            async function getCollectionFullInfo() {
                const res = await getCollectionFullInfoById(collectionId);
                if (res?.status === 200) {
                    setCollectionInfo(res?.data?.data?.collectionData);
                }
            }
            getCollectionFullInfo();
        }
    }, [collectionId]);

    return (
        <Fragment>
            <section className='lightMode'>
                <OwensHeader />
                <div className='pi-15 min-vh-100'>
                    <h1 className='font16pxSemiBold lightmode-text-blue mt-40'>Transaction History</h1>
                    <h3 className='font10px lightmode-text-gray mt-2'>{location?.state?.name}</h3>
                    <CollectionDetails className="noBorder mt-20" data={collectionInfo} />
                    <div className='main-white-card'>
                        <InfiniteScroll
                            next={fetchMoreItems}
                            hasMore={data.length < totalCount}
                            dataLength={data.length}
                            loader={false}>
                            <TransactionCardDetail data={data} loading={loading} />
                        </InfiniteScroll>


                    </div>
                </div>
            </section>

        </Fragment>
    )
}

export default TransactionHistory

// All the collection related common functions written here
import http from "../../helpers/Interceptors";
import {
    GET_ALL_FAVOURITE_COLLECTION_API,
    GET_COLLECTION_ACTIVITY_API,
    GET_COLLECTION_FULL_INFO_API,
    GET_COLLECTION_LIST_API,
    MARKETPLACE_CARDS_API,
    MARKETPLACE_COLLECTION_TRANSACTIONS_API,
} from "../ApisPath";
import { changeURLLastString } from "../../constants/commonFunc";
import { useDispatch, useSelector } from "react-redux";
import { SetAllWishlistedCollection } from "../../redux/actions/cardDetailActions";

export const COLLECTION_HELPER = () => {

    const dispatch = useDispatch()
    const favoriteIds = useSelector((state) => state?.cardDetail?.wishlistedCollection?.getFavoriteListIds)
    const WISHLISTED_COLLECTION_IDS = favoriteIds || []

    // It will return all the favourited collection list of logined user
    const getMyFavouriteCollection = () => {
        return http
            .get(GET_ALL_FAVOURITE_COLLECTION_API)
            .then((res) => {
                const apiData = res?.data?.data
                console.log("All collec", apiData)
                dispatch(SetAllWishlistedCollection(apiData))
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    // It will return all the collection list based on page and limit
    const getCollectionList = (pageNo = 1, limit = 10) => {
        const queryParams = `?page=${pageNo}&limit=${limit}`;
        return http
            .get(GET_COLLECTION_LIST_API + queryParams)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    // get the collection info based on collectionId
    const getCollectionFullInfoById = (collectionId) => {
        if (!collectionId) return "";
        const apiPath = changeURLLastString(
            GET_COLLECTION_FULL_INFO_API,
            collectionId
        );
        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    // get the NFT cards listing based on collection Id and page no and limit params
    const getNFTCardsByCollectionId = (collectionName, page = 1, limit = 10, schemaValue = "") => {

        const schemaParam = schemaValue ? "&schemaId=" + schemaValue : ""
        const apiPath =
            MARKETPLACE_CARDS_API +
            "?page=" +
            page +
            "&limit=" +
            limit +
            "&collectionId=" +
            collectionName
            + schemaParam

        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };


    // get the transaction history based on the collection Id
    const getCollectionTransactionsById = (
        collectionId,
        page = 1,
        limit = 10
    ) => {
        if (!collectionId) return "";

        const apiPath =
            MARKETPLACE_COLLECTION_TRANSACTIONS_API +
            "?page=" +
            page +
            "&limit=" +
            limit +
            "&collectionId=" +
            collectionId;
        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    // get the activity history based on the collection Id
    const getCollectionActivityById = (
        collectionId,
        page = 1,
        limit = 10
    ) => {
        if (!collectionId) return "";

        const apiPath =
            changeURLLastString(GET_COLLECTION_ACTIVITY_API, collectionId) +
            "?page=" +
            page +
            "&limit=" +
            limit
        return http
            .get(apiPath)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    };


    const getCollectionTransactionType = (type) => {

        let color = ""
        let textDesc = ""
        let typeText = ""

        if (type === 1) {
            color = "green-clr";
            textDesc = "pay in"
            typeText = "PayIn"
        } else if (type === 2) {
            color = "blue-clr";
            textDesc = "pay out"
            typeText = "PayOut"
        } else if (type === 3) {
            color = "blue-clr";
            textDesc = "listed card for"
            typeText = "ListCard"
        } else if (type === 4) {
            color = "pink-clr";
            textDesc = "cancel listing"
            typeText = "CancelList"
        } else if (type === 5) {
            color = "green-clr";
            textDesc = "added offer for"
            typeText = "AddOffer"
        } else if (type === 6) {
            color = "blue-clr";
            textDesc = "canceled offer for"
            typeText = "CancelOffer"
        } else if (type === 7) {
            color = "green-clr";
            textDesc = "direct buy"
            typeText = "DirectBuy"
        } else if (type === 8) {
            color = "pink-clr";
            textDesc = "accepted offer for"
            typeText = "AcceptOffer"
        } else if (type === 9) {
            color = "blue-clr";
            textDesc = "deposited card"
            typeText = "DepositCard"
        } else if (type === 10) {
            color = "green-clr";
            textDesc = "withdraw card"
            typeText = "WithdrawCard"
        } else if (type === 11) {
            color = "blue-clr";
            textDesc = "gift send"
            typeText = "GiftSend"
        } else if (type === 12) {
            color = "green-clr";
            textDesc = "gift received"
            typeText = "GiftReceived"
        } else if (type === 13) {
            color = "green-clr";
            textDesc = "direct sold"
            typeText = "DirectSold"

        } else if (type === 14) {
            color = "green-clr";
            textDesc = "Burn"
            typeText = "Burn"

        } else if (type === 15) {
            color = "blue-clr";
            textDesc = "Mint"
            typeText = "Mint"

        } else if (type === 16) {
            color = "blue-clr";
            textDesc = "Unpacked Card"
            typeText = "UnpackedCard"

        } else if (type === 17) {
            color = "green-clr";
            textDesc = "Unpacked Received Card"
            typeText = "UnpackedReceivedCard"

        } else if (type === 18) {
            color = "blue-clr";
            textDesc = "OfferExpired"
            typeText = "OfferExpired"

        } else if (type === 19) {
            color = "blue-clr";
            textDesc = "Add Special Offer"
            typeText = "AddSpecialOffer"

        } else if (type === 20) {
            color = "blue-clr";
            textDesc = "Lent"
            typeText = "Lent"

        } else if (type === 21) {
            color = "blue-clr";
            textDesc = "Borrowed"
            typeText = "Borrowed"
        }
        else if (type === 22) {
            color = "blue-clr";
            textDesc = "Returned"
            typeText = "Returned"
        }
        else if (type === 23) {
            color = "blue-clr";
            textDesc = "Settled"
            typeText = "Settled"
        }
        else if (type === 24) {
            color = "blue-clr";
            textDesc = "Accept Counter Offer"
            typeText = "AcceptCounterOffer"
        }

        return { typeText, textDesc, color }
    };

    //     PayIn = 1,
    //   PayOut = 2,
    //   ListCard = 3,
    //   CancelList = 4,
    //   AddOffer = 5,
    //   CancelOffer = 6,
    //   DirectBuy = 7,
    //   AcceptOffer = 8,
    //   DepositCard = 9,
    //   WithdrawCard = 10,
    //   GiftSend = 11,
    //   GiftReceived = 12,
    //   DirectSold = 13,
    //   Burn = 14,
    //   Mint = 15,
    //   UnpackedCard = 16,
    //   UnpackedReceivedCard = 17,
    //   OfferExpired = 18,
    //   AddSpecialOffer = 19,
    //   Lent = 20,
    //   Borrowed = 21,
    //   Returned = 22,
    //   Settled = 23,
    //   AcceptCounterOffer = 24,

    return {
        WISHLISTED_COLLECTION_IDS,
        getCollectionFullInfoById,
        getNFTCardsByCollectionId,
        getCollectionList,
        getCollectionActivityById,
        getMyFavouriteCollection,
        getCollectionTransactionsById,
        getCollectionTransactionType
    };
};

export default COLLECTION_HELPER;

import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OtpInput from "react-otp-input";
import PrimaryButton from '../Login/PrimaryButton';

const FinalOTPVerification = () => {

    const navigate = useNavigate()
    const [otp, setOtp] = useState("");


    return (
        <Fragment>
            <h6 className='font14px mt-1'>To confirm the withdrawal of $ 500/-</h6>
            <h6 className='font14px'>Enter the OTP has been sent to your registered mobile number.</h6>

            <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                className="otp-input-form"
                containerStyle="otp-grid"
                isInputNum
                placeholder="______"
            />
            <div className="d-flex justify-content-center align-items-center mt-15">
                <p className="font14px">Didn't receive it?</p>



                <button className="btn font14px lightmode-text-secondary p-1" type="button">
                    Resend OTP
                </button>
            </div>
            <div className={`primaryBtn-block mt-50`}>
                <PrimaryButton heading="Submit" type="button" />
            </div>
            {/* {errorMessage.status &&
            <p className="form-error">
                {errorMessage?.message}
            </p>
            }
            {successMessage &&
            <p className="form-success">
                {successMessage}
            </p>
            } */}
        </Fragment>
    )
}

export default FinalOTPVerification
import { Fragment, useEffect, useState } from "react"
import { GET_ERROR_TOAST_MSG, HANDLE_ONLY_NUMBER_INPUT, PRICE_FORMAT, SEND_PRICE_FORMAT } from "../../constants/commonFunc";
import USER_HELPER from "../../helpers/apis/userHelper";
import { WITHDRAW_BANK_LIST, WITHDRAW_MONEY_API } from "../../helpers/ApisPath";
import { AmountIcon, ArrowRightIcon, BankIcon, GoBackIcon } from "../../helpers/svgConst";
import http from "../../helpers/Interceptors"
import WITHDRAW_HELPER from "../../helpers/apis/WithdrawHelper";
import { useDispatch, useSelector } from "react-redux";
import { SetActiveBankAccountAction } from "../../redux/actions/withdrawActions";
import { API_BASE_URL } from "../../constants/envConstants";
import { useNavigate } from "react-router-dom";
import Paths from "../../helpers/Paths";
import PrimaryButton from "../../components/Login/PrimaryButton";

const WithDrawForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [inputAmount, setInputAmount] = useState(0);
    const { loginUserInfo, handleGoBack } = USER_HELPER();
    const amountArr = [500, 1000, 1500, 2000]
    const [bankList, setBankList] = useState([])
    const [errorMessage, setErrorMessage] = useState({ status: false, message: "" })
    const activeBankInfo = useSelector((state) => state?.withdraw?.activeBankAccount)
    const [loading, setLoading] = useState(false)

    const { getUserBanks } = WITHDRAW_HELPER()

    const handleChangeInputAmount = (e) => {
        setInputAmount(e.target.value);
    };
    const addMoney = (price) => {
        setInputAmount(price);
    };


    const rupeesSignPos = 55 + (inputAmount ? inputAmount.toString().length * 4 : 1);

    useEffect(() => {
        getUserBanks().then((res) => {
            if (res?.status === 200) {
                setBankList(res?.data?.data)
            }
        })
    }, [])


    const handleWithdrawAmount = () => {
        setErrorMessage({ status: false, message: "" })
        if (inputAmount === 0) {
            setErrorMessage({ status: true, message: "Please enter withdraw amount" })
            return
        }
        if (!activeBankInfo || Object.keys(activeBankInfo).length === 0) {
            setErrorMessage({ status: true, message: "Please select bank for withdraw" })
            return
        }
        setLoading(true)
        setErrorMessage({ status: false, message: "" })
        const formData = {
            "amount": Number(SEND_PRICE_FORMAT(inputAmount)),
            "accountNumber": activeBankInfo?.accountNumber,
            "name": loginUserInfo?.name,
            "ifsc": activeBankInfo?.ifsc,
            "email": loginUserInfo?.email,
            "upiAddress": "",
            "bankDetailId": activeBankInfo?.id
        }

        http.post(WITHDRAW_MONEY_API, formData)
            .then((res) => {
                if (res?.status === 200) {
                    navigate(Paths.WithdrawMoneySucessPage, { state: { price: inputAmount }, replace: true })
                }
            }).catch((err) => {
                const errMsg = GET_ERROR_TOAST_MSG(err)
                setErrorMessage({ status: true, message: "Failed to withdraw " + errMsg })
            }).finally(() => {
                setLoading(false)
            })



    }

    const handleRedirectWithdrawForm = (bankInfo) => {
        dispatch(SetActiveBankAccountAction(bankInfo))
    }


    return (
        <Fragment>
            <section className="lightMode">
                <div className="pi-15 pt-30 pb-20">
                    <button className="btn px-0" onClick={handleGoBack}>
                        <GoBackIcon />
                    </button>

                    <h1 className="font14px mt-35">
                        Withdraw money to your bank account.
                    </h1>
                    <p className="font10px lightmode-text-midnight-gray mt-25">
                        Current Balance
                    </p>
                    <p className="font24px lightmode-text-blue">
                        {loginUserInfo?.balance ? PRICE_FORMAT(loginUserInfo?.balance) : 0}
                    </p>

                    <div className="white-card grayBorder mt-40 p-20">
                        <div className="d-flex align-items-center">
                            <AmountIcon className="mr-2" />
                            <p className="font16px lightmode-text-blue">Enter the amount</p>
                        </div>

                        <div className="w-100 mt-40 mb-40 d-flex align-items-center">
                            <span
                                className="font40px lightmode-text-secondary priceIconDeposit"
                                style={{ right: `${rupeesSignPos}%` }}
                            >
                                $
                            </span>
                            <input
                                type="number"
                                onChange={handleChangeInputAmount}
                                onWheel={(e) => e.target.blur()}
                                pattern="\d*"
                                onKeyDown={(e) => HANDLE_ONLY_NUMBER_INPUT(e)}
                                className=" transparentAmountInput font40px lightmode-text-secondary text-center"
                                value={inputAmount}
                            />
                        </div>

                        <div className="d-flex gap-10">

                            {amountArr.map((amount) => {
                                return (
                                    <Fragment>
                                        <button
                                            className={`btn addAmountBtn ${inputAmount === amount ? "active" : ""}`}
                                            onClick={() => addMoney(amount)}
                                        >
                                            {amount}
                                        </button>
                                    </Fragment>
                                )
                            })}


                        </div>
                    </div>
                    <p className="mt-30 font14px lightmode-text-blue">
                        Select preferred method
                    </p>
                    {bankList && bankList.length > 0 && bankList.map((bank, index) => {
                        return (
                            <Fragment key={index}>
                                <div
                                    className={`main-white-card mt-10 d-flex gap-10 align-items-center ${activeBankInfo?.accountNumber === bank?.accountNumber ? "selectedTab" : ""}`}
                                    onClick={() => handleRedirectWithdrawForm(bank)}>
                                    <BankIcon className="mr-2" />
                                    <div>
                                        <p className="bankAccoubt font16px lightmode-text-blue ">
                                            {bank?.accountNumber}
                                        </p>
                                        <p className="font10px lightmode-text-purple mt-1">{bank?.bankName}</p>
                                    </div>
                                    <ArrowRightIcon className="ml-auto mr-10" />
                                </div>
                            </Fragment>
                        )
                    })}


                    {errorMessage?.status &&
                        <p className="form-error mt-4">
                            {errorMessage?.message}
                        </p>
                    }

                    <PrimaryButton
                        primaryClassName="mt-25"
                        onClick={handleWithdrawAmount}
                        isLoading={loading}
                        heading={"Withdraw"}
                    />


                </div>
            </section>
        </Fragment>
    )
}

export default WithDrawForm
import React, { Fragment, useEffect, useState } from 'react'
import "../../components/Listing/ListingStyles.css"
import NFTListingForm from "../../components/Listing/NFTListingForm";
import { useLocation, useParams } from "react-router-dom";
import { a11yProps, GET_ALL_QUERY_PARAMS, TabPanel } from "../../constants/commonFunc";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import USER_HELPER from '../../helpers/apis/userHelper';
import SubHeader from '../../components/Common/SubHeader';
import { useSelector } from 'react-redux';


const ListYourNFT = () => {

    const params = useParams()
    const CARD_ID = params?.cardId
    const location = useLocation()
    const queryParam = GET_ALL_QUERY_PARAMS(location?.search)
    const type = queryParam?.type
    const [tabValue, setTabValue] = useState(0);
    const { handleGoBack, loginUserInfo } = USER_HELPER()
    const listNFTInfo = useSelector((state) => state?.cardDetail?.listYourNFT)

    useEffect(() => {
        // if list nft card info is not saved in redux redirect to the old page
        if (!listNFTInfo || Object.keys(listNFTInfo).length === 0) {
            handleGoBack(true)
        }
    }, [])

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (
        <Fragment>
            <section className='lightMode'>
                <div className="pi-15">
                    <SubHeader />

                    {type !== "success" &&
                        <Fragment>
                            <div className='tab-bg mt-4'>
                                <Tabs
                                    className='listingTabFlex'
                                    value={tabValue}
                                    variant="scrollable"
                                    onChange={handleChange}
                                    TabIndicatorProps={{ style: { display: "none" } }}
                                >
                                    <Tab
                                        disableRipple
                                        label="Fixed Price"
                                        className="listingTab" {...a11yProps(0)} />
                                    <Tab
                                        disableRipple
                                        label="Set Auction"
                                        className="listingTab" {...a11yProps(1)} />
                                    <Tab
                                        disableRipple
                                        label="Rent NFT"
                                        className="listingTab" {...a11yProps(2)} />
                                </Tabs>
                            </div>
                            <TabPanel value={tabValue} index={0}>
                                {type !== "success" && <NFTListingForm type="FIXED_PRICE" />}
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                {type !== "success" && <NFTListingForm type="AUCTION" />}
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                {type !== "success" && <NFTListingForm type="RENT" />}
                            </TabPanel>
                        </Fragment>
                    }


                </div>
            </section>
        </Fragment>
    )
}
export default ListYourNFT

import React, {Fragment} from 'react'
import {DrawerLine, InfoIcon} from "../../helpers/svgConst";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";


const NFTListingFeeInfo = ({heading, fees}) => {

    const [state, setState] = React.useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const ListingFeeData = (anchor) => {
        return (
            <Box
                sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
                className="pos-rel"
            >
                <List>
                    <div className='text-center'>
                        <DrawerLine className="drawerLine"/>
                    </div>
                    <ListItem disablePadding>

                        <ListItemText>
                            <div className='pi-15 soraFont'>
                                <div className='mt-10 d-flex justify-content-between mb-10'>
                                    <p className='font16pxBold lightmode-text-midnight-blue'>{heading}</p>
                                    <p className='font16pxBold lightmode-text-midnight-blue'>{fees}%</p>
                                </div>
                                <Divider/>
                                <ol className='pl-0 mt-15 mx-3 font12px'>
                                    <li className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Commodo
                                        viverra est libero sit risus eget sed sapien odio.
                                    </li>
                                    <li className='mt-2'>Sed ac, ut purus maecenas. Nibh hac commodo vel, pellentesque
                                        pellentesque ac tristique consectetur odio.
                                    </li>
                                    <li className='mt-2'>Blandit lobortis posuere mauris sit gravida leo nisi. Amet
                                        vitae,
                                        velit mattis volutpat.
                                    </li>
                                    <li className='mt-2'>Velit, nisi, molestie pharetra facilisis sit facilisi velit a
                                        scelerisque.
                                    </li>
                                </ol>
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Box>
        )
    }

    return (
        <Fragment>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='font12px' onClick={toggleDrawer("bottom", true)}>{heading}</p>
                <button className='btn p-0 ml-auto' onClick={toggleDrawer("bottom", true)}>
                    <InfoIcon/>
                </button>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={state["bottom"]}
                    onClose={toggleDrawer("bottom", false)}
                    onOpen={toggleDrawer("bottom", true)}
                    className="swipableDrawerBlock"
                >
                    {ListingFeeData("bottom")}
                </SwipeableDrawer>
                <p className='font12px ml-2'>{fees}%</p>
            </div>
        </Fragment>
    )
}

export default NFTListingFeeInfo

import {
    MARKETPLACE_API_PATH_CHANGE,
    MARKETPLACE_PRICE_CHANGE,
    MARKETPLACE_SCHEMA_CHANGE,
    MARKETPLACE_SCHEMA_OPTIONS_LIST,
    MARKETPLACE_SORT_CHANGE,
    SET_MARKETPLACE_POINTS_RANGE,
    SHOW_MARKETPLACE_FILTERS,
    SHOW_MARKETPLACE_POINTS_RANGE,
    START_MARKETPLACE_API_CALL
} from "../action-types/marketplace.types";

export const ChangeMarketplaceSortingAction = (sortValue, customSortText) => {
    return {
        type: MARKETPLACE_SORT_CHANGE,
        sortValue,
        customSortText
    }
}


export const ChangeMarketplacePricingAction = (price) => {
    return {
        type: MARKETPLACE_PRICE_CHANGE,
        price
    }
}

export const ShowHideMarketplaceFiltersListAction = (isVisible) => {
    return {
        type: SHOW_MARKETPLACE_FILTERS,
        isVisible
    }
}

export const ChangeMarketplaceSchemaAction = (payload) => {
    return {
        type: MARKETPLACE_SCHEMA_CHANGE,
        payload
    }
}

export const ChangeMarketplaceAPIPath = (apiPath) => {
    return {
        type: MARKETPLACE_API_PATH_CHANGE,
        apiPath

    }
}

export const MarketplaceAPICallStart = (apiCallStart) => {
    return {
        type: START_MARKETPLACE_API_CALL,
        apiCallStart
    }
}

export const SetMarketplaceSchemaListAction = (payload) => {
    return {
        type: MARKETPLACE_SCHEMA_OPTIONS_LIST,
        payload
    }
}

export const ShowHidePointsFilter = (isShow) => {
    return {
        type: SHOW_MARKETPLACE_POINTS_RANGE,
        isShow
    }
}

export const SetMarketplacePointsRange = (payload) => {
    return {
        type: SET_MARKETPLACE_POINTS_RANGE,
        payload
    }
}